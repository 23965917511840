import colors from 'assets/theme/base/colors';

const { white, grey } = colors;

// types
type Types = any;

const stepIcon: Types = {
  styleOverrides: {
    root: {
      color: grey[300],
      width: 26,
      height: 26,
      '.MuiStepIcon-text': {
        fill: grey[500],
        fontWeight: 600,
      },

      '&.Mui-active': {
        '.MuiStepIcon-text': {
          fill: white.main,
        },
      },
    },
  },
};

export default stepIcon;
