import { RadioProps } from '@mui/material';

import { Icon } from 'components/Icon';

const radio = {
  defaultProps: {
    disableRipple: true,
    icon: <Icon type="radioUnChecked" />,
    checkedIcon: <Icon type="radioChecked" />,
  },
  styleOverrides: {
    root: ({ ownerState }: { ownerState: RadioProps }) => ({
      padding: 0,
      opacity: 0.7,
      overflow: 'hidden',
      ':hover': {
        opacity: 1,
      },
      '&.Mui-checked': {
        opacity: 1,
        ':hover': {
          '& svg': {
            opacity: 0.7,
          },
        },
      },
      transition: 'all 200ms ease-in-out',
      ...(ownerState.size === 'small' && {
        '& svg': { width: 18, height: 18 },
      }),
      ...(ownerState.size === 'medium' && {
        '& svg': { width: 20, height: 20 },
      }),
    }),
  },
};

export default radio;
