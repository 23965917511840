import { FC } from 'react';

import { Card, Dialog, Stack, Table, TableBody, TableContainer, Typography } from '@mui/material';

import { BaseDialogWindow } from 'components/BaseDialogWindow';
import { PopoverWrapper } from 'components/MenuPopover';
import { Scrollbar } from 'components/ScrollBar';
import {
  CustomTableHeader,
  CustomTablePagination,
  TableNoData,
  TableSkeleton,
  TableTabsFilterPanel,
} from 'components/Table';
import { FiltersMenuContent, ProductsServicesListTableRow, TableTabs, TableTabsProps } from 'pages/Catalog';
import { PRODUCTS_SERVICES_TABLE_HEADERS } from 'pages/Catalog/config';

import { AddServiceDialogWindowForm } from './AddServiceDialogWindowForm';
import { usePLServicesState } from './usePLServices.state';

export const PracticeLibraryServices: FC<TableTabsProps> = ({ handleSetTabValue, tabValue }) => {
  const {
    searchValue,
    onChangeSearchInputValue,
    isOpenDeleteDialogWindow,
    closeDeleteDialogWindow,
    closeFiltersMenu,
    openFilterMenu,
    isFiltersMenuOpen,
    items,
    isLoading,
    total,
    onChangeRowsPerPage,
    rowsPerPage,
    onChangePage,
    page,
    appliedFilters,
    isActionPending,
    closeAddServiceDialog,
    isAddServiceDialogOpen,
    openAddServiceDialog,
    onGetServicesHandler,
    onActivateServiceHandler,
    onDeactivateServiceHandler,
    onClickActionOptionHandler,
    isDeactivateDialogOpen,
    isActivateDialogOpen,
    closeDeactivateDialog,
    closeActivateDialog,
    actionState,
    onApplyFilters,
    isFiltersApplied,
    onDeleteServiceHandler,
  } = usePLServicesState();

  return (
    <Card>
      <TableTabsFilterPanel
        searchInputProps={{
          value: searchValue,
          placeholder: 'Type to search',
          onChange: onChangeSearchInputValue,
        }}
        onOpenFilterMenuHandler={openFilterMenu}
        actionButtonProps={{
          children: 'Add service',
          onClick: openAddServiceDialog,
        }}
        isFiltersApplied={isFiltersApplied}
        tabs={<TableTabs tabValue={tabValue} handleSetTabValue={handleSetTabValue} />}
      />

      <TableContainer sx={{ position: 'relative' }}>
        <Scrollbar>
          <Table size="medium" sx={{ minWidth: 800 }}>
            <CustomTableHeader
              headLabel={PRODUCTS_SERVICES_TABLE_HEADERS}
              sx={{
                bgcolor: 'grey.200',
                th: { p: 1 },
                'th:first-of-type': { pl: '16px !important' },
              }}
            />

            <TableBody>
              {isLoading ? (
                <TableSkeleton cellsAmount={3} />
              ) : (
                items.map(el => (
                  <ProductsServicesListTableRow
                    key={el?.id}
                    onClickActivationOptionHandler={onClickActionOptionHandler}
                    description={el?.description}
                    title={el?.title}
                    id={el?.id}
                    isActive={el?.isActive}
                    isThreeDotsButton
                    library="pl"
                    type="service"
                  />
                ))
              )}
              <TableNoData isNotFound={!isLoading && !items?.length} />
            </TableBody>
          </Table>
        </Scrollbar>
      </TableContainer>

      <CustomTablePagination
        count={total}
        page={page - 1}
        rowsPerPage={rowsPerPage}
        onPageChange={onChangePage}
        onRowsPerPageChange={onChangeRowsPerPage}
      />

      <Dialog
        open={isAddServiceDialogOpen}
        onClose={closeAddServiceDialog}
        PaperProps={{
          sx: { width: 1, maxWidth: 750 },
        }}
      >
        <AddServiceDialogWindowForm
          onCloseDialogHandler={closeAddServiceDialog}
          onGetServicesHandler={onGetServicesHandler}
        />
      </Dialog>

      <BaseDialogWindow
        onClickCancelButtonHandler={closeDeleteDialogWindow}
        open={isOpenDeleteDialogWindow}
        description={
          <Typography textAlign="center" variant="body2">
            Are you sure you want to delete{' '}
            <Typography variant="body2" component="span" fontWeight={600}>
              {actionState.name}?
            </Typography>
          </Typography>
        }
        title="Deleting service"
        isApproveButtonDisabled={isActionPending}
        isApproveButtonLoading={isActionPending}
        approveButtonTitle="Delete service"
        onClickApproveButtonHandler={onDeleteServiceHandler}
      />

      <BaseDialogWindow
        open={isDeactivateDialogOpen}
        onClickCancelButtonHandler={closeDeactivateDialog}
        description={
          <Stack spacing={1}>
            <Typography variant="body2" fontWeight={400}>
              Are you sure you want to inactivate{' '}
              <Typography variant="button" fontWeight={700}>
                {actionState.name}
              </Typography>{' '}
              service?
            </Typography>
          </Stack>
        }
        title="Inactivate service"
        isApproveButtonDisabled={isActionPending}
        isApproveButtonLoading={isActionPending}
        approveButtonTitle="Inactivate service"
        onClickApproveButtonHandler={onDeactivateServiceHandler}
      />

      <BaseDialogWindow
        open={isActivateDialogOpen}
        onClickCancelButtonHandler={closeActivateDialog}
        description={
          <Stack spacing={1}>
            <Typography variant="body2" fontWeight={400}>
              Are you sure you want to activate{' '}
              <Typography variant="button" fontWeight={700}>
                {actionState.name}
              </Typography>{' '}
              service?
            </Typography>
          </Stack>
        }
        title="Activate service"
        isApproveButtonDisabled={isActionPending}
        isApproveButtonLoading={isActionPending}
        approveButtonTitle="Activate service"
        onClickApproveButtonHandler={onActivateServiceHandler}
      />

      <PopoverWrapper variant="filter" open={isFiltersMenuOpen} title="Filter" handleClosePopover={closeFiltersMenu}>
        <FiltersMenuContent appliedFilters={appliedFilters} onApplyFilters={onApplyFilters} />
      </PopoverWrapper>
    </Card>
  );
};
