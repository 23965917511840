import { FC } from 'react';

import { InfoRounded } from '@mui/icons-material';
import { Link, Stack, Typography } from '@mui/material';

type LabelItemProps = {
  title: string;
  isNewVersionAvailable?: boolean;
};

export const LabelItem: FC<LabelItemProps> = ({ isNewVersionAvailable, title }) => {
  return (
    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1} component="p">
      <Typography
        variant="button"
        fontWeight={400}
        sx={{ textDecoration: 'underline !important', ':hover': { opacity: 0.75 } }}
        component={Link}
        href="#"
      >
        {title}
      </Typography>
      {isNewVersionAvailable && (
        <Typography
          display="flex"
          alignItems="center"
          fontWeight={600}
          color="primary.main"
          variant="button"
          component="span"
        >
          <InfoRounded sx={{ mr: '2px', width: 17, height: 17 }} />
          Updated
        </Typography>
      )}
    </Stack>
  );
};
