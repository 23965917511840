import { FC } from 'react';

import { DatePicker } from '@mui/x-date-pickers';

import { MDInput } from 'components/MDInput';

import { BaseDatePickerProps } from './types';

export const BaseDatePicker: FC<BaseDatePickerProps> = ({
  isError,
  value,
  slotProps,
  sx,
  onChange,
  onClickClearButtonHandler,
  onErrorHandler,
  ...rest
}) => {
  return (
    <DatePicker
      {...rest}
      onError={onErrorHandler}
      slots={{ textField: MDInput }}
      slotProps={{
        clearButton: { sx: { p: '6px' } },
        openPickerButton: { sx: { p: '6px', mr: '-5px' } },
        field: { clearable: true, onClear: onClickClearButtonHandler },
        textField: {
          error: false,
          size: 'small',
        },
        ...slotProps,
      }}
      sx={sx}
      value={value}
      onChange={onChange}
    />
  );
};
