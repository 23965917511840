import { startTransition, useCallback, useEffect, useState } from 'react';

import { useAppStore } from 'store';
import { shallow } from 'zustand/shallow';

import { MM_DD_YYYY_DATE_FORMAT } from 'constants/dateFormats';
import { useRouter } from 'hooks/useRouter';
import { useTabs } from 'hooks/useTabs';
import { calculateAge, dateToCustomFormat } from 'utils/helpers';

import { PATIENT_TABS } from './types';

const tabs = [PATIENT_TABS.PROFILE, PATIENT_TABS.PATIENT_CONSULTATIONS, PATIENT_TABS.PATIENT_PROFILE];

export const usePatientViewState = () => {
  const { params } = useRouter();

  const { setPatient, patient, fetchPatient } = useAppStore(
    store => ({
      patient: store.patient,
      fetchPatient: useCallback(store.fetchPatient, []),
      setPatient: store.setPatient,
    }),
    shallow
  );

  const { person, birthDate } = patient || {};

  const [isLoading, setIsLoading] = useState(true);

  const onGetPatientHandler = async () => {
    !isLoading && setIsLoading(true);
    await fetchPatient(params?.id);
    setIsLoading(false);
  };

  useEffect(() => {
    onGetPatientHandler();

    return () => setPatient(null);
  }, []);

  const { setTabValue, tabValue } = useTabs();

  const fullName = `${person?.firstName} ${person?.lastName}`;
  const fullNameWidthMiddleName = `${person?.firstName} ${person?.middleName} ${person?.lastName}`;

  const formatedBirthDate = dateToCustomFormat(birthDate, MM_DD_YYYY_DATE_FORMAT);
  const age = calculateAge(birthDate);

  const isNoPatient = !isLoading && !patient;

  const handleSetTabValue = (_: React.SyntheticEvent<Element, Event>, newValue: number) =>
    startTransition(() => {
      setTabValue(newValue);
    });

  return {
    isLoading,
    handleSetTabValue,
    tabValue,
    person,
    formatedBirthDate,
    fullName,
    fullNameWidthMiddleName,
    patient,
    tabs,
    age,
    isNoPatient,
    onGetPatientHandler,
  };
};
