import { FC } from 'react';

import { useSearchParams } from 'react-router-dom';

import { GlobalLibrary } from './GlobalLibrary';
import { PracticeLibrary } from './PracticeLibrary';

export const EditTagCategory: FC = () => {
  const [searchParams] = useSearchParams();
  const paramsLibrary = searchParams.get('library');

  return paramsLibrary === 'practice' ? <PracticeLibrary /> : <GlobalLibrary />;
};
