import { FC } from 'react';

import { Box, Stack, Tooltip, Typography, alpha } from '@mui/material';

import { Icon, TIconType } from 'components/Icon';

import { LINKED_CATALOG_ITEM, LINKED_CONTENT_ITEM, LinkedContentStatisticItemProps } from './types';

const ITEM_CONTENT: Record<LINKED_CONTENT_ITEM | LINKED_CATALOG_ITEM, { icon: TIconType; tooltipTitle: string }> = {
  video: { icon: 'linkedContentVideo', tooltipTitle: 'Video' },
  image: {
    icon: 'linkedContentImage',
    tooltipTitle: 'Image',
  },
  before_after: {
    icon: 'linkedContentBeforeAfter',
    tooltipTitle: 'Before and After',
  },
  text: {
    icon: 'linkedContentText',
    tooltipTitle: 'Text',
  },
  page: {
    icon: 'linkedContentPage',
    tooltipTitle: 'Page',
  },
  services: {
    icon: 'linkedBuilding',
    tooltipTitle: 'Services',
  },
  products: {
    icon: 'linkedMedical',
    tooltipTitle: 'Products',
  },
};

export const LinkedContentStatisticItem: FC<LinkedContentStatisticItemProps> = ({ color, amount, type }) => {
  const { icon, tooltipTitle } = ITEM_CONTENT[type];
  return (
    <Tooltip title={tooltipTitle} placement="top">
      <Box
        sx={{
          borderRadius: theme => theme.borders.borderRadius.lg,
          p: 1,
          bgcolor: theme => theme.palette.grey[200],
          ...(!amount && {
            color: theme => theme.palette.grey[400],
          }),
          ...(color &&
            amount && {
              bgcolor: theme => alpha(theme.palette[color].main, 0.05),
              color: theme => theme.palette[color].main,
            }),
        }}
      >
        <Stack direction="row" alignItems="center" spacing={1}>
          <Icon
            sx={{
              color: 'inherit',
              ...(!amount &&
                !color && {
                  svg: {
                    filter: 'invert(0.5)',
                    fill: theme => theme.palette.grey[400],
                    opacity: 0.5,
                  },
                }),
              ...(color &&
                amount && {
                  color: theme => theme.palette[color].main,
                }),
            }}
            type={icon}
          />
          <Typography variant="button" fontWeight={500}>
            {amount}
          </Typography>
        </Stack>
      </Box>
    </Tooltip>
  );
};
