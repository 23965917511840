import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import { CreateBAalbumFormSchema, createBAalbumValidationSchema } from 'pages/BeforeAfter';
import { formErrorHandler } from 'utils/errorHanders';

export const useBAprofileState = () => {
  // TODO refactoring. Make it generic
  const formMethods = useForm<CreateBAalbumFormSchema>({
    resolver: yupResolver(createBAalbumValidationSchema),
    mode: 'onTouched',
    defaultValues: { description: '', name: '' },
  });

  const {
    handleSubmit,
    setError,
    formState: { isDirty, isValid, isSubmitting },
  } = formMethods;

  const onFormSubmitHandler = handleSubmit(() => {
    try {
    } catch (error) {
      formErrorHandler({ error, config: { formError: { setError } } });
    }
  });

  return { formMethods, onFormSubmitHandler };
};
