import { useCallback, useState } from 'react';

import { useBoolean } from './useBoolean';

export type ActionDataInitialState = {
  id: string;
  name: string;
  actionType: ActionType | null;
};

const ActionDataInitialStateValue: ActionDataInitialState = {
  actionType: null,
  id: '',
  name: '',
};

export const useActionDialogManagement = () => {
  const [isActionPending, setIsActionPending] = useState(false);

  const [actionState, setActionState] = useState(ActionDataInitialStateValue);

  const [isActivateDialogOpen, openActivateDialog, closeActivateDialog] = useBoolean();

  const [isDeactivateDialogOpen, openDeactivateDialog, closeDeactivateDialog] = useBoolean();

  const [isOpenDeleteDialogWindow, openDeleteDialogWindow, closeDeleteDialogWindow] = useBoolean();

  const [isOpenCopyDialogWindow, openCopyDialogWindow, closeCopyDialogWindow] = useBoolean();

  const onClickActionOptionHandler = useCallback((actionStateData: ActionDataInitialState) => {
    setActionState(actionStateData);

    switch (actionStateData.actionType) {
      case 'activate':
        return openActivateDialog();
      case 'deactivate':
        return openDeactivateDialog();
      case 'copy':
        return openCopyDialogWindow();
      case 'delete':
        return openDeleteDialogWindow();
      default:
        break;
    }
  }, []);

  return {
    openActivateDialog,
    openDeactivateDialog,
    onClickActionOptionHandler,
    isDeactivateDialogOpen,
    closeDeactivateDialog,
    isActivateDialogOpen,
    closeActivateDialog,
    actionState,
    isActionPending,
    setIsActionPending,
    isOpenDeleteDialogWindow,
    openDeleteDialogWindow,
    closeDeleteDialogWindow,
    isOpenCopyDialogWindow,
    openCopyDialogWindow,
    closeCopyDialogWindow,
  };
};
