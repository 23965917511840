import { FC, memo, useCallback, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Stack, alpha, styled } from '@mui/material';
import { Resolver, useForm } from 'react-hook-form';

import { FormDialogWindowContent } from 'components/BaseDialogWindow';
import { RHFTextField } from 'components/HookForm';
import { Icon } from 'components/Icon';
import MDButton from 'components/MDButton';
import { Scrollbar } from 'components/ScrollBar';
import { ToastType, notice } from 'components/ToastNotification';
import { useUserProfile } from 'hooks/useUserProfile';
import { formErrorHandler } from 'utils/errorHanders';

import { CreateAlbumFormSchema, createAlbumValidationSchema, defaultValues } from './form.config';

const SUPPORTED_FILE_TYPES = ['image/jpg', 'image/jpeg', 'image/png'];

type AddAlbumDialogWindowFormProps = {
  onCloseDialogHandler: VoidFunction;
};

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export const AddAlbumDialogWindowForm: FC<AddAlbumDialogWindowFormProps> = memo(({ onCloseDialogHandler }) => {
  const { isRoleAdmin, practiceProfile } = useUserProfile();

  const {
    register,
    handleSubmit,
    setError,
    formState: { isValid, isSubmitting, errors },
  } = useForm<CreateAlbumFormSchema>({
    resolver: yupResolver(createAlbumValidationSchema) as Resolver<CreateAlbumFormSchema>,
    mode: 'onTouched',
    defaultValues,
  });

  const onFormSubmitHandler = handleSubmit(async formData => {
    const { name, description } = formData;
    try {
      onCloseDialogHandler();
      notice(ToastType.SUCCESS, 'Album has been successfully created!');
    } catch (error) {
      formErrorHandler({ error, config: { formError: { setError } } });
    }
  });

  const isSubmitButtonDisabled = !isValid || isSubmitting;

  const [file, setFile] = useState<File | null>(null);
  const [previewURL, setPreviewURL] = useState('');

  const [isFileValidData, setIsFileValidData] = useState<{ isFileValid: boolean; errorMessage: string }>({
    isFileValid: true,
    errorMessage: '',
  });

  const validateFileHandler = useCallback((file: File) => {
    if (file.size / 1000 ** 2 > 5) {
      setIsFileValidData({ errorMessage: 'The file size should be equal or less than 5 MB', isFileValid: false });
      return;
    }

    if (!SUPPORTED_FILE_TYPES.includes(file.type)) {
      setIsFileValidData({
        errorMessage: 'This file type is not supported. Only PNG, JPEG, JPG',
        isFileValid: false,
      });
      return;
    }
    setIsFileValidData({ errorMessage: '', isFileValid: true });
  }, []);

  const onChangeFileInputHandler = useCallback(async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      validateFileHandler(file);

      setFile(file);
      setPreviewURL(URL.createObjectURL(file));

      event.target.value = '';
    }
  }, []);

  const onClickRemoveButtonHandler = () => {
    setFile(null);
    setPreviewURL('');
  };

  return (
    <FormDialogWindowContent
      onFormSubmitHandler={onFormSubmitHandler}
      headerProps={{ title: 'Add Album', onClickCancelButtonHandler: onCloseDialogHandler }}
      actionProps={{
        approveButtonProps: { disabled: isSubmitButtonDisabled, isLoading: isSubmitting },
        cancelButtonProps: { children: 'Cancel' },
      }}
    >
      <Scrollbar>
        <Stack
          sx={{
            '.MuiStack-root': {
              width: 1,
            },
          }}
          spacing={2}
          mb="1px"
        >
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2.5} alignItems="center">
            <Box
              sx={{
                width: '100px',
                height: '100px',
                flexShrink: 0,
                borderRadius: theme => theme.borders.borderRadius.xl,
                border: theme => `${theme.borders.borderWidth[1]} solid ${theme.palette.grey[300]}`,
                bgcolor: theme => alpha(theme.palette.primary.light, 0.1),
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
                overflow: 'hidden',
              }}
            >
              {previewURL ? (
                <Box
                  component="img"
                  alt="album cover"
                  src={previewURL}
                  width={1}
                  height={1}
                  sx={{ objectFit: 'cover' }}
                />
              ) : (
                <Icon type="galleryIcon" />
              )}
            </Box>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <MDButton component="label" role={undefined} startIcon={<Icon type="uploadIcon" />}>
                Upload cover
                <VisuallyHiddenInput id="uploadInput" type="file" onChange={onChangeFileInputHandler} />
              </MDButton>
              <MDButton variant="outlined" color="dark" disabled={!previewURL} onClick={onClickRemoveButtonHandler}>
                Remove
              </MDButton>
            </Stack>
          </Stack>
          <RHFTextField
            register={register}
            registerName="name"
            registerErrors={errors.name?.message}
            fullWidth
            required
            label="Name"
            placeholder="Enter name"
          />

          <RHFTextField
            register={register}
            registerName="description"
            registerErrors={errors.description?.message}
            fullWidth
            placeholder="Enter description"
            label="Description"
            multiline
            maxRows={8}
            minRows={5}
          />
        </Stack>
      </Scrollbar>
    </FormDialogWindowContent>
  );
});
