import { useBoolean } from 'hooks/useBoolean';
import { usePopover } from 'hooks/usePopover';

export const usePromptLibraryState = () => {
  const {
    handleClosePopover: closeFilterMenu,
    handleOpenPopover: openFilterMenu,
    openPopover: isFilterMenuOpen,
  } = usePopover();

  const [isOpenDeleteDialogWindow, openDeleteDialogWindow, closeDeleteDialogWindow] = useBoolean();

  return {
    closeFilterMenu,
    openFilterMenu,
    isFilterMenuOpen,
    isOpenDeleteDialogWindow,
    openDeleteDialogWindow,
    closeDeleteDialogWindow,
  };
};
