import { CreatePracticePayload } from 'apiServices';
import * as yup from 'yup';

import { Shape } from 'types/RHF-types';

export type CreatePracticeFormSchema = Omit<CreatePracticePayload, 'rag' | 'isActive'> & {
  rag?: string;
};

export const createPracticeValidationSchema = yup
  .object()
  .shape<Shape<CreatePracticePayload>>({
    name: yup.string().required().label('Practice name'),
    nameShort: yup.string().optional().label('Practice short name'),
    rag: yup.string().optional().label('RAG'),
  })
  .required();

export const practiceFormDefaultValues: CreatePracticeFormSchema = {
  name: '',
  nameShort: '',
  rag: '',
};
