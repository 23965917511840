import { useEffect, useState } from 'react';

import { getAIModelVersionByModelId } from 'apiServices/ml/ai-model-version.api';
import { AIModelVersionResponse } from 'apiServices/ml/types';

import { ToastType, notice } from 'components/ToastNotification';

type UseVersionsResult = {
  versions: AIModelVersionResponse[];
  isLoading: boolean;
  error: string | null;
};

export const useVersions = (modelId: string): UseVersionsResult => {
  const [versions, setVersions] = useState<AIModelVersionResponse[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchVersions = async () => {
      setIsLoading(true);
      try {
        const response = await getAIModelVersionByModelId(modelId);
        setVersions(response.data);
      } catch (err: any) {
        const errorMessage = err?.message || 'Something went wrong';
        setError(errorMessage);
        notice(ToastType.ERROR, errorMessage);
      } finally {
        setIsLoading(false);
      }
    };

    if (modelId) {
      fetchVersions();
    }
  }, [modelId]);

  return { versions, isLoading, error };
};
