import { useCallback, useState } from 'react';

import { AutocompleteInputChangeReason } from '@mui/material';
import { getOffices } from 'apiServices';
import { getPractices } from 'apiServices/practice/practice.api';
import { GetOfficesParams, GetPracticesParams, OfficeListItem, PracticeListItem } from 'apiServices/practice/types';

import { backendErrorHandler } from 'utils/errorHanders';

export const useOptions = () => {
  const [practices, setPractice] = useState<PracticeListItem[]>([]);
  const [isPracticeLoading, setIsPracticeLoading] = useState(true);

  const fetchPractices = async (params?: GetPracticesParams) => {
    !isPracticeLoading && setIsPracticeLoading(true);
    try {
      const { data } = await getPractices({ page: 1, size: 100, is_active: true, ...params });
      setPractice(data?.items);
    } catch (error) {
      console.error(error);
      backendErrorHandler({ error, config: { customErrorMessage: 'Failed to get practices, please try again!' } });
    } finally {
      setIsPracticeLoading(false);
    }
  };

  const [practiceSearchValue, setPracticeSearchValue] = useState('');

  const onChangePracticeInputValueHandler = useCallback(
    (_: React.SyntheticEvent<Element, Event>, value: string, reason: AutocompleteInputChangeReason) => {
      if (reason === 'input') setPracticeSearchValue(value);

      if (reason === 'clear') setPracticeSearchValue('');
    },
    []
  );

  const [offices, setOffices] = useState<OfficeListItem[]>([]);
  const [isOfficeLoading, setIsOfficeLoading] = useState(true);

  const fetchOffices = async (params?: GetOfficesParams) => {
    !isPracticeLoading && setIsOfficeLoading(true);
    try {
      const { data } = await getOffices(params);
      setOffices(data?.items);
    } catch (error) {
      console.error(error);
      backendErrorHandler({
        error,
        config: { customErrorMessage: 'Failed to get practice offices, please try again!' },
      });
    } finally {
      setIsOfficeLoading(false);
    }
  };

  return {
    practiceSearchValue,
    onChangePracticeInputValueHandler,
    fetchOffices,
    isOfficeLoading,
    offices,
    practices,
    fetchPractices,
    isPracticeLoading,
  };
};
