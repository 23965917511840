import { AuthSession, fetchAuthSession, setUpTOTP } from 'aws-amplify/auth';

import { awsCognitoErrorHandler } from 'utils/errorHanders';

const APP_NAME = 'Aesthetics360';

export const getAuthSession = async (): Promise<AuthSession> => {
  try {
    return await fetchAuthSession();
  } catch (error) {
    console.error(error);
    awsCognitoErrorHandler({ error, customErrorMessage: 'Failed to get auth session, please try again!' });
  }
};

export const setupTOTPHandler = async (): Promise<URL> => {
  const totpSetupDetails = await setUpTOTP();

  const setupUri = totpSetupDetails.getSetupUri(APP_NAME);

  return setupUri;
};
