import { FC } from 'react';

import { Box, Card, Dialog, Grid, Stack, Table, TableBody, TableContainer, Typography } from '@mui/material';

import { BaseDialogWindow } from 'components/BaseDialogWindow';
import { PopoverWrapper } from 'components/MenuPopover';
import { Scrollbar } from 'components/ScrollBar';
import {
  CustomTableHeader,
  CustomTablePagination,
  TableFiltersPanel,
  TableNoData,
  TableSkeleton,
} from 'components/Table';

import { AddUserDialogWindowForm, FiltersMenuContent, UserTableRow } from './components';
import { USERS_LIST_TABLE_HEAD } from './table.config';
import { useUsersState } from './useUsers.state';

export const UsersList: FC = () => {
  const {
    isLoading,
    onGetUsersHandler,
    onChangeSearchValueHandler,
    searchValue,
    closeFiltersMenu,
    isFiltersMenuOpen,
    openFilterMenu,
    page,
    rowsPerPage,
    onChangeRowsPerPage,
    total,
    onChangePageHandler,
    appliedFilters,
    items,
    onApplyFilters,
    closeDeleteDialogWindow,
    isOpenDeleteDialogWindow,
    onClickDeleteMenuItemHandler,
    selectedItem,
    isDeleting,
    onDeleteUserHandler,
    onClickActionOptionHandler,
    onActivateUserHandler,
    onDeactivateUserHandler,
    closeActivateDialog,
    closeDeactivateDialog,
    actionState,
    isDeactivateDialogOpen,
    isActivateDialogOpen,
    isActionPending,
    practiceProfileId,
    isOpenAddUserDialogWindow,
    openAddUserDialogWindow,
    closeAddUserDialogWindow,
  } = useUsersState();

  return (
    <Box component="section">
      <Grid item xs={12}>
        <Card>
          <TableFiltersPanel
            searchInputProps={{
              value: searchValue,
              placeholder: 'Search by name',
              onChange: onChangeSearchValueHandler,
            }}
            onOpenFilterMenuHandler={openFilterMenu}
            actionButtonProps={{ onClick: openAddUserDialogWindow, children: 'Add user' }}
          />

          <TableContainer>
            <Scrollbar>
              <Table size="medium" sx={{ minWidth: 800 }}>
                <CustomTableHeader
                  headLabel={USERS_LIST_TABLE_HEAD}
                  sx={{
                    bgcolor: 'grey.200',
                    th: { p: 1 },
                    'th:first-of-type': { pl: '16px !important' },
                  }}
                />

                <TableBody>
                  {isLoading ? (
                    <TableSkeleton cellsAmount={4} />
                  ) : (
                    items.map(user => (
                      <UserTableRow
                        key={user?.id}
                        firstName={user?.person?.firstName}
                        lastName={user?.person?.lastName}
                        middleName={user?.person?.middleName}
                        email={user?.person?.contact?.email}
                        userRole={user?.role}
                        createdAt={user?.createdAt}
                        id={user?.id}
                        isActive={user?.isActive}
                        onClickDeleteMenuItemHandler={onClickDeleteMenuItemHandler}
                        onClickActivationOptionHandler={onClickActionOptionHandler}
                        isDotsButton={practiceProfileId !== user?.id}
                      />
                    ))
                  )}
                  <TableNoData isNotFound={!isLoading && !total} />
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>

          <CustomTablePagination
            count={total}
            page={page - 1}
            rowsPerPage={rowsPerPage}
            onPageChange={onChangePageHandler}
            onRowsPerPageChange={onChangeRowsPerPage}
          />
        </Card>
      </Grid>

      <PopoverWrapper
        open={isFiltersMenuOpen}
        title="Filter"
        handleClosePopover={closeFiltersMenu}
        sx={{ width: 1, maxWidth: 290, mt: 1, px: 2, py: 1 }}
        variant="filter"
      >
        <FiltersMenuContent appliedFilters={appliedFilters} onApplyFilters={onApplyFilters} />
      </PopoverWrapper>

      <BaseDialogWindow
        onClickCancelButtonHandler={closeDeleteDialogWindow}
        open={isOpenDeleteDialogWindow}
        description={
          <Typography textAlign="center" variant="body2">
            Are you sure you want to delete{' '}
            <Typography variant="body2" component="span" fontWeight={600}>
              {selectedItem.name}?
            </Typography>
          </Typography>
        }
        title="Deleting user"
        approveButtonTitle="Delete user"
        isApproveButtonDisabled={isDeleting}
        isApproveButtonLoading={isDeleting}
        onClickApproveButtonHandler={onDeleteUserHandler}
      />

      <BaseDialogWindow
        open={isDeactivateDialogOpen}
        onClickCancelButtonHandler={closeDeactivateDialog}
        description={
          <Stack spacing={1}>
            <Typography variant="body2" fontWeight={400}>
              You are about to inactivate{' '}
              <Typography variant="button" fontWeight={700}>
                {actionState.name}
              </Typography>{' '}
              account. Please be aware that the user will no longer have access to the account. This action is
              reversible, you can reactivate the account at any time to restore access.
            </Typography>
          </Stack>
        }
        title="Inactivate user"
        isApproveButtonDisabled={isActionPending}
        isApproveButtonLoading={isActionPending}
        approveButtonTitle="Inactivate user"
        onClickApproveButtonHandler={onDeactivateUserHandler}
      />

      <BaseDialogWindow
        open={isActivateDialogOpen}
        onClickCancelButtonHandler={closeActivateDialog}
        description={
          <Stack spacing={1}>
            <Typography variant="body2" fontWeight={400}>
              You are about to activate{' '}
              <Typography variant="button" fontWeight={700}>
                {actionState.name}
              </Typography>{' '}
              account. Please be aware that the user will regain access to their account.
            </Typography>
          </Stack>
        }
        title="Activate user"
        isApproveButtonDisabled={isActionPending}
        isApproveButtonLoading={isActionPending}
        approveButtonTitle="Activate user"
        onClickApproveButtonHandler={onActivateUserHandler}
      />

      <Dialog
        open={isOpenAddUserDialogWindow}
        onClose={closeAddUserDialogWindow}
        PaperProps={{
          sx: { width: 1, maxWidth: 750 },
        }}
      >
        <AddUserDialogWindowForm
          onCloseDialogHandler={closeAddUserDialogWindow}
          onGetUsersHandler={onGetUsersHandler}
        />
      </Dialog>
    </Box>
  );
};
