import * as yup from 'yup';

import { emailRegExp } from 'constants/regex';

export type SignInFormSchema = Partial<{
  email: string;
  password: string;
}>;

export const firebaseSignInFormValidationSchema: yup.ObjectSchema<SignInFormSchema> = yup
  .object({
    email: yup
      .string()
      .required('Email field is required')
      .matches(emailRegExp, 'Please enter a valid email')
      .label('Email'),
    password: yup.string().required('Password field is required'),
  })
  .required();

export const defaultValues: SignInFormSchema = { email: '', password: '' };
