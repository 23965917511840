import { FC } from 'react';

import { Skeleton, TableCell, TableRow } from '@mui/material';

import { ITableSkeletonProps } from './Table.types';

export const TableSkeleton: FC<ITableSkeletonProps> = props => {
  const { cellsAmount = 3, rowsAmount = 5 } = props;
  return (
    <>
      {[...Array(rowsAmount)].map((_, index) => (
        <TableRow key={index} sx={{ width: 1, ':hover': { cursor: 'initial' } }}>
          {[...Array(cellsAmount)].map((_, index) => (
            <TableCell key={index} sx={{ p: 1.4 }}>
              <Skeleton variant="rounded" sx={{ width: '80%', height: 30 }} />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </>
  );
};
