import { useEffect, useRef } from 'react';

import { AxiosError } from 'axios';
import { apiClient } from 'lib/api';

import { ToastType, notice } from 'components/ToastNotification';
import { useCognitoAuthContext } from 'context';

export const useInterceptor = () => {
  const interceptorId = useRef<number | null>(null);

  const { signOutCognito } = useCognitoAuthContext();

  useEffect(() => {
    interceptorId.current = apiClient.interceptors.response.use(
      response => {
        return response;
      },
      (error: AxiosError) => {
        switch (error?.response?.status) {
          case 401:
            signOutCognito();
            notice(ToastType.WARNING, 'Access denied!');
            return Promise.reject(error);
          case 403:
            // TODO clarify flow. check error, only if practice is not active do sign out ?
            signOutCognito();
            return Promise.reject(error);
          default:
            return Promise.reject(error);
        }
      }
    );
    return () => {
      apiClient.interceptors.response.eject(interceptorId.current);
    };
  }, [interceptorId]);
};
