import { FC, useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Card, CardContent, CardHeader, Grid, Stack } from '@mui/material';
import { addPromptVersion, updatePromptVersion } from 'apiServices/ml/prompt-version.api';
import { PromptVersionPostRequest } from 'apiServices/ml/types';
import { Resolver, useForm } from 'react-hook-form';

import MDButton from 'components/MDButton';
import { MDInput } from 'components/MDInput';
import TextFieldWithDropdown from 'components/TextFieldWithDropdown';
import { useRouter } from 'hooks/useRouter';

import AllFields from '../allFields';
import { PromptVersionValidationSchema, defaultPromptVersionValues } from './form.config';

type PromptVersionFormProps = {
  mode?: 'create' | 'update';
  initialData?: PromptVersionPostRequest;
};

export const AddUpdatePromptVersion: FC<PromptVersionFormProps> = ({ mode = 'create' }) => {
  const { navigate, state, params } = useRouter();
  const initialData = state?.promptVersion || defaultPromptVersionValues;

  const [text, setText] = useState(initialData.text || '');

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
    setValue,
  } = useForm<PromptVersionPostRequest>({
    mode: 'onTouched',
    resolver: yupResolver(PromptVersionValidationSchema) as unknown as Resolver<PromptVersionPostRequest>,
    defaultValues: {
      prompt_id: state?.prompt_id || initialData.prompt_id,
      text: text,
      version: String(initialData.version) || '',
    },
  });

  useEffect(() => {
    if (mode === 'update' && initialData) {
      setValue('text', text);
      setValue('version', String(initialData.version));
      setValue('prompt_id', initialData.prompt_id);
    }
  }, [mode, initialData, setValue, text]);

  const onSubmit = handleSubmit(async (formData: PromptVersionPostRequest) => {
    try {
      if (!state?.prompt_id) {
        console.error('Missing prompt_id');
        return;
      }
      formData.text = text;
      if (mode === 'create') {
        formData.prompt_id = state?.prompt_id;
        await addPromptVersion(formData.prompt_id, formData);
      } else if (mode === 'update' && params?.version_id) {
        await updatePromptVersion(state.promptVersion?.prompt_id, Number(state.promptVersion?.version), formData);
      } else {
        console.error('Missing version_id for update');
        return;
      }
      navigate(-1);
    } catch (error) {
      console.error(`Failed to ${mode === 'create' ? 'create' : 'update'} prompt version:`, error);
    }
  });

  return (
    <Box component="section" height={1}>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} md={8}>
          <Card>
            <CardHeader title={mode === 'create' ? 'Create Prompt' : 'Update Prompt'} />
            <CardContent>
              <Stack spacing={3}>
                <MDInput
                  fullWidth
                  label="Prompt Name"
                  {...register('version')}
                  error={!!errors.version}
                  helperText={errors.version?.message}
                />

                <TextFieldWithDropdown text={text} setText={setText} allFields={AllFields} name={'Text'} />
              </Stack>

              <Stack direction="row" justifyContent="flex-end" spacing={2} style={{ marginTop: '20px' }}>
                <MDButton
                  variant="outlined"
                  onClick={() => navigate(-1)}
                  style={{
                    minWidth: '150px',
                  }}
                >
                  Cancel
                </MDButton>
                <MDButton
                  variant="contained"
                  onClick={onSubmit}
                  disabled={!isValid || isSubmitting}
                  style={{
                    color: 'white',
                    minWidth: '150px',
                  }}
                >
                  {mode === 'create' ? 'Create Prompt' : 'Update Prompt'}
                </MDButton>
              </Stack>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AddUpdatePromptVersion;
