import { FC, memo, useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from '@mui/material';
import { GetPracticesParams, createExpert } from 'apiServices';
import { Resolver, useForm } from 'react-hook-form';

import { FormDialogWindowContent } from 'components/BaseDialogWindow';
import { RHFAutocompleteField, RHFTextField } from 'components/HookForm';
import { Scrollbar } from 'components/ScrollBar';
import { ToastType, notice } from 'components/ToastNotification';
import { useDebounce } from 'hooks/useDebounce';
import { useOptions } from 'hooks/useOptions';
import { usePracticeEnums } from 'hooks/usePracticeEnums';
import { useUserProfile } from 'hooks/useUserProfile';
import { formErrorHandler } from 'utils/errorHanders';

import { CreateUserFormSchema, createUserValidationSchema, defaultValues } from './form.config';

type AddUserDialogWindowFormProps = {
  onCloseDialogHandler: VoidFunction;
  onGetUsersHandler: (data: { params?: GetPracticesParams; isDefaultPage?: boolean }) => Promise<void>;
};

export const AddUserDialogWindowForm: FC<AddUserDialogWindowFormProps> = memo(
  ({ onGetUsersHandler, onCloseDialogHandler }) => {
    const { isRoleAdmin, practiceProfile } = useUserProfile();

    const {
      control,
      register,
      handleSubmit,
      setError,
      formState: { isValid, isSubmitting, errors },
    } = useForm<CreateUserFormSchema>({
      resolver: yupResolver(createUserValidationSchema) as Resolver<CreateUserFormSchema>,
      mode: 'onTouched',
      defaultValues: {
        ...defaultValues,
        ...(!isRoleAdmin && {
          practiceId: practiceProfile?.practice?.id || '',
        }),
      },
    });

    const onFormSubmitHandler = handleSubmit(async formData => {
      const { role, person, practiceId } = formData;
      try {
        await createExpert({
          practiceId,
          role,
          person: {
            firstName: person.firstName,
            lastName: person.lastName,
            ...(person.middleName && {
              middleName: person.middleName,
            }),
            contact: {
              email: person.contact.email,
            },
          },
        });
        onCloseDialogHandler();
        notice(ToastType.SUCCESS, 'User has been successfully created!');

        await onGetUsersHandler({ isDefaultPage: true, params: { page: 1 } });
      } catch (error) {
        formErrorHandler({ error, config: { formError: { setError } } });
      }
    });

    const isSubmitButtonDisabled = !isValid || isSubmitting;

    const { practiceRolesEnum, fetchPracticeRolesEnum, isPracticeRolesEnumLoading } = usePracticeEnums();

    const userRoles = practiceRolesEnum.slice(3);

    const { practiceSearchValue, onChangePracticeInputValueHandler, fetchPractices, practices, isPracticeLoading } =
      useOptions();

    useEffect(() => {
      fetchPracticeRolesEnum();
    }, []);

    const debouncedPracticeSearchValue = useDebounce(practiceSearchValue);

    useEffect(() => {
      fetchPractices({
        ...(debouncedPracticeSearchValue && { search: debouncedPracticeSearchValue }),
      });
    }, [debouncedPracticeSearchValue]);

    return (
      <FormDialogWindowContent
        onFormSubmitHandler={onFormSubmitHandler}
        headerProps={{ title: 'Add User', onClickCancelButtonHandler: onCloseDialogHandler }}
        actionProps={{
          approveButtonProps: { disabled: isSubmitButtonDisabled, isLoading: isSubmitting },
          cancelButtonProps: { children: 'Cancel' },
        }}
      >
        <Scrollbar>
          <Stack spacing={2} width={1} maxHeight={{ xs: 350, sm: 1 }} pb={1}>
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              gap={2}
              sx={{
                '.MuiStack-root': {
                  width: 1,
                },
              }}
            >
              <RHFTextField
                register={register}
                registerName="person.firstName"
                registerErrors={errors.person?.firstName?.message}
                fullWidth
                required
                label="First Name"
                placeholder="Enter first name"
              />

              <RHFTextField
                register={register}
                registerName="person.lastName"
                registerErrors={errors?.person?.lastName?.message}
                fullWidth
                required
                placeholder="Enter last name"
                label="Last name"
              />
            </Stack>

            <Stack
              direction={{
                xs: 'column',
                sm: 'row',
              }}
              gap={2}
              sx={{
                '.MuiStack-root': {
                  width: 1,
                },
              }}
            >
              <RHFTextField
                register={register}
                registerName="person.contact.email"
                registerErrors={errors?.person?.contact?.email?.message}
                fullWidth
                required
                placeholder="Enter email"
                label="Email"
              />

              <RHFAutocompleteField
                control={control}
                name="role"
                isRequired
                label="Role"
                placeholder="Select role"
                options={userRoles}
                getOptionLabel={option => {
                  if (typeof option === 'string') return option;
                  return option.name;
                }}
                isOptionEqualToValue={(option, value) => option?.value === value?.value}
                loading={isPracticeRolesEnumLoading}
              />
            </Stack>

            <Stack direction={{ xs: 'column', sm: 'row' }} gap={2} width={{ xs: 1, sm: 0.5 }} pr={1}>
              {isRoleAdmin && (
                <RHFAutocompleteField
                  control={control}
                  name="practiceId"
                  isRequired
                  placeholder="Select practice"
                  label="Practice"
                  options={practices}
                  onInputChange={onChangePracticeInputValueHandler}
                  getOptionLabel={option => {
                    if (typeof option === 'string') return option;
                    return option.name;
                  }}
                  isOptionEqualToValue={(option, value) => option?.id === value?.id}
                  loading={isPracticeLoading}
                />
              )}
            </Stack>
          </Stack>
        </Scrollbar>
      </FormDialogWindowContent>
    );
  }
);
