import { FC } from 'react';

import { Button, Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

import { ROUTES } from 'constants/routes';

export const AlreadyVerifiedEmail: FC = () => {
  return (
    <Stack spacing={3.5} textAlign={{ xs: 'center', lg: 'start' }}>
      <Stack spacing={2}>
        <Typography variant="h2" fontWeight={600} width={{ lg: 350, md: 'auto' }}>
          Welcome to Aesthetics360
        </Typography>

        <Stack color="text.main">
          <Typography variant="button" fontWeight={400}>
            Your email is already verified!
          </Typography>
          <Typography variant="button" fontWeight={400}>
            You can now log in to your account.
          </Typography>
        </Stack>
      </Stack>
      <Button
        variant="contained"
        sx={{ color: theme => theme.palette.common.white, minHeight: 36 }}
        component={Link}
        to={ROUTES.signIn}
        fullWidth
        size="medium"
      >
        To Login
      </Button>
    </Stack>
  );
};
