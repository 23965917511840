import * as yup from 'yup';

import { emailRegExp } from 'constants/regex';

export type UserProfileSchema = Partial<{
  firstName: string;
  lastName: string;
  email: string;
}>;

export const userProfileValidationSchema: yup.ObjectSchema<UserProfileSchema> = yup
  .object({
    firstName: yup.string().required().label('First name'),
    lastName: yup.string().required().label('Last name'),
    email: yup.string().required().matches(emailRegExp, 'Please enter a valid email').label('Email'),
  })
  .required();
