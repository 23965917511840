import { PASSWORD_REG_EXP } from 'constants/regex';

export type ValidationRule = {
  label: string;
  isValid: boolean;
};

type ValidationData = {
  rules: ValidationRule[];
  isValid: boolean;
};

const getPasswordRules = (value: string, confirmValue: string): ValidationRule[] => [
  {
    label: '8-16 characters in length',
    isValid: value.length >= 8 && value.length <= 16,
  },
  {
    label: 'contains at least 1 uppercase letter',
    isValid: PASSWORD_REG_EXP.upperCase.test(value),
  },
  {
    label: 'contains at least 1 lowercase letter',
    isValid: PASSWORD_REG_EXP.lowerCase.test(value),
  },
  {
    label: 'contains at least 1 number',
    isValid: PASSWORD_REG_EXP.number.test(value),
  },
  {
    label: 'contains at least 1 special character (~!@#$%^&*)',
    isValid: PASSWORD_REG_EXP.specialCharacter.test(value),
  },
  {
    label: 'passwords match',
    isValid: value === confirmValue && value.length > 0,
  },
];

export const setPasswordValidationData = (value: string, confirmValue: string): ValidationData => {
  const rules = getPasswordRules(value, confirmValue);
  return {
    rules,
    isValid: rules.every(rule => rule.isValid),
  };
};

export const changePasswordValidationData = (
  currentPassword: string,
  newPassword: string,
  confirmPassword: string
): ValidationData => {
  const currentPasswordIsValid = getPasswordRules(currentPassword, currentPassword).every(rule => rule.isValid);
  const newPasswordData = setPasswordValidationData(newPassword, confirmPassword);

  return {
    rules: [{ label: 'current password format is valid', isValid: currentPasswordIsValid }, ...newPasswordData.rules],
    isValid: currentPasswordIsValid && newPasswordData.isValid,
  };
};

export const resetPasswordValidationData = (
  code: string,
  newPassword: string,
  confirmPassword: string
): ValidationData => {
  const rules = [
    { label: 'verification code is provided', isValid: code.length === 6 },
    ...getPasswordRules(newPassword, confirmPassword),
  ];
  return {
    rules,
    isValid: rules.every(rule => rule.isValid),
  };
};
