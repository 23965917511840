import { startTransition } from 'react';

import { useTabs } from 'hooks/useTabs';

export const usePromptViewState = () => {
  const { tabValue, setTabValue } = useTabs();

  const handleSetTabValue = (_: React.SyntheticEvent<Element, Event>, newValue: number) =>
    startTransition(() => {
      setTabValue(newValue);
    });

  return { handleSetTabValue, tabValue };
};
