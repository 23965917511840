import { useCallback, useEffect, useState } from 'react';

import { AutocompleteInputChangeReason } from '@mui/material';
import { DictionaryItem } from 'apiServices';

import { useDebounce } from './useDebounce';
import { useDictionary } from './useDictionary';

type UseGeoDataProps = {
  currentStateISOcode: string;
  currentCountryISOcode?: string;
};

export const useGeoData = ({ currentStateISOcode, currentCountryISOcode }: UseGeoDataProps) => {
  const {
    fetchCountries,
    fetchCountryStates,
    setCountryStates,
    setStateCities,
    fetchStateCities,
    countries,
    countryStates,
    stateCities,
    isCountriesLoading,
    isStateCitiesLoading,
    isCountryStatesLoading,
    fetchCountry,
    isCountryLoading,
    country,
    setCountry,
  } = useDictionary();

  const [countryValue, setCountryValue] = useState('');

  const debouncedCountryValue = useDebounce(countryValue, 200);

  useEffect(() => {
    if (debouncedCountryValue)
      fetchCountries({
        page: 1,
        size: 100,
        ...(debouncedCountryValue && { search: debouncedCountryValue }),
      });
  }, [debouncedCountryValue]);

  const onChangeCountryInputValueHandler = useCallback(
    async (_: React.SyntheticEvent<Element, Event>, value: string, reason: AutocompleteInputChangeReason) => {
      if (value.length >= 3 && reason === 'input') {
        setCountryValue(value);
      }

      if (reason === 'clear') {
        countries?.items?.length && setCountryStates([]);
        setStateCities([]);
        setCountryValue('');

        if (country) {
          setCountry(null);
          await fetchCountries({ page: 1, size: 100 });
        }

        countries?.items?.length < 10 && (await fetchCountries({ page: 1, size: 100 }));
      }

      if (reason === 'input' && !value && !countries?.items?.length) {
        fetchCountries({ page: 1, size: 100 });
      }
    },
    [countries?.items, country]
  );

  const [cityValue, setCityValue] = useState('');

  const debouncedCityValue = useDebounce(cityValue, 200);

  useEffect(() => {
    if (currentStateISOcode && debouncedCityValue)
      fetchStateCities(currentStateISOcode, {
        page: 1,
        size: 100,
        ...(debouncedCityValue && { search: debouncedCityValue }),
      });
  }, [debouncedCityValue]);

  const onChangeCityInputValueHandler = useCallback(
    async (_: React.SyntheticEvent<Element, Event>, value: string, reason: AutocompleteInputChangeReason) => {
      if (value.length >= 3 && reason === 'input') {
        setCityValue(value);
      }

      if (reason === 'clear') {
        setCityValue('');
        stateCities?.length < 10 && fetchStateCities(currentStateISOcode, { page: 1, size: 100, search: value });
      }

      if (reason === 'input' && !value && !stateCities.length) {
        fetchStateCities(currentStateISOcode, { page: 1, size: 100 });
      }
    },
    [currentStateISOcode, stateCities.length]
  );

  const onSelectCountryHandler = useCallback(
    async (option: (string | DictionaryItem)[] | NonNullable<string | DictionaryItem>) => {
      if (!option) {
        setCountryStates([]);
        return;
      }
      if (typeof option !== 'string' && 'isoCode' in option) {
        await fetchCountryStates(option.isoCode, { page: 1, size: 100 });
      }
    },
    []
  );

  const onSelectStateHandler = useCallback(
    async (option: (string | DictionaryItem)[] | NonNullable<string | DictionaryItem>) => {
      if (!option) {
        setStateCities([]);
        return;
      }
      if (typeof option !== 'string' && 'isoCode' in option) {
        await fetchStateCities(option.isoCode, { page: 1, size: 100 });
      }
    },
    []
  );

  useEffect(() => {
    if (currentStateISOcode && currentCountryISOcode) fetchStateCities(currentStateISOcode, { page: 1, size: 100 });

    if (currentCountryISOcode) {
      fetchCountry(currentCountryISOcode);
      fetchCountryStates(currentCountryISOcode, { page: 1, size: 100 });
    } else fetchCountries({ page: 1, size: 100 });
  }, []);

  return {
    countries,
    countryStates,
    stateCities,
    isCountriesLoading,
    isStateCitiesLoading,
    isCountryStatesLoading,
    onChangeCountryInputValueHandler,
    onChangeCityInputValueHandler,
    onSelectCountryHandler,
    onSelectStateHandler,
    isCountryLoading,
    country,
  };
};
