import { useCallback, useEffect, useState } from 'react';

type UseSideNavScrollTracker = {
  defaultActiveSectionId: string;
};

export const useSideNavScrollTracker = ({ defaultActiveSectionId }: UseSideNavScrollTracker) => {
  const [activeSectionId, setActiveSectionId] = useState(defaultActiveSectionId);

  const [isScrolling, setIsScrolling] = useState(false);

  const onChangeInViewHandler = (inView: boolean, entry: IntersectionObserverEntry) => {
    const entryId = entry.target.id;

    if (activeSectionId !== entryId && inView) setActiveSectionId(entryId);
  };

  const executeScroll = useCallback((refEntry: IntersectionObserverEntry | null) => {
    if (refEntry && refEntry.target) {
      setIsScrolling(true);
      refEntry.target.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  const handlerScrollEnd = () => {
    if (isScrolling) setIsScrolling(false);
  };

  useEffect(() => {
    window.addEventListener('scrollend', handlerScrollEnd);
    return () => {
      window.removeEventListener('scrollend', handlerScrollEnd);
    };
  }, [isScrolling]);

  return {
    activeSectionId,
    executeScroll,
    onChangeInViewHandler,
    isScrolling,
  };
};
