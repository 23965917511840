import { FC, useState } from 'react';

import { KeyboardArrowDownRounded } from '@mui/icons-material';
import { Chip, ChipProps } from '@mui/material';

import { MenuPopover, PopoverMenuItem } from 'components/MenuPopover';
import { usePopover } from 'hooks/usePopover';

import { ActiveStatusChipSkeleton } from './ActiveStatusChip.skeleton';

export type ActiveStatusChipProps = ChipProps & {
  isActive: boolean;
  onDeactivateHandler: () => Promise<void> | void;
  onActivateHandler: () => Promise<void> | void;
  isLoading: boolean;
};

export const ActiveStatusChip: FC<ActiveStatusChipProps> = ({
  isActive,
  onActivateHandler,
  onDeactivateHandler,
  isLoading,
  sx,
  ...other
}) => {
  const { handleClosePopover, handleOpenPopover, openPopover } = usePopover();

  const onClickMenuItemHandler = async () => {
    handleClosePopover();
    isActive ? await onDeactivateHandler() : await onActivateHandler();
  };

  return (
    <>
      {isLoading ? (
        <ActiveStatusChipSkeleton />
      ) : (
        <Chip
          clickable
          variant="soft"
          onClick={handleOpenPopover}
          color={isActive ? 'primary' : 'default'}
          label={isActive ? 'Active' : 'Deactivated'}
          icon={<KeyboardArrowDownRounded fontSize="medium" />}
          sx={{
            flexDirection: 'row-reverse',
            '.MuiChip-label': {
              pl: 1,
              pr: 0.5,
            },
            '.MuiChip-icon': {
              ml: 0,
              mr: 0.5,
              transition: theme =>
                theme.transitions.create('all', {
                  easing: theme.transitions.easing.easeInOut,
                  duration: theme.transitions.duration.shortest,
                }),
              transform: openPopover ? 'rotate(-180deg)' : 'rotate(0)',
            },
            ...sx,
          }}
          {...other}
        />
      )}

      <MenuPopover open={openPopover} onClose={handleClosePopover}>
        <PopoverMenuItem onClick={onClickMenuItemHandler} title={isActive ? 'Deactivate' : 'Activate'} />
      </MenuPopover>
    </>
  );
};
