import { CreateGlobalLibraryTagCategoryPayload } from 'apiServices';
import * as yup from 'yup';

import { Shape } from 'types/RHF-types';

export type CreateTagCategoryFormSchema = Pick<CreateGlobalLibraryTagCategoryPayload, 'name' | 'serviceName'>;

export const createTagCategoryValidationSchema = yup
  .object()
  .shape<Shape<CreateTagCategoryFormSchema>>({
    name: yup.string().required().label('Tag category name').trim(),
    serviceName: yup.string().optional().label('Tag category service name').nullable().trim(),
  })
  .required();

export const createTagCategoryDefaultValues: CreateTagCategoryFormSchema = {
  name: '',
  serviceName: '',
};
