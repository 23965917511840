import { forwardRef } from 'react';

import { AvatarProps } from '@mui/material';

import MDAvatarRoot, { AvatarOwnerState } from 'components/MDAvatar/MDAvatarRoot';
import { nameAvatar } from 'utils/helpers';

export interface MDAvatarProps extends AvatarProps, AvatarOwnerState {}

export const MDAvatar = forwardRef<HTMLDivElement, MDAvatarProps>(({ avatarSize, children, ...rest }, ref) => {
  const childrenToShow = typeof children === 'string' ? nameAvatar(children) : children;
  return (
    <MDAvatarRoot ref={ref} ownerState={{ avatarSize }} {...rest}>
      {childrenToShow}
    </MDAvatarRoot>
  );
});
