/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// Material Dashboard 2 PRO React TS Base Styles
import colors from 'assets/theme/base/colors';
import typography from 'assets/theme/base/typography';
import pxToRem from 'assets/theme/functions/pxToRem';

const { dark } = colors;
const { size, fontWeightRegular } = typography;

// types
type Types = any;

const formControlLabel: Types = {
  styleOverrides: {
    root: {
      marginLeft: 0,
      marginRight: 0,
      minHeight: pxToRem(24),
      marginBottom: pxToRem(2),
    },

    labelPlacementStart: {
      '.MuiFormControlLabel-label': {
        marginRight: 6,
      },
    },

    labelPlacementEnd: {
      '.MuiFormControlLabel-label': {
        marginLeft: 6,
      },
    },

    label: {
      display: 'inline-block',
      fontSize: size.sm,
      fontWeight: fontWeightRegular,
      color: dark.main,
      lineHeight: 1,
      '&.Mui-disabled': {
        color: dark.main,
      },
    },
  },
};

export default formControlLabel;
