import { FC, memo, useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from '@mui/material';
import { PLServicesSearchParams, createPLService } from 'apiServices';
import { Resolver, useForm } from 'react-hook-form';

import { FormDialogWindowContent } from 'components/BaseDialogWindow';
import { RHFAutocompleteField, RHFTextField } from 'components/HookForm';
import { Scrollbar } from 'components/ScrollBar';
import { ToastType, notice } from 'components/ToastNotification';
import { useDebounce } from 'hooks/useDebounce';
import { useOptions } from 'hooks/useOptions';
import { useUserProfile } from 'hooks/useUserProfile';
import {
  CreateCatalogPLItemFormSchema,
  createCatalogPLItemDefaultValues,
  createCatalogPLItemValidationSchema,
} from 'pages/Catalog/config';
import { formErrorHandler } from 'utils/errorHanders';

type AddServiceDialogWindowFormProps = {
  onCloseDialogHandler: VoidFunction;
  onGetServicesHandler: (params?: PLServicesSearchParams) => Promise<void>;
};

export const AddServiceDialogWindowForm: FC<AddServiceDialogWindowFormProps> = memo(
  ({ onGetServicesHandler, onCloseDialogHandler }) => {
    const { isRoleAdmin, practiceId } = useUserProfile();

    const {
      register,
      handleSubmit,
      setError,
      control,
      formState: { isValid, isSubmitting, errors },
    } = useForm<CreateCatalogPLItemFormSchema>({
      resolver: yupResolver(createCatalogPLItemValidationSchema) as Resolver<CreateCatalogPLItemFormSchema>,
      mode: 'onTouched',
      defaultValues: {
        ...createCatalogPLItemDefaultValues,
        ...(!isRoleAdmin && {
          practiceId: practiceId || '',
        }),
      },
    });

    const onFormSubmitHandler = handleSubmit(async ({ practiceId, title, description }) => {
      try {
        await createPLService({ title, practiceId, ...(description && { description }) });

        onCloseDialogHandler();
        notice(ToastType.SUCCESS, 'Service has been successfully created!');

        await onGetServicesHandler({ page: 1 });
      } catch (error) {
        formErrorHandler({ error, config: { formError: { setError } } });
      }
    });

    const isSubmitButtonDisabled = !isValid || isSubmitting;

    const { practiceSearchValue, onChangePracticeInputValueHandler, fetchPractices, practices, isPracticeLoading } =
      useOptions();

    const debouncedPracticeSearchValue = useDebounce(practiceSearchValue);

    useEffect(() => {
      fetchPractices({
        ...(debouncedPracticeSearchValue && { search: debouncedPracticeSearchValue }),
      });
    }, [debouncedPracticeSearchValue]);

    return (
      <FormDialogWindowContent
        onFormSubmitHandler={onFormSubmitHandler}
        headerProps={{ title: 'Add Service', onClickCancelButtonHandler: onCloseDialogHandler }}
        actionProps={{
          approveButtonProps: { disabled: isSubmitButtonDisabled, isLoading: isSubmitting },
          cancelButtonProps: { children: 'Cancel' },
        }}
      >
        <Scrollbar>
          <Stack spacing={2} width={1} maxHeight={{ xs: 350, sm: 1 }} pb={1}>
            <Stack
              gap={2}
              sx={{
                '.MuiStack-root': {
                  width: 1,
                },
              }}
            >
              <RHFTextField
                register={register}
                registerName="title"
                registerErrors={errors?.title?.message}
                fullWidth
                required
                label="Name"
                placeholder="Enter name"
              />

              <RHFTextField
                register={register}
                registerName="description"
                registerErrors={errors?.description?.message}
                fullWidth
                multiline
                maxRows={8}
                minRows={6}
                placeholder="Enter description"
                label="Description"
              />

              {isRoleAdmin && (
                <RHFAutocompleteField
                  control={control}
                  name="practiceId"
                  isRequired
                  placeholder="Select practice"
                  label="Practice"
                  options={practices}
                  onInputChange={onChangePracticeInputValueHandler}
                  getOptionLabel={option => {
                    if (typeof option === 'string') return option;
                    return option.name;
                  }}
                  isOptionEqualToValue={(option, value) => option?.id === value?.id}
                  loading={isPracticeLoading}
                />
              )}
            </Stack>
          </Stack>
        </Scrollbar>
      </FormDialogWindowContent>
    );
  }
);
