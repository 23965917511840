import { apiClient } from 'lib/api';

import { CreatePLProductPayload, PLProduct, PLProductsSearchParams, UpdatePLProductPayload } from './types';

const BASE_URL = '/practice/products';

export const getPLProducts = async (params: PLProductsSearchParams) =>
  await apiClient.get<ListOfItems<PLProduct>>(BASE_URL, { params });

export const createPLProduct = async (payload: CreatePLProductPayload) =>
  await apiClient.post<PLProduct>(BASE_URL, payload);

export const importToPLProduct = async (payload: ImportToPLPayload) =>
  await apiClient.post<PLProduct>(`${BASE_URL}/import`, payload);

export const getPLProduct = async (id: string) => await apiClient.get<PLProduct>(`${BASE_URL}/${id}`);

export const updatePLProduct = async (id: string, payload: UpdatePLProductPayload) =>
  await apiClient.patch<PLProduct>(`${BASE_URL}/${id}`, payload);

export const deactivatePLProduct = async (id: string) =>
  await apiClient.delete<PLProduct>(`${BASE_URL}/${id}/deactivate`);

export const activatePLProduct = async (id: string) => await apiClient.patch<PLProduct>(`${BASE_URL}/${id}/activate`);

export const deletePLProduct = async (id: string) => await apiClient.delete(`${BASE_URL}/${id}`);
