import { FC } from 'react';

import { Box, FormHelperText } from '@mui/material';
import OtpInput, { OTPInputProps } from 'react-otp-input';

import { MDInput } from 'components/MDInput';

type OTPinputProps = Omit<OTPInputProps, 'renderInput'> & {
  errorMessage?: string;
};

export const OTPinput: FC<OTPinputProps> = ({ errorMessage, ...rest }) => {
  return (
    <Box
      position="relative"
      width={1}
      display="flex"
      flexDirection="column"
      justifyContent={{ xs: 'center', lg: 'start' }}
      sx={theme => ({
        '.MuiFormControl-root': {
          width: 'auto',
        },

        '& > div': {
          justifyContent: 'flex-start',
        },
        [theme.breakpoints.down('lg')]: {
          '& > div': {
            justifyContent: 'center',
          },
        },
      })}
    >
      <OtpInput
        shouldAutoFocus
        numInputs={6}
        renderSeparator={
          <Box component="span" mx={1}>
            -
          </Box>
        }
        renderInput={({ value, ref, onBlur, onChange, onFocus, onPaste, onInput, onKeyDown }, index) => {
          return (
            <MDInput
              fullWidth
              value={value}
              onBlur={onBlur}
              onChange={onChange}
              onFocus={onFocus}
              onPaste={onPaste}
              onInput={onInput}
              onKeyDown={onKeyDown}
              name="otp-input"
              inputProps={{
                ref,
                sx: { fontWeight: 500, fontSize: 26 },
                autoComplete: 'off',
              }}
              InputProps={{
                sx: {
                  width: { xs: 35, sm: 50 },
                  height: { xs: '48px !important', sm: '58px !important' },
                  '.MuiInputBase-input': {
                    p: { xs: '8px', sm: '14px' },
                  },
                },
                autoComplete: 'new-password',
              }}
              sx={{
                '.MuiInputBase-input': {
                  textAlign: 'center',
                },
              }}
              error={!!errorMessage}
            />
          );
        }}
        {...rest}
      />

      <FormHelperText
        sx={{
          color: theme => theme.palette.error.main,
          textAlign: { xs: 'center', lg: 'start' },
        }}
      >
        {errorMessage}
      </FormHelperText>
    </Box>
  );
};
