import { useCallback, useMemo } from 'react';

import { ExpandMoreRounded } from '@mui/icons-material';
import Autocomplete, {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteProps,
  AutocompleteRenderInputParams,
} from '@mui/material/Autocomplete';
import { TextFieldProps } from '@mui/material/TextField';
import { DictionaryItem, MedicalCondition, PatientEnum } from 'apiServices';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';

import { MDInput } from 'components/MDInput';
import { genericMemo, getElementById } from 'utils/helpers';

type RHFAutocompleteInfinityScrollProps = {
  hasNextPage: boolean;
  onLoadMoreItemsHandler: ({ page, size }: { size: number; page: number }) => Promise<{
    items: (DictionaryItem | PatientEnum)[];
    page: number;
    pages: number;
  }>;
};

type RHFAutocompleteFieldProps<
  OptionType extends { id: string } | DictionaryItem | PatientEnum | MedicalCondition,
  TField extends FieldValues
> = {
  control: Control<TField>;
  name: Path<TField>;
  placeholder?: string;
  label?: string;
  onChangeHandler?: (
    option: OptionType | (string | OptionType)[] | NonNullable<string | OptionType>,
    reason: AutocompleteChangeReason,
    details: AutocompleteChangeDetails<OptionType>
  ) => void | Promise<void>;
  isRequired?: boolean;
} & Omit<AutocompleteProps<OptionType, boolean | undefined, boolean | undefined, boolean | undefined>, 'renderInput'>;

export const RHFAutocompleteField = genericMemo(
  <OptionType extends { id: string } | PatientEnum | DictionaryItem | MedicalCondition, TField extends FieldValues>(
    props: RHFAutocompleteFieldProps<OptionType, TField>
  ) => {
    const {
      placeholder,
      onChangeHandler,
      name,
      control,
      isRequired,
      freeSolo = false,
      getOptionLabel,
      isOptionEqualToValue,
      options = [],
      multiple = false,
      loading,
      disabled,
      label,
      sx,
      ...others
    } = props;

    const emptyValue: [] | null = multiple ? [] : null;
    return (
      <Controller
        name={name}
        control={control}
        rules={{
          required: {
            value: isRequired || false,
            message: 'This field is requried',
          },
        }}
        render={({ field, fieldState: { error } }) => {
          const { onChange, value, ref } = field;

          const currentValue = useMemo(
            () => (Array.isArray(value) ? value : getElementById(value, [...options])),
            [value, options]
          );

          const onChangeAutocompleteHandler = useCallback(
            (
              _: React.SyntheticEvent<Element, Event>,
              value: (string | OptionType)[] | OptionType | NonNullable<string | OptionType> | null,
              reason: AutocompleteChangeReason,
              details: AutocompleteChangeDetails<OptionType>
            ) => {
              onChangeHandler?.(value, reason, details);
              if (Array.isArray(value) || typeof value === 'string') {
                onChange(value);
              } else {
                if (!value) {
                  return onChange(null);
                }
                if ('icd10Code' in value) {
                  return onChange(value.icd10Code);
                }
                if ('id' in value) {
                  return onChange(value?.id);
                }
                if ('isoCode' in value) {
                  return onChange(value.isoCode);
                }
                if ('value' in value) return onChange(value.value);
              }
            },
            [onChangeHandler]
          );

          return (
            <Autocomplete
              multiple={multiple}
              freeSolo={freeSolo}
              value={currentValue || emptyValue}
              getOptionLabel={getOptionLabel}
              isOptionEqualToValue={isOptionEqualToValue}
              onChange={onChangeAutocompleteHandler}
              disabled={disabled}
              loading={loading}
              options={options}
              popupIcon={<ExpandMoreRounded />}
              sx={{ width: 1, ...sx }}
              renderInput={props => (
                <MDInput
                  placeholder={placeholder}
                  label={label}
                  isLoading={loading}
                  required={isRequired}
                  error={!!error?.message}
                  helperText={error?.message}
                  inputRef={ref}
                  {...props}
                />
              )}
              {...others}
            />
          );
        }}
      />
    );
  }
);

type AutocompleteTextFieldProps = Pick<TextFieldProps, 'inputRef' | 'required' | 'placeholder' | 'label'> &
  AutocompleteRenderInputParams & {
    isLoading: boolean;
    errorMessage?: string;
  };
