import { FC } from 'react';

import { CircularProgress, CircularProgressProps, InputAdornment, InputAdornmentProps } from '@mui/material';

type LoadingAdornmentProps = {
  inputAdornmentProps: InputAdornmentProps;
  circularProgessProps: CircularProgressProps;
};

export const LoadingAdornment: FC<LoadingAdornmentProps> = ({ inputAdornmentProps, circularProgessProps }) => (
  <InputAdornment {...inputAdornmentProps}>
    <CircularProgress {...circularProgessProps} />
  </InputAdornment>
);
