import { FC, memo } from 'react';

import { InfoOutlined } from '@mui/icons-material';
import { FormHelperText as MUIFormHelperText } from '@mui/material';

import MDTypography from 'components/MDTypography';

type FormHelperTextProps = {
  text: React.ReactNode;
};

export const FormHelperText: FC<FormHelperTextProps> = memo(({ text }) => (
  <MUIFormHelperText component="p" sx={{ mt: '5px', alignItems: 'center', display: 'flex' }}>
    <InfoOutlined sx={{ mr: 1, width: 18, height: 18 }} />
    <MDTypography
      variant="button"
      sx={{
        mt: '1px',
        whiteSpace: 'initial',
        fontSize: 12,
        fontWeight: 400,
        lineHeight: 'unset',
      }}
    >
      {text}
    </MDTypography>
  </MUIFormHelperText>
));
