import React, { FC, useState } from 'react';

import { Box, FormControlLabel, Stack, Switch } from '@mui/material';

import { FilterMenuPopoverButtons } from 'components/MenuPopover';

type FiltersMenuContentProps = {
  appliedFilters: BaseFilterState;
  onApplyFilters: (newFilters: BaseFilterState) => void;
};

export const FiltersMenuContent: FC<FiltersMenuContentProps> = ({ appliedFilters, onApplyFilters }) => {
  const [filters, setFilters] = useState(appliedFilters);

  const onClickResetFiltersButtonHandler = () => setFilters({ isShowDeactivated: false });

  const onClickApplyFiltersButtonHandler = async () => onApplyFilters(filters);

  const onChangeSwitchHandler = (_: React.SyntheticEvent<Element, Event>, checked: boolean) => {
    setFilters(prevState => ({ ...prevState, isShowDeactivated: checked }));
  };

  const isApplyButtonDisabled = appliedFilters.isShowDeactivated === filters.isShowDeactivated;

  const isResetButtonDisabled = filters.isShowDeactivated === false;

  return (
    <Stack position="relative">
      <Stack
        mt={1}
        height={1}
        spacing={2}
        sx={{
          mb: '50px',
          py: '10px',
        }}
      >
        <Box>
          <FormControlLabel
            onChange={onChangeSwitchHandler}
            checked={filters.isShowDeactivated}
            control={<Switch />}
            label="Show deactivated"
            labelPlacement="start"
          />
        </Box>
      </Stack>
      <FilterMenuPopoverButtons
        isClearButtonDisabled={isResetButtonDisabled}
        onClickClearButtonHandler={onClickResetFiltersButtonHandler}
        isApplyButtonDisabled={isApplyButtonDisabled}
        onClickApplyButtonHandler={onClickApplyFiltersButtonHandler}
      />
    </Stack>
  );
};
