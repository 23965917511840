import { ROUTES } from 'constants/routes';
import { useBoolean } from 'hooks/useBoolean';
import { useRouter } from 'hooks/useRouter';

type UseDiscardChangesDialogWindowProps = {
  isDirty: boolean;
  backRoute: ROUTES | string;
};

export const useDiscardChangesDialogWindow = ({ backRoute, isDirty }: UseDiscardChangesDialogWindowProps) => {
  const { navigate } = useRouter();
  const [isOpenDiscardDialogWindow, openDiscardDialogWindow, closeDiscardDialogWindow] = useBoolean();

  const onClickCancelButtonHandler = () => {
    if (isDirty) return openDiscardDialogWindow();
    navigate(backRoute);
  };

  const onClickDiscardButtonHandler = () => navigate(backRoute);

  return {
    isOpenDiscardDialogWindow,
    openDiscardDialogWindow,
    onClickDiscardButtonHandler,
    closeDiscardDialogWindow,
    onClickCancelButtonHandler,
  };
};
