import { useCallback, useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { updatePractice } from 'apiServices';
import { Resolver, useForm } from 'react-hook-form';
import { useInView } from 'react-intersection-observer';
import { useAppStore } from 'store';
import { shallow } from 'zustand/shallow';

import { ToastType, notice } from 'components/ToastNotification';
import { ROUTES } from 'constants/routes';
import { useSideNavScrollTracker } from 'hooks';
import useResponsive from 'hooks/useResponsive';
import { useRouter } from 'hooks/useRouter';
import { formErrorHandler } from 'utils/errorHanders';

import { EditPracticeFormSchema, editPracticeValidationSchema } from './components/EditPracticeForm/form.config';

export const useViewPracticeState = () => {
  const { params } = useRouter();

  const practiceId = params?.id;

  const { fetchPractice, practice, setPractice } = useAppStore(
    store => ({
      fetchPractice: useCallback(store.fetchPractice, []),
      practice: store.practice,
      setPractice: store.setPractice,
    }),
    shallow
  );

  const [isLoading, setIsLoading] = useState(true);

  const onGetPracticeHandler = async () => {
    !isLoading && setIsLoading(true);
    await fetchPractice(practiceId);
    setIsLoading(false);
  };

  useEffect(() => {
    onGetPracticeHandler();

    return () => {
      setPractice(null);
    };
  }, []);

  const formMethods = useForm<EditPracticeFormSchema>({
    resolver: yupResolver(editPracticeValidationSchema) as Resolver<EditPracticeFormSchema>,
    mode: 'onTouched',
    values: {
      name: practice?.name,
      nameShort: practice?.nameShort || '',
      rag: practice?.rag?.data || '',
    },
  });

  const {
    handleSubmit,
    setError,
    formState: { isDirty, isValid, isSubmitting },
  } = formMethods;

  const onFormSubmitHandler = handleSubmit(async formData => {
    try {
      await updatePractice(practiceId, {
        ...formData,
        isActive: practice?.isActive,
        nameShort: formData.nameShort || null,
        rag: formData.rag ? { data: formData.rag } : {},
      });

      await fetchPractice(practiceId);

      notice(ToastType.SUCCESS, 'Practice profile has been successfully edited!');
    } catch (error) {
      console.error(error);
      formErrorHandler({
        error,
        config: { formError: { setError } },
        customErrorMessage: 'Failed to edit practice profile, please try again!',
      });
    }
  });

  const isSubmitButtonDisabled = !isDirty || !isValid || isSubmitting;

  const handleReload = () => window.location.reload();

  const isNoPractice = !isLoading && !practice;

  // TODO logic with tracking active side nav menu item
  // const isSmallScreen = useResponsive('down', 'sm');

  // const { isScrolling, activeSectionId, executeScroll, onChangeInViewHandler } = useSideNavScrollTracker({
  //   defaultActiveSectionId: PATIENT_PROFILE_ID,
  // });

  // const { ref: patientProfileRef, entry: patientProfileEntry } = useInView({
  //   threshold: isSmallScreen ? 0.5 : 0.9,
  //   initialInView: true,
  //   onChange: onChangeInViewHandler,
  // });

  return {
    practice,
    formMethods,
    isSubmitting,
    isSubmitButtonDisabled,
    onFormSubmitHandler,
    fetchPractice,
    practiceId,
    isLoading,
    handleReload,
    onGetPracticeHandler,
    isNoPractice,
    isDirty,
  };
};
