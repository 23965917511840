import { FC } from 'react';

import { DeleteOutlineOutlined, MoreVertOutlined } from '@mui/icons-material';
import { IconButton, SxProps, TableCell, Theme } from '@mui/material';

import { MenuPopover, PopoverMenuItem, PopoverMenuItemProps } from 'components/MenuPopover';
import { MenuPopoverProps } from 'components/MenuPopover/types';

type ThreeDotsTableCellProps = MenuPopoverProps & {
  onClickButtonHandler: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  menuItems: PopoverMenuItemProps[];
  onClickDeleteMenuItemHandler?: (event: React.MouseEvent<HTMLLIElement>) => void;
  onClickUpdateMenuItemHandler?: (event: React.MouseEvent<HTMLLIElement>) => void;
  tableCellSX?: SxProps<Theme>;
};

export const ThreeDotsTableCell: FC<ThreeDotsTableCellProps> = ({
  onClickButtonHandler,
  menuItems,
  open,
  onClose,
  onClickDeleteMenuItemHandler,
  tableCellSX,
  ...rest
}) => {
  const menuItemsToMap = [
    ...menuItems,

    onClickDeleteMenuItemHandler && {
      icon: <DeleteOutlineOutlined fontSize="small" />,
      title: 'Delete',
      sx: {
        ':hover': {
          bgcolor: (theme: Theme) => theme.palette.error.main,
          color: (theme: Theme) => theme.palette.common.white,
        },
      },
      onClick: onClickDeleteMenuItemHandler,
    },
  ].filter(Boolean);

  return (
    <TableCell sx={tableCellSX}>
      <IconButton color={open ? 'inherit' : 'default'} onClick={onClickButtonHandler}>
        <MoreVertOutlined fontSize="medium" />
      </IconButton>

      <MenuPopover open={open} onClose={onClose} arrow="right-top" sx={{ width: 160 }} {...rest}>
        {menuItemsToMap.map(({ icon, title, ...rest }) => (
          <PopoverMenuItem key={title} title={title} icon={icon} {...rest} />
        ))}
      </MenuPopover>
    </TableCell>
  );
};
