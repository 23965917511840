export const SIDE_NAVIGATION = {
  W_DASHBOARD: 230,
  W_DASHBOARD_TABLET: 230,
  W_DASHBOARD_MINI: 65,

  H_DASHBOARD_ITEM: 38,
  H_DASHBOARD_SUB_ITEM: 34,
};

export const HEADER_CONFIG = {
  H_DASHBOARD_HEADER: 55,
};

export const ICON = {
  NAV_ITEM: 16,
};
