import { FC } from 'react';

import { Box, Grid, Stack, Typography } from '@mui/material';

import AuthLogo from 'assets/icons/aesthetics360_logo.svg';
import MDTypography from 'components/MDTypography';
import { useMaterialUIController } from 'context';
import { AuthFlowBackgrounds } from 'types/enums';

import Footer from './components/Footer';
import { LayoutWrapperProps } from './types';

export const IllustrationLayout: FC<LayoutWrapperProps> = ({
  children,
  authMode = 'signIn',
  description,
  illustration = AuthFlowBackgrounds.SIGN_IN,
  title,
  backButton,
}) => {
  const { controller } = useMaterialUIController();
  const { darkMode } = controller;

  return (
    <Box width={1} height="100%" minHeight="100vh">
      <Grid
        container
        sx={{
          backgroundColor: ({ palette: { background, white } }) => (darkMode ? background.default : white.main),
        }}
      >
        <Grid item xs={12} lg={6} sx={{ mx: 'auto', position: 'relative' }}>
          <Box display="flex" flexDirection="column" justifyContent="center" minHeight={'100vh'}>
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              sx={{
                position: 'absolute',
                top: 15,
                left: 15,
              }}
            >
              <Box component="img" src={AuthLogo} width={24} height={24} />
              <MDTypography variant="h1" fontWeight={600} fontSize={14}>
                Aesthetics360
              </MDTypography>
            </Stack>

            <Grid
              container
              sx={{
                alignItems: 'center',
                pt: 7,
                flex: 1,
              }}
            >
              <Grid item xs={11} sm={8} md={8} lg={9} xl={9} mx="auto">
                {authMode === 'signIn' && (
                  <Box pt={3} py={3.5} px={3}>
                    <Stack spacing={3} alignItems="flex-start">
                      {backButton && backButton}
                      <Typography mb={1} variant="h2" fontWeight={600}>
                        {title}
                      </Typography>
                    </Stack>

                    <Typography
                      mt={1}
                      display="flex"
                      lineHeight="20px"
                      variant="button"
                      fontWeight={400}
                      color="text.main"
                    >
                      {description}
                    </Typography>
                  </Box>
                )}
                <Box p={3} pt={0} width={1}>
                  {children}
                </Box>
              </Grid>
            </Grid>

            <Box
              sx={{
                display: 'flex',
                width: 1,
                mb: 2,
              }}
            >
              <Footer />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={0} lg={6}>
          <Box display={{ xs: 'none', lg: 'block' }} py="10px" width={1} height={1} pr="10px">
            <Box height={1} width={1}>
              <Box
                display={{ xs: 'none', lg: 'flex' }}
                width={1}
                height={1}
                borderRadius="30px"
                sx={{ background: `url(${illustration}) no-repeat center center`, backgroundSize: 'cover' }}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
