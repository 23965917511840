import { FC, useCallback, useEffect, useState } from 'react';

import { Badge, Box, alpha } from '@mui/material';
import { FileWithPath } from 'react-dropzone';

import { UploadAvatarDialogWindow, ZoomCropDialogWindow } from 'components/BaseDialogWindow';
import { Icon } from 'components/Icon';
import { MDAvatar, MDAvatarProps, MDAvatarSkeleton } from 'components/MDAvatar';
import { useBoolean } from 'hooks/useBoolean';

export type UploadAvatarProps = MDAvatarProps & {
  isDisabled?: boolean;
  onCreateFileHandler: () => Promise<void>;
};

export const UploadAvatar: FC<UploadAvatarProps> = ({
  src,
  isDisabled,
  avatarSize,
  variant = 'circular',
  children,
  onCreateFileHandler,
  ...rest
}) => {
  // TODO useFilePick
  const [isUploadFileDialogOpen, openUploadFileDialog, closeUploadFileDialog] = useBoolean();

  const [isCropFileDialogOpen, openCropFileDialog, closeCropFileDialog] = useBoolean();

  const [file, setFile] = useState<FileWithPath | null>(null);

  const [previewURL, setPreviewURL] = useState('');

  const onUploadFileHandler = useCallback((fileList: readonly FileWithPath[]) => {
    setFile(fileList?.[0]);
    setPreviewURL(URL.createObjectURL(fileList?.[0]));

    closeUploadFileDialog();
    openCropFileDialog();
  }, []);

  const onUploadNewFileHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    setPreviewURL(URL.createObjectURL(file));
    setFile(file);

    event.target.value = '';
  }, []);

  const onCloseCropFileDialogWindowHandler = useCallback(() => {
    closeCropFileDialog();
    setFile(null);
    setPreviewURL('');
  }, []);

  const [isLoading, setIsLoading] = useState(false);

  const onSaveFileHandler = async () => {
    setIsLoading(true);
    // TODO
    // await onCreateFileHandler()
    setIsLoading(false);
  };

  useEffect(() => {
    return () => {
      if (previewURL) URL.revokeObjectURL(previewURL);
    };
  }, [previewURL]);

  return (
    <>
      {isLoading ? (
        <MDAvatarSkeleton avatarSize={avatarSize} />
      ) : (
        <Badge
          overlap="circular"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          badgeContent={
            <Box sx={{ height: 28, width: 28, borderRadius: '50%', p: 0.5, bgcolor: theme => theme.palette.grey[200] }}>
              <Box
                sx={{
                  width: 1,
                  height: 1,
                  borderRadius: '50%',
                  p: '3px',
                  alignItems: 'center',
                  justifyContent: 'center',
                  display: 'flex',
                  bgcolor: theme => alpha(theme.palette.grey[300], 0.5),
                }}
              >
                {src ? <Icon type="editPen" /> : <Icon type="uploadIcon" />}
              </Box>
            </Box>
          }
        >
          <MDAvatar
            src={src || ''}
            avatarSize={avatarSize}
            variant={variant}
            {...rest}
            onClick={openUploadFileDialog}
            sx={{ ':hover': { cursor: 'pointer' }, bgcolor: theme => alpha(theme.palette.primary.light, 0.1) }}
          >
            {children}
          </MDAvatar>
        </Badge>
      )}

      <UploadAvatarDialogWindow
        open={isUploadFileDialogOpen}
        onClickCloseButtonHandler={closeUploadFileDialog}
        onUploadFileHandler={onUploadFileHandler}
      />

      {isCropFileDialogOpen && (
        <ZoomCropDialogWindow
          image={previewURL}
          open={isCropFileDialogOpen}
          onClickCloseButtonHandler={onCloseCropFileDialogWindowHandler}
          onUploadNewFileHandler={onUploadNewFileHandler}
          onSaveFileHandler={onSaveFileHandler}
          isLoading={isLoading}
        />
      )}
    </>
  );
};
