import borders from 'assets/theme/base/borders';
import colors from 'assets/theme/base/colors';
import typography from 'assets/theme/base/typography';

const tablePagination = {
  defaultProps: {
    backIconButtonProps: {
      size: 'small' as const,
    },
    nextIconButtonProps: {
      size: 'small' as const,
    },
    SelectProps: {
      MenuProps: {
        MenuListProps: {
          sx: {
            '& .MuiMenuItem-root': {
              ...typography.body2,
            },
          },
        },
      },
    },
  },
  styleOverrides: {
    root: {
      borderTop: `${borders.borderWidth[1]} solid ${colors.light.main}`,
      '& .MuiSelect-iconOpen': {
        transform: 'translateY(-50%) rotate(-90deg) !important',
      },
      '& .MuiInputBase-root': {
        marginRight: 8,
        order: -1,
        fontWeight: 500,
        borderRadius: borders.borderRadius.md,
        overflow: 'hidden',
        border: `1px solid ${colors.grey[300]}`,
        backgroundColor: colors.grey[200],
        height: 28,
        justifyContent: 'space-between',
      },

      '.MuiPaper-root.MuiPopover-paper': {
        padding: '4px !important',
      },

      '& .MuiSelect-select.MuiTablePagination-select': {
        padding: '0 30px 0 8px !important',
        height: '28px !important',
      },
    },
    selectLabel: {
      fontWeight: 500,
      fontSize: 12,
      order: -1,
    },
    displayedRows: {
      fontWeight: 500,
      fontSize: 12,
    },
    toolbar: {
      minHeight: '48px !important',
      width: '100%',
    },
    actions: {
      marginRight: '8px',
    },
  },
};

export default tablePagination;
