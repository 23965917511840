import { Contact, ContactHomeAddress, Patient, PatientCard, UpdatePatientPayload } from 'apiServices';
import * as yup from 'yup';

import { Shape } from 'types/RHF-types';
import { EmailLazyValidationSchema, PhoneValidationSchema, WebsiteLazyValidationSchema } from 'utils/formValidation';

export type EditPatientFormSchema = Omit<UpdatePatientPayload, 'userId' | 'person'> & {
  patientCard: Partial<
    Omit<Patient['patientCard'], 'allergies' | 'medicalConditions' | 'rag' | 'id'> & {
      rag: string;
    }
  >;
  contact: Partial<
    Pick<Contact, 'email' | 'phone' | 'website'> & {
      homeAddress: Pick<ContactHomeAddress, 'countryIsoCode' | 'cityId' | 'stateIsoCode'>;
      billingAddress: Pick<ContactHomeAddress, 'countryIsoCode' | 'cityId' | 'stateIsoCode'>;
    }
  >;
} & {
  birthDate: string;
  person: Omit<Person, 'id'>;
};

const editPatientCardValidationSchema = yup
  .object<PatientCard>()
  .shape<Shape<Omit<PatientCard, 'medicalConditions' | 'allergies'>>>({
    bioGender: yup.string().optional().label('Bio gender').nullable(),
    bloodType: yup.string().optional().label('Blood type').nullable(),
    painTolerance: yup.string().optional().label('Pain tolerance').nullable(),
  })
  .required();

export const editPatientProfileValidationSchema = {
  ssn: yup.string().optional().label('Social Security number').max(10).trim().nullable(),
  birthDate: yup.string().required().label('Date of birth'),
  gender: yup.string().optional().label('Gender').nullable(),
  ethnicGroup: yup.string().optional().label('Ethnic group').nullable(),
  person: yup
    .object()
    .shape<Shape<Person>>({
      firstName: yup.string().required().label('First name'),
      lastName: yup.string().required().label('Last name'),
      title: yup.string().optional().label('Title').nullable(),
      middleName: yup.string().optional().label('Middle name').nullable(),
      position: yup.string().optional().label('Position').nullable(),
    })
    .required(),
};

export const editPatientContactValidationSchema = yup
  .object()
  .shape<Shape<Contact>>({
    billingAddress: yup
      .object()
      .shape<Shape<ContactHomeAddress>>({
        countryIsoCode: yup.string().optional().label('Billing address country').nullable(),
        stateIsoCode: yup.string().optional().label('Billing address state').nullable(),
        cityId: yup.string().optional().label('Billing address city').nullable(),
      })
      .notRequired()
      .nullable(),
    homeAddress: yup
      .object()
      .shape<Shape<ContactHomeAddress>>({
        countryIsoCode: yup.string().optional().label('Home address country').nullable(),
        cityId: yup.string().optional().label('Home address city').nullable(),
        stateIsoCode: yup.string().optional().label('Home address state').nullable(),
      })
      .notRequired()
      .nullable(),
    email: EmailLazyValidationSchema,
    phone: PhoneValidationSchema,
    website: WebsiteLazyValidationSchema,
  })
  .required();

export const editPatientValidationSchema = yup
  .object({
    ...editPatientProfileValidationSchema,
    contact: editPatientContactValidationSchema,
    patientCard: editPatientCardValidationSchema,
  })
  .required();
