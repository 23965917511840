import { TagListSortFieldName } from 'apiServices';

import { LIBRARY_TABS } from 'types/enums';

import { FiltersInitialState } from './types';

export const TABS: LIBRARY_TABS[] = [LIBRARY_TABS.GLOBAL_LIBRARY, LIBRARY_TABS.PRACTICE_LIBRARY];

export const TAG_LIST_TABLE_HEADERS: TableHeaderConfig<TagListSortFieldName>[] = [
  { id: 'name', label: 'Tag category' },
  { id: 'tags', label: 'Tags', disableSorting: true },
  { id: 'actions', disableSorting: true },
];

export const FiltersInitialStateValue: FiltersInitialState = {
  isShowDeactivated: false,
};
