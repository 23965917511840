import { Theme, alpha } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';

import borders from 'assets/theme/base/borders';
import colors from 'assets/theme/base/colors';

const { transparent, error } = colors;
const { borderRadius } = borders;

const textField: { styleOverrides?: Partial<OverridesStyleRules<'root', 'MuiTextField', Omit<Theme, 'components'>>> } =
  {
    styleOverrides: {
      root: {
        '& .MuiInputBase-root': {
          height: 40,
        },

        '& .MuiInputBase-sizeSmall': {
          height: 36,
        },

        '.MuiInputBase-multiline': {
          height: 'auto',
        },

        backgroundColor: transparent.main,
        borderRadius: borderRadius.lg,
        '& .Mui-error.MuiInputBase-root': {
          backgroundColor: alpha(error.main, 0.1),
        },
        '.MuiInputBase-root': {
          paddingRight: 0,
        },
        '& .MuiInputBase-adornedEnd': {
          paddingRight: 14,
        },
        '& .MuiInputBase-adornedStart': {
          paddingLeft: 14,
        },
      },
    },
  };

export default textField;
