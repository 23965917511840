import { FC, memo } from 'react';

import { DeleteOutlined } from '@mui/icons-material';
import { Stack } from '@mui/material';

import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import { MDInput } from 'components/MDInput';

type MedicalConditionsItemProps = {
  medicalConditionIcd10Code: string;
  symptoms: string;
  medConditionId: string;
  onClickRemoveButtonHandler: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

export const MedicalConditionsItem: FC<MedicalConditionsItemProps> = memo(
  ({ medConditionId, onClickRemoveButtonHandler, medicalConditionIcd10Code, symptoms }) => {
    return (
      <Stack spacing={2} width={1} alignItems="center">
        <Stack width={1} spacing={2}>
          <MDInput label="Medical conditions" disabled value={medicalConditionIcd10Code} />
          <MDInput fullWidth label="Symptoms" minRows={1} maxRows={4} multiline disabled value={symptoms} />
        </Stack>

        <MDBox sx={{ display: 'flex', alignSelf: 'flex-end', mr: '3px !important', mt: 1 }}>
          <MDButton
            onClick={onClickRemoveButtonHandler}
            size="small"
            color="error"
            variant="outlined"
            startIcon={<DeleteOutlined />}
            id={medConditionId}
          >
            Remove
          </MDButton>
        </MDBox>
      </Stack>
    );
  }
);
