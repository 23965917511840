import { FC } from 'react';

import { Box, Card, Grid } from '@mui/material';

import { FormProvider } from 'components/HookForm';
import { ROUTES } from 'constants/routes';
import { BeforeAfterPhotos, ProfileFormSection, ProfileHeader } from 'pages/BeforeAfter';

import { useBAprofileState } from './usePAprofile.state';

export const PracticeLibraryBAProfile: FC = () => {
  const { formMethods, onFormSubmitHandler } = useBAprofileState();

  return (
    <Box
      component="section"
      sx={{
        display: 'flex',
        height: 1,
      }}
    >
      <Grid container spacing={2}>
        {
          <Grid item xs={12}>
            <Card>
              {false ? (
                <>
                  {/* <HeaderSkeleton />
                  <FormContentSkeleton rows={7} /> */}
                </>
              ) : (
                <>
                  <ProfileHeader
                    openActivateDialog={() => {}}
                    openDeactivateDialog={() => {}}
                    isStatusLoading={false}
                    isActive={false}
                    title="Facial Rejuvenation"
                    subtitle="Dermal filler for fuller lips"
                    backRoute={ROUTES.beforeAfter}
                  />
                  <FormProvider methods={formMethods} onSubmit={onFormSubmitHandler}>
                    <ProfileFormSection />
                  </FormProvider>
                  <BeforeAfterPhotos data={[]} />
                </>
              )}
            </Card>
          </Grid>
        }
      </Grid>
    </Box>
  );
};
