import authCoverImage from 'assets/illustrations/auth_cover.png';

export enum AuthFlowBackgrounds {
  SIGN_IN = authCoverImage,
}

export enum BUTTON_OPTIONS {
  SAVE_CLOSE = 'Save and close',
  SAVE_CONTINUE_EDITING = 'Save and continue editing',
}

export enum LIBRARY_TABS {
  GLOBAL_LIBRARY = 'Global Library',
  PRACTICE_LIBRARY = 'Practice Library',
}

export enum UserRoles {
  ROLE_ADMIN = 'ROLE_ADMIN',
  ROLE_PRACTICE = 'ROLE_PRACTICE',
  ROLE_AI_TESTER = 'ROLE_AI_TESTER',
  ROLE_SERVICE = 'ROLE_SERVICE',
  ROLE_PRACTICE_ADMIN = 'ROLE_PRACTICE_ADMIN',
  ROLE_PRACTICE_OFFICE_ADMIN = 'ROLE_PRACTICE_OFFICE_ADMIN',
  ROLE_PRACTICE_DOCTOR = 'ROLE_PRACTICE_DOCTOR',
  ROLE_PRACTICE_SECRETARY = 'ROLE_PRACTICE_SECRETARY',
}

export enum EXPERT_ROLE {
  PRACTICE_ADMIN = 'practice_admin',
  OFFICE_ADMIN = 'office_admin',
  DOCTOR = 'doctor',
  SECRETARY = 'secretary',
}

export enum EXPERT_ROLE_TO_SHOW {
  PRACTICE_ADMIN = 'Practice Admin',
  OFFICE_ADMIN = 'Office Admin',
  DOCTOR = 'Doctor',
  SECRETARY = 'Secretary',
}
