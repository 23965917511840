import { Box, BoxProps } from '@mui/material';
import { FormProvider as Form, UseFormReturn } from 'react-hook-form';

type FormProviderProps<TMethods> = {
  children: React.ReactNode;
  methods: UseFormReturn<TMethods>;
  onSubmit?: VoidFunction;
  formProps?: BoxProps;
};

export function FormProvider<TMethods>({ children, onSubmit, methods, formProps }: FormProviderProps<TMethods>) {
  return (
    <Form {...methods}>
      <Box component="form" onSubmit={onSubmit} {...formProps}>
        {children}
      </Box>
    </Form>
  );
}
