import { apiClient } from 'lib/api';

import { ExpertRoleEnum, PracticeEnum, PracticeEnums } from './types';

const BASE_PRACTICE_ENUM_API_URL = '/practice/enums';

export const getAllPracticeEnums = async () => await apiClient.get<PracticeEnums[]>(BASE_PRACTICE_ENUM_API_URL);

export const getPracticePersonTitlesEnum = async () =>
  await apiClient.get<PracticeEnum[]>(`${BASE_PRACTICE_ENUM_API_URL}/person_titles`);

export const getPracticeRolesEnum = async () =>
  await apiClient.get<ExpertRoleEnum[]>(`${BASE_PRACTICE_ENUM_API_URL}/roles`);
