import { ToastType, notice } from 'components/ToastNotification';
import { ERROR_MESSAGES } from 'constants/messages';

type CognitoErrorType = 'LimitExceededException' | 'CodeMismatchException' | 'NotAuthorizedException';

type ErrorHandlerProps = {
  error: unknown;
  customErrorMessage?: string;
  customErrorHandler?: (error: Error) => void;
  errorTypeHandler?: {
    [type in CognitoErrorType]?: (error: Error) => void | Promise<void>;
  };
};

// TODO remove if do not need anymore
export const customAWSCongnitoErrorHandler = (error: Error): void => {
  switch (error.name) {
    case 'LimitExceededException':
      return notice(ToastType.ERROR, 'Attempt limit exceeded. Try again in 30 minutes.');

    default:
      return;
  }
};

export const awsCognitoErrorHandler = ({
  error,
  customErrorHandler,
  customErrorMessage,
  errorTypeHandler,
}: ErrorHandlerProps) => {
  if (error instanceof Error) {
    if (customErrorHandler) return customErrorHandler(error);

    if (errorTypeHandler && errorTypeHandler[error.name as CognitoErrorType]) {
      return errorTypeHandler[error.name as CognitoErrorType]?.(error);
    }

    return notice(ToastType.ERROR, error.message || ERROR_MESSAGES.smt_went_wrong);
  }

  return notice(ToastType.ERROR, customErrorMessage || ERROR_MESSAGES.smt_went_wrong);
};
