import { useEffect, useState } from 'react';

import { getFeedbacksByPromptId } from 'apiServices/ml/prompt_feedback.api';

export const usePromptFeedbacks = (promptId: string) => {
  const [feedbacks, setFeedbacks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<null | string>(null);

  useEffect(() => {
    const fetchFeedbacks = async () => {
      setLoading(true);
      try {
        const response = await getFeedbacksByPromptId(promptId);

        setFeedbacks(response.data);
      } catch (err) {
        console.log('response FeedBack Error: ', error);

        setError('Failed to fetch feedbacks');
      } finally {
        setLoading(false);
      }
    };

    if (promptId) {
      fetchFeedbacks();
    }
  }, [promptId]);

  return { feedbacks, loading, error };
};
