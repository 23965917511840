import { CreatePatientMedicalCondition } from 'apiServices';
import * as yup from 'yup';

import { Shape } from 'types/RHF-types';

export type CreateMedicalConditionFormSchema = CreatePatientMedicalCondition;

export const createMedicalConditionValidationSchema = yup
  .object()
  .shape<Shape<CreateMedicalConditionFormSchema>>({
    medicalConditionIcd10Code: yup.string().required().label('Medical condition ICD-10 code').nullable(),
    symptoms: yup.string().required().label('Symptoms'),
  })
  .required();

export const medicalConditionDefaultValues: CreateMedicalConditionFormSchema = {
  medicalConditionIcd10Code: '',
  symptoms: '',
};
