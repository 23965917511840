import { FC } from 'react';

import { Stack, Typography } from '@mui/material';
import { IllustrationLayout } from 'layouts/IllustrationLayout';
import { BackButton } from 'layouts/IllustrationLayout/components';
import { Link } from 'react-router-dom';

import { RHFTextField } from 'components/HookForm';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import { ROUTES } from 'constants/routes';

import { VerifyTOTPcodeStep } from './components';
import { AUTH_STEPS, useSignInState } from './hooks/useSignIn.state';

export const SignIn: FC = () => {
  const {
    errors,
    isSubmitButtonDisabled,
    isSubmitting,
    onFormSubmitHandler,
    register,
    authStep,
    getCognitoCurrentUser,
    getPracticeProfileAndLegalDocsHandler,
  } = useSignInState();

  return (
    <>
      {authStep === AUTH_STEPS.SIGN_IN && (
        <IllustrationLayout title="Sign in" description="Please enter your credentials to log in">
          <form onSubmit={onFormSubmitHandler}>
            <Stack spacing={2.5}>
              <Stack spacing={2.5}>
                <RHFTextField
                  registerName="email"
                  registerErrors={errors?.email?.message}
                  type="email"
                  label="Email"
                  placeholder="Enter your email"
                  fullWidth
                  register={register}
                />

                <RHFTextField
                  register={register}
                  registerName="password"
                  registerErrors={errors?.password?.message}
                  type="password"
                  label="Password"
                  placeholder="Enter your password"
                  fullWidth
                  isPasswordField
                />
              </Stack>

              <MDButton
                type="submit"
                fullWidth
                color="primary"
                disabled={isSubmitButtonDisabled}
                isLoading={isSubmitting}
              >
                Sign in
              </MDButton>

              <MDBox display="flex" justifyContent="center">
                <Typography
                  component={Link}
                  to={ROUTES.forgotPassword}
                  variant="caption"
                  sx={{
                    color: theme => theme.palette.primary.light,
                    ':hover': { opacity: 0.75 },
                  }}
                  fontWeight="500"
                >
                  Forgot password?
                </Typography>
              </MDBox>
            </Stack>
          </form>
        </IllustrationLayout>
      )}

      {authStep === AUTH_STEPS.VERIFY_TOTP_CODE && (
        <IllustrationLayout
          title="Verification code"
          description="Enter the 6-digit verification code from your TOTP application"
          backButton={<BackButton title="Back to Login" />}
        >
          <VerifyTOTPcodeStep
            getPracticeProfileAndLegalDocsHandler={getPracticeProfileAndLegalDocsHandler}
            getCognitoCurrentUser={getCognitoCurrentUser}
          />
        </IllustrationLayout>
      )}
    </>
  );
};
