import { useMemo } from 'react';

function throttle<Args extends unknown[]>(fn: (...args: Args) => void, cooldown: number) {
  let lastArgs: Args | undefined;

  const run = () => {
    if (lastArgs) {
      fn(...lastArgs);
      lastArgs = undefined;
    }
  };

  const throttled = (...args: Args) => {
    const isOnCooldown = !!lastArgs;

    lastArgs = args;

    if (isOnCooldown) return;

    setTimeout(run, cooldown);
  };

  return throttled;
}

export function useThrottle<Args extends unknown[]>(
  cb: (...args: Args) => void,
  cooldown: number = 500,
  deps: any[] = []
) {
  return useMemo(() => throttle(cb, cooldown), deps);
}
