import { IconButton, styled } from '@mui/material';

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  zIndex: theme.zIndex.appBar + 1,
  position: 'fixed',
  top: 10,
  border: `solid 1px ${theme.palette.divider}`,
  ':hover': {
    backgroundColor: theme.palette.common.white,
  },
}));
