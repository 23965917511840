import { BASE_DICTIONARY_API_URL } from 'apiServices/api.routes';
import { apiClient } from 'lib/api';

import { CreateLegalDocPayload, GetLegalDocParams, LegalDocItem, UpdateLegalDocPayload } from './types';

const API_SUB_URL = 'legal_docs';

export const getLegalDocs = async (params: GetLegalDocParams) =>
  await apiClient.get<ListOfItems<LegalDocItem>>(`${BASE_DICTIONARY_API_URL}/${API_SUB_URL}`, { params });

export const createLegalDoc = async (payload: CreateLegalDocPayload) =>
  await apiClient.post<LegalDocItem>(`${BASE_DICTIONARY_API_URL}/${API_SUB_URL}`, payload);

export const getLegalDocByType = async (legalDocType: string) =>
  await apiClient.get<LegalDocItem>(`${`${BASE_DICTIONARY_API_URL}/${API_SUB_URL}`}/${legalDocType}`);

export const updateLegalDoc = async (docId: string, payload: UpdateLegalDocPayload) =>
  await apiClient.patch<LegalDocItem>(`${BASE_DICTIONARY_API_URL}/${API_SUB_URL}/${docId}`, payload);

export const deleteLegalDoc = async (docId: string) =>
  await apiClient.delete(`${BASE_DICTIONARY_API_URL}/${API_SUB_URL}/${docId}`);

export const signLegalDoc = async (docId: string) =>
  await apiClient.post(`${BASE_DICTIONARY_API_URL}/${API_SUB_URL}/${docId}/sign`);
