import { FC } from 'react';

import { Box, Stack, Typography } from '@mui/material';

import { MDAvatar } from 'components/MDAvatar';
import { Scrollbar } from 'components/ScrollBar';

import { NoteProps } from './types';

export const Note: FC<NoteProps> = ({ note, noteAvatar, noteDate, noteAuthor }) => {
  return (
    <>
      {/* TODO: Add note card when BE is ready */}
      {/*      <IconButton
                        sx={{
                          color: theme => theme.palette.black.main,
                          bgcolor: 'transparent',
                          marginLeft: 'auto',
                        }}
                      >
                        <Icon type="add" />
                        <MDTypography fontSize="14px" sx={{ fontWeight: '400' }} variant="button">
                          Add Note
                        </MDTypography>
                      </IconButton> */}
      <Stack
        sx={{
          overflow: 'hidden',
          border: ({ borders }) => `${borders.borderWidth[1]} solid ${borders.borderColor}`,
          borderRadius: theme => theme.borders.borderRadius.lg,
          maxWidth: { xs: 1, lg: 430 },
          width: 1,
          minWidth: 250,
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing={1}
          sx={{ p: 0.5, bgcolor: theme => theme.palette.grey[100] }}
        >
          <Stack direction="row" alignItems="center" gap={1}>
            <MDAvatar avatarSize="xs" />
            <Typography fontSize={12} fontWeight={400}>
              John Doe
            </Typography>
          </Stack>
          <Typography color="text.secondary" fontSize={12} fontWeight={400}>
            Jul 1 at 2:37 pm
          </Typography>
        </Stack>
        <Box p="2px">
          <Scrollbar sx={{ maxHeight: { xs: 150, lg: 50 }, height: 1 }}>
            <Typography fontSize={12} fontWeight={400}>
              Patient prefers morning appointments and needs at least 24 hours' notice for any changes. She is allergic
              to penicillin, so alternative medications are necessary. The patient may experience mild anxiety during
              procedures and prefers receiving all instructions via email for easy reference.
            </Typography>
          </Scrollbar>
        </Box>
      </Stack>
    </>
  );
};
