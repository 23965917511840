const createCatalogMessages = (entity: string) => ({
  inactivateFailed: `Failed to inactivate ${entity}, please try again!`,
  activateFailed: `Failed to activate ${entity}, please try again!`,
  inactivateSuccess: `${entity.charAt(0).toUpperCase() + entity.slice(1)} has been successfully inactivated!`,
  activateSuccess: `${entity.charAt(0).toUpperCase() + entity.slice(1)} has been successfully activated!`,
  deleteSuccess: `${entity.charAt(0).toUpperCase() + entity.slice(1)} has been successfully deleted!`,
  deleteFailed: `Failed to delete ${entity}, please try again!`,
  copyFailed: `Failed to copy ${entity}, please try again!`,
  copySuccess: `${entity.charAt(0).toUpperCase() + entity.slice(1)} has been successfully copied!`,
});

export const CATALOG_SERVICE_MESSAGES = createCatalogMessages('service');
export const CATALOG_PRODUCT_MESSAGES = createCatalogMessages('product');
