import { FC } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@mui/material';
import { confirmSignIn } from 'aws-amplify/auth';
import { Controller, Resolver, useForm } from 'react-hook-form';

import { OTPinput } from 'components/Form';
import MDButton from 'components/MDButton';
import { awsCognitoErrorHandler } from 'utils/errorHanders';

import { TOTP_MFA_verification_code_validation_schema, TOT_MFA_verification_code_schema } from './form.config';

type VerifyTOTPcodeStepProps = {
  getCognitoCurrentUser: () => Promise<void>;
  getPracticeProfileAndLegalDocsHandler: () => Promise<void>;
};

export const VerifyTOTPcodeStep: FC<VerifyTOTPcodeStepProps> = ({
  getCognitoCurrentUser,
  getPracticeProfileAndLegalDocsHandler,
}) => {
  const {
    control,
    formState: { isValid, isSubmitting },
    handleSubmit,
    reset,
  } = useForm<TOT_MFA_verification_code_schema>({
    resolver: yupResolver(TOTP_MFA_verification_code_validation_schema) as Resolver<TOT_MFA_verification_code_schema>,
    mode: 'onTouched',
    defaultValues: {
      TOTPverificationCode: '',
    },
  });

  const onFormSubmitHandler = handleSubmit(async ({ TOTPverificationCode }) => {
    try {
      const { isSignedIn, nextStep } = await confirmSignIn({ challengeResponse: TOTPverificationCode });

      if (isSignedIn && nextStep.signInStep === 'DONE') {
        await getCognitoCurrentUser();

        await getPracticeProfileAndLegalDocsHandler();
      }
    } catch (error) {
      console.error(error);
      reset();
      awsCognitoErrorHandler({ error, customErrorMessage: 'Failed to verify TOTP code, please try again!' });
    }
  });

  return (
    <Box
      sx={{
        '& > div': {
          alignItems: 'flex-start',
        },
      }}
      component="form"
      display="flex"
      width={1}
      flexDirection="column"
      gap={2.5}
      onSubmit={onFormSubmitHandler}
    >
      <Controller
        control={control}
        name="TOTPverificationCode"
        render={({ field: { ref, ...other } }) => {
          return <OTPinput {...other} containerStyle={{ justifyContent: 'center' }} />;
        }}
      />
      <MDButton disabled={!isValid} isLoading={isSubmitting} type="submit" fullWidth>
        Verify
      </MDButton>
    </Box>
  );
};
