import { BASE_DICTIONARY_API_URL } from 'apiServices/api.routes';
import { apiClient } from 'lib/api';

import { DictionaryItem, GetDictionaryItemsParams } from './types';

export const getStates = async (params: GetDictionaryItemsParams) =>
  await apiClient.get<ListOfItems<DictionaryItem>>(`${BASE_DICTIONARY_API_URL}/states`, { params });

export const getStateByISOcode = async (ISOcode: string) =>
  await apiClient.get<DictionaryItem>(`${BASE_DICTIONARY_API_URL}/states/${ISOcode}`);
