export enum LINKED_CONTENT_ITEM {
  VIDEO = 'video',
  IMAGE = 'image',
  BEFORE_AFTER = 'before_after',
  TEXT = 'text',
  PAGE = 'page',
}

export enum LINKED_CATALOG_ITEM {
  SERVICES = 'services',
  PRODUCTS = 'products',
}

export type LinkedContentStatisticItemProps = {
  amount: number;
  type: LINKED_CONTENT_ITEM | LINKED_CATALOG_ITEM;
  color?: 'primary' | 'success';
};
