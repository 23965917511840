import { FC, useEffect } from 'react';

import { Box, Stack, Typography, alpha } from '@mui/material';
import { FileWithPath, useDropzone } from 'react-dropzone';

import { Icon } from 'components/Icon';
import MDButton, { MDButtonProps } from 'components/MDButton';

type DropZoneProps = {
  uploadButtonProps?: MDButtonProps;
  onUploadFileHandler: (file: readonly FileWithPath[]) => void;
  buttonTitle: string;
};

export const DropZone: FC<DropZoneProps> = ({ buttonTitle, uploadButtonProps, onUploadFileHandler }) => {
  const { open, isDragActive, acceptedFiles, getRootProps, getInputProps } = useDropzone({
    noClick: true,
    multiple: false,
  });

  useEffect(() => {
    if (acceptedFiles.length) onUploadFileHandler(acceptedFiles);
  }, [acceptedFiles]);

  return (
    <Stack alignItems="center">
      <Box
        sx={{
          flexDirection: 'column',
          alignItems: 'center',
          p: 2.5,
          outlineWidth: '2px',
          borderRadius: '2px',
          ...(isDragActive && {
            outlineStyle: 'dashed',
            outlineColor: theme => theme.palette.primary.main,
            backgroundColor: theme => alpha(theme.palette.primary.main, 0.1),
          }),
          color: 'text',
        }}
        {...getRootProps({ className: 'dropzone' })}
      >
        <input {...getInputProps()} />
        <Stack alignItems="center" textAlign="center">
          <Icon type="dropFileIcon" />
          <Typography variant="body2" fontWeight={600} fontSize={14} mt={1.5}>
            Drop your image here to upload
          </Typography>
          <Typography fontSize={14} variant="body2" color="text.main">
            Works with any .JPG, .PNG file, up to 5MB
          </Typography>
        </Stack>
      </Box>

      <Box mt={1}>
        <MDButton {...uploadButtonProps} onClick={open}>
          {buttonTitle}
        </MDButton>
      </Box>
    </Stack>
  );
};
