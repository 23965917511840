import { FC } from 'react';

import { Box, Card, Dialog, Grid, Stack, Table, TableBody, TableContainer } from '@mui/material';
import { getPracticeLibraryTag } from 'apiServices';

import { MDInput } from 'components/MDInput';
import { PopoverWrapper } from 'components/MenuPopover';
import { Scrollbar } from 'components/ScrollBar';
import {
  CustomTableHeader,
  CustomTablePagination,
  TableFiltersPanel,
  TableNoData,
  TableSkeleton,
} from 'components/Table';
import { FilterPopoverContent } from 'pages/TagManagement';

import { CreateTagMenuContent, Dialogs, EditTagCategoryCard, EditTagMenuContent, TagsTableRow } from '../components';
import { TAGS_TABLE_HEADER } from '../config';
import { useEditPracticeLibraryState } from './useEditPracticeLibrary.state';

export const PracticeLibrary: FC = () => {
  const {
    closeDeleteDialogWindow,
    isOpenDeleteDialogWindow,
    onChangeSearchInputValue,
    openDeleteDialogWindow,
    searchValue,
    tags,
    register,
    isSubmitButtonDisabled,
    onFormSubmitHandler,
    isSubmitting,
    isLoading,
    isLoadingTags,
    total,
    page,
    rowsPerPage,
    onChangePage,
    onChangeRowsPerPage,
    appliedFilters,
    onApplyFilters,
    isFiltersMenuOpen,
    closeFiltersMenu,
    openFilterMenu,
    actionState,
    closeActivateDialog,
    closeDeactivateDialog,
    isActivateDialogOpen,
    isActionPending,
    isDeactivateDialogOpen,
    onClickActionOptionHandler,
    onActivateTagHandler,
    onDeactivateTagHandler,
    isAddTagMenuOpen,
    openAddTagMenu,
    closeAddTagMenu,
    isEditTagMenuOpen,
    closeEditTagMenu,
    onClickEditOptionMenuHandler,
    selectedTagId,
    isFiltersApplied,
    createTagHandler,
    editTagHandler,
    order,
    orderBy,
    onSort,
  } = useEditPracticeLibraryState();

  return (
    <Box component="section">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <EditTagCategoryCard
            onFormSubmitHandler={onFormSubmitHandler}
            isSubmitting={isSubmitting}
            isSubmitButtonDisabled={isSubmitButtonDisabled}
            isLoading={isLoading}
          >
            <Stack
              width={1}
              direction={{ xs: 'column', sm: 'row' }}
              spacing={2}
              justifyContent="space-between"
              sx={{ '.MuiStack-root': { width: 1 } }}
            >
              <MDInput required label="Name" placeholder="Enter name" {...register('name')} />
            </Stack>
          </EditTagCategoryCard>
        </Grid>

        <Grid item xs={12}>
          <Card sx={{ borderRadius: 2, overflow: 'hidden' }}>
            <TableFiltersPanel
              searchInputProps={{
                value: searchValue,
                placeholder: 'Search by name',
                onChange: onChangeSearchInputValue,
              }}
              actionButtonProps={{ children: 'Add tag', onClick: openAddTagMenu }}
              onOpenFilterMenuHandler={openFilterMenu}
              isFiltersApplied={isFiltersApplied}
            />

            <TableContainer sx={{ position: 'relative' }}>
              <Scrollbar>
                <Table size="medium" sx={{ width: 1, minWidth: 500 }}>
                  {!isLoadingTags && (
                    <colgroup>
                      <Box component="col" width={1} />
                      <Box component="col" width="20%" />
                    </colgroup>
                  )}
                  <CustomTableHeader
                    headLabel={TAGS_TABLE_HEADER}
                    sx={{
                      th: { p: 1 },
                      'th:first-of-type': { pl: '16px !important' },
                    }}
                    order={order}
                    orderBy={orderBy}
                    onSort={onSort}
                  />

                  <TableBody>
                    {isLoadingTags ? (
                      <TableSkeleton cellsAmount={2} />
                    ) : (
                      tags?.map((tag, index) => (
                        <TagsTableRow
                          key={tag?.id}
                          tagName={tag?.name}
                          index={index}
                          isActive={tag?.isActive}
                          id={tag?.id}
                          openDeleteDialogWindow={openDeleteDialogWindow}
                          onClickEditOptionMenuHandler={onClickEditOptionMenuHandler}
                          onClickActivationOptionHandler={onClickActionOptionHandler}
                        />
                      ))
                    )}
                    <TableNoData isNotFound={!isLoadingTags && !tags.length} />
                  </TableBody>
                </Table>
              </Scrollbar>
            </TableContainer>

            <CustomTablePagination
              count={total}
              page={page - 1}
              rowsPerPage={rowsPerPage}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
            />
          </Card>
        </Grid>
      </Grid>

      {/* TODO after BE implementation */}
      {/* <BaseDialogWindow
        onClickCancelButtonHandler={closeDeleteDialogWindow}
        open={isOpenDeleteDialogWindow}
        description="Are you sure you want to delete tag category?"
        title="Deleting tag category"
        isApproveButtonDisabled={false}
        isApproveButtonLoading={false}
        approveButtonTitle="Delete tag category"
        onClickApproveButtonHandler={() => {}}
      /> */}

      <Dialogs
        isDeactivateDialogOpen={isDeactivateDialogOpen}
        closeDeactivateDialog={closeDeactivateDialog}
        isActivateDialogOpen={isActivateDialogOpen}
        closeActivateDialog={closeActivateDialog}
        isLoading={isActionPending}
        name={actionState.name}
        onActivateTagHandler={onActivateTagHandler}
        onDeactivateTagHandler={onDeactivateTagHandler}
      />

      <PopoverWrapper
        open={isFiltersMenuOpen}
        title="Filter by tags"
        handleClosePopover={closeFiltersMenu}
        sx={{ mt: 1, px: 2, py: 1 }}
        variant="filter"
      >
        <FilterPopoverContent appliedFilters={appliedFilters} onApplyFilters={onApplyFilters} />
      </PopoverWrapper>

      <Dialog open={isAddTagMenuOpen} onClose={closeAddTagMenu} fullWidth>
        <CreateTagMenuContent createTagHandler={createTagHandler} closeMenuHandler={closeAddTagMenu} />
      </Dialog>

      <Dialog open={isEditTagMenuOpen} onClose={closeEditTagMenu} fullWidth>
        <EditTagMenuContent
          getItemFc={getPracticeLibraryTag}
          editTagHandler={editTagHandler}
          closeMenuHandler={closeEditTagMenu}
          tagId={selectedTagId}
        />
      </Dialog>
    </Box>
  );
};
