import { FC, useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Grid, Stack } from '@mui/material';
import { createPrompt, updatePrompt } from 'apiServices/ml/prompt.api';
import { PromptPostRequest } from 'apiServices/ml/types';
import { Resolver, useForm } from 'react-hook-form';

import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import { MDInput } from 'components/MDInput';
import MDTypography from 'components/MDTypography';
import TextFieldWithDropdown from 'components/TextFieldWithDropdown';
import { useRouter } from 'hooks/useRouter';

import AllFields from '../allFields';
import { CreatePromptValidationSchema, defaultPromptValues } from './form.config';

type CreateOrUpdatePromptProps = {
  mode: 'create' | 'update';
  initialData?: PromptPostRequest;
  promptId?: string;
};

export const CreateOrUpdatePrompt: FC<CreateOrUpdatePromptProps> = ({ mode, initialData, promptId }) => {
  const { navigate, state } = useRouter();
  const [text, setText] = useState(initialData?.text || '');
  const [comment, setComment] = useState(initialData?.comment || '');

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isValid, isSubmitting },
    reset,
  } = useForm<PromptPostRequest>({
    mode: 'onTouched',
    resolver: yupResolver(CreatePromptValidationSchema) as Resolver<PromptPostRequest>,
    defaultValues: defaultPromptValues,
  });

  useEffect(() => {
    if (mode === 'update' && initialData) {
      reset(initialData);
      setText(initialData.text || '');
      setComment(initialData.comment || '');
    }
  }, [initialData, mode, reset]);

  useEffect(() => {
    setValue('text', text);
  }, [text, setValue]);

  const onFormSubmitHandler = handleSubmit(async formData => {
    try {
      if (mode === 'create') {
        await createPrompt(formData);
      } else if (mode === 'update' && promptId) {
        await updatePrompt(promptId, formData);
      }
      navigate('/prompt-library', {
        state: {
          text: text,
        },
      });
    } catch (error) {
      console.error('Failed to submit form:', error);
    }
  });

  return (
    <MDBox component="section" height={1}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <MDBox
            sx={{
              width: '100%',
              maxWidth: '1000px',
              margin: '0 auto',
              position: 'relative',
              backgroundColor: 'white',
            }}
          >
            <MDTypography variant="h6">{mode === 'create' ? 'Create Prompt' : 'Update Prompt'}</MDTypography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Stack spacing={3}>
                  <MDInput
                    {...register('name')}
                    fullWidth
                    placeholder="Enter Prompt Name"
                    error={!!errors.name}
                    helperText={errors.name?.message}
                  />
                  <TextFieldWithDropdown text={text} setText={setText} allFields={AllFields} name={'Text'} />
                  <MDTypography>Comment</MDTypography>
                  <MDInput
                    {...register('comment')}
                    fullWidth
                    multiline
                    rows={3}
                    value={comment}
                    onChange={e => setComment(e.target.value)}
                    error={!!errors.comment}
                    helperText={errors.comment?.message}
                  />
                </Stack>
              </Grid>
            </Grid>

            <Box display="flex" justifyContent="space-between" sx={{ marginTop: '20px' }}>
              <MDButton
                variant="outlined"
                onClick={() => navigate(-1)}
                startIcon={<CloseIcon />}
                sx={{
                  borderColor: '#1A73E8',
                  color: '#1A73E8',
                  fontWeight: 'bold',
                  minWidth: '150px',
                }}
              >
                Cancel
              </MDButton>
              <MDButton
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={onFormSubmitHandler}
                disabled={!isValid || isSubmitting}
                sx={{
                  backgroundColor: '#1A73E8',
                  color: 'white',
                  fontWeight: 'bold',
                  minWidth: '150px',
                }}
              >
                {mode === 'create' ? 'Create Prompt' : 'Update Prompt'}
              </MDButton>
            </Box>
          </MDBox>
        </Grid>
      </Grid>
    </MDBox>
  );
};
