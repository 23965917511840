import { FC } from 'react';

import { PlayArrowRounded } from '@mui/icons-material';
import { Box, FormControlLabel, Switch, TablePagination } from '@mui/material';

import { CustomTablePaginationActions } from './CustomTablePaginationActions';
import { TableStyles as Styled } from './Table.styles';
import { TCustomTablePaginationProps } from './Table.types';

export const CustomTablePagination: FC<TCustomTablePaginationProps> = props => {
  const { dense, onChangeDense, rowsPerPageOptions = [20, 30, 40], sx, count, ...other } = props;

  return (
    <Box
      sx={{
        position: 'relative',
        bgcolor: ({ palette }) => palette.background.paper,
        borderBottomRightRadius: 12,
        borderBottomLeftRadius: 12,
        '.MuiTablePagination-root': {
          mt: '-1px',
        },
        ...sx,
      }}
    >
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        ActionsComponent={CustomTablePaginationActions}
        slotProps={{
          select: {
            IconComponent: props => <PlayArrowRounded {...props} fontSize="medium" sx={Styled.SelectIconSX} />,
          },
        }}
        count={count || 0}
        {...other}
      />

      {onChangeDense && (
        <FormControlLabel
          label="Dense"
          control={<Switch checked={dense} onChange={onChangeDense} />}
          sx={Styled.FormControlLabelSX}
        />
      )}
    </Box>
  );
};
