import { FC, memo } from 'react';

import { Box, Card, CardContent, Chip, Stack } from '@mui/material';
import { FileWithPath } from 'react-dropzone';

import rgba from 'assets/theme/functions/rgba';
import { DropZone } from 'components/DropZone';
import { Icon } from 'components/Icon';
import MDButton from 'components/MDButton';
import { UploadFileButton } from 'components/UploadFileButton';

type UploadImageCardProps = {
  previewURL: string;
  onClickRemoveImageHandler: VoidFunction;
  onUploadAnotherFileHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onUploadFileHandler: (file: readonly FileWithPath[]) => void;
  isDisabled?: boolean;
  type: 'before' | 'after';
};

export const UploadImageCard: FC<UploadImageCardProps> = memo(
  ({ previewURL, onUploadAnotherFileHandler, onClickRemoveImageHandler, onUploadFileHandler, isDisabled, type }) => {
    const label = type === 'before' ? 'Before' : 'After';

    return (
      <Card
        sx={{
          overflow: 'hidden',
          width: 1,
          boxShadow: 'none',
          height: 362,
          ...(isDisabled && {
            ':before': {
              content: '""',
              position: 'absolute',
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
              background: theme => rgba(theme.palette.common.white, 0.5),
              zIndex: theme => theme.zIndex.speedDial,
              borderRadius: theme => theme.borders.borderRadius.xl,
            },
          }),
        }}
      >
        {previewURL ? (
          <Stack
            height={1}
            width={1}
            sx={{
              ':hover': {
                '& .action-buttons': {
                  display: 'block',
                },
              },
            }}
          >
            <Chip
              sx={{
                bgcolor: theme => theme.palette.common.white,
                position: 'absolute',
                top: 10,
                left: '50%',
                transform: 'translateX(-50%)',
              }}
              variant="soft"
              color="default"
              label={label}
            />
            <Box sx={{ objectFit: 'cover' }} width={1} height={1} component="img" src={previewURL} />
            <Stack
              spacing={1}
              className="action-buttons"
              sx={{
                position: 'absolute',
                bottom: 16,
                left: 0,
                right: 0,
                mx: 2,
                display: 'none',
              }}
            >
              <UploadFileButton
                startIcon={<Icon type="uploadIcon" />}
                fullWidth
                onChangeHandler={onUploadAnotherFileHandler}
              >
                Upload another
              </UploadFileButton>
              <MDButton fullWidth color="light" onClick={onClickRemoveImageHandler}>
                Remove
              </MDButton>
            </Stack>
          </Stack>
        ) : (
          <CardContent
            sx={{
              height: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              px: 3.5,
              pt: 4,
              ':last-child': { pb: 3.5 },
            }}
          >
            <Stack spacing={1} justifyContent="center" alignItems="center">
              <Box>
                <Chip variant="soft" color="default" label={label} />
              </Box>
              <DropZone
                buttonTitle="Upload image"
                onUploadFileHandler={onUploadFileHandler}
                uploadButtonProps={{ startIcon: <Icon type="uploadIcon" /> }}
              />
            </Stack>
          </CardContent>
        )}
      </Card>
    );
  }
);
