import { BASE_PATIENTS_API_URL } from 'apiServices/api.routes';
import { apiClient } from 'lib/api';

import { AddPatientAllergy, Patient } from './types';

export const addPatientAllergy = async (patientId: string, payload: AddPatientAllergy) => {
  await apiClient.put<Patient>(`${BASE_PATIENTS_API_URL}/${patientId}/allergies`, payload);
};

export const deletePatientAllergy = async (patientId: string, allergyId: string): Promise<void> => {
  await apiClient.delete(`${BASE_PATIENTS_API_URL}/${patientId}/allergies/${allergyId}`);
};
