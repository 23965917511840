import { ExpandMoreRounded } from '@mui/icons-material';
import { Autocomplete, Stack } from '@mui/material';
import { DictionaryItem, MedicalCondition, PatientEnum } from 'apiServices';

import { MDInput } from 'components/MDInput';
import MDTypography from 'components/MDTypography';

import { MDAutocompleteProps } from './types';

export const MDAutocomplete = <TValue extends { id: string } | DictionaryItem | PatientEnum | MedicalCondition>({
  inputProps: { label, ...inputPropsOther },
  inputType,
  ...rest
}: MDAutocompleteProps<TValue>) => {
  return (
    <Stack width={1}>
      <Stack spacing={0.5} component="label">
        {label && (
          <MDTypography variant="caption" fontWeight={400}>
            {label}
          </MDTypography>
        )}
        <Autocomplete
          popupIcon={<ExpandMoreRounded />}
          renderInput={params => (
            <MDInput {...params} InputLabelProps={{ shrink: true }} fullWidth {...inputPropsOther} />
          )}
          {...rest}
        />
      </Stack>
    </Stack>
  );
};
