import { useEffect, useState } from 'react';

import { getPractices } from 'apiServices/practice/practice.api';
import { PracticeListItem } from 'apiServices/practice/types';

export const usePractices = () => {
  const [practices, setPractice] = useState<PracticeListItem[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchPractices = async () => {
      setIsLoading(true);
      try {
        const response = await getPractices({});
        setPractice(response.data.items || []);
      } catch (err) {
        setError('Error fetching Practice');
      } finally {
        setIsLoading(false);
      }
    };

    fetchPractices();
  }, []);

  return { practices, isLoading, error };
};
