import { BASE_DICTIONARY_API_URL } from 'apiServices/api.routes';
import { apiClient } from 'lib/api';

import { DictionaryItem, GetDictionaryItemsParams } from './types';

export const getCountries = async (params: GetDictionaryItemsParams) =>
  await apiClient.get<ListOfItems<DictionaryItem>>(`${BASE_DICTIONARY_API_URL}/countries`, { params });

export const getCountryByISOcode = async (ISOcode: string) =>
  await apiClient.get<DictionaryItem>(`${BASE_DICTIONARY_API_URL}/countries/${ISOcode}`);

export const getCountryStatesByISOcode = async (ISOcode: string, params: GetDictionaryItemsParams) =>
  await apiClient.get<ListOfItems<DictionaryItem>>(`${BASE_DICTIONARY_API_URL}/countries/${ISOcode}/states`, {
    params,
  });
