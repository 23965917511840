import { FC } from 'react';

import { ContentCopyOutlined, ThumbDownOutlined, ThumbUpOutlined } from '@mui/icons-material';
import { Box, Divider, IconButton, Stack } from '@mui/material';
import { runPromptResponseData } from 'apiServices/ml/types';

import { MDAvatar } from 'components/MDAvatar';
import MDTypography from 'components/MDTypography';
import useResponsive from 'hooks/useResponsive';
import { useRouter } from 'hooks/useRouter';

type MainContentProps = {
  responseData: runPromptResponseData;
};

export const MainContent: FC<MainContentProps> = ({ responseData }) => {
  const { state } = useRouter();
  const isSmallScreen = useResponsive('down', 'md');

  const modelOutput = responseData?.data?.result || `Prompt Text: ${state?.text || 'Prompt Text'}`;
  const isModelOutputPresent = !!responseData?.data;

  return (
    <Stack direction={isSmallScreen ? 'column' : 'row'} width={1} height={1}>
      <Stack width={1} height={1} spacing={1.5} py={1} pr={1.5}>
        <MDTypography variant="h5">{`${state?.breadCrumbName}` || 'Prompt Name'}</MDTypography>

        <Box bgcolor="grey.200" p={1} borderRadius="6px">
          <MDTypography variant="body2">
            This prompt provides recommendations for medications and treatments for patients recovering from
            rhinoplasty. It helps doctors select the most suitable medications to manage pain, swelling, and overall
            recovery.
          </MDTypography>
        </Box>

        <Stack spacing={1} flexGrow={1} height={1}>
          <Box
            sx={{
              display: 'flex',
              border: theme => `1px solid ${theme.palette.grey[300]}`,
              p: 1.5,
              borderRadius: '14px',
              flexGrow: 1,
              alignItems: 'flex-start',
            }}
          >
            <Stack direction="row" spacing={1} alignItems="center">
              <MDAvatar avatarSize="sm" />
              <Stack spacing={1}>
                <Box
                  sx={{
                    bgcolor: 'grey.100',
                    borderRadius: '6px',
                    p: 1,
                    wordBreak: 'break-word',
                  }}
                >
                  <MDTypography
                    variant="body2"
                    sx={{
                      color: isModelOutputPresent ? 'inherit' : 'rgba(0, 0, 0, 0.5)',
                    }}
                  >
                    {modelOutput}
                  </MDTypography>
                </Box>
              </Stack>
            </Stack>
          </Box>

          <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1}>
            <IconButton disabled size="small">
              <ContentCopyOutlined fontSize="medium" />
            </IconButton>
            <IconButton disabled size="small">
              <ThumbUpOutlined fontSize="medium" />
            </IconButton>
            <IconButton disabled size="small">
              <ThumbDownOutlined fontSize="medium" />
            </IconButton>
          </Stack>
        </Stack>
      </Stack>

      <Divider orientation={isSmallScreen ? 'horizontal' : 'vertical'} />
    </Stack>
  );
};
