import colors from 'assets/theme/base/colors';

const formHelperText: any = {
  defaultProps: { component: 'div' },
  styleOverrides: {
    root: {
      whiteSpace: 'initial',
      fontSize: 12,
      fontWeight: 400,
      marginLeft: 0,
      backgroundSize: 12,
      marginTop: 0,
      '&.Mui-error': {
        color: colors.error.main,
      },
    },
  },
};

export default formHelperText;
