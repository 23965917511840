import { AddOutlined, SearchRounded } from '@mui/icons-material';
import { Box, Stack } from '@mui/material';

import { Icon } from 'components/Icon';
import MDButton from 'components/MDButton';
import { MDInput } from 'components/MDInput';
import { MDSelectButton } from 'components/MDSelectButton';

import { TableTabsFiltersPanelProps } from './types';

export const TableTabsFilterPanel = <TMenuItem extends string>({
  onOpenFilterMenuHandler,
  actionButtonProps,
  searchInputProps,
  selectButtonMenuItems,
  setButtonOption,
  isFiltersApplied,
  tabs,
}: TableTabsFiltersPanelProps<TMenuItem>) => {
  return (
    <Box sx={{ p: 1.5 }}>
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        justifyContent="space-between"
        alignItems="center"
        spacing={{ xs: 2, sm: 1 }}
      >
        <Stack component="ul" sx={{ listStyleType: 'none' }} direction="row" alignItems="center">
          {tabs}
        </Stack>

        <Stack direction="row" alignItems="center" spacing={2}>
          <MDInput
            size="small"
            InputProps={{
              startAdornment: <SearchRounded fontSize="medium" color="action" />,
            }}
            {...searchInputProps}
          />

          <Stack direction="row" alignItems="center" spacing={1}>
            <MDButton
              variant="outlined"
              size="small"
              sx={{
                lineHeight: 'initial',
                minWidth: 'unset',
                width: 40,
              }}
              onClick={onOpenFilterMenuHandler}
            >
              <Icon
                type="filter"
                sx={{
                  width: 20,
                  height: 20,
                  alignItems: 'center',
                }}
              />
              {isFiltersApplied && (
                <Box
                  component="span"
                  ml={0.5}
                  sx={{ flexShrink: 0, width: 6, height: 6, borderRadius: '50%', bgcolor: 'success.light' }}
                />
              )}
            </MDButton>
            {actionButtonProps && !setButtonOption && (
              <MDButton
                startIcon={<AddOutlined sx={{ width: 20, height: 20 }} />}
                {...actionButtonProps}
                sx={{ lineHeight: 'initial', ...actionButtonProps?.sx }}
                size="small"
              />
            )}

            {setButtonOption && selectButtonMenuItems && (
              <MDSelectButton
                size="small"
                sx={{ lineHeight: 'initial' }}
                setButtonOption={setButtonOption}
                menuItems={selectButtonMenuItems}
              />
            )}
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};
