import { FC } from 'react';

import { CardContent, Skeleton, Stack } from '@mui/material';

type FormContentSkeletonProps = {
  rows?: number;
};
export const FormContentSkeleton: FC<FormContentSkeletonProps> = ({ rows }) => {
  return (
    <CardContent sx={{ pt: 2 }}>
      <Stack spacing={2.5}>
        {[...Array(rows)].map((_, index) => (
          <Stack
            key={index}
            direction={{ xs: 'column', sm: 'row' }}
            alignItems="center"
            justifyContent="space-between"
            spacing={2.5}
            width={1}
          >
            <Skeleton variant="rounded" sx={{ minHeight: 30, width: 1 }} />

            <Skeleton variant="rounded" sx={{ minHeight: 30, width: 1 }} />

            <Skeleton variant="rounded" sx={{ minHeight: 30, width: 1 }} />
          </Stack>
        ))}
      </Stack>
    </CardContent>
  );
};
