import { CreatePatientPayload } from 'apiServices';
import * as yup from 'yup';

import { EmailLazyValidationSchema, PhoneValidationSchema } from 'utils/formValidation';

export type CreatePatientFormSchema = Pick<CreatePatientPayload['person'], 'firstName' | 'lastName' | 'middleName'> &
  Pick<CreatePatientPayload['contact'], 'email' | 'phone'> &
  Pick<CreatePatientPayload, 'birthDate' | 'practiceId'> & {
    rag?: string;
  };

export const createPatientValidationSchema = yup
  .object({
    firstName: yup.string().required().label('First name'),
    middleName: yup.string().optional().label('Middle name').nullable(),
    lastName: yup.string().required().label('Last name'),
    phone: PhoneValidationSchema,
    email: EmailLazyValidationSchema,
    rag: yup.string().optional().label('RAG').nullable(),
    birthDate: yup.string().required().label('Date of birth'),
    practiceId: yup.string().required().label('Practice'),
  })
  .required();

export const patientFormDefaultValues: CreatePatientFormSchema = {
  birthDate: '',
  rag: '',
  firstName: '',
  middleName: '',
  lastName: '',
  email: '',
  phone: '',
  practiceId: '',
};
