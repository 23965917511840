const AllFields = {
  Person: {
    model: 'Person',
    fields: ['firstName', 'lastName', 'middleName', 'position'],
  },
  Address: {
    model: 'Address',
    fields: ['countryIsoCode', 'stateIsoCode', 'cityId', 'line1', 'line2', 'zip', 'latitude', 'longitude'],
  },
  Contact: {
    model: 'Contact',
    fields: ['email', 'phone', 'website', 'homeAddressId', 'billingAddressId'],
  },
  Patient: {
    model: 'Patient',
    fields: ['practiceId', 'userId', 'ssn', 'birthDate', 'gender', 'ethnicGroup', 'createdAt', 'updatedAt'],
  },
  PatientCard: {
    model: 'PatientCard',
    fields: [
      'gender',
      'bioGender',
      'ethnicGroup',
      'painTolerance',
      'title',
      'bloodType',
      'ssn',
      'birthDate',
      'userId',
      'allergyIcd10Code',
      'medicalConditionIcd10Code',
      'symptoms',
      'customConsultationWorkflow',
      'rag',
    ],
  },
  PatientAllergy: {
    model: 'PatientAllergy',
    fields: ['patientCardId', 'allergyIcd10Code'],
  },
  PatientMedicalCondition: {
    model: 'PatientMedicalCondition',
    fields: ['patientCardId', 'medicalConditionIcd10Code', 'symptoms'],
  },
  Practice: {
    model: 'Practice',
    fields: ['id', 'name', 'nameShort', 'rag', 'isActive', 'createdAt', 'updatedAt'],
  },
  PersonContact: {
    model: 'Person',
    fields: ['firstName', 'lastName', 'title', 'middleName', 'position', 'practiceId', 'expertId', 'contactId'],
  },
  Service: {
    model: 'Service',
    fields: ['id', 'title', 'description', 'price', 'isActive', 'isPreferred', 'createdAt', 'updatedAt'],
  },
  Office: {
    model: 'Office',
    fields: [
      'id',
      'label',
      'countryIsoCode',
      'stateIsoCode',
      'cityId',
      'line1',
      'line2',
      'zip',
      'latitude',
      'longitude',
    ],
  },
  Expert: {
    model: 'Expert',
    fields: ['id', 'isActive', 'role', 'name'],
  },
  WorkingDay: {
    model: 'WorkingDay',
    fields: ['id', 'weekDay', 'isWorking', 'openAt', 'closeAt', 'isLunch', 'lunchStartAt', 'lunchEndAt'],
  },
  Attachment: {
    model: 'Attachment',
    fields: [
      'visitId',
      'patientId',
      'expertId',
      'attachmentType',
      'filePath',
      'fileSize',
      'fileExtension',
      'fileMetadata',
    ],
  },
};

export default AllFields;
