import { AddOutlined, SearchRounded } from '@mui/icons-material';
import { Box, Stack } from '@mui/material';

import { Icon } from 'components/Icon';
import MDButton from 'components/MDButton';
import { MDInput } from 'components/MDInput';
import { MDSelectButton } from 'components/MDSelectButton';

import { TableFiltersPanelProps } from './types';

export const TableFiltersPanel = <TMenuItem extends string>({
  onOpenFilterMenuHandler,
  actionButtonProps,
  secondaryActionButtonProps,
  searchInputProps,
  selectButtonMenuItems,
  setButtonOption,
  isFiltersApplied,
}: TableFiltersPanelProps<TMenuItem>) => {
  return (
    <Box sx={{ p: 1.5 }}>
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        justifyContent="space-between"
        alignItems="center"
        spacing={{ xs: 2, sm: 1 }}
      >
        <MDInput
          size="small"
          InputProps={{
            startAdornment: <SearchRounded fontSize="medium" color="action" />,
          }}
          {...searchInputProps}
        />
        <Stack direction="row" alignItems="center" spacing={1}>
          <MDButton
            variant="outlined"
            color="primary"
            size="small"
            sx={{
              lineHeight: 'initial',
              minWidth: 'unset',
              width: 40,
            }}
            onClick={onOpenFilterMenuHandler}
          >
            <Icon
              type="filter"
              sx={{
                width: 20,
                height: 20,
                alignItems: 'center',
              }}
            />
            {isFiltersApplied && (
              <Box
                component="span"
                ml={0.5}
                sx={{ flexShrink: 0, width: 6, height: 6, borderRadius: '50%', bgcolor: 'success.light' }}
              />
            )}
          </MDButton>

          {actionButtonProps && !setButtonOption && (
            <MDButton
              startIcon={<AddOutlined sx={{ width: 20, height: 20 }} />}
              {...actionButtonProps}
              sx={{ lineHeight: 'initial', ...actionButtonProps?.sx }}
              size="small"
            />
          )}
          {secondaryActionButtonProps && !setButtonOption && (
            <MDButton
              startIcon={<AddOutlined sx={{ width: 20, height: 20 }} />}
              {...secondaryActionButtonProps}
              sx={{ lineHeight: 'initial', ...secondaryActionButtonProps?.sx }}
              size="small"
            />
          )}

          {setButtonOption && selectButtonMenuItems && (
            <MDSelectButton
              size="small"
              sx={{ lineHeight: 'initial' }}
              setButtonOption={setButtonOption}
              menuItems={selectButtonMenuItems}
            />
          )}
        </Stack>
      </Stack>
    </Box>
  );
};
