import React, { FC, memo, useEffect, useState } from 'react';

import { Card, Stack } from '@mui/material';

import { DiscardChangesDialogWindow } from 'components/BaseDialogWindow';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import { MDSelectButton } from 'components/MDSelectButton';
import { useDiscardChangesDialogWindow } from 'hooks';
import { useThrottle } from 'hooks/useThrottle';
import { BUTTON_OPTIONS } from 'types/enums';

const MENU_ITEMS: BUTTON_OPTIONS[] = [BUTTON_OPTIONS.SAVE_CLOSE, BUTTON_OPTIONS.SAVE_CONTINUE_EDITING];

type FormActionsCardProps = {
  isDisabled: boolean;
  isLoading: boolean;
  onFormSubmitHandler: (e?: React.BaseSyntheticEvent<object, any, any>) => Promise<void>;
  setButtonOption?: React.Dispatch<React.SetStateAction<BUTTON_OPTIONS>>;
  backRoute: string;
  isFormDirty: boolean;
};

export const FormActionsCard: FC<FormActionsCardProps> = memo(
  ({ isDisabled, isLoading, onFormSubmitHandler, setButtonOption, isFormDirty, backRoute }) => {
    const [isScrolling, setIsScrolling] = useState(false);

    const handlerScroll = () => {
      const scrolledTo = window.scrollY + window.innerHeight;
      const isReachBottom = Math.floor(document.body.scrollHeight) === Math.floor(scrolledTo);

      if (!isReachBottom) setIsScrolling(true);
      else {
        setIsScrolling(false);
      }
    };

    const throttledScrollHandler = useThrottle(handlerScroll, 250);

    useEffect(() => {
      window.addEventListener('scroll', throttledScrollHandler);
      return () => {
        window.removeEventListener('scroll', throttledScrollHandler);
      };
    }, [throttledScrollHandler]);

    const {
      closeDiscardDialogWindow,
      isOpenDiscardDialogWindow,
      onClickCancelButtonHandler,
      onClickDiscardButtonHandler,
    } = useDiscardChangesDialogWindow({ backRoute, isDirty: isFormDirty });

    return (
      <>
        <Card
          sx={{
            border: theme => `1px solid ${theme.palette.grey[200]}`,
            position: 'sticky',
            bottom: 10,
            zIndex: theme => theme.zIndex.fab,
            ...(isScrolling && {
              boxShadow: theme => theme.boxShadows.colored.dark,
            }),
          }}
        >
          <MDBox p={2} sx={{ background: 'none' }}>
            <Stack direction={{ xs: 'column', md: 'row' }} justifyContent="flex-end" spacing={2}>
              <MDButton variant="outlined" onClick={onClickCancelButtonHandler}>
                Cancel
              </MDButton>
              {setButtonOption ? (
                <MDSelectButton
                  onClick={onFormSubmitHandler}
                  disabled={isDisabled}
                  isLoading={isLoading}
                  setButtonOption={setButtonOption}
                  fullWidth
                  menuItems={MENU_ITEMS}
                />
              ) : (
                <MDButton type="submit" onClick={onFormSubmitHandler} disabled={isDisabled} isLoading={isLoading}>
                  Save
                </MDButton>
              )}
            </Stack>
          </MDBox>
        </Card>

        <DiscardChangesDialogWindow
          open={isOpenDiscardDialogWindow}
          onClickCancelButtonHandler={onClickDiscardButtonHandler}
          onClickApproveButtonHandler={closeDiscardDialogWindow}
        />
      </>
    );
  }
);
