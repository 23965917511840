import { PatientSortFieldName } from 'apiServices';

export const PATIENTS_TABLE_HEAD: TableHeaderConfig<PatientSortFieldName>[] = [
  { id: 'person.last_name', label: 'Last name' },
  { id: 'person.first_name', label: 'First name' },
  { id: 'middleName', label: 'Middle name', disableSorting: true },
  { id: 'birthDate', label: 'Date of birth (Age)', disableSorting: true },
  { id: 'gender', label: 'Gender', disableSorting: true },
  { id: 'ethnicGroup', label: 'Ethnic group', disableSorting: true },
];
