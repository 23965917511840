import { BASE_DICTIONARY_API_URL } from 'apiServices/api.routes';
import { apiClient } from 'lib/api';

import { DictionaryCityItem, GetDictionaryItemsParams } from './types';

export const getStateCities = async (ISOcode: string, params: GetDictionaryItemsParams) =>
  await apiClient.get<ListOfItems<DictionaryCityItem>>(`${BASE_DICTIONARY_API_URL}/states/${ISOcode}/cities`, {
    params,
  });

export const getCityById = async (cityId: string) =>
  await apiClient.get<DictionaryCityItem>(`${BASE_DICTIONARY_API_URL}/cities/${cityId}`);
