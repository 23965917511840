import { FC } from 'react';

import { Box, Chip, ChipProps } from '@mui/material';

type MDChipProps = ChipProps;

export const MDChip: FC<MDChipProps> = ({ color, ...other }) => {
  return (
    <Chip
      color={color}
      icon={
        <Box
          sx={{
            width: 6,
            height: 6,
            borderRadius: '50%',
            bgcolor: ({ palette }) => (color === 'default' ? palette.grey[800] : palette[color].main),
          }}
        />
      }
      {...other}
    />
  );
};
