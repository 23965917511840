import { FC } from 'react';

import { CardContent, Skeleton, Stack } from '@mui/material';

type FormCardSkeletonProps = {
  rows?: number;
};

export const FormSkeleton: FC<FormCardSkeletonProps> = ({ rows = 3 }) => {
  return (
    <CardContent sx={{ pt: 2 }}>
      <Skeleton variant="rounded" sx={{ minHeight: 20, width: '60%', mb: 2 }} />

      <Stack spacing={2}>
        {[...Array(rows)].map((_, index) => (
          <Stack key={index} direction="row" alignItems="center" justifyContent="space-between" gap={2} width={1}>
            <Skeleton variant="rounded" sx={{ minHeight: 30, width: 1 }} />

            <Skeleton variant="rounded" sx={{ minHeight: 30, width: 1 }} />
          </Stack>
        ))}
      </Stack>
    </CardContent>
  );
};
