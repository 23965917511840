import { useCallback } from 'react';

import { useAppStore } from 'store';
import { shallow } from 'zustand/shallow';

import { useCognitoAuthContext } from 'context';
import { UserRoles } from 'types/enums';

export const useUserProfile = () => {
  const { setCognitoProfile, cognitoUser, practiceProfile, getProfileLegalDocsHandler, setUserProfile } = useAppStore(
    store => ({
      setUserProfile: store.setUserProfile,
      setCognitoProfile: store.setCognitoProfile,
      cognitoUser: store.cognitoUser,
      practiceProfile: store.practiceProfile,
      getProfileLegalDocsHandler: useCallback(store.getProfileLegalDocsHandler, []),
    }),
    shallow
  );

  const userRole = practiceProfile?.role || cognitoUser?.roles?.[0];
  const isRoleAdmin = userRole === UserRoles.ROLE_ADMIN;

  const practiceId = practiceProfile?.practice?.id;

  return {
    setCognitoProfile,
    cognitoUser,
    isRoleAdmin,
    practiceProfile,
    userRole,
    getProfileLegalDocsHandler,
    setUserProfile,
    practiceId,
  };
};
