import { FC, memo } from 'react';

import { Divider, Stack } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import { FormHeader, FormItem } from 'components/Form';
import { RHFAutocompleteField, RHFDatePicker, RHFTextField } from 'components/HookForm';

import { PATIENT_PROFILE_ID } from '../../constants';
import { EditPatientFormSchema } from './form.config';
import { PatientProfileCardProps } from './types';

export const PatientProfileCard: FC<PatientProfileCardProps> = memo(
  ({
    personTitles,
    ethnicGroups,
    genders,
    cardRef,
    memoizedGetOptionLabel,
    memoizedIsOptionEqualToValue,
    isAllPatientEnumsLoading,
  }) => {
    const {
      register,
      formState: { errors },
      control,
    } = useFormContext<EditPatientFormSchema>();

    return (
      <>
        <Stack ref={cardRef} id={PATIENT_PROFILE_ID} sx={{ p: { xs: 2, sm: 2.5, md: 3, lg: 3.5 } }}>
          <FormHeader title="Patient profile" subtitle="Fill out the details below to create a new patient." />

          <Stack>
            <FormItem title="First name" isRequired>
              <RHFTextField
                register={register}
                registerName="person.firstName"
                registerErrors={errors.person?.firstName?.message}
                fullWidth
                placeholder="Enter first name"
              />
            </FormItem>

            <FormItem title="Middle name">
              <RHFTextField
                register={register}
                registerName="person.middleName"
                registerErrors={errors.person?.middleName?.message}
                fullWidth
                placeholder="Enter middle name"
              />
            </FormItem>

            <FormItem title="Last name" isRequired>
              <RHFTextField
                register={register}
                registerName="person.lastName"
                registerErrors={errors.person?.lastName?.message}
                fullWidth
                placeholder="Enter last name"
              />
            </FormItem>

            <FormItem title="Date of birth" isRequired>
              <RHFDatePicker control={control} name="birthDate" isDisableFuture placeholder="Select date of birth" />
            </FormItem>

            <FormItem title="Gender">
              <RHFAutocompleteField
                control={control}
                name="gender"
                placeholder="Select gender"
                options={genders}
                getOptionLabel={memoizedGetOptionLabel}
                isOptionEqualToValue={memoizedIsOptionEqualToValue}
                loading={isAllPatientEnumsLoading}
              />
            </FormItem>

            <FormItem title="Ethnic group">
              <RHFAutocompleteField
                control={control}
                name="ethnicGroup"
                placeholder="Select ethnic group"
                options={ethnicGroups}
                getOptionLabel={memoizedGetOptionLabel}
                isOptionEqualToValue={memoizedIsOptionEqualToValue}
                loading={isAllPatientEnumsLoading}
              />
            </FormItem>

            <FormItem title="Social Security number">
              <RHFTextField
                register={register}
                registerName="ssn"
                registerErrors={errors?.ssn?.message}
                fullWidth
                type="number"
                placeholder="Enter social security number"
              />
            </FormItem>

            <FormItem title="Position">
              <RHFTextField
                register={register}
                registerName="person.position"
                registerErrors={errors.person?.position?.message}
                fullWidth
                placeholder="Enter position"
              />
            </FormItem>

            <FormItem title="Title" isDivider={false}>
              <RHFAutocompleteField
                control={control}
                name="person.title"
                placeholder="Select title"
                options={personTitles}
                getOptionLabel={memoizedGetOptionLabel}
                isOptionEqualToValue={memoizedIsOptionEqualToValue}
                loading={isAllPatientEnumsLoading}
              />
            </FormItem>
          </Stack>
        </Stack>

        <Divider />
      </>
    );
  }
);
