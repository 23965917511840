import { FC, memo } from 'react';

import { Box } from '@mui/material';

import { StyledRootScrollbar, StyledScrollbar } from './scrollBar.styles';
import { IScrollbarProps } from './types';

import 'simplebar-react/dist/simplebar.min.css';

export const Scrollbar: FC<IScrollbarProps> = memo(props => {
  const { children, sx, ...other } = props;

  const userAgent = typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent;

  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
  if (isMobile) {
    return (
      <Box sx={{ overflowX: 'auto', ...sx }} {...other}>
        {children}
      </Box>
    );
  }

  return (
    <StyledRootScrollbar>
      <StyledScrollbar clickOnTrack={true} sx={sx} {...other}>
        {children}
      </StyledScrollbar>
    </StyledRootScrollbar>
  );
});
