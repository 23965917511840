import { ReactNode, forwardRef } from 'react';

import { ButtonProps, CircularProgress } from '@mui/material';

import MDButtonRoot, { ButtonOwnerState } from 'components/MDButton/MDButtonRoot';
import { useMaterialUIController } from 'context/MaterialUIContextProvider';

export interface MDButtonProps extends Omit<ButtonProps, 'color' | 'variant'>, ButtonOwnerState {
  children?: ReactNode;
  isLoading?: boolean;
  component?: React.ElementType;
}

const MDButton = ({
  color = 'primary',
  variant = 'contained',
  size = 'medium',
  circular,
  iconOnly,
  children,
  isLoading,
  startIcon,
  endIcon,
  ...rest
}: MDButtonProps) => {
  const { controller } = useMaterialUIController();
  const { darkMode } = controller;

  return (
    <MDButtonRoot
      {...rest}
      color="primary"
      variant={variant === 'gradient' ? 'contained' : variant}
      size={size}
      ownerState={{ color, variant, size, circular, iconOnly, darkMode }}
      startIcon={isLoading ? null : startIcon}
      endIcon={isLoading ? null : endIcon}
    >
      {isLoading ? (
        <CircularProgress size={20} sx={{ color: variant === 'outlined' ? 'info' : 'white !important' }} />
      ) : (
        children
      )}
    </MDButtonRoot>
  );
};

export default MDButton;
