import { FC, memo, useState } from 'react';

import { Box, FormControlLabel, Stack, Switch } from '@mui/material';

import { FilterMenuPopoverButtons } from 'components/MenuPopover';

import { AppliedFilters, FiltersInitialState } from '../types';

type FilterPopoverProps = {
  appliedFilters: AppliedFilters;
  onApplyFilters: (newFilters: FiltersInitialState) => void;
  isHideShowDeactived?: boolean;
};

export const FilterPopoverContent: FC<FilterPopoverProps> = memo(
  ({ isHideShowDeactived, appliedFilters, onApplyFilters }) => {
    const [filters, setFilters] = useState(appliedFilters);

    const onClickResetFiltersButtonHandler = () => setFilters({ isShowDeactivated: false });

    const onClickApplyFiltersButtonHandler = async () => onApplyFilters(filters);

    const isApplyButtonDisabled = appliedFilters.isShowDeactivated === filters.isShowDeactivated;

    const isResetButtonDisabled = filters.isShowDeactivated === false;

    const onChangeSwitchHandler = (_: React.SyntheticEvent<Element, Event>, checked: boolean) => {
      setFilters(prevState => ({ ...prevState, isShowDeactivated: checked }));
    };

    return (
      <Stack position="relative">
        <Stack
          mt={1}
          height={1}
          spacing={2}
          sx={{
            mb: '50px',
            py: '10px',
          }}
        >
          {isHideShowDeactived ? null : (
            <Box>
              <FormControlLabel
                onChange={onChangeSwitchHandler}
                checked={filters.isShowDeactivated}
                control={<Switch />}
                label="Show deactivated"
                labelPlacement="start"
              />
            </Box>
          )}
        </Stack>

        <FilterMenuPopoverButtons
          isClearButtonDisabled={isResetButtonDisabled}
          onClickClearButtonHandler={onClickResetFiltersButtonHandler}
          isApplyButtonDisabled={isApplyButtonDisabled}
          onClickApplyButtonHandler={onClickApplyFiltersButtonHandler}
        />
      </Stack>
    );
  }
);
