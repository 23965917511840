import { FC } from 'react';

import { Card, CardContent, Skeleton, Stack } from '@mui/material';

export const PracticeHeaderSkeleton: FC = () => {
  return (
    <Card>
      <CardContent sx={{ py: '16px !important' }}>
        <Stack direction="row" spacing={2}>
          <Stack spacing={2}>
            <Skeleton variant="rounded" sx={{ width: 200, height: 20 }} />
            <Skeleton variant="rounded" sx={{ width: 150, height: 20 }} />
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};
