import { FC, useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { Box, Card, CardContent, CardHeader, Divider, Grid, IconButton, Stack, TextField } from '@mui/material';
import { addAIModel, getAIModelById, updateAIModel } from 'apiServices/ml/ai-model.api';
import { AIModelPatchRequest, AIModelPostRequest } from 'apiServices/ml/types';
import { Resolver, useFieldArray, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import { ROUTES } from 'constants/routes';
import { useRouter } from 'hooks/useRouter';

import { AddAIModelValidationSchema } from './form.config';

type AddModelProps = {
  mode?: 'create' | 'update';
};

export const AddModel: FC<AddModelProps> = ({ mode = 'create' }) => {
  const { navigate, state, params } = useRouter();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isValid, isSubmitting },
    reset,
  } = useForm<AIModelPostRequest | AIModelPatchRequest>({
    mode: 'onTouched',
    resolver: yupResolver(AddAIModelValidationSchema) as Resolver<AIModelPatchRequest>,
    defaultValues: {
      name: '',
      parameters: [{ param_name: 'Parameter name', param_type: 'string' }],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'parameters',
  });

  useEffect(() => {
    if (mode === 'update' && state?.model) {
      reset(state.model);
    }
  }, [mode, state, reset]);

  const onFormSubmitHandler = handleSubmit(async formData => {
    try {
      if (mode === 'create') {
        await addAIModel(formData as AIModelPostRequest);
      } else {
        await updateAIModel(params.id.trim(), formData as AIModelPatchRequest);
      }
      navigate(ROUTES.modelList);
    } catch (error) {
      console.error(`Failed to ${mode === 'create' ? 'create' : 'update'} model:`, error);
    }
  });

  return (
    <Box component="section" height={1}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <CardHeader title={mode === 'create' ? 'Add AI Model' : 'Update AI Model'} />
            <CardContent>
              <Stack
                component="form"
                onSubmit={onFormSubmitHandler}
                spacing={3}
                divider={<Divider sx={{ mt: '0 !important' }} />}
              >
                <Stack spacing={3} width={1}>
                  <TextField
                    {...register('name')}
                    fullWidth
                    label="Model Name"
                    error={!!errors.name}
                    helperText={errors.name?.message}
                    sx={{ marginBottom: '20px' }}
                  />
                </Stack>

                <Stack spacing={3} width={1}>
                  <Box>
                    <h3>Parameters</h3>
                    {fields.map((field, index) => (
                      <Stack
                        key={field.id}
                        direction="row"
                        spacing={2}
                        alignItems="center"
                        sx={{ marginBottom: '20px' }}
                      >
                        <TextField
                          {...register(`parameters.${index}.param_name` as const)}
                          fullWidth
                          label="Parameter Name"
                          error={!!errors.parameters?.[index]?.param_name}
                          helperText={errors.parameters?.[index]?.param_name?.message}
                          sx={{ marginBottom: '10px' }}
                        />
                        <TextField
                          {...register(`parameters.${index}.param_type` as const)}
                          fullWidth
                          label="Parameter Type"
                          error={!!errors.parameters?.[index]?.param_type}
                          helperText={errors.parameters?.[index]?.param_type?.message}
                          sx={{ marginBottom: '10px' }}
                        />
                        {fields.length > 1 && (
                          <IconButton color="error" onClick={() => remove(index)}>
                            <RemoveCircleIcon />
                          </IconButton>
                        )}
                      </Stack>
                    ))}
                  </Box>
                  <IconButton color="primary" onClick={() => append({ param_name: '', param_type: '' })}>
                    <AddCircleIcon />
                  </IconButton>
                </Stack>
              </Stack>
            </CardContent>
          </Card>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            position: 'sticky',
            bottom: 10,
            zIndex: theme => theme.zIndex.fab,
          }}
        >
          <Card sx={{ border: theme => `1px solid ${theme.palette.grey[200]}` }}>
            <MDBox p={2} sx={{ background: 'none' }}>
              <Stack direction={{ xs: 'column', md: 'row' }} justifyContent={'flex-end'} spacing={2}>
                <MDButton variant="outlined" onClick={() => window.history.back()}>
                  Cancel
                </MDButton>
                <MDButton type="submit" onClick={onFormSubmitHandler} disabled={!isValid || isSubmitting}>
                  {mode === 'create' ? 'Add Model' : 'Update Model'}
                </MDButton>
              </Stack>
            </MDBox>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};
