import { CSSObject, SwitchProps, alpha } from '@mui/material';

import borders from 'assets/theme/base/borders';
import colors from 'assets/theme/base/colors';

const { white, grey } = colors;
const { borderRadius } = borders;

const rootStyle = (ownerState: SwitchProps): CSSObject => ({
  width: 48,
  height: 24,
  padding: 0,
  borderRadius: borderRadius.xl,
  overflow: 'hidden',

  ...(ownerState.size === 'small' && {
    width: 40,
    height: 20,
  }),
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    color: `${white.main} !important`,
    ...(ownerState.size === 'small' && {
      width: 16,
      height: 16,
    }),
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    borderRadius: 14,
    backgroundColor: alpha(grey[400], 0.48),
  },
  '& .MuiSwitch-switchBase': {
    padding: 0,
    transitionDuration: '300ms',

    top: '50%',
    transform: 'translate(2px,-50%)',
    '&.Mui-checked': {
      transform: 'translate(25px, -50%)',

      '&+.MuiSwitch-track': { opacity: 1 },
      ...(ownerState.size === 'small' && {
        transform: 'translate(22px, -50%)',
      }),
    },
    '&.Mui-disabled': {
      '& .MuiSwitch-thumb': { opacity: 1 },
      '&+.MuiSwitch-track': { opacity: 0.48 },
    },
  },
});

const switchButton = {
  styleOverrides: {
    root: ({ ownerState }: { ownerState: SwitchProps }) => {
      return rootStyle(ownerState);
    },
  },
};

export default switchButton;
