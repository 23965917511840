import { alpha, lighten } from '@mui/material';

import colors from 'assets/theme/base/colors';

const { info, dark, grey } = colors;

const globals = {
  html: {
    scrollBehavior: 'smooth',
    minHeight: '100%',
  },
  body: {
    backgroundColor: lighten(grey[200], 0.1),
    height: '100%',
    scrollbarWidth: 'thin',
    scrollbarColor: `${alpha(grey[500], 0.48)} white`,
    '::-webkit-scrollbar': {
      width: 10,
    },

    '::-webkit-scrollbar-track': {
      background: 'white',
    },

    '::-webkit-scrollbar-thumb': {
      background: alpha(grey[500], 0.48),
      borderRadius: 6,
    },

    '::-webkit-scrollbar-thumb:hover': {
      background: alpha(grey[600], 0.48),
    },
  },

  '#root': {
    width: '100%',
    minHeight: '100%',
  },
  '*, *::before, *::after': {
    margin: 0,
    padding: 0,
  },
  'a, a:link, a:visited': {
    textDecoration: 'none',
  },
  'a.link, .link, a.link:link, .link:link, a.link:visited, .link:visited': {
    color: `${dark.main} !important`,
    transition: 'color 150ms ease-in !important',
  },
  'a.link:hover, .link:hover, a.link:focus, .link:focus': {
    color: `${info.main} !important`,
  },
  input: {
    '&[type=number]': {
      MozAppearance: 'textfield',
      '&::-webkit-outer-spin-button': {
        margin: 0,
        WebkitAppearance: 'none',
      },
      '&::-webkit-inner-spin-button': {
        margin: 0,
        WebkitAppearance: 'none',
      },
    },
  },

  'input:-webkit-autofill,input:-webkit-autofill:hover,input:-webkit-autofill:focus,input:-webkit-autofill:active': {
    transition: 'background-color 600000s 0s, color 600000s 0s',
  },
};

export default globals;
