import { FC, useState } from 'react';

import { Collapse } from '@mui/material';

import { useActiveLink } from 'hooks/useActiveLink';

import { NavItem } from '../NavItem';
import { NavSubList } from './NavSublist';
import { INavListRootProps } from './navList.types';

export const NavList: FC<INavListRootProps> = props => {
  const { data, depth, pathes, hasChild, isNavMini } = props;

  const { active, isExternalLink } = useActiveLink(
    pathes?.length ? pathes : data.path.path,
    hasChild || data.path?.isDeepActive
  );

  const [isOpen, setIsOpen] = useState(active);

  const handleToggle = () => setIsOpen(!isOpen);

  return (
    <>
      <NavItem
        item={data}
        depth={depth}
        open={isOpen}
        active={active}
        isExternalLink={isExternalLink}
        onClick={handleToggle}
        isNavMini={isNavMini}
      />

      {hasChild && (
        <Collapse in={isOpen} unmountOnExit>
          <NavSubList isNavMini={isNavMini} data={data.children} depth={depth} />
        </Collapse>
      )}
    </>
  );
};
