import { Theme, alpha } from '@mui/material';

type Props = { theme: Theme; isActive: boolean; isDisabled?: boolean };

export const sideNavMenuItemStyles = ({
  theme: {
    borders: { borderRadius },
    palette: { primary, light, grey },
    transitions,
  },
  isActive,
  isDisabled,
}: Props) => ({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: isActive ? alpha(primary.light, 0.1) : 'inherit',
  borderRadius: borderRadius.md,
  color: isActive ? primary.main : 'inherit',
  padding: 1,
  transition: transitions.create('background-color', {
    easing: transitions.easing.easeInOut,
    duration: transitions.duration.shorter,
  }),
  '&:hover': {
    backgroundColor: light.main,
  },

  ...(isDisabled && {
    pointerEvents: 'none',
    backgroundColor: grey[300],
  }),
});
