import { FC, SVGProps } from 'react';

import { Box, SxProps, Theme } from '@mui/material';

import { ReactComponent as add } from 'assets/icons/add_icon.svg';
import { ReactComponent as arrowLeftIcon } from 'assets/icons/arrow_left_icon.svg';
import { ReactComponent as beforeAfterFilled } from 'assets/icons/before_after_filled_icon.svg';
import { ReactComponent as beforeAfter } from 'assets/icons/before_after_icon.svg';
import { ReactComponent as calendar } from 'assets/icons/calendar_icon.svg';
import { ReactComponent as catalog } from 'assets/icons/catalog_icon.svg';
import { ReactComponent as closeIcon } from 'assets/icons/close_icon.svg';
import { ReactComponent as closeSquareIcon } from 'assets/icons/close_square_icon.svg';
import { ReactComponent as checked } from 'assets/icons/components/checkbox/checked.svg';
import { ReactComponent as unChecked } from 'assets/icons/components/checkbox/unchecked.svg';
import { ReactComponent as filter } from 'assets/icons/components/filter.svg';
import { ReactComponent as home } from 'assets/icons/components/home.svg';
import { ReactComponent as page } from 'assets/icons/components/page.svg';
import { ReactComponent as patients } from 'assets/icons/components/patients.svg';
import { ReactComponent as practices } from 'assets/icons/components/practices.svg';
import { ReactComponent as radioChecked } from 'assets/icons/components/radio/checked.svg';
import { ReactComponent as radioUnChecked } from 'assets/icons/components/radio/unchecked.svg';
import { ReactComponent as tag_managment } from 'assets/icons/components/tag_managment.svg';
import { ReactComponent as toastError } from 'assets/icons/components/toast/toast_error.svg';
import { ReactComponent as toastSuccess } from 'assets/icons/components/toast/toast_success.svg';
import { ReactComponent as toastWarning } from 'assets/icons/components/toast/toast_warning.svg';
import { ReactComponent as users } from 'assets/icons/components/users.svg';
import { ReactComponent as copy } from 'assets/icons/copy_icon.svg';
import { ReactComponent as dropFileIcon } from 'assets/icons/drop_file_icon.svg';
import { ReactComponent as editPen } from 'assets/icons/edit_pen_icon.svg';
import { ReactComponent as email } from 'assets/icons/email_icon.svg';
import { ReactComponent as eyeClosed } from 'assets/icons/eye_closed_icon.svg';
import { ReactComponent as eyeOpened } from 'assets/icons/eye_open_icon.svg';
import { ReactComponent as galleryIcon } from 'assets/icons/gallery_icon.svg';
import { ReactComponent as groupAmountIcon } from 'assets/icons/group_amount_icon.svg';
import { ReactComponent as groupIcon } from 'assets/icons/group_icon.svg';
import { ReactComponent as linkedBuilding } from 'assets/icons/linked/linked_building.svg';
import { ReactComponent as linkedContentBeforeAfter } from 'assets/icons/linked/linked_content_before_after.svg';
import { ReactComponent as linkedContentImage } from 'assets/icons/linked/linked_content_image.svg';
import { ReactComponent as linkedContentPage } from 'assets/icons/linked/linked_content_page.svg';
import { ReactComponent as linkedContentText } from 'assets/icons/linked/linked_content_text.svg';
import { ReactComponent as linkedContentVideo } from 'assets/icons/linked/linked_content_video.svg';
import { ReactComponent as linkedMedical } from 'assets/icons/linked/linked_medical.svg';
import { ReactComponent as moveIcon } from 'assets/icons/move_icon.svg';
import { ReactComponent as phoneIcon } from 'assets/icons/phone_icon.svg';
import { ReactComponent as refreshIcon } from 'assets/icons/refresh_icon.svg';
import { ReactComponent as sidebarClose } from 'assets/icons/sidebar_close.svg';
import { ReactComponent as starsIcon } from 'assets/icons/stars_icon.svg';
import { ReactComponent as trashBin } from 'assets/icons/trash_bin_icon.svg';
import { ReactComponent as uploadIcon } from 'assets/icons/upload_icon.svg';
import { ReactComponent as website } from 'assets/icons/website_icon.svg';

const ICONS = {
  arrowLeftIcon,
  phoneIcon,
  groupAmountIcon,
  closeSquareIcon,
  groupIcon,
  closeIcon,
  starsIcon,
  linkedContentText,
  moveIcon,
  refreshIcon,
  dropFileIcon,
  galleryIcon,
  uploadIcon,
  eyeOpened,
  editPen,
  add,
  trashBin,
  copy,
  eyeClosed,
  beforeAfterFilled,
  beforeAfter,
  email,
  website,
  filter,
  catalog,
  home,
  page,
  patients,
  practices,
  tag_managment,
  users,
  checked,
  unChecked,
  radioUnChecked,
  radioChecked,
  toastWarning,
  toastError,
  toastSuccess,
  linkedMedical,
  linkedContentVideo,
  linkedContentPage,
  linkedContentBeforeAfter,
  linkedContentImage,
  linkedBuilding,
  calendar,
  sidebarClose,
};

export type TIconType = keyof typeof ICONS;

interface IIconProps extends SVGProps<SVGSVGElement> {
  type: TIconType;
  sx?: SxProps<Theme>;
}

export const Icon: FC<IIconProps> = props => {
  const { type, sx, ...other } = props;
  const NewIcon = ICONS[type];

  return (
    <Box component="span" display="flex" sx={sx}>
      <NewIcon {...other} />
    </Box>
  );
};
