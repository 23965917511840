import { ListSubheader, styled } from '@mui/material';

export const StyledSubheader = styled(ListSubheader)(({ theme }) => ({
  ...theme.typography.overline,
  fontSize: 12,
  fontWeight: 600,
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(1),
  color: theme.palette.text.secondary,
}));
