export const BASE_PATIENTS_API_URL = '/patient/patients';

export const BASE_DICTIONARY_API_URL = '/dict';

export const BASE_PRACTICE_URL = '/practice/practices';

export const BASE_PRACTICE_PROFILE_API_URL = '/practice/profile';

export const BASE_EXPERTS_URL = '/practice/experts';

export const BASE_PRACTICE_OFFICE_URL = '/practice/offices';

export const BASE_AI_MODELS_VERSION_API_URL = 'ml/ai-model-version';

export const BASE_AI_MODELS_API_URL = 'ml/ai-models';

export const BASE_PROMPTS_API_URL = '/prompt';

export const BASE_PROMPT_RUNNER_API_URL = '/prompt-runner';

export const BASE_PROMPT_FEEDBACKS_API_URL = '/prompt-feedback';
