import { useCallback, useRef, useState } from 'react';

import { ITableProps, SortingConfiguration } from '../components/Table/Table.types';

export type UseTableProps<TSortPropertyName> = {
  defaultDense?: boolean;
  defaultOrder?: SortDirection;
  defaultOrderBy?: TSortPropertyName;
  defaultSelected?: string[];
  defaultRowsPerPage?: number;
  defaultCurrentPage?: number;
};

const PAGINATION_SETTINGS = {
  page: 1,
  itemsPerPage: 20,
};

export const useTable = <TSortPropertyName>(
  props?: UseTableProps<TSortPropertyName>
): ITableProps<TSortPropertyName> => {
  const [dense, setDense] = useState(!!props?.defaultDense);

  const [orderBy, setOrderBy] = useState<TSortPropertyName | null>(props?.defaultOrderBy || null);

  const [order, setOrder] = useState<SortDirection>(props?.defaultOrder || 'asc');

  const [page, setPage] = useState(props?.defaultCurrentPage || PAGINATION_SETTINGS.page);

  const [rowsPerPage, setRowsPerPage] = useState(props?.defaultRowsPerPage || PAGINATION_SETTINGS.itemsPerPage);

  const [selected, setSelected] = useState<string[]>(props?.defaultSelected || []);

  const onSort = useCallback(
    (id: TSortPropertyName) => {
      const isAsc = orderBy === id && order === 'asc';
      if (id) {
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(id);
      }
    },
    [order, orderBy]
  );

  const onSelectRow = useCallback(
    (id: string) => {
      const selectedIndex = selected.indexOf(id);

      let newSelected: string[] = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
      }
      setSelected(newSelected);
    },
    [selected]
  );

  const onSelectAllRows = useCallback((checked: boolean, newSelecteds: string[]) => {
    if (checked) {
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  }, []);

  const onChangePage = useCallback((_: unknown, newPage: number) => {
    setPage(newPage + 1);
  }, []);

  const onChangeRowsPerPage = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setPage(1);
    setRowsPerPage(parseInt(event.target.value, 10));
  }, []);

  const onChangeDense = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  }, []);

  const isResettingPage = useRef(false);

  const resetPageHandler = useCallback(() => {
    if (isResettingPage.current) {
      page !== 1 && setPage(1);
      isResettingPage.current = false;
    }
  }, [page, isResettingPage]);

  return {
    dense,
    order,
    page,
    orderBy,
    rowsPerPage,

    selected,
    onSelectRow,
    onSelectAllRows,

    onSort,
    onChangePage,
    onChangeDense,
    onChangeRowsPerPage,

    setPage,
    setDense,
    setOrder,
    setOrderBy,
    setSelected,
    setRowsPerPage,

    isResettingPage,
    resetPageHandler,
  };
};
