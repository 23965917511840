import { FC, useState } from 'react';

import { ExpandMoreOutlined } from '@mui/icons-material';
import {
  Checkbox,
  Chip,
  Collapse,
  FormControlLabel,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material';

import { LinkedCatalogItems, LinkedContentStatistic } from 'components/LinkedContent';
import { Scrollbar } from 'components/ScrollBar';
import { CustomTableHeader } from 'components/Table';

export const TagsTableRow: FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  const onClick = () => setIsOpen(prevState => !prevState);

  const [isUserTagsChecked, setIsUserTagsChecked] = useState(false);

  const [isAITagsChecked, setIsAITagsChecked] = useState(false);
  return (
    <>
      <TableRow
        sx={{
          td: { p: 1 },
          ':hover': {
            cursor: 'initial',
          },
        }}
      >
        <TableCell colSpan={3}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <FormControlLabel
              onChange={(e, checked) => {
                e.stopPropagation();
              }}
              sx={{
                '& .MuiSvgIcon-root': {
                  border: theme => `1px solid ${theme.palette.grey[400]}`,
                },
              }}
              control={<Checkbox />}
              label={'Areas (2/5)'}
            />
            <IconButton size="small" onClick={onClick}>
              <ExpandMoreOutlined fontSize="medium" />
            </IconButton>
          </Stack>
        </TableCell>
      </TableRow>
      <TableRow sx={{ td: { width: 200 }, 'td:first-of-type': { p: 0 } }}>
        <TableCell sx={{ p: 0, borderBottom: 'none' }} colSpan={3}>
          <Collapse in={isOpen} timeout="auto" unmountOnExit>
            <Table>
              <CustomTableHeader
                headLabel={[
                  { id: 'tag', label: 'Tag' },
                  { id: 'linkedCatalogItems', label: 'Linked catalog items' },
                  { id: 'linkedContent', label: 'Linked content' },
                ]}
                sx={{
                  'th:first-of-type': { pl: 2 },
                }}
              />
              <TableBody>
                <TableRow
                  sx={{
                    td: { p: 1 },
                    'td:first-of-type': { pl: 2 },
                  }}
                >
                  <TableCell>
                    <Checkbox
                      disabled={isAITagsChecked}
                      onChange={(e, checked) => {
                        setIsUserTagsChecked(checked);
                      }}
                    />
                    <Chip sx={{ ml: 1 }} size="small" label="Body" />
                  </TableCell>

                  <TableCell>
                    <LinkedCatalogItems />
                  </TableCell>

                  <TableCell>
                    <LinkedContentStatistic />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
