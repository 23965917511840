import { Badge, Box, Icon, Stack, SxProps, Theme, Typography } from '@mui/material';

import { sideNavMenuItemStyles } from './BaseSideNavMenu.styles';
import { CardWrapper } from './CardWrapper';

type BaseSideMenuRouteItem<T extends React.ReactNode> = {
  label: T;
  icon?: string;
  isActive?: boolean;
  isDisabled?: boolean;
  badgeContent?: number;
  href: string;
  onClick?: (value: T) => void;
};

type BaseSideMenuProps<T extends React.ReactNode> = {
  sidenavItems: BaseSideMenuRouteItem<T>[];
  sx?: SxProps<Theme>;
  onClickMenuHandler?: (value: T) => void;
};

export const BaseSideFormNavMenu = <T extends React.ReactNode>({
  sidenavItems,
  sx,
  onClickMenuHandler,
}: BaseSideMenuProps<T>) => {
  const renderSidenavItems = sidenavItems.map(
    ({ onClick, icon, href, label, isActive, isDisabled, badgeContent }, key) => {
      const itemKey = `item-${key}`;

      const onClickHandler = () => (onClickMenuHandler ? onClickMenuHandler(label) : onClick(label));

      return (
        <Box key={itemKey} component="li" pt={key === 0 ? 0 : 1} onClick={onClickHandler}>
          <Stack
            component="a"
            href={href}
            direction="row"
            spacing={{ xs: 1.5, sm: 1 }}
            sx={theme => sideNavMenuItemStyles({ theme, isDisabled, isActive })}
          >
            {icon && (
              <Badge badgeContent={badgeContent} color="error">
                <Icon fontSize="small">{icon}</Icon>
              </Badge>
            )}
            <Typography variant="button" fontWeight="regular" textTransform="capitalize" noWrap>
              {label}
            </Typography>
          </Stack>
        </Box>
      );
    }
  );

  return <CardWrapper sx={sx}>{renderSidenavItems}</CardWrapper>;
};
