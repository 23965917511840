import { FC, useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Stack, TextField } from '@mui/material';
import { createPatientMedicalCondition } from 'apiServices';
import { Resolver, useForm } from 'react-hook-form';

import { RHFAutocompleteField } from 'components/HookForm';
import MDButton from 'components/MDButton';
import { ToastType, notice } from 'components/ToastNotification';
import { useDictionary } from 'hooks/useDictionary';
import { formErrorHandler } from 'utils/errorHanders';

import {
  CreateMedicalConditionFormSchema,
  createMedicalConditionValidationSchema,
  medicalConditionDefaultValues,
} from './form.config';

type CreateMedicalConditionMenuContentProps = {
  closeMenuHandler: VoidFunction;
  patientId: string;
  fetchPatient: (patientId: string) => Promise<void>;
};

export const CreateMedicalConditionMenuContent: FC<CreateMedicalConditionMenuContentProps> = ({
  closeMenuHandler,
  fetchPatient,
  patientId,
}) => {
  const { fetchMedicalConditions, medicalConditions, isMedicalConditionsLoading } = useDictionary();

  useEffect(() => {
    fetchMedicalConditions({ page: 1, size: 50 });
  }, []);

  const {
    register,
    handleSubmit,
    setError,
    control,
    formState: { errors, isValid, isSubmitting },
  } = useForm<CreateMedicalConditionFormSchema>({
    resolver: yupResolver(createMedicalConditionValidationSchema) as Resolver<CreateMedicalConditionFormSchema>,
    mode: 'onTouched',
    defaultValues: medicalConditionDefaultValues,
  });

  const onFormSubmitHandler = handleSubmit(async formData => {
    try {
      await createPatientMedicalCondition(patientId, formData);
      await fetchPatient(patientId);

      closeMenuHandler();

      notice(ToastType.SUCCESS, 'Medical condition has been successfully created!');
    } catch (error) {
      console.error(error);
      formErrorHandler({
        error,
        config: { formError: { setError } },
        customErrorMessage: 'Failed to create medical condition, please try again!',
      });
    }
  });

  return (
    <Box
      component="form"
      onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
        e.stopPropagation();
        onFormSubmitHandler(e);
      }}
      sx={{
        mt: 3,
        pb: 1.5,
        height: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Stack flex={1} spacing={2}>
        <RHFAutocompleteField
          control={control}
          name={'medicalConditionIcd10Code' as const}
          placeholder="Medical conditions"
          options={medicalConditions}
          getOptionLabel={option => {
            if (typeof option === 'string') return option;
            return option.name;
          }}
          isOptionEqualToValue={(option, value) => option?.id === value?.id}
          loading={isMedicalConditionsLoading}
        />
        <TextField
          fullWidth
          label="Symptoms"
          minRows={1}
          maxRows={4}
          multiline
          {...register('symptoms')}
          error={!!errors?.symptoms?.message}
          helperText={errors?.symptoms?.message}
        />
      </Stack>

      <Stack direction="row" width={1} justifyContent="space-between" spacing={1.5}>
        <MDButton variant="outlined" size="small" onClick={closeMenuHandler} fullWidth>
          Cancel
        </MDButton>
        <MDButton disabled={isSubmitting || !isValid} size="small" isLoading={isSubmitting} fullWidth type="submit">
          Save
        </MDButton>
      </Stack>
    </Box>
  );
};
