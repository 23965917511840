import { FC } from 'react';

import { Grid } from '@mui/material';

import { ChangePassword, MFAProviders } from './components';

export const Security: FC = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ChangePassword />
      </Grid>
      <Grid item xs={12}>
        <MFAProviders />
      </Grid>
    </Grid>
  );
};
