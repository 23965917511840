import React, { FC, useEffect, useState } from 'react';

import { Autocomplete, AutocompleteInputChangeReason, Stack } from '@mui/material';

import MDButton from 'components/MDButton';
import { MDInput } from 'components/MDInput';

export const FiltersMenuContent: FC<any> = ({ appliedFilters, setAppliedFilters, onGetPatientsHandler }) => {
  const [filters, setFilters] = useState<any>(appliedFilters);

  useEffect(() => {}, []);

  const [isFiltersApplying, setIsFiltersApplying] = useState(false);

  const onClickResetFiltersButtonHandler = () => setFilters(null);

  const onClickApplyFiltersButtonHandler = async () => {
    setIsFiltersApplying(true);

    setIsFiltersApplying(false);
    setAppliedFilters(filters);
  };

  const isApplyButtonDisabled = false;
  const isResetButtonDisabled = false;

  const renderAutocomplete = <TOption,>({
    getOptionLabel,
    isLoading,
    isOptionEqualToValue,
    label,
    onChange,
    onInputChange,
    options,
    placeholder,
    value,
    isDisabled,
  }: {
    value: TOption;
    onChange: (event: React.SyntheticEvent<Element, Event>, value: TOption) => void;
    options: TOption[];
    label: string;
    placeholder: string;
    isLoading: boolean;
    isOptionEqualToValue: (option: TOption, value: TOption) => boolean;
    getOptionLabel: (option: TOption) => string;
    onInputChange?: (
      _: React.SyntheticEvent<Element, Event>,
      value: string,
      reason: AutocompleteInputChangeReason
    ) => Promise<void>;
    isDisabled?: boolean;
  }) => (
    <Autocomplete
      value={value}
      onChange={onChange}
      onInputChange={onInputChange}
      getOptionLabel={getOptionLabel}
      options={options}
      freeSolo={false}
      isOptionEqualToValue={isOptionEqualToValue}
      disableClearable={false}
      disabled={isDisabled}
      sx={{ width: 1 }}
      renderInput={params => (
        <MDInput
          isLoading={isLoading}
          {...params}
          label={label}
          placeholder={placeholder}
          InputLabelProps={{ shrink: true }}
          fullWidth
        />
      )}
    />
  );
  return (
    <Stack width={1} position="relative">
      <Stack
        mt={1}
        height={1}
        spacing={2}
        width={1}
        sx={{
          mb: '50px',
          py: '10px',
        }}
      >
        <Stack spacing={2.5} mt={2} width={1}>
          {renderAutocomplete({
            value: null,
            getOptionLabel: option => option.value,
            isLoading: false,
            isOptionEqualToValue: (option, value) => option.name === value.name,
            label: 'Workflow',
            onChange: () => {},
            options: [],
            placeholder: 'Select a workflow',
          })}

          {renderAutocomplete({
            value: null,
            getOptionLabel: option => option.value,
            isLoading: false,
            isOptionEqualToValue: (option, value) => option.name === value.name,
            label: 'Interaction',
            onChange: () => {},
            options: [],
            placeholder: 'Select an interaction',
          })}

          {renderAutocomplete({
            value: null,
            getOptionLabel: option => option.value,
            isLoading: false,
            isOptionEqualToValue: (option, value) => option.name === value.name,
            label: 'Assessment',
            onChange: () => {},
            options: [],
            placeholder: 'Select an assessment',
          })}
        </Stack>
      </Stack>
      <Stack
        spacing={1.5}
        py={1}
        direction="row"
        position="absolute"
        sx={{ bottom: 0, width: 1, zIndex: 10, bgcolor: theme => theme.palette.common.white }}
      >
        <MDButton
          variant="outlined"
          size="small"
          disabled={isResetButtonDisabled}
          onClick={onClickResetFiltersButtonHandler}
          fullWidth
        >
          Clear
        </MDButton>
        <MDButton
          disabled={isApplyButtonDisabled || isFiltersApplying}
          size="small"
          onClick={onClickApplyFiltersButtonHandler}
          isLoading={isFiltersApplying}
          fullWidth
        >
          Apply
        </MDButton>
      </Stack>
    </Stack>
  );
};
