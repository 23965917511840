import { BASE_PRACTICE_OFFICE_URL } from 'apiServices/api.routes';
import { apiClient } from 'lib/api';

import {
  CreateOfficePayload,
  GetOfficesByPracticeParams,
  GetOfficesParams,
  OfficeListItem,
  UpdateOfficePayload,
} from './types';

export const getOffices = async (params: GetOfficesParams) =>
  await apiClient.get<ListOfItems<OfficeListItem>>(BASE_PRACTICE_OFFICE_URL, { params });

export const createOffice = async (payload: CreateOfficePayload) =>
  await apiClient.post<OfficeListItem>(BASE_PRACTICE_OFFICE_URL, payload);

export const getOfficeByPractice = async (officeId: string, params: GetOfficesByPracticeParams) =>
  await apiClient.get<OfficeListItem>(`${BASE_PRACTICE_OFFICE_URL}/${officeId}`, { params });

export const getOffice = async (officeId: string) =>
  await apiClient.get<OfficeListItem>(`${BASE_PRACTICE_OFFICE_URL}/${officeId}`);

export const updateOffice = async (officeId: string, payload: UpdateOfficePayload) =>
  await apiClient.patch<OfficeListItem>(`${BASE_PRACTICE_OFFICE_URL}/${officeId}`, payload);

export const deleteOffice = async (officeId: string) =>
  await apiClient.delete(`${BASE_PRACTICE_OFFICE_URL}/${officeId}`);
