import { BreadcrumbsRoute } from 'use-react-router-breadcrumbs';

import { DYNAMIC_BREADCRUMBS, getBreadCrumbName } from 'utils/helpers/breadcrumbs';

export enum ROUTES {
  home = '/',
  signIn = '/authentification/sign-in',
  verifyCode = '/authentification/verify-code',
  setupAccount = '/setup-account',
  forgotPassword = '/authentification/forgot-password',
  resetPassword = '/authentification/reset-password',
  profile = '/profile',
  profileSecurity = '/profile/security',
  practiceManagement = '/practice-management',
  practiceManagementProfile = '/practice-management/:id',
  users = '/users',
  usersProfile = '/users/:id',
  patients = '/patients',
  patientProfile = '/patients/:id',
  tagManagement = '/tag-management',
  tagManagementEditTagCategory = '/tag-management/:id',
  catalog = '/catalog',
  catalogGLServiceProfile = '/catalog/gl-service/:id',
  catalogPLServiceProfile = '/catalog/pl-service/:id',
  catalogGLProductProfile = '/catalog/gl-product/:id',
  catalogPLProductProfile = '/catalog/pl-product/:id',
  promptLibrary = '/prompt-library',
  viewPrompt = '/prompt-library/:id',
  createPrompt = '/prompt-library/create-prompt',
  updatePrompt = '/prompt-library/update-prompt/:id',
  addAIModel = '/prompt-library/add-ai-model',
  updateAIModel = '/prompt-library/update-ai-model/:id',
  addAIModelVersion = '/prompt-library/add-ai-model-version',
  modelList = '/prompt-library/ai-model-list',
  aiModelVersions = '/ai-models/:model_name/:model_id/versions',
  updateAIModelVersion = '/update-ai-models/:model_name/:version_id/versions',
  beforeAfter = '/before-after',
  promptVersionList = '/prompt-versions-list/:prompt_id',
  promptVersionUpdate = '/prompt-version-form/:version_id',
  promptVersionAdd = '/prompt-add-version-form',
  beforeAfterGLProfile = '/before-after/gl/:id',
  beforeAfterPLProfile = '/before-after/pl/:id',
  addBeforeAfter = '/before-after/create',
  notFound = '*',
}

export const BREADCRUMBS_ROUTES: BreadcrumbsRoute<string>[] = [
  {
    path: ROUTES.viewPrompt,
    breadcrumb: getBreadCrumbName,
  },
  {
    path: ROUTES.patientProfile,
    breadcrumb: DYNAMIC_BREADCRUMBS.patient,
  },
  {
    path: ROUTES.practiceManagementProfile,
    breadcrumb: DYNAMIC_BREADCRUMBS.practice,
  },

  {
    path: ROUTES.tagManagementEditTagCategory,
    breadcrumb: DYNAMIC_BREADCRUMBS.editPracticeTag,
  },

  {
    path: ROUTES.usersProfile,
    breadcrumb: DYNAMIC_BREADCRUMBS.userProfile,
  },

  {
    path: ROUTES.catalogGLServiceProfile,
    breadcrumb: DYNAMIC_BREADCRUMBS.glServiceProfile,
  },
  {
    path: ROUTES.catalogPLServiceProfile,
    breadcrumb: DYNAMIC_BREADCRUMBS.plServiceProfile,
  },
  {
    path: ROUTES.catalogGLProductProfile,
    breadcrumb: DYNAMIC_BREADCRUMBS.glProductProfile,
  },

  {
    path: ROUTES.catalogPLProductProfile,
    breadcrumb: DYNAMIC_BREADCRUMBS.plProductProfile,
  },
];
