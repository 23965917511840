import { StateCreator } from 'zustand';

import { sliceResetFns } from '../../Store';
import { CatalogInitialState, CatalogSliceType } from './types';

const initialState: CatalogInitialState = {
  catalogGLData: {
    GLServices: {
      page: null,
      items: [],
      total: null,
      size: null,
      pages: null,
    },
    GLProducts: {
      page: null,
      items: [],
      total: null,
      size: null,
      pages: null,
    },
  },
  catalogPLData: {
    PLServices: {
      page: null,
      items: [],
      total: null,
      size: null,
      pages: null,
    },
    PLProducts: {
      page: null,
      items: [],
      total: null,
      size: null,
      pages: null,
    },
  },
  catalogActiveLibrary: 'global',
  catalogItemsType: 'services',
};

export const CatalogSlice: StateCreator<
  CatalogSliceType,
  [['zustand/devtools', never], ['zustand/immer', never]],
  [],
  CatalogSliceType
> = set => {
  sliceResetFns.add(() => set(initialState));
  return {
    ...initialState,
    setGLServices: data => {
      set(state => {
        state.catalogGLData.GLServices = data;
      });
    },
    setPLServices: data => {
      set(state => {
        state.catalogPLData.PLServices = data;
      });
    },
    setGLProducts: data => {
      set(state => {
        state.catalogGLData.GLProducts = data;
      });
    },
    setPLProducts: data => {
      set(state => {
        state.catalogPLData.PLProducts = data;
      });
    },
    setCatalogActiveLibrary: value => {
      set(state => {
        state.catalogActiveLibrary = value;
      });
    },
    setCatalogItemsType: value => {
      set(state => {
        state.catalogItemsType = value;
      });
    },
  };
};
