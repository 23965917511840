import colors from 'assets/theme/base/colors';

const tableCell = {
  styleOverrides: {
    root: {
      borderBottom: 'none',
      padding: 8,
      '&:first-of-type': { paddingLeft: 16 },
      fontSize: 14,
      fontWeight: 500,
    },
    head: {
      color: colors.text.secondary,
      fontSize: 12,
      fontWeight: 600,
      backgroundColor: colors.grey[200],
    },
    stickyHeader: {
      backgroundColor: colors.background.default,
      backgroundImage: `linear-gradient(to bottom, ${colors.grey[100]} 0%, ${colors.grey[100]} 100%)` as const,
    },
    paddingCheckbox: {
      paddingLeft: '8px',
    },
  },
};

export default tableCell;
