export type BaseType = {
  handleNextStep: (step: STEPS_FLOW) => void;
};

export type SetPasswordStepProps = BaseType & {
  code?: string;
  userName?: string;
};

export enum STEPS_FLOW {
  SETUP_CREDENTIALS = 'setup_credentials',
  COMPLETED = 'completed',
  EMAIL_ALREADY_VERIFIED = 'email_already_verified',
}

export type SetConfirmationCodeProps = BaseType & {
  setConfirmationCode: React.Dispatch<React.SetStateAction<string>>;
};
