import { useState } from 'react';

import { STEPS_FLOW } from './types';

export const useSignUpState = () => {
  const [activeStep, setActiveStep] = useState<STEPS_FLOW>(STEPS_FLOW.SETUP_CREDENTIALS);

  const handleNextStep = (step: STEPS_FLOW) => setActiveStep(step);

  return {
    activeStep,
    handleNextStep,
  };
};
