import { FC } from 'react';

import { Skeleton, Stack } from '@mui/material';

type ProfileSkeletonProps = {
  isSwitcher?: boolean;
};

export const ProfileSkeleton: FC<ProfileSkeletonProps> = ({ isSwitcher }) => {
  return (
    <Stack spacing={2}>
      {isSwitcher && <Skeleton variant="rounded" sx={{ height: 30, width: 100, mb: 2 }} />}
      <Skeleton variant="rounded" sx={{ height: 36, width: 1, mb: 2 }} />
      <Skeleton variant="rounded" sx={{ height: 100, width: 1, mb: 2 }} />
    </Stack>
  );
};
