import { FC } from 'react';

import { Box, Card, CardContent, CardHeader, Divider, Skeleton, Stack } from '@mui/material';

import MDButton from 'components/MDButton';

type EditTagCategoryCardProps = {
  onFormSubmitHandler: (e?: React.BaseSyntheticEvent<object, any, any>) => Promise<void>;
  isSubmitting: boolean;
  isSubmitButtonDisabled: boolean;
  children: React.ReactNode;
  isLoading: boolean;
  isGlobalLibrary?: boolean;
};

export const EditTagCategoryCard: FC<EditTagCategoryCardProps> = ({
  onFormSubmitHandler,
  isSubmitButtonDisabled,
  isSubmitting,
  children,
  isLoading,
  isGlobalLibrary,
}) => {
  return (
    <Card component="form" onSubmit={onFormSubmitHandler}>
      <CardHeader title="Edit tag category" sx={{ pb: 0 }} />
      <Divider sx={{ my: 2 }} />
      <CardContent sx={{ pt: 0 }}>
        <Stack spacing={2.5} maxWidth={isGlobalLibrary ? 1 : '50%'}>
          {isLoading ? (
            <>
              <Stack
                direction={{ xs: 'column', sm: 'row' }}
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
              >
                <Skeleton variant="rounded" sx={{ minHeight: 36, width: 1, mb: 2 }} />
                {isGlobalLibrary && <Skeleton variant="rounded" sx={{ minHeight: 36, width: 1, mb: 2 }} />}
              </Stack>
              <Skeleton variant="rounded" sx={{ minHeight: 32, width: 60, mb: 2 }} />
            </>
          ) : (
            <>
              {children}

              <Box>
                <MDButton type="submit" disabled={isSubmitButtonDisabled} isLoading={isSubmitting}>
                  Save
                </MDButton>
              </Box>
            </>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
};
