import { FC } from 'react';

import { Checkbox, Stack } from '@mui/material';

import { LabelItem } from './LabelItem';

type DocItemProps = {
  id: string;
  isChecked: boolean;
  title: string;
  isNewVersionAvailable: boolean;
  onChangeCheckboxHandler: (event: React.SyntheticEvent<Element, Event>, checked: boolean) => void;
};

export const DocItem: FC<DocItemProps> = ({ id, onChangeCheckboxHandler, isChecked, isNewVersionAvailable, title }) => {
  return (
    <Stack spacing={1} direction="row" alignItems="flex-start">
      <Checkbox id={id} onChange={onChangeCheckboxHandler} checked={isChecked} />
      <LabelItem title={title} isNewVersionAvailable={isNewVersionAvailable} />
    </Stack>
  );
};
