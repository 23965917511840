import { FC } from 'react';

import { Grid } from '@mui/material';

import { useCatalogTableTabs } from 'pages/Catalog/useCatalogTableTabs';

import { PracticeLibraryProducts } from './Products';
import { PracticeLibraryServices } from './Services';

export const PracticeLibrary: FC = () => {
  const { handleSetTabValue, tabValue } = useCatalogTableTabs();

  return (
    <Grid item xs={12}>
      {tabValue === 0 ? (
        <PracticeLibraryServices handleSetTabValue={handleSetTabValue} tabValue={tabValue} />
      ) : (
        <PracticeLibraryProducts handleSetTabValue={handleSetTabValue} tabValue={tabValue} />
      )}
    </Grid>
  );
};
