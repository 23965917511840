import borders from 'assets/theme/base/borders';
import colors from 'assets/theme/base/colors';
import typography from 'assets/theme/base/typography';

const { inputBorderColor, grey, transparent } = colors;
const { borderRadius } = borders;
const { size } = typography;

const inputOutlined = {
  styleOverrides: {
    root: {
      backgroundColor: transparent.main,
      fontSize: size.sm,
      borderRadius: borderRadius.lg,

      '&:hover .MuiOutlinedInput-notchedOutline:not(.Mui-focused .MuiOutlinedInput-notchedOutline):not(.Mui-error .MuiOutlinedInput-notchedOutline)':
        {
          borderColor: grey[500],
        },
    },

    notchedOutline: {
      borderColor: inputBorderColor,
    },

    input: {
      color: grey[700],
      padding: '9px 12px',
      backgroundColor: transparent.main,
      textOverflow: 'ellipsis',
    },

    inputSizeSmall: {
      fontSize: size.xs,
      padding: '5px 12px',
      '::placeholder': {
        transform: 'translateY(1px)',
      },
    },

    multiline: {
      color: grey[700],
      padding: 0,
    },
  },
};

export default inputOutlined;
