import borders from 'assets/theme/base/borders';
import colors from 'assets/theme/base/colors';
import pxToRem from 'assets/theme/functions/pxToRem';
import { Icon } from 'components/Icon';

const { borderRadius } = borders;
const { grey } = colors;

type Types = any;

const checkbox: Types = {
  defaultProps: {
    disableRipple: true,
    icon: <Icon type="unChecked" />,
    checkedIcon: <Icon type="checked" />,
  },
  styleOverrides: {
    root: {
      padding: 0,
      borderRadius: borderRadius.md,
      opacity: 0.7,
      ':hover': {
        opacity: 1,
      },

      '&.Mui-checked': {
        opacity: 1,
        ':hover': {
          '& svg': {
            opacity: 0.7,
          },
        },
      },

      '&.Mui-disabled': {
        color: grey[300],
        opacity: 0.85,
      },

      '& .MuiSvgIcon-root': {
        backgroundPosition: 'center',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        width: pxToRem(20),
        height: pxToRem(20),
        color: 'inherit',
      },
    },
  },
};

export default checkbox;
