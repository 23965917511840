import * as yup from 'yup';

import { emailRegExp, webSiteURLRegExp } from 'constants/regex';

export const EmailValidationSchema = yup
  .string()
  .required()
  .email('Email must be a valid email address')
  .matches(emailRegExp, 'Please enter a valid email')
  .label('Email');

export const EmailLazyValidationSchema = yup.lazy((value: string) => {
  return value?.length ? EmailValidationSchema : yup.string().optional().label('Email').nullable();
});

export const WebsiteLazyValidationSchema = yup.lazy((value: string) => {
  return value?.length
    ? yup
        .string()
        .required()
        .matches(webSiteURLRegExp, 'Please enter a valid website url. For example: https://example.com')
        .label('Website')
    : yup.string().optional().label('Website').nullable();
});

export const PhoneValidationSchema = yup.string().optional().label('Phone number').trim().nullable();

export type TOT_MFA_verification_code_schema = {
  TOTPverificationCode: string;
};

export const TOTP_MFA_verification_code_validation_schema: yup.ObjectSchema<TOT_MFA_verification_code_schema> = yup
  .object({
    TOTPverificationCode: yup.string().required().label('TOTP verification code').min(6).max(6),
  })
  .required();
