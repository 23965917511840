import { FC, memo, useState } from 'react';

import { LogoutOutlined, MoreHorizRounded } from '@mui/icons-material';
import { IconButton, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { BaseDialogWindow } from 'components/BaseDialogWindow';
import { MDAvatar } from 'components/MDAvatar';
import { MenuPopover, PopoverMenuItem } from 'components/MenuPopover';
import { ROUTES } from 'constants/routes';
import { useCognitoAuthContext } from 'context';
import { useBoolean } from 'hooks/useBoolean';
import { usePopover } from 'hooks/usePopover';
import { useUserProfile } from 'hooks/useUserProfile';

import { createTransition } from './helpers';

type NavAccountProps = {
  isNavMini: boolean;
};

export const NavAccount: FC<NavAccountProps> = memo(({ isNavMini }) => {
  const { handleClosePopover, handleOpenPopover, openPopover } = usePopover();

  const [isLogoutWindow, openLogoutWindow, closeLogOutWindow] = useBoolean(false);

  const navigate = useNavigate();

  const { signOutCognito } = useCognitoAuthContext();

  const { cognitoUser } = useUserProfile();

  const onClickProfileButtonHandler = () => {
    navigate(ROUTES.profile);
    handleClosePopover();
  };

  const onClickLogoutMenuItemHandler = () => {
    openLogoutWindow();
    handleClosePopover();
  };

  const [isLoading, setIsLoading] = useState(false);

  const onClickLogoutHandler = async () => {
    setIsLoading(true);

    await signOutCognito();
    closeLogOutWindow();

    setIsLoading(false);
  };

  return (
    <Stack
      pt={1}
      direction={isNavMini ? 'column' : 'row'}
      spacing={0.5}
      justifyContent={isNavMini ? 'center' : 'space-between'}
      alignItems="center"
    >
      <Stack direction="row" spacing={isNavMini ? 0 : 0.75} alignItems="center">
        <MDAvatar avatarSize="xs">{cognitoUser?.username}</MDAvatar>
        <Typography
          variant="caption"
          fontWeight={400}
          fontSize={14}
          noWrap
          maxWidth={150}
          width={1}
          sx={{
            transition: theme => createTransition(theme, 'all'),
            ...(isNavMini && {
              overflow: 'hidden',
              flexGrow: 0,
              display: 'none',
            }),
          }}
        >
          {cognitoUser?.username}
        </Typography>
      </Stack>

      <IconButton size="small" onClick={handleOpenPopover}>
        <MoreHorizRounded />
      </IconButton>

      <MenuPopover
        open={openPopover}
        arrow="left-bottom"
        onClose={handleClosePopover}
        sx={{ transform: 'translateX(5px) !important' }}
      >
        <PopoverMenuItem title="Profile" onClick={onClickProfileButtonHandler} />

        <PopoverMenuItem
          title="Logout"
          icon={<LogoutOutlined fontSize="small" />}
          onClick={onClickLogoutMenuItemHandler}
          sx={{
            ':hover': {
              backgroundColor: theme => theme.palette.error.main,
              color: theme => theme.palette.common.white,
            },
          }}
        />
      </MenuPopover>

      <BaseDialogWindow
        open={isLogoutWindow}
        title="Logout"
        onClickCancelButtonHandler={closeLogOutWindow}
        description="Are you sure you want to log out?"
        cancelButtonTitle="Back"
        approveButtonTitle="Log out"
        onClickApproveButtonHandler={onClickLogoutHandler}
        isApproveButtonLoading={isLoading}
        isApproveButtonDisabled={isLoading}
      />
    </Stack>
  );
});
