import { FC } from 'react';

import { Stack } from '@mui/material';

import { LinkedContentStatisticItem } from './LinkedContentStatisticItem';
import { LINKED_CATALOG_ITEM } from './types';

export const LinkedCatalogItems: FC = () => {
  return (
    <Stack direction="row" spacing={1}>
      <LinkedContentStatisticItem type={LINKED_CATALOG_ITEM.SERVICES} amount={0} color="primary" />

      <LinkedContentStatisticItem type={LINKED_CATALOG_ITEM.PRODUCTS} amount={3} color="success" />
    </Stack>
  );
};
