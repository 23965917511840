/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// Material Dashboard 2 PRO React TS Base Styles
import { stepConnectorClasses } from '@mui/material';

import borders from 'assets/theme/base/borders';
import colors from 'assets/theme/base/colors';

const { grey, primary } = colors;
const { borderWidth } = borders;

const stepConnector = {
  styleOverrides: {
    root: {
      // width: 110,
      transition: 'all 200ms linear',
      borderColor: grey[200],
      [`&.${stepConnectorClasses.completed}, &.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
          borderColor: primary.main,
        },
      },
    },

    line: {
      borderWidth: borderWidth[3],
      borderColor: grey[300],
    },
  },
};

export default stepConnector;
