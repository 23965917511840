import { FC, memo } from 'react';

import { DeleteOutlined } from '@mui/icons-material';
import { Stack } from '@mui/material';

import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import { MDInput } from 'components/MDInput';

type AllergyItemItemProps = {
  allergyName: string;
  icd10Code: string;
  allergyId: string;
  onClickRemoveButtonHandler: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

export const AllergyItem: FC<AllergyItemItemProps> = memo(
  ({ onClickRemoveButtonHandler, allergyId, allergyName, icd10Code }) => {
    return (
      <Stack spacing={2} width={1} alignItems="center">
        <Stack width={1} spacing={2}>
          <MDInput label="Allergy name" disabled value={allergyName} multiline minRows={1} maxRows={4} />
          <MDInput fullWidth label="ICD-10 code" disabled value={icd10Code} />
        </Stack>

        <MDBox sx={{ display: 'flex', alignSelf: 'flex-end', mr: '3px !important', mt: 1 }}>
          <MDButton
            onClick={onClickRemoveButtonHandler}
            size="small"
            color="error"
            variant="outlined"
            startIcon={<DeleteOutlined />}
            id={allergyId}
          >
            Remove
          </MDButton>
        </MDBox>
      </Stack>
    );
  }
);
