import { FC } from 'react';

import { Stack } from '@mui/material';

import { LinkedContentStatisticItem } from './LinkedContentStatisticItem';
import { LINKED_CONTENT_ITEM } from './types';

export const LinkedContentStatistic: FC = () => {
  return (
    <Stack spacing={1} direction="row">
      <LinkedContentStatisticItem type={LINKED_CONTENT_ITEM.VIDEO} amount={4} />

      <LinkedContentStatisticItem type={LINKED_CONTENT_ITEM.IMAGE} amount={0} />

      <LinkedContentStatisticItem type={LINKED_CONTENT_ITEM.BEFORE_AFTER} amount={10} />

      <LinkedContentStatisticItem type={LINKED_CONTENT_ITEM.TEXT} amount={1} />

      <LinkedContentStatisticItem type={LINKED_CONTENT_ITEM.PAGE} amount={3} />
    </Stack>
  );
};
