import { LinkProps } from '@mui/material/Link';

const link: { defaultProps?: Partial<LinkProps> } = {
  defaultProps: {
    underline: 'none',
    color: 'inherit',
  },
};

export default link;
