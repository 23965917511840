import { Box, styled } from '@mui/material';

import { HEADER_CONFIG } from 'constants/layout-config';

export const MainLayoutStyles = {
  BoxSX: {
    width: 1,
    minHeight: '100vh',
    display: 'flex',
  },

  ContentWrapper: styled(Box)(() => ({
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: 16,
  })),

  Header: styled(Box)(({ theme }) => ({
    width: '100%',
    display: 'flex',
    padding: 16,
    paddingLeft: 20,
    backgroundColor: theme.palette.common.white,
    borderBottom: `1px solid ${theme.palette.divider}`,
    height: HEADER_CONFIG.H_DASHBOARD_HEADER,
    [theme.breakpoints.down('md')]: {
      padding: 12,
    },
  })),
};
