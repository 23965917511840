import { useEffect } from 'react';

import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { useLocation } from 'react-router-dom';

import theme from 'assets/theme';
import { ToastNotification } from 'components/ToastNotification';
import { CognitoAuthContextProvider } from 'context';

import { AppRouter } from './App.router';

export default function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <CognitoAuthContextProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <AppRouter />
        </LocalizationProvider>
      </CognitoAuthContextProvider>
      <ToastNotification />
    </ThemeProvider>
  );
}
