import { useState } from 'react';

import { AxiosResponse } from 'axios';

import { backendErrorHandler } from 'utils/errorHanders';

type UseSelectedItemProps<T> = {
  getItemById: (id: string) => Promise<AxiosResponse<T>>;
  initialLoadingState?: boolean;
};

export const useSelectedItem = <T>({ getItemById, initialLoadingState = false }: UseSelectedItemProps<T>) => {
  const [isSelectedItemLoading, setIsSelectedItemLoading] = useState(initialLoadingState);
  const [selectedItem, setSelectedItem] = useState<null | T>(null);

  const onGetItemHandler = async (id: string) => {
    setIsSelectedItemLoading(true);
    try {
      const { data } = await getItemById(id);

      setSelectedItem(data);
      setIsSelectedItemLoading(false);
    } catch (error) {
      setIsSelectedItemLoading(false);
      console.error(error);
      backendErrorHandler({ error });
    } finally {
      setIsSelectedItemLoading(false);
    }
  };

  return { onGetItemHandler, selectedItem, isSelectedItemLoading };
};
