import { useState } from 'react';

import {
  PatientEnum,
  PatientEnums,
  getPatientBioGenderEnums,
  getPatientBloodTypes,
  getPatientEnums,
  getPatientEthnicGroups,
  getPatientGenderEnums,
  getPatientPainTolerances,
  getPatientPersonTitles,
} from 'apiServices';

import { ToastType, notice } from 'components/ToastNotification';

export const usePatientEnums = () => {
  const [patientEnums, setPatientEnums] = useState<PatientEnums | null>(null);
  const [isAllPatientEnumsLoading, setIsAllPatientEnumsLoading] = useState(true);

  const fetchAllPatientEnums = async () => {
    try {
      const { data } = await getPatientEnums();
      setPatientEnums(data);
      setIsAllPatientEnumsLoading(false);
    } catch (err) {
      console.error(err);
      notice(ToastType.ERROR, 'Failed to get patient enums, please try again!');
    } finally {
      setIsAllPatientEnumsLoading(false);
    }
  };

  const [patientGenderEnums, setPatientGenderEnums] = useState<PatientEnum[]>([]);
  const [isPatientGenderEnumsLoading, setIsPatientGenderEnumsLoading] = useState(true);

  const fetchPatientGenderEnums = async () => {
    try {
      const { data } = await getPatientGenderEnums();
      setPatientGenderEnums(data);
      setIsPatientGenderEnumsLoading(false);
    } catch (err) {
      console.error(err);
      notice(ToastType.ERROR, 'Failed to get patient gender, please try again!');
    } finally {
      setIsPatientGenderEnumsLoading(false);
    }
  };

  const [patientBioGenderEnums, setPatientBioGenderEnums] = useState<PatientEnum[]>([]);
  const [isPatientBioGenderEnumsLoading, setIsPatientBioGenderEnumsLoading] = useState(true);

  const fetchPatientBioGenderEnums = async () => {
    try {
      const { data } = await getPatientBioGenderEnums();
      setPatientBioGenderEnums(data);
      setIsPatientBioGenderEnumsLoading(false);
    } catch (err) {
      console.error(err);
      notice(ToastType.ERROR, 'Failed to get patient bio gender, please try again!');
    } finally {
      setIsPatientBioGenderEnumsLoading(false);
    }
  };

  const [patientEthnicGroupsEnums, setPatientEthnicGroupsEnums] = useState<PatientEnum[]>([]);
  const [isPatientEthnicGroupsEnumsLoading, setIsPatientEthnicGroupsEnumsLoading] = useState(true);

  const fetchPatientEthnicGroupsEnums = async () => {
    try {
      const { data } = await getPatientEthnicGroups();
      setPatientEthnicGroupsEnums(data);
      setIsPatientEthnicGroupsEnumsLoading(false);
    } catch (err) {
      console.error(err);
      notice(ToastType.ERROR, 'Failed to get patient ethnic groups, please try again!');
    } finally {
      setIsPatientEthnicGroupsEnumsLoading(false);
    }
  };

  const [patientPainTolerancesEnums, setPatientPainTolerancesEnums] = useState<PatientEnum[]>([]);
  const [isPatientPainTolerancesEnumsLoading, setIsPatientPainTolerancesEnumsLoading] = useState(true);

  const fetchPatientPainTolerancesEnums = async () => {
    try {
      const { data } = await getPatientPainTolerances();
      setPatientPainTolerancesEnums(data);
      setIsPatientPainTolerancesEnumsLoading(false);
    } catch (err) {
      console.error(err);
      notice(ToastType.ERROR, 'Failed to get patient pain tolerances, please try again!');
    } finally {
      setIsPatientPainTolerancesEnumsLoading(false);
    }
  };

  const [patientPersonTitlesEnums, setPatientPersonTitlesEnums] = useState<PatientEnum[]>([]);
  const [isPatientPersonTitlesEnumsLoading, setIsPatientPersonTitlesEnumsLoading] = useState(true);

  const fetchPatientPersonTitlesEnums = async () => {
    try {
      const { data } = await getPatientPersonTitles();
      setPatientPersonTitlesEnums(data);
      setIsPatientPersonTitlesEnumsLoading(false);
    } catch (err) {
      console.error(err);
      notice(ToastType.ERROR, 'Failed to get patient person titles, please try again!');
    } finally {
      setIsPatientPersonTitlesEnumsLoading(false);
    }
  };

  const [patientBloodTypesEnums, setPatientBloodTypesEnums] = useState<PatientEnum[]>([]);
  const [isPatientBloodTypesEnumsLoading, setIsPatientBloodTypesEnumsLoading] = useState(true);

  const fetchPatientBloodTypesEnums = async () => {
    try {
      const { data } = await getPatientBloodTypes();
      setPatientBloodTypesEnums(data);
      setIsPatientBloodTypesEnumsLoading(false);
    } catch (err) {
      console.error(err);
      notice(ToastType.ERROR, 'Failed to get patient blood types, please try again!');
    } finally {
      setIsPatientBloodTypesEnumsLoading(false);
    }
  };

  return {
    fetchAllPatientEnums,
    patientEnums,
    isAllPatientEnumsLoading,
    fetchPatientGenderEnums,
    isPatientGenderEnumsLoading,
    patientGenderEnums,
    fetchPatientBioGenderEnums,
    patientBioGenderEnums,
    isPatientBioGenderEnumsLoading,
    fetchPatientEthnicGroupsEnums,
    isPatientEthnicGroupsEnumsLoading,
    patientEthnicGroupsEnums,
    fetchPatientPainTolerancesEnums,
    isPatientPainTolerancesEnumsLoading,
    patientPainTolerancesEnums,
    fetchPatientPersonTitlesEnums,
    isPatientPersonTitlesEnumsLoading,
    patientPersonTitlesEnums,
    fetchPatientBloodTypesEnums,
    isPatientBloodTypesEnumsLoading,
    patientBloodTypesEnums,
  };
};
