import { BASE_DICTIONARY_API_URL } from 'apiServices/api.routes';
import { apiClient } from 'lib/api';

import { CreateGLProductPayload, GLProduct, GLProductsSearchParams, UpdateGLProductPayload } from './types';

const BASE_PRODUCTS_API_URL = `${BASE_DICTIONARY_API_URL}/products`;

export const getGLProducts = async (params: GLProductsSearchParams) =>
  await apiClient.get<ListOfItems<GLProduct>>(BASE_PRODUCTS_API_URL, { params });

export const createGLProduct = async (payload: CreateGLProductPayload) =>
  await apiClient.post<GLProduct>(BASE_PRODUCTS_API_URL, payload);

export const getGLProduct = async (id: string) => await apiClient.get<GLProduct>(`${BASE_PRODUCTS_API_URL}/${id}`);

export const updateGLProduct = async (id: string, payload: UpdateGLProductPayload) =>
  await apiClient.patch<GLProduct>(`${BASE_PRODUCTS_API_URL}/${id}`, payload);

export const deleteGLProduct = async (id: string) => await apiClient.delete(`${BASE_PRODUCTS_API_URL}/${id}`);

export const deactivateGLProduct = async (id: string) =>
  await apiClient.delete<GLProduct>(`${BASE_PRODUCTS_API_URL}/${id}/deactivate`);

export const activateGLProduct = async (id: string) =>
  await apiClient.patch<GLProduct>(`${BASE_PRODUCTS_API_URL}/${id}/activate`);
