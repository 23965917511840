import { FC } from 'react';

import { RefreshRounded } from '@mui/icons-material';
import { Box, Card, Grid, Stack } from '@mui/material';

import { BaseSideFormNavMenu } from 'components/BaseSideNavMenu';
import { EmptyStateWithAction } from 'components/EmptyState';
import { FormActionsCard, FormSkeleton, SideNavCardSkeleton } from 'components/Form';
import { FormProvider } from 'components/HookForm';
import { ROUTES } from 'constants/routes';
import { getFormSideNavItems } from 'pages/PracticeManagement/helpers';
import { PRACTICES_FORM_CARDS } from 'pages/PracticeManagement/practices.enum';

import { EditPracticeForm, PracticeHeader, PracticeHeaderSkeleton } from './components';
import { useViewPracticeState } from './useViewPractice.state';

export const ViewPractice: FC = () => {
  const {
    practice,
    formMethods,
    isSubmitButtonDisabled,
    isSubmitting,
    onFormSubmitHandler,
    fetchPractice,
    practiceId,
    isLoading,
    onGetPracticeHandler,
    isNoPractice,
    isDirty,
  } = useViewPracticeState();

  const sideNavItems = getFormSideNavItems(PRACTICES_FORM_CARDS.PRACTICE_PROFILE).slice(0, 2);

  return (
    <Box
      component="section"
      mb={2}
      sx={{
        display: 'flex',
        ...(isNoPractice && {
          flex: 1,
        }),
      }}
    >
      <Grid container spacing={2}>
        {isNoPractice ? (
          <EmptyStateWithAction
            title="Failed to get practice"
            subtitle="Please use the button below to try once again."
            actionButtonProps={{
              title: 'Get practice',
              onClickHandler: onGetPracticeHandler,
              buttonProps: { startIcon: <RefreshRounded fontSize="medium" />, isLoading, disabled: isLoading },
            }}
          />
        ) : (
          <>
            <Grid item xs={12}>
              {isLoading ? (
                <PracticeHeaderSkeleton />
              ) : (
                <PracticeHeader isActive={practice?.isActive} name={practice?.name} nameShort={practice?.nameShort} />
              )}
            </Grid>

            <Grid item xs={12}>
              {isLoading ? (
                <Grid container spacing={2}>
                  <Grid item xs={12} md={3}>
                    <Card>
                      <SideNavCardSkeleton />
                    </Card>
                  </Grid>

                  <Grid item xs={12} md={9}>
                    <Stack spacing={2}>
                      <Card>
                        <FormSkeleton rows={2} />
                      </Card>

                      <Card>
                        <FormSkeleton rows={5} />
                      </Card>
                    </Stack>
                  </Grid>
                </Grid>
              ) : (
                <Grid container spacing={2}>
                  <Grid item xs={12} md={3}>
                    <BaseSideFormNavMenu onClickMenuHandler={() => {}} sidenavItems={sideNavItems} />
                  </Grid>
                  <Grid item xs={12} md={9}>
                    <FormProvider methods={formMethods} onSubmit={onFormSubmitHandler}>
                      <EditPracticeForm
                        refs={[]}
                        contactPersons={practice?.contactPersons}
                        practiceId={practiceId}
                        fetchPractice={fetchPractice}
                      />
                    </FormProvider>
                    <FormActionsCard
                      isFormDirty={isDirty}
                      backRoute={ROUTES.practiceManagement}
                      isDisabled={isSubmitButtonDisabled}
                      isLoading={isSubmitting}
                      onFormSubmitHandler={onFormSubmitHandler}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
};
