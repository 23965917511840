import * as yup from 'yup';

import { Shape } from 'types/RHF-types';

export type CreateTagFormSchema = {
  name: string;
};

export const createTagValidationSchema = yup
  .object()
  .shape<Shape<CreateTagFormSchema>>({
    name: yup.string().required().label('Tag name').trim(),
  })
  .required();

export const createTagDefaultValues: CreateTagFormSchema = {
  name: '',
};
