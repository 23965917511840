import { AddPatientAllergy } from 'apiServices';
import * as yup from 'yup';

import { Shape } from 'types/RHF-types';

export type CreateAllergyFormSchema = AddPatientAllergy;

export const createAllergyValidationSchema = yup
  .object()
  .shape<Shape<CreateAllergyFormSchema>>({
    allergyIcd10Code: yup.string().required().label('Allergy ICD-10 code').nullable(),
  })
  .required();

export const allergyDefaultValues: CreateAllergyFormSchema = {
  allergyIcd10Code: '',
};
