import { useCallback, useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { AppStore } from 'store';

import { ROUTES } from 'constants/routes';
import { useAppSettings } from 'hooks/useAppSettings';
import { useInterceptor } from 'hooks/useInterceptor';
import useResponsive from 'hooks/useResponsive';

export const useMainLayoutState = () => {
  const isMobile = useResponsive('down', 426);

  const isTablet = useResponsive('down', 'md');

  const [isOpenMobileNavBar, setIsOpenMobileNavBar] = useState(false);

  const handleOpen = useCallback(() => {
    setIsOpenMobileNavBar(true);
  }, []);

  const handleClose = useCallback(() => {
    setIsOpenMobileNavBar(false);
  }, []);

  const { isNavMini } = useAppSettings();

  useInterceptor();

  const navigate = useNavigate();

  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === 'store') {
        const parsedNewState: { state: AppStore } = JSON.parse(event?.newValue || 'null');

        if (!parsedNewState?.state?.cognitoUser?.userId) navigate(ROUTES.signIn);
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  return {
    isMobile,
    isNavMini,
    isTablet,
    isOpenMobileNavBar,
    handleOpen,
    handleClose,
  };
};
