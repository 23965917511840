import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';

import { CheckRounded, InfoOutlined } from '@mui/icons-material';
import { IconButton, Radio, Slider, Stack, Switch } from '@mui/material';
import { runPrompt } from 'apiServices/ml/prompt.api';
import { createFeedback } from 'apiServices/ml/prompt_feedback.api';
import { AIModelItemResponse, AIModelVersionResponse, runPromptResponseData } from 'apiServices/ml/types';
import { Patient } from 'apiServices/patient/types';
import { Practice, PracticeListItem } from 'apiServices/practice/types';

import { BaseDialogWindow } from 'components/BaseDialogWindow';
import { MDAutocomplete } from 'components/MDAutocomplete';
import { MDAvatar } from 'components/MDAvatar';
import MDButton from 'components/MDButton';
import { MDInput } from 'components/MDInput';
import MDTypography from 'components/MDTypography';
import { useBoolean } from 'hooks/useBoolean';
import { usePatients } from 'hooks/usePatients';
import { usePractices } from 'hooks/usePractices';
import { useRouter } from 'hooks/useRouter';

import { FeedbackContent } from './FeedbackContent';

type FeedbackData = {
  comments: string;
  expectedResponse: string;
  ratings: {
    [key: string]: number;
  };
};

export const OptionsContent: FC<{
  setResponseData: (data: any) => void;
  responseData: runPromptResponseData;
  setPatient: Dispatch<SetStateAction<Patient>>;
  setPractice: Dispatch<SetStateAction<Practice>>;
  selectedPatient: Patient;
}> = ({ setResponseData, responseData, setPatient, setPractice, selectedPatient }) => {
  const [isFeedback, setIsFeedback] = useState(false);
  const [feedbackData, setFeedbackData] = useState<FeedbackData | null>(null);
  const [isPatientInfoDialogOpen, openPatientInfoDialog, closePatientInfoDialog] = useBoolean(false);
  const { params } = useRouter();

  const { practices, isLoading: isPracticesLoading } = usePractices();

  const [selectedPractice, setSelectedPractice] = useState<Practice | null>(null);

  const { patients, isLoading: isPatientLoading, error } = usePatients(selectedPractice?.id);

  const [formData, setFormData] = useState({
    user_id: params.id,
    practice_id: null,
    patient_id: null,
    prompt_id: params.id,
    parameters: {},
    input_data: {},
    comment: '',
    used_parameters: null,
    accuracy_stars: null,
    relevancy_stars: null,
    coherence_stars: null,
    preferred_output: null,
    feedback_datetime: null,
  });

  const onClickInfoButtonHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    openPatientInfoDialog();
  };

  const convertPracticeListItemToPractice = (item: PracticeListItem): Practice => {
    return {
      id: item.id,
      opensearch_index_name: item.opensearch_index_name,
      isActive: item.isActive,
      name: item.name,
      nameShort: item.nameShort || null,
      contactPersons: [],
      services: [],
      offices: [],
      rag: {},
      createdAt: '',
      updatedAt: '',
    };
  };

  useEffect(() => {
    if (feedbackData) {
      setFormData(prevData => ({
        ...prevData,
        comments: feedbackData.comments,
        expectedResponse: feedbackData.expectedResponse,
        ratings: feedbackData.ratings,
      }));
    }
  }, [feedbackData]);

  const handlePracticeChange = (event: any, newValue: PracticeListItem | null) => {
    if (newValue && typeof newValue === 'object' && 'id' in newValue) {
      const convertedPractice = convertPracticeListItemToPractice(newValue);
      setSelectedPractice(convertedPractice);
      setPractice(convertedPractice);
      setFormData(prevData => ({
        ...prevData,
        practice_id: convertedPractice.id,
      }));
    } else {
      setSelectedPractice(null);
      setPractice(null);
      setFormData(prevData => ({
        ...prevData,
        practice_id: null,
      }));
    }
  };

  const handlePatientChange = (event: any, newValue: Patient) => {
    setPatient(newValue);
    setFormData(prevData => ({
      ...prevData,
      patient_id: newValue?.id || null,
    }));
  };

  const collectFeedbackData = (data: FeedbackData | null) => {
    setFeedbackData(data);
  };

  const testPrompt = async () => {
    try {
      const usedParametersString = formData.used_parameters ? JSON.stringify(formData.used_parameters) : '{}';

      const updatedFormData = {
        ...formData,
        used_parameters: usedParametersString,
      };

      const response = await runPrompt(
        params.id,
        selectedPractice?.opensearch_index_name,
        selectedPatient?.opensearch_index_name
      );
      console.log('Model output: ', response);

      if (response.status === 200 || response.status === 201) {
        setResponseData(response);
      } else {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
    } catch (error) {
      console.error('Failed to fetch prompt:', error);
    }
  };

  const handleParameterChange = (paramName: string, value: any) => {
    setFormData(prevData => ({
      ...prevData,
      used_parameters: {
        ...prevData.used_parameters,
        [paramName]: value,
      },
    }));
  };

  const submitFeedback = async () => {
    try {
      if (!feedbackData) {
        console.error('No feedback data provided.');
        return;
      }

      const usedParametersString = formData.used_parameters ? JSON.stringify(formData.used_parameters) : '{}';

      const payload = {
        account_id: null as string | null,
        cognito_username: 'test.admin@aesthetics360.com',
        prompt_id: formData.prompt_id,
        model_response: responseData?.data?.result || '',
        accuracy_stars: feedbackData.ratings.accuracy,
        relevancy_stars: feedbackData.ratings.relevance,
        coherence_stars: feedbackData.ratings.coherence,
        ai_model_name: 'Mistral',
      };

      await createFeedback(payload);
    } catch (error) {
      console.error('Error response:', error);
    }
  };

  return (
    <Stack px={2.5} py={2} spacing={2} height={1}>
      <BaseDialogWindow
        onClickCancelButtonHandler={closePatientInfoDialog}
        open={isPatientInfoDialogOpen}
        description={<Stack spacing={1}>{/* ...dialog description content... */}</Stack>}
        approveButtonTitle="Select"
        cancelButtonTitle="Back"
        onClickApproveButtonHandler={() => {}}
      />
      <MDTypography variant="h5">Options</MDTypography>
      <Stack spacing={2.5} flex={1}>
        {!isFeedback ? (
          <>
            <MDAutocomplete
              inputProps={{ label: 'Practice' }}
              options={practices || []}
              freeSolo={false}
              disableClearable={false}
              loading={isPracticesLoading}
              getOptionLabel={option => (option as PracticeListItem).name}
              onChange={(event, newValue) => handlePracticeChange(event, newValue as PracticeListItem | null)}
            />
            <MDAutocomplete
              inputProps={{ label: 'Patient' }}
              options={(patients as Patient[]) || []}
              freeSolo={false}
              disableClearable={false}
              loading={isPatientLoading}
              disabled={!selectedPractice}
              getOptionLabel={option =>
                `${(option as Patient).person.firstName} ${(option as Patient).person.lastName}`
              }
              onChange={(event, newValue) => handlePatientChange(event, newValue as Patient)}
              renderOption={(props, option, ownerState) => {
                const patientName = `${(option as Patient).person.firstName} ${(option as Patient).person.lastName}`;
                const patientDescription = `${(option as Patient).ethnicGroup} yo ${(option as Patient).gender}`;
                return PatientOption(
                  props,
                  option,
                  ownerState,
                  onClickInfoButtonHandler,
                  patientName,
                  patientDescription
                );
              }}
            />
            <MDAutocomplete
              inputProps={{ label: 'AI Model' }}
              value={'Mistral'}
              options={[]}
              freeSolo={false}
              disableClearable={false}
            />
          </>
        ) : (
          <FeedbackContent collectFeedbackData={collectFeedbackData} />
        )}
      </Stack>
      <MDButton
        onClick={() => {
          setIsFeedback(prevState => !prevState);
          if (isFeedback) {
            submitFeedback();
          } else {
            testPrompt();
          }
        }}
        startIcon={<CheckRounded fontSize="medium" />}
        size="small"
      >
        {!isFeedback ? 'Test Prompt' : 'Submit Feedback'}
      </MDButton>
    </Stack>
  );
};

export const PatientOption = <TOption,>(
  props: React.HTMLAttributes<HTMLLIElement>,
  option: TOption,
  ownerState: any,
  onClickInfoButtonHandler: (event: React.MouseEvent<HTMLButtonElement>) => void,
  name: string,
  description: string
) => {
  return (
    <Stack
      {...props}
      sx={{ px: '0 !important', pr: '1px !important' }}
      component="li"
      direction="row"
      justifyContent="space-between !important"
    >
      <Stack direction="row" spacing="2px" alignItems="center">
        <Radio size="small" />
        <MDAvatar avatarSize="sm" />
        <Stack>
          <MDTypography variant="button" sx={{ fontWeight: 500 }}>
            {name}
          </MDTypography>

          <MDTypography variant="button" noWrap maxWidth={150}>
            {description}
          </MDTypography>
        </Stack>
      </Stack>

      <IconButton onClick={onClickInfoButtonHandler} disableRipple color="default" size="small">
        <InfoOutlined />
      </IconButton>
    </Stack>
  );
};
