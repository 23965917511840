import { FC } from 'react';

import { Stack, Typography } from '@mui/material';

import { BaseDialogWindow } from 'components/BaseDialogWindow';

type DialogsProps = {
  isDeactivateDialogOpen: boolean;
  closeDeactivateDialog: () => void;
  isActivateDialogOpen: boolean;
  closeActivateDialog: () => void;
  isLoading: boolean;
  name: string;
  onActivateTagHandler: () => Promise<void>;
  onDeactivateTagHandler: () => Promise<void>;
};

export const Dialogs: FC<DialogsProps> = ({
  closeActivateDialog,
  isActivateDialogOpen,
  isLoading,
  name,
  closeDeactivateDialog,
  isDeactivateDialogOpen,
  onActivateTagHandler,
  onDeactivateTagHandler,
}) => {
  return (
    <>
      <BaseDialogWindow
        open={isDeactivateDialogOpen}
        onClickCancelButtonHandler={closeDeactivateDialog}
        description={
          <Stack spacing={1}>
            <Typography variant="body2" fontWeight={400}>
              Are you sure you want to inactivate{' '}
              <Typography variant="button" fontWeight={700}>
                {name}
              </Typography>{' '}
              tag?
            </Typography>
          </Stack>
        }
        title="Inactivate tag"
        isApproveButtonDisabled={isLoading}
        isApproveButtonLoading={isLoading}
        approveButtonTitle="Inactivate tag"
        onClickApproveButtonHandler={onDeactivateTagHandler}
      />

      <BaseDialogWindow
        open={isActivateDialogOpen}
        onClickCancelButtonHandler={closeActivateDialog}
        description={
          <Stack spacing={1}>
            <Typography variant="body2" fontWeight={400}>
              Are you sure you want to activate{' '}
              <Typography variant="button" fontWeight={700}>
                {name}
              </Typography>{' '}
              tag?
            </Typography>
          </Stack>
        }
        title="Activate tag"
        isApproveButtonDisabled={isLoading}
        isApproveButtonLoading={isLoading}
        approveButtonTitle="Activate tag"
        onClickApproveButtonHandler={onActivateTagHandler}
      />
    </>
  );
};
