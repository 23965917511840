import { apiClient } from 'lib/api';

import {
  PromptCollectionResponse,
  PromptItemResponse,
  PromptPatchRequest,
  PromptPostRequest,
  PromptWithFeedbacksResponse,
} from './types';

const BASE_URL = {
  baseURL: process.env.REACT_APP_BASE_PROMPT_API_URL,
};

export const runPrompt = async (promptId: string, practice_index_name: string, patient_index_name: string) => {
  const response = await apiClient.post(
    `/prompt-runner/${promptId}`,
    {
      practice_index_name: 'practice-1',
      patient_index_name: 'patient-1',
    },
    BASE_URL
  );

  console.log('response runPrompt: ', response);
  return response;
};
export const getAllPrompts = async (skip: number = 0, limit: number = 10) => {
  const response = await apiClient.get<PromptCollectionResponse[]>(`/prompt`, BASE_URL);
  return response;
};

export const getPromptById = async (promptId: string) => {
  const response = await apiClient.get<PromptItemResponse>(`/prompt/${promptId}`);
  return response;
};

export const getPromptWithFeedbacks = async (promptId: string) => {
  const response = await apiClient.get<PromptWithFeedbacksResponse>(`/prompt-feedback/${promptId}`, BASE_URL);
  return response;
};

export const createPrompt = async (payload: PromptPostRequest) => {
  const response = await apiClient.post<PromptItemResponse>(`/prompt`, payload, BASE_URL);
  return response;
};

export const updatePrompt = async (promptId: string, payload: PromptPatchRequest) => {
  const response = await apiClient.patch<PromptItemResponse>(`/prompt/${promptId}`, payload, BASE_URL);
  return response;
};

export const deletePrompt = async (promptId: string) => {
  const response = await apiClient.delete(`/prompt/${promptId}`, BASE_URL);
  return response;
};
