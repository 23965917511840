import { FC, memo, useEffect, useMemo, useState } from 'react';

import { CheckRounded } from '@mui/icons-material';
import { Dialog, DialogActions, DialogContent, Divider, Stack, Typography } from '@mui/material';
import { signLegalDoc } from 'apiServices/dictionary/legalDocs.api';
import { useAppStore } from 'store';
import { shallow } from 'zustand/shallow';

import MDButton from 'components/MDButton';
import { ToastType, notice } from 'components/ToastNotification';
import { useBoolean } from 'hooks/useBoolean';
import { backendErrorHandler } from 'utils/errorHanders';

import { DialogHeader } from '../components';
import { DocItem } from './DocItem';

export const LegalDocumentsDialogWindow: FC = memo(() => {
  const {
    setLegalDocuments,
    legalDocuments: { documents, isSkippedSigning },
    getProfileLegalDocsHandler,
  } = useAppStore(
    store => ({
      legalDocuments: store.legalDocuments,
      setLegalDocuments: store.setLegalDocuments,
      getProfileLegalDocsHandler: store.getProfileLegalDocsHandler,
    }),
    shallow
  );

  const legalDocsToSign = useMemo(() => {
    return documents?.legalDocs?.filter(doc => doc.signRequired);
  }, [documents]);

  const [checkedIds, setCheckedIds] = useState<Set<string>>(new Set());

  const onChangeCheckboxHandler = (event: React.SyntheticEvent<Element, Event>, checked: boolean) => {
    const id = event.currentTarget.id;

    setCheckedIds(prevState => {
      const newCheckedIds = new Set(prevState);

      if (checked) {
        newCheckedIds.add(id);
      } else newCheckedIds.delete(id);

      return newCheckedIds;
    });
  };

  const [isDialogOpen, openDialog, closeDialog] = useBoolean();

  const onClickCloseDialogHandler = () => {
    closeDialog();
    setLegalDocuments({ documents, isSkippedSigning: true });
  };

  const isButtonDisabled = checkedIds.size !== legalDocsToSign?.length;

  useEffect(() => {
    if (legalDocsToSign?.length && !isSkippedSigning) openDialog();
  }, [legalDocsToSign, isSkippedSigning]);

  const [isLoading, setIsLoading] = useState(false);

  const onSignDocumentsHandler = async () => {
    try {
      setIsLoading(true);
      const promise = Array.from(checkedIds).map(id => signLegalDoc(id));

      await Promise.all(promise);
      await getProfileLegalDocsHandler();

      closeDialog();

      setCheckedIds(new Set());

      notice(ToastType.SUCCESS, 'Legal documents have been successfully signed!');
    } catch (error) {
      console.error(error);
      backendErrorHandler({
        error,
        config: { customErrorMessage: 'Failed to sign legal documents, please try again!' },
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog
      open={isDialogOpen}
      PaperProps={{
        sx: {
          maxWidth: 640,
        },
      }}
    >
      <Stack px={3}>
        <DialogHeader
          sx={{ px: 0 }}
          title="Accept Legal Documents"
          onClickCancelButtonHandler={onClickCloseDialogHandler}
        />

        <DialogContent sx={{ px: 0 }}>
          <Stack spacing={1.5}>
            <Typography variant="button" fontWeight={400} textAlign={{ xs: 'center', md: 'left' }}>
              To continue using Aesthetic360, please review and accept the following legal documents
            </Typography>

            <Stack spacing={1.5}>
              <Typography variant="button" fontWeight={600} color="dark.main">
                Manage Legal Documents
              </Typography>
              <Stack spacing={1.3}>
                {legalDocsToSign?.map(el =>
                  el.signRequired ? (
                    <DocItem
                      key={el.id}
                      id={el.id}
                      isChecked={checkedIds.has(el.id)}
                      onChangeCheckboxHandler={onChangeCheckboxHandler}
                      title={el.title}
                      isNewVersionAvailable={el.signRequestType === 'new_version'}
                    />
                  ) : null
                )}
              </Stack>
            </Stack>
          </Stack>
        </DialogContent>

        <Divider />

        <DialogActions sx={{ px: 0 }}>
          <MDButton
            onClick={onSignDocumentsHandler}
            disabled={isButtonDisabled || isLoading}
            fullWidth
            startIcon={<CheckRounded fontSize="medium" />}
            isLoading={isLoading}
          >
            Accept and Continue
          </MDButton>
        </DialogActions>
      </Stack>
    </Dialog>
  );
});
