import { useCallback, useMemo, useState } from 'react';

import { useSearchParams } from 'react-router-dom';

import { useActionDialogManagement } from 'hooks/useActionDialogManagement';
import { useBoolean } from 'hooks/useBoolean';
import { useDebounce } from 'hooks/useDebounce';
import { usePopover } from 'hooks/usePopover';
import { useRouter } from 'hooks/useRouter';
import { FiltersInitialState, FiltersInitialStateValue } from 'pages/TagManagement';
import { backendErrorHandler } from 'utils/errorHanders';
import { checkIsFilterApplied } from 'utils/helpers';

type UseEditTagCategoryStateProps = {
  onDeactivate: (id: string) => Promise<void>;
  onActivate: (id: string) => Promise<void>;
  isResettingPage: React.MutableRefObject<boolean>;
};

export const useEditTagCategoryState = ({
  onDeactivate,
  onActivate,
  isResettingPage,
}: UseEditTagCategoryStateProps) => {
  const {
    onClickActionOptionHandler,
    isDeactivateDialogOpen,
    closeDeactivateDialog,
    isActivateDialogOpen,
    closeActivateDialog,
    actionState,
    isActionPending,
    setIsActionPending,
  } = useActionDialogManagement();

  const { params } = useRouter();

  const categoryId = params?.id;

  const [isAddTagMenuOpen, openAddTagMenu, closeAddTagMenu] = useBoolean();
  const [isEditTagMenuOpen, openEditTagMenu, closeEditTagMenu] = useBoolean();

  const [selectedTagId, setSelectedTagId] = useState('');

  const onClickEditOptionMenuHandler = useCallback((tagId: string) => {
    openEditTagMenu();
    setSelectedTagId(tagId);
  }, []);

  const onDeactivateTagHandler = async () => {
    setIsActionPending(true);
    const { id } = actionState;
    try {
      await onDeactivate(id);

      closeDeactivateDialog();
    } catch (error) {
      backendErrorHandler({
        error,
        config: {
          customErrorMessage: 'Failed to inactivate tag, please try again!',
        },
      });
    } finally {
      setIsActionPending(false);
    }
  };

  const onActivateTagHandler = async () => {
    setIsActionPending(true);
    const { id } = actionState;

    try {
      await onActivate(id);

      closeActivateDialog();
    } catch (error) {
      backendErrorHandler({
        error,
        config: {
          customErrorMessage: 'Failed to activate tag, please try again!',
        },
      });
    } finally {
      setIsActionPending(false);
    }
  };

  const [searchParams] = useSearchParams();

  const urlTagParam = searchParams.get('tag');

  const [searchValue, setSearchValue] = useState(urlTagParam || '');

  const debouncedSearchValue = useDebounce(searchValue, 200);

  const onChangeSearchInputValue = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
    isResettingPage.current = true;
  }, []);

  const {
    handleClosePopover: closeFiltersMenu,
    handleOpenPopover: openFilterMenu,
    openPopover: isFiltersMenuOpen,
  } = usePopover();

  const [appliedFilters, setAppliedFilters] = useState<FiltersInitialState>(FiltersInitialStateValue);

  const onApplyFilters = useCallback((newFilters: FiltersInitialState) => {
    setAppliedFilters(newFilters);
    isResettingPage.current = true;
    closeFiltersMenu();
  }, []);

  const isFiltersApplied = useMemo(
    () => checkIsFilterApplied(FiltersInitialStateValue, appliedFilters),
    [appliedFilters]
  );

  return {
    isFiltersApplied,
    onApplyFilters,
    appliedFilters,
    isFiltersMenuOpen,
    closeFiltersMenu,
    openFilterMenu,
    categoryId,
    debouncedSearchValue,
    onChangeSearchInputValue,
    searchValue,
    urlTagParam,
    onClickActionOptionHandler,
    isDeactivateDialogOpen,
    closeDeactivateDialog,
    isActivateDialogOpen,
    closeActivateDialog,
    actionState,
    isActionPending,
    setIsActionPending,
    selectedTagId,
    onClickEditOptionMenuHandler,
    setSelectedTagId,
    isEditTagMenuOpen,
    isAddTagMenuOpen,
    openAddTagMenu,
    closeAddTagMenu,
    closeEditTagMenu,
    openEditTagMenu,
    onDeactivateTagHandler,
    onActivateTagHandler,
  };
};
