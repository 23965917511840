import { FC } from 'react';

import { Box, List, Stack } from '@mui/material';

import { SIDENAV_ROUTES } from 'constants/sideNavRoutes';

import { NavList } from './NavList';
import { StyledSubheader } from './VerticalNavSection.styles';
import { INavSectionProps } from './verticalNavSection.types';

export const VerticalNavSection: FC<INavSectionProps> = props => {
  const { sx, isNavMini, ...other } = props;

  return (
    <Stack flex={1} sx={sx} {...other}>
      {SIDENAV_ROUTES.map(group => {
        const key = group.subheader || group.items[0].title;
        return (
          <List key={key} disablePadding>
            {group.subheader && <StyledSubheader disableSticky>{group.subheader}</StyledSubheader>}

            {!group.subheader && <Box pt={2} pb={1} />}
            {group.items.map(list => (
              <NavList
                key={list.title + list.path}
                isNavMini={isNavMini}
                data={list}
                depth={1}
                hasChild={!!list.children}
                pathes={list.pathes}
              />
            ))}
          </List>
        );
      })}
    </Stack>
  );
};
