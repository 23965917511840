import colors from 'assets/theme/base/colors';
import pxToRem from 'assets/theme/functions/pxToRem';

const { grey } = colors;

type BorderWidth = {
  0: number;
  1: string;
  2: string;
  3: string;
};

type BorderRadius = {
  xs: string;
  sm: string;
  md: string;
  lg: string;
  xl: string;
  xxl: string;
  section: string;
};

interface BorderTypes {
  borderColor: string;
  borderWidth: BorderWidth;
  borderRadius: BorderRadius;
}

declare module '@mui/material/styles' {
  interface Theme {
    borders: BorderTypes;
  }

  //The configuration object that you pass to createTheme() to customize the theme.
  interface ThemeOptions {
    borders: BorderTypes;
  }
}

const borders: BorderTypes = {
  borderColor: grey[300],

  borderWidth: {
    0: 0,
    1: pxToRem(1),
    2: pxToRem(2),
    3: pxToRem(3),
  },

  borderRadius: {
    xs: pxToRem(1.6),
    sm: pxToRem(2),
    md: pxToRem(6),
    lg: pxToRem(8),
    xl: pxToRem(12),
    xxl: pxToRem(16),
    section: pxToRem(160),
  },
};

export default borders;
