import { useState } from 'react';

import { GetPatientsParams, PatientSortFieldName, getPatients } from 'apiServices';
import { useAppStore } from 'store';
import { shallow } from 'zustand/shallow';

import { useBoolean } from 'hooks/useBoolean';
import { useDebounce } from 'hooks/useDebounce';
import { usePopover } from 'hooks/usePopover';
import { useTable } from 'hooks/useTable';
import { useUserProfile } from 'hooks/useUserProfile';
import { backendErrorHandler } from 'utils/errorHanders';

import { FilterInitialState } from './types';

export const AGE_FROM_DEFAULT_VALUE = 0;
export const AGE_TO_DEFAULT_VALUE = 100;

export const FilterInitialStateValue: FilterInitialState = {
  ageValues: [AGE_FROM_DEFAULT_VALUE, AGE_TO_DEFAULT_VALUE],
  bioGenderValue: null,
  cityValue: null,
  countryValue: null,
  ethnicGroupValue: null,
  genderValue: null,
  stateValue: null,
};

export const usePatientsState = () => {
  const {
    patients: { patients, total },
    setPatients,
  } = useAppStore(
    store => ({
      patients: store.patients,
      setPatients: store.setPatients,
    }),
    shallow
  );

  const { isRoleAdmin } = useUserProfile();

  const [appliedFilters, setAppliedFilters] = useState<FilterInitialState>(FilterInitialStateValue);

  // const { navigate } = useRouter();

  // const onClickAddPatientButtonHandler = () => navigate(ROUTES.patientsAdd);

  const { page, rowsPerPage, onChangePage, onChangeRowsPerPage, setPage, order, orderBy, onSort } =
    useTable<PatientSortFieldName>({
      defaultOrderBy: 'person.last_name',
      defaultOrder: 'asc',
    });

  const [isLoading, setIsLoading] = useState(true);

  const [searchValue, setSearchValue] = useState('');

  const onChangeSearchValueHandler = (event: React.ChangeEvent<HTMLInputElement>) => setSearchValue(event.target.value);

  const debouncedSearchValue = useDebounce(searchValue, 200);

  const onGetPatientsHandler = async ({
    params,
    isDefaultPage,
  }: {
    params?: GetPatientsParams;
    isDefaultPage?: boolean;
  }) => {
    const pageToPass = !!params || debouncedSearchValue ? 1 : searchParams.page;

    try {
      if (debouncedSearchValue || isDefaultPage) setPage(1);

      !isLoading && setIsLoading(true);

      const {
        data: { items, page, pages, size, total },
      } = await getPatients({
        order: [
          `${orderBy},${order}`,
          `${orderBy === 'person.last_name' ? 'person.first_name' : 'person.last_name'},${order}`,
        ],
        ...searchParams,
        page: pageToPass,
        ...params,
      });
      setPatients({ page, pages, patients: items, size, total });
      isFiltersMenuOpen && closeFiltersMenu();

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
      backendErrorHandler({ error, config: { customErrorMessage: 'Failed to get patients, please try again!' } });
    }
  };

  const onChangePageHandler = async (e: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    const newPage = page + 1;
    setPage(newPage);

    await onGetPatientsHandler({ params: { page: page + 1 } });
  };

  const searchParams: GetPatientsParams = {
    ...(debouncedSearchValue && {
      search: debouncedSearchValue,
    }),
    page,
    size: rowsPerPage,
    ...(appliedFilters.bioGenderValue && {
      bio_gender: appliedFilters.bioGenderValue.value,
    }),
    ...(appliedFilters.genderValue && {
      gender: appliedFilters.genderValue.value,
    }),
    ...(appliedFilters.cityValue && {
      home_address_city_id: appliedFilters.cityValue.id,
    }),
    ...(appliedFilters.countryValue && {
      home_address_country_iso_code: appliedFilters.countryValue.isoCode,
    }),
    ...(appliedFilters.stateValue && {
      home_address_state_iso_code: appliedFilters.stateValue.isoCode,
    }),
    age_from: appliedFilters.ageValues?.[0],
    age_to: appliedFilters.ageValues?.[1],
  };

  const {
    handleClosePopover: closeFiltersMenu,
    handleOpenPopover: openFilterMenu,
    openPopover: isFiltersMenuOpen,
  } = usePopover();

  const [isOpenAddPatientDialogWindow, openAddPatientDialogWindow, closeAddPatientDialogWindow] = useBoolean();

  return {
    patients,
    isLoading,
    page,
    rowsPerPage,
    total,
    onGetPatientsHandler,
    onChangePage,
    onChangeRowsPerPage,
    isFiltersMenuOpen,
    openFilterMenu,
    closeFiltersMenu,
    searchValue,
    onChangeSearchValueHandler,
    debouncedSearchValue,
    appliedFilters,
    setAppliedFilters,
    onChangePageHandler,
    setPage,
    onSort,
    orderBy,
    order,
    isOpenAddPatientDialogWindow,
    openAddPatientDialogWindow,
    closeAddPatientDialogWindow,
    isRoleAdmin,
  };
};
