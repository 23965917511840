import { devtools, persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { createWithEqualityFn } from 'zustand/traditional';

import {
  AppSettingsSlice,
  AppSettingsSliceType,
  CatalogProductProfile,
  CatalogProductProfileSliceType,
  CatalogServiceProfile,
  CatalogServiceProfileSliceType,
  CatalogSlice,
  CatalogSliceType,
  ExpertSliceType,
  ExpertsSlice,
  PatientsSlice,
  PatientsSliceType,
  PracticesSlice,
  PracticesSliceType,
  TagManagementSlice,
  TagManagementSliceType,
  UserProfileSlice,
  UserProfileSliceType,
} from './slices';

export type AppStore = TagManagementSliceType &
  PatientsSliceType &
  PracticesSliceType &
  AppSettingsSliceType &
  UserProfileSliceType &
  ExpertSliceType &
  CatalogSliceType &
  CatalogServiceProfileSliceType &
  CatalogProductProfileSliceType;

export const sliceResetFns = new Set<() => void>();

export const resetAllSlices = () => {
  sliceResetFns.forEach(resetFn => {
    resetFn();
  });
};

export const useAppStore = createWithEqualityFn<AppStore>()(
  persist(
    devtools(
      immer((...args) => ({
        ...PatientsSlice(...args),
        ...PracticesSlice(...args),
        ...AppSettingsSlice(...args),
        ...UserProfileSlice(...args),
        ...TagManagementSlice(...args),
        ...ExpertsSlice(...args),
        ...CatalogSlice(...args),
        ...CatalogServiceProfile(...args),
        ...CatalogProductProfile(...args),
      }))
    ),
    { name: 'store' }
  )
);
