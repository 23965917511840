import { FC } from 'react';

import { Box, Card, Grid } from '@mui/material';

import { FormActions } from 'components/Form';
import { FormProvider } from 'components/HookForm';
import { ROUTES } from 'constants/routes';
import { BeforeAfterPhotos, ProfileFormSection, ProfileHeader } from 'pages/BeforeAfter';

import { useBAprofileState } from './useBAprofile.state';

export const GlobalLibraryBAProfile: FC = () => {
  const { formMethods, onFormSubmitHandler } = useBAprofileState();

  return (
    <Box
      component="section"
      sx={{
        display: 'flex',
        height: 1,
        // ...(isNoUser && {
        //   flex: 1,
        // }),
      }}
    >
      <Grid container spacing={2}>
        {
          //   <EmptyStateWithAction
          //     title="Failed to get user"
          //     subtitle="Please use the button below to try once again."
          //     actionButtonProps={{
          //       title: 'Get user',
          //       onClickHandler: onGetUserHandler,
          //       buttonProps: { startIcon: <RefreshRounded fontSize="medium" />, isLoading, disabled: isLoading },
          //     }}
          //   />
          <Grid item xs={12}>
            <Card
              sx={
                {
                  // ...(!isLoading && {
                  //   height: 1,
                  // }),
                }
              }
            >
              {false ? (
                <>
                  {/* <HeaderSkeleton />
                  <FormContentSkeleton rows={7} /> */}
                </>
              ) : (
                <>
                  <ProfileHeader
                    openActivateDialog={() => {}}
                    openDeactivateDialog={() => {}}
                    isStatusLoading={false}
                    isActive={false}
                    title="Facial Rejuvenation"
                    subtitle="Dermal filler for fuller lips"
                    backRoute={ROUTES.beforeAfter}
                  />
                  <FormProvider methods={formMethods} onSubmit={onFormSubmitHandler}>
                    <ProfileFormSection />
                  </FormProvider>
                  <BeforeAfterPhotos data={[]} />
                  {/* <FormProvider
                    formProps={{ sx: { position: 'relative', flex: 1 } }}
                    methods={formMethods}
                    onSubmit={onFormSubmitHandler}
                  >
                    <UserForm isRoleField={!isHideRoleField} />
                  </FormProvider> */}
                  {/* <FormActionsCardBeta
                    onClickDiscardButtonHandler={onClickDiscardButtonHandler}
                    isDisabled={isSubmitButtonDisabled}
                    isLoading={isSubmitting}
                    onClickSaveChangesButtonHandler={closeDiscardDialogWindow}
                    isOpenDiscardDialogWindow={isOpenDiscardDialogWindow}
                    onClickCancelButtonHandler={onClickCancelButtonHandler}
                    onFormSubmitHandler={onFormSubmitHandler}
                    isDirtyForm={isDirty}
                  /> */}
                </>
              )}
            </Card>
          </Grid>
        }
      </Grid>

      {/* <BaseDialogWindow
        open={isDeactivateDialogOpen}
        onClickCancelButtonHandler={closeDeactivateDialog}
        description="Are you sure you want to inactivate this user?"
        title="Inactivate user"
        isApproveButtonDisabled={isActivatingProcess}
        isApproveButtonLoading={isActivatingProcess}
        approveButtonTitle="Inactivate user"
        onClickApproveButtonHandler={onDeactivateUserHandler}
      />

      <BaseDialogWindow
        open={isActivateDialogOpen}
        onClickCancelButtonHandler={closeActivateDialog}
        description="Are you sure you want to activate this user?"
        title="Activate user"
        isApproveButtonDisabled={isActivatingProcess}
        isApproveButtonLoading={isActivatingProcess}
        approveButtonTitle="Activate user"
        onClickApproveButtonHandler={onActivateUserHandler}
      /> */}
    </Box>
  );
};
