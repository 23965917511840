import * as yup from 'yup';

export type CreateBAalbumFormSchema = {
  name?: string;
  description?: string;
};

export const createBAalbumValidationSchema = yup
  .object()
  .shape({
    name: yup.string().required().label('Name'),
    description: yup.string().optional().label('Description'),
  })
  .required();

export const defaultValues: CreateBAalbumFormSchema = {
  name: '',
  description: '',
};
