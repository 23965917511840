import { useCallback } from 'react';

import { useAppStore } from 'store';
import { shallow } from 'zustand/shallow';

export const useAppSettings = () => {
  const { changeSettingsValues, themeLayout } = useAppStore(
    store => ({
      changeSettingsValues: store.onChangeAppSettingsValue,
      themeLayout: store.themeLayout,
    }),
    shallow
  );

  const onToggleLayout = useCallback(
    () => changeSettingsValues('themeLayout', themeLayout === 'default' ? 'mini' : 'default'),
    [themeLayout]
  );

  const isNavMini = themeLayout === 'mini';

  return { onToggleLayout, themeLayout, isNavMini };
};
