import React, { FC, useEffect, useMemo, useState } from 'react';

import { Autocomplete, AutocompleteInputChangeReason, Box, FormControlLabel, Stack, Switch } from '@mui/material';
import { OfficeListItem, PracticeListItem } from 'apiServices';

import { MDInput } from 'components/MDInput';
import { FilterMenuPopoverButtons } from 'components/MenuPopover';
import { useDebounce } from 'hooks/useDebounce';
import { useOptions } from 'hooks/useOptions';
import { useUserProfile } from 'hooks/useUserProfile';
import { getElementById, getFilterApplyButtonStatus, getFilterResetButtonStatus } from 'utils/helpers';

import { FiltersMenuContentProps } from '../types';

export const FiltersMenuContent: FC<FiltersMenuContentProps> = ({ appliedFilters, onApplyFilters }) => {
  const { fetchOffices, offices, isOfficeLoading, fetchPractices, isPracticeLoading, practices } = useOptions();

  const isRoleAdmin = useUserProfile().isRoleAdmin;

  const [filters, setFilters] = useState(appliedFilters);

  const onClickResetFiltersButtonHandler = () =>
    setFilters({ isShowDeactivated: false, office_id: '', practice_id: '' });

  const onClickApplyFiltersButtonHandler = async () => onApplyFilters(filters);

  const onChangeSwitchHandler = (_: React.SyntheticEvent<Element, Event>, checked: boolean) => {
    setFilters(prevState => ({ ...prevState, isShowDeactivated: checked }));
  };

  const onChagePracticeValueHandler = async (_: React.SyntheticEvent<Element, Event>, value: PracticeListItem) => {
    setFilters(prev => ({ ...prev, practice_id: value?.id }));
  };

  const onChageOfficeValueHandler = async (_: React.SyntheticEvent<Element, Event>, value: OfficeListItem) => {
    setFilters(prev => ({ ...prev, office_id: value?.id }));
  };

  const [practiceSearchValue, setPracticeSearchValue] = useState('');

  const debouncedPracticeSearchValue = useDebounce(practiceSearchValue);

  const onChangePracticeInputValueHandler = (
    _: React.SyntheticEvent<Element, Event>,
    value: string,
    reason: AutocompleteInputChangeReason
  ) => {
    if (reason === 'input' || reason === 'clear') {
      setPracticeSearchValue(value || '');
    }
  };

  const [officeSearchValue, setOfficeSearchValue] = useState('');

  const debouncedOfficeSearchValue = useDebounce(officeSearchValue);

  const onChangeOfficeInputValueHandler = (
    _: React.SyntheticEvent<Element, Event>,
    value: string,
    reason: AutocompleteInputChangeReason
  ) => {
    if (reason === 'input' || reason === 'clear') {
      setOfficeSearchValue(value || '');
    }
  };

  useEffect(() => {
    if (isRoleAdmin)
      fetchPractices({
        page: 1,
        size: 100,
        ...(debouncedPracticeSearchValue && { search: debouncedPracticeSearchValue }),
      });
  }, [debouncedPracticeSearchValue]);

  useEffect(() => {
    if (isRoleAdmin)
      fetchOffices({ page: 1, size: 100, ...(debouncedOfficeSearchValue && { search: debouncedOfficeSearchValue }) });
  }, [debouncedOfficeSearchValue]);

  const filterEntries = Object.entries(filters);

  const isApplyButtonDisabled = useMemo(
    () => getFilterApplyButtonStatus({ appliedFilters, filterEntries }),
    [filterEntries]
  );

  const isResetButtonDisabled = useMemo(() => getFilterResetButtonStatus({ filterEntries }), [filterEntries]);

  const renderAutocomplete = <TOption,>({
    getOptionLabel,
    isLoading,
    isOptionEqualToValue,
    label,
    onChange,
    onInputChange,
    options,
    placeholder,
    value,
    isDisabled,
  }: {
    value: TOption;
    onChange: (event: React.SyntheticEvent<Element, Event>, value: TOption) => void;
    options: TOption[];
    label: string;
    placeholder: string;
    isLoading: boolean;
    isOptionEqualToValue: (option: TOption, value: TOption) => boolean;
    getOptionLabel: (option: TOption) => string;
    onInputChange?: (
      _: React.SyntheticEvent<Element, Event>,
      value: string,
      reason: AutocompleteInputChangeReason
    ) => Promise<void> | void;
    isDisabled?: boolean;
  }) => (
    <Autocomplete
      value={value}
      onChange={onChange}
      onInputChange={onInputChange}
      getOptionLabel={getOptionLabel}
      options={options}
      freeSolo={false}
      isOptionEqualToValue={isOptionEqualToValue}
      disableClearable={false}
      disabled={isDisabled}
      renderInput={params => (
        <MDInput
          isLoading={isLoading}
          {...params}
          label={label}
          placeholder={placeholder}
          InputLabelProps={{ shrink: true }}
          fullWidth
        />
      )}
    />
  );

  const practiceSelectedValue = useMemo(
    () => getElementById(filters?.practice_id, practices),
    [filters?.practice_id, practices]
  );

  const officeSelectedValue = useMemo(() => getElementById(filters?.office_id, offices), [filters?.office_id, offices]);

  return (
    <Stack position="relative">
      <Stack
        mt={1}
        height={1}
        spacing={2}
        sx={{
          mb: '50px',
          py: '10px',
        }}
      >
        {isRoleAdmin &&
          renderAutocomplete({
            options: practices,
            isOptionEqualToValue(option, value) {
              return option?.id === value?.id;
            },
            getOptionLabel(option) {
              return option?.name;
            },
            isLoading: isPracticeLoading,
            label: 'Practice',
            placeholder: 'Select practice',
            onInputChange: onChangePracticeInputValueHandler,
            onChange: onChagePracticeValueHandler,
            value: practiceSelectedValue,
          })}

        {isRoleAdmin &&
          renderAutocomplete({
            options: offices,
            isOptionEqualToValue(option, value) {
              return option?.id === value?.id;
            },
            getOptionLabel(option) {
              return option?.label;
            },
            isLoading: isOfficeLoading,
            label: 'Office',
            placeholder: 'Select office',
            onInputChange: onChangeOfficeInputValueHandler,
            onChange: onChageOfficeValueHandler,
            value: officeSelectedValue,
          })}

        <Box>
          <FormControlLabel
            onChange={onChangeSwitchHandler}
            checked={filters.isShowDeactivated}
            control={<Switch />}
            label="Show deactivated"
            labelPlacement="start"
          />
        </Box>
      </Stack>
      <FilterMenuPopoverButtons
        isClearButtonDisabled={isResetButtonDisabled}
        onClickClearButtonHandler={onClickResetFiltersButtonHandler}
        isApplyButtonDisabled={isApplyButtonDisabled}
        onClickApplyButtonHandler={onClickApplyFiltersButtonHandler}
      />
    </Stack>
  );
};
