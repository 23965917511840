import { FC } from 'react';

import { Skeleton } from '@mui/material';

import { AVATAR_SIZES_MAP, AvatarOwnerState } from './MDAvatarRoot';

type MDAvatarSkeletonProps = Required<AvatarOwnerState> & {
  shape?: 'rect' | 'round';
};

export const MDAvatarSkeleton: FC<MDAvatarSkeletonProps> = ({ avatarSize, shape = 'round' }) => {
  const size = AVATAR_SIZES_MAP[avatarSize || 'md'];
  return (
    <Skeleton
      variant={shape === 'round' ? 'circular' : 'rounded'}
      sx={{
        width: size.width,
        height: size.width,
        flexShrink: 0,
      }}
    />
  );
};
