import { FC } from 'react';

import { Box, Grid } from '@mui/material';
import { Outlet } from 'react-router-dom';

import { BaseSideNavMenu } from 'components/BaseSideNavMenu';
import { ROUTES } from 'constants/routes';

const sidenavItems = [
  { icon: 'folder_shared', label: 'profile', href: ROUTES.profile },
  { icon: 'gpp_good', label: 'security', href: ROUTES.profileSecurity },
];

export const ProfileLayout: FC = () => {
  return (
    <Box component="section">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={3}>
          <BaseSideNavMenu sidenavItems={sidenavItems} />
        </Grid>
        <Grid item xs={12} sm={12} md={9}>
          <Outlet />
        </Grid>
      </Grid>
    </Box>
  );
};
