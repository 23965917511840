import { FC, memo } from 'react';

import { Box, Stack } from '@mui/material';

import MDTypography from 'components/MDTypography';

import { BaseDialogWindow } from '../BaseDialogWindow';
import { TimeoutSessionDialogWindowProps } from '../types';
import { CircularWithValueLabel } from './CircularWithValueLabel';

export const TimeoutSessionDialogWindow: FC<TimeoutSessionDialogWindowProps> = memo(
  ({ open, isFinished, onClickApproveButtonHandler, onClickCancelButtonHandler, startTimerValue, timerValue }) => {
    return (
      <BaseDialogWindow
        open={open}
        description={
          <Stack sx={{ fontWeight: 400 }} spacing={1.5}>
            <MDTypography variant="body2" textAlign="center">
              For your security, we log you out automatically when you have not been active for a certain period of
              time.
            </MDTypography>
            {!isFinished && (
              <Box>
                <CircularWithValueLabel timerValue={timerValue} startTimerValue={startTimerValue} />
              </Box>
            )}
          </Stack>
        }
        title="You will be logged out soon"
        approveButtonTitle="Stay logged in"
        cancelButtonTitle="Logout now"
        onClickCancelButtonHandler={onClickCancelButtonHandler}
        onClickApproveButtonHandler={onClickApproveButtonHandler}
      />
    );
  }
);
