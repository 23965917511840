import { FC } from 'react';

import { ArrowBackIosNewRounded } from '@mui/icons-material';

import MDButton from 'components/MDButton';
import { ROUTES } from 'constants/routes';
import { useRouter } from 'hooks/useRouter';

type BackButtonProps = {
  navigateTo?: ROUTES;
  title: string;
};

export const BackButton: FC<BackButtonProps> = ({ navigateTo, title }) => {
  const { back, navigate } = useRouter();

  const onClick = () => {
    navigateTo ? navigate(navigateTo) : back();
  };

  return (
    <MDButton
      variant="text"
      color="dark"
      sx={{ fontSize: 14, px: 0.5 }}
      startIcon={<ArrowBackIosNewRounded />}
      onClick={onClick}
    >
      {title}
    </MDButton>
  );
};
