import colors from 'assets/theme/base/colors';

const { grey, white, text } = colors;

const slider: any = {
  defaultProps: {
    valueLabelDisplay: 'auto',
  },
  styleOverrides: {
    root: {
      '&.Mui-disabled': {
        color: text.disabled,
      },
    },
    rail: {
      opacity: 0.32,
      backgroundColor: grey[400],
    },
    markLabel: {
      fontSize: 13,
      color: text.disabled,
    },
    valueLabel: {
      borderRadius: 6,
      color: white.main,
      backgroundColor: grey[600],
      zIndex: 10,
      paddingLeft: 8,
      paddingRight: 8,
      fontSize: 12,
      fontWeight: 600,
      '&:before': {
        borderBottomRightRadius: 2,
      },
    },
    thumb: {
      width: 12,
      height: 12,
      border: `2px solid`,
      boxShadow: 'none',
      backgroundColor: white.main,
      '&.Mui-focusVisible': {
        boxShadow: 'none',
      },

      '&.Mui-active': {
        boxShadow: '0px 0px 0px 10px rgba(37, 112, 235, 0.16)',
      },
      '&:after': {
        width: 12,
        height: 12,
      },
    },
  },
};

export default slider;
