import { apiClient } from 'lib/api';

import { BASE_AI_MODELS_API_URL } from '../api.routes';
import { AIModelCollectionResponse, AIModelItemResponse, AIModelPatchRequest, AIModelPostRequest } from './types';

export const getAllAIModels = async (skip: number = 0, limit: number = 10) =>
  await apiClient.get<AIModelCollectionResponse[]>(BASE_AI_MODELS_API_URL, { params: { skip, limit } });

export const getAIModelById = async (modelId: string) =>
  await apiClient.get<AIModelItemResponse>(`${BASE_AI_MODELS_API_URL}/${modelId}`);

export const addAIModel = async (payload: AIModelPostRequest) =>
  await apiClient.post<AIModelItemResponse>(BASE_AI_MODELS_API_URL, payload);

export const updateAIModel = async (modelId: string, payload: AIModelPatchRequest) =>
  await apiClient.patch<AIModelItemResponse>(`${BASE_AI_MODELS_API_URL}/${modelId}`, payload);

export const deleteAIModel = async (modelId: string) => await apiClient.delete(`${BASE_AI_MODELS_API_URL}/${modelId}`);
