import { FC } from 'react';

import { Box, Divider, IconButton, Stack, Typography, alpha } from '@mui/material';

import { Icon } from 'components/Icon';
import { ToastType, notice } from 'components/ToastNotification';
import { UploadAvatar } from 'components/UploadAvatar';
import { UserRoleChip } from 'components/UserRoleChip';

import { ActionsHeader } from './ActionsHeader';
import { Note } from './Note';
import { EntityHeaderProps } from './types';

export const EntityHeader: FC<EntityHeaderProps> = ({
  onClickThreeDotsButtonHandler,
  noteProps,
  title,
  subtitle,
  tabs,
  id,
  avatarProps,
  activeStatusProps,
  role,
}) => {
  const onClickCopy = async () => {
    try {
      await navigator.clipboard.writeText(id || '');
      notice(ToastType.SUCCESS, 'Successfully copied!');
    } catch (error) {
      notice(ToastType.ERROR, 'Failed to copy, please try again.');
    }
  };

  return (
    <>
      <Box sx={{ p: 2, ...(tabs && { pb: 0 }) }}>
        <ActionsHeader
          activeStatusProps={activeStatusProps}
          onClickThreeDotsButtonHandler={onClickThreeDotsButtonHandler}
        />
        <Stack
          direction={{ xs: 'column', lg: 'row' }}
          alignItems={{ xs: 'center', sm: 'flex-start' }}
          gap={2}
          width={1}
          justifyContent="space-between"
        >
          <Stack direction={{ xs: 'column', sm: 'row' }} gap={2} alignItems={{ xs: 'center', sm: 'flex-start' }}>
            <UploadAvatar avatarSize="3xl" {...avatarProps} />
            <Stack sx={{ position: 'relative', flexGrow: 1 }} justifyContent="space-between">
              <Stack direction={{ xs: 'column', sm: 'row' }} alignItems="center" spacing={{ xs: 1, sm: 1.5 }}>
                <Typography textAlign={{ xs: 'center', sm: 'start' }} fontSize={{ xs: 18, md: 22 }} fontWeight={500}>
                  {title}
                </Typography>

                {role && <UserRoleChip userRole={role} />}
                {id && (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      borderRadius: '6px',
                      p: 0.5,
                      bgcolor: theme => alpha(theme.palette.primary.light, 0.1),
                      gap: 0.5,
                    }}
                  >
                    <Typography color="primary.light" fontSize={12} fontWeight={400}>
                      ID {id}
                    </Typography>
                    <IconButton onClick={onClickCopy} sx={{ color: theme => theme.palette.primary.light }}>
                      <Icon sx={{ svg: { width: 14, height: 14 } }} type="copy" />
                    </IconButton>
                  </Box>
                )}
              </Stack>
              {subtitle && (
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent={{ xs: 'center', sm: 'flex-start' }}
                  spacing={1}
                  mt={{ xs: 1, sm: 0 }}
                >
                  {subtitle}
                </Stack>
              )}
            </Stack>
          </Stack>

          {noteProps && <Note {...noteProps} />}
        </Stack>

        {tabs}
      </Box>
      <Divider sx={{ mt: tabs ? '-1px' : 0 }} />
    </>
  );
};
