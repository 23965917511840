import { FC } from 'react';

import { Dialog, DialogContent, Stack } from '@mui/material';

import { DialogHeader, UploadAvatarDialogWindowProps } from 'components/BaseDialogWindow';
import { DropZone } from 'components/DropZone';

export const UploadAvatarDialogWindow: FC<UploadAvatarDialogWindowProps> = ({
  onClickCloseButtonHandler,
  onUploadFileHandler,
  ...props
}) => {
  return (
    <Dialog onClose={onClickCloseButtonHandler} PaperProps={{ sx: { maxWidth: 550, width: 1 } }} {...props}>
      <Stack px={3}>
        <DialogHeader sx={{ px: 0 }} onClickCancelButtonHandler={onClickCloseButtonHandler} title="Add Photo" />

        <DialogContent sx={{ px: 0, py: 4, textAlign: { xs: 'center', sm: 'left' } }}>
          <DropZone buttonTitle="Select photo" onUploadFileHandler={onUploadFileHandler} />
        </DialogContent>
      </Stack>
    </Dialog>
  );
};
