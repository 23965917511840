import { useAppStore } from 'store';
import { shallow } from 'zustand/shallow';

import { useTabs } from 'hooks/useTabs';

export const useCatalogTableTabs = () => {
  const { catalogItemsType, setCatalogItemsType } = useAppStore(
    store => ({
      catalogItemsType: store.catalogItemsType,
      setCatalogItemsType: store.setCatalogItemsType,
    }),
    shallow
  );

  const { setTabValue, tabValue } = useTabs(catalogItemsType === 'services' ? 0 : 1);

  const handleSetTabValue = (_: React.SyntheticEvent<Element, Event>, newValue: number) => {
    setTabValue(newValue);
    setCatalogItemsType(newValue === 0 ? 'services' : 'products');
  };

  return { tabValue, handleSetTabValue };
};
