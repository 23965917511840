import { FC } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material';
import { confirmUserAttribute, updateUserAttribute } from 'aws-amplify/auth';
import { Controller, useForm } from 'react-hook-form';

import { OTPinput } from 'components/Form';
import { RHFTextField } from 'components/HookForm';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDTypography from 'components/MDTypography';
import { ToastType, notice } from 'components/ToastNotification';
import { useCognitoAuthContext } from 'context';
import { useBoolean } from 'hooks/useBoolean';
import { useUserProfile } from 'hooks/useUserProfile';
import { awsCognitoErrorHandler } from 'utils/errorHanders';

import {
  UserEmailSchema,
  VerificationCodeSchema,
  userEmailValidationSchema,
  verificationCodeValidationSchema,
} from './form.config';

export const UserEmailForm: FC = () => {
  const { cognitoUser } = useUserProfile();

  const [isConfirmEmailDialogopen, onOpenConfirmEmailDialog, onCloseConfirmEmailDialog] = useBoolean();

  const {
    register,
    formState: { errors, isValid, isSubmitting, isDirty },
    handleSubmit,
  } = useForm<UserEmailSchema>({
    resolver: yupResolver(userEmailValidationSchema),
    mode: 'onTouched',
    defaultValues: {
      email: cognitoUser?.userAttributes?.email || '',
    },
  });

  const onFormSubmitHandler = handleSubmit(async ({ email }) => {
    try {
      const response = await updateUserAttribute({
        userAttribute: {
          attributeKey: 'email',
          value: email,
        },
      });
      if (!response?.isUpdated && response?.nextStep?.updateAttributeStep === 'CONFIRM_ATTRIBUTE_WITH_CODE') {
        notice(ToastType.INFO, 'Please check your email for the verification code.');
        onOpenConfirmEmailDialog();
      }
    } catch (error) {
      console.error(error);
      awsCognitoErrorHandler({ error, customErrorMessage: 'Failed to update email, please try again!' });
    }
  });

  const isSubmitButtonDisable = !isValid || isSubmitting || !isDirty;

  const {
    formState: { errors: errorsVerify, isValid: isValidVerify, isSubmitting: isSubmittingVerify },
    handleSubmit: handleSubmitVerify,
    reset: resetVerify,
    control: controlVerify,
  } = useForm<VerificationCodeSchema>({
    resolver: yupResolver(verificationCodeValidationSchema),
    mode: 'onTouched',
    defaultValues: {
      verificationCode: '',
    },
  });

  const onVerifySubmitHandler = handleSubmitVerify(async ({ verificationCode }) => {
    try {
      await confirmUserAttribute({ userAttributeKey: 'email', confirmationCode: verificationCode });
      onCloseConfirmEmailDialog();
      notice(ToastType.SUCCESS, 'Successfully verified!');
      resetVerify();
    } catch (error) {
      console.error(error);
      awsCognitoErrorHandler({ error, customErrorMessage: 'Failed to verify email, please try again!' });
    }
  });

  const isVerifyButtonDisable = !isValidVerify || isSubmittingVerify;

  return (
    <Box>
      <Box component="form" onSubmit={onFormSubmitHandler}>
        <Stack spacing={2}>
          <MDTypography variant="body2" color="text">
            Please enter your new email address. After updating, you will need to verify your new email before you can
            use it.
          </MDTypography>
          <RHFTextField
            registerErrors={errors?.email?.message}
            registerName="email"
            register={register}
            label="Email"
            required
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <MDButton type="submit" isLoading={isSubmitting} disabled={isSubmitButtonDisable}>
              Update email
            </MDButton>
          </Stack>
        </Stack>
      </Box>

      <Dialog open={isConfirmEmailDialogopen} onClose={onCloseConfirmEmailDialog}>
        <DialogTitle sx={{ textAlign: 'center', pb: 0 }}>Enter the TOTP verification code</DialogTitle>

        <MDBox component="form" display="flex" flexDirection="column" gap={2.5} onSubmit={onVerifySubmitHandler}>
          <DialogContent sx={{ mt: 1, px: 2.5, py: 1.5 }}>
            <Stack spacing={2}>
              <MDTypography variant="body2" color="text" textAlign="center">
                Please verify your new email address. A verification code has been sent to your new email.
              </MDTypography>
              <Controller
                control={controlVerify}
                name="verificationCode"
                render={({ field: { ref, onChange, ...other }, fieldState: { error } }) => {
                  return (
                    <OTPinput {...other} renderSeparator={<Box width={16} />} onChange={value => onChange(value)} />
                  );
                }}
              />
            </Stack>
          </DialogContent>

          <DialogActions sx={{ justifyContent: 'center' }}>
            <Stack
              width={1}
              direction={{ xs: 'column', sm: 'row' }}
              alignItems="center"
              justifyContent="center"
              spacing={2}
            >
              <MDButton onClick={onCloseConfirmEmailDialog} variant="outlined" color="error">
                Cancel
              </MDButton>
              <MDButton type="submit" isLoading={isSubmittingVerify} disabled={isVerifyButtonDisable} autoFocus>
                Verify
              </MDButton>
            </Stack>
          </DialogActions>
        </MDBox>
      </Dialog>
    </Box>
  );
};
