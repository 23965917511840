import { FC } from 'react';

import { AddOutlined } from '@mui/icons-material';
import { Stack } from '@mui/material';

import MDBox from 'components/MDBox';
import MDButton, { MDButtonProps } from 'components/MDButton';
import MDTypography from 'components/MDTypography';

type ActionButtonProps = {
  title: string;
  onClickHandler?: () => void | Promise<void>;
  buttonProps?: MDButtonProps;
};

type EmptyStateWithActionProps = {
  title: string;
  subtitle?: string;
  actionButtonProps: ActionButtonProps;
};

export const EmptyStateWithAction: FC<EmptyStateWithActionProps> = ({ title, subtitle, actionButtonProps }) => {
  return (
    <Stack flex={1} alignItems="center" justifyContent="center" position="relative">
      <Stack spacing={2}>
        <Stack textAlign="center">
          <MDTypography variant="h4">{title}</MDTypography>
          <MDTypography variant="h6" sx={{ color: 'grey.600' }}>
            {subtitle}
          </MDTypography>
        </Stack>

        <MDBox sx={{ m: '16px auto !important' }}>
          <MDButton
            variant="contained"
            color="primary"
            size="medium"
            onClick={actionButtonProps?.onClickHandler}
            startIcon={<AddOutlined fontSize="medium" sx={{ width: 20, height: 20, mr: 1 }} />}
            {...actionButtonProps?.buttonProps}
          >
            {actionButtonProps.title}
          </MDButton>
        </MDBox>
      </Stack>
    </Stack>
  );
};
