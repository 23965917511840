import { PromptVersionPostRequest } from 'apiServices/ml/types';
import * as yup from 'yup';

export const PromptVersionValidationSchema = yup.object().shape({
  version: yup.string().required('Version is required').max(50, 'Version must be at most 50 characters'),

  description: yup.string().required('Description is required').max(500, 'Description must be at most 500 characters'),

  prompt_id: yup.string().required('Prompt ID is required').uuid('Prompt ID must be a valid UUID'),
});

export const defaultPromptVersionValues: PromptVersionPostRequest = {
  prompt_id: '',
  text: 'Description of prompt version',
};
