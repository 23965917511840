import { FC, useCallback, useEffect, useState } from 'react';

import { Stack } from '@mui/material';

import { MDInput } from 'components/MDInput';

import { RatingItem } from './RatingItem';

interface FeedbackData {
  comments: string;
  expectedResponse: string;
  ratings: {
    [key: string]: number;
  };
}

interface FeedbackContentProps {
  collectFeedbackData: (data: FeedbackData) => void;
}

const ratingRows = [
  { key: 'accuracy', label: 'Accuracy' },
  { key: 'relevance', label: 'Relevance' },
  { key: 'coherence', label: 'Coherence' },
];

export const FeedbackContent: FC<FeedbackContentProps> = ({ collectFeedbackData }) => {
  const [comments, setComments] = useState<string>('');
  const [expectedResponse, setExpectedResponse] = useState<string>('');
  const [ratings, setRatings] = useState<{ [key: string]: number }>({
    lastTime: 0,
    accuracy: 0,
    relevance: 0,
    coherence: 0,
  });

  const handleRatingChange = useCallback((ratingName: string, newValue: number | null) => {
    setRatings(prevRatings => ({
      ...prevRatings,
      [ratingName]: newValue !== null ? newValue : prevRatings[ratingName],
    }));
  }, []);

  useEffect(() => {
    const data: FeedbackData = {
      comments,
      expectedResponse,
      ratings,
    };
    collectFeedbackData(data);
  }, [comments, expectedResponse, ratings, collectFeedbackData]);

  return (
    <Stack spacing={2.5}>
      <MDInput
        label="General Comments"
        placeholder="Enter comments"
        multiline
        minRows={4}
        maxRows={6}
        value={comments}
        onChange={event => setComments(event.target.value)}
      />

      <MDInput
        label="Expected Response"
        placeholder="Enter response"
        multiline
        minRows={4}
        maxRows={6}
        value={expectedResponse}
        onChange={event => setExpectedResponse(event.target.value)}
      />

      <Stack spacing={2}>
        {ratingRows.map(({ key, label }) => (
          <RatingItem
            key={key}
            ratingValue={ratings[key]}
            name={label}
            onChange={newValue => handleRatingChange(key, newValue)}
          />
        ))}
      </Stack>
    </Stack>
  );
};
