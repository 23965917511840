import { FC } from 'react';

import { ArrowBackIosRounded } from '@mui/icons-material';
import { Stack } from '@mui/material';

import MDButton from 'components/MDButton';

export const FooterContent: FC = () => {
  return (
    <Stack
      borderTop={theme => `1px solid ${theme.palette.grey[300]}`}
      direction="row"
      spacing={1}
      justifyContent="space-between"
      py={1}
      alignItems="center"
    >
      <MDButton
        sx={{ width: { xs: 1, sm: 'auto' } }}
        size="small"
        variant="outlined"
        startIcon={<ArrowBackIosRounded fontSize="medium" />}
      >
        Back
      </MDButton>
    </Stack>
  );
};
