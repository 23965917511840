import { useState } from 'react';

import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';
import { IconButton, InputAdornment } from '@mui/material';
import { FieldValues, Path, RegisterOptions, UseFormRegister } from 'react-hook-form';

import { MDInput, MDInputProps } from 'components/MDInput';

type RHFTextFieldProps<TField extends FieldValues> = MDInputProps & {
  register: UseFormRegister<TField>;
  registerOptions?: RegisterOptions<TField, any>;
  registerName: Path<TField>;
  registerErrors: string;
  isPasswordField?: boolean;
};

export const RHFTextField = <TField extends FieldValues>(props: RHFTextFieldProps<TField>) => {
  const {
    registerErrors,
    registerName,
    register,
    registerOptions,
    label,
    size = 'medium',
    isPasswordField,
    type,
    required,
    ...rest
  } = props;

  const [isShowPassword, setIsShowPassword] = useState(false);

  const onClickHidePasswordIconHandler = () => setIsShowPassword(!isShowPassword);

  const fieldType = isPasswordField && !isShowPassword ? 'password' : isPasswordField && isShowPassword ? 'text' : type;

  return (
    <MDInput
      {...register(registerName, registerOptions)}
      label={label}
      required={required}
      size={size}
      error={!!registerErrors}
      helperText={String(registerErrors || '')}
      type={fieldType}
      InputProps={{
        ...(isPasswordField && {
          endAdornment: (
            <InputAdornment position="end">
              <IconButton size="small" onClick={onClickHidePasswordIconHandler} edge="end">
                {isShowPassword ? (
                  <VisibilityOutlined fontSize="medium" />
                ) : (
                  <VisibilityOffOutlined fontSize="medium" />
                )}
              </IconButton>
            </InputAdornment>
          ),
        }),
      }}
      {...rest}
    />
  );
};
