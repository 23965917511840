import { FC } from 'react';

import { SearchOutlined } from '@mui/icons-material';
import { Box, Stack, TableCell, TableRow, Typography } from '@mui/material';

import { ITableNoDataProps } from './Table.types';

export const TableNoData: FC<ITableNoDataProps> = props => {
  const { isNotFound, sx } = props;
  return (
    <TableRow
      sx={{
        ':hover': {
          boxShadow: 'none',
          cursor: 'auto',
        },
        ...sx,
      }}
    >
      {isNotFound ? (
        <TableCell colSpan={12}>
          <Stack
            spacing={2}
            textAlign="center"
            sx={{
              height: 1,
              textAlign: 'center',
              p: theme => theme.spacing(5, 2),
            }}
          >
            <Stack spacing={1.5} justifyContent="center" alignItems="center">
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{
                  boxShadow: theme => theme.boxShadows.lg,
                  bgcolor: theme => theme.palette.white.main,
                  width: 42,
                  height: 42,
                  border: theme => `1px solid ${theme.palette.grey[100]}`,
                  borderRadius: theme => theme.borders.borderRadius.lg,
                }}
              >
                <SearchOutlined fontSize="medium" />
              </Box>
              <Stack spacing={1}>
                <Typography variant="h6">No results found</Typography>
              </Stack>
            </Stack>
          </Stack>
        </TableCell>
      ) : (
        <TableCell colSpan={12} sx={{ p: 0 }} />
      )}
    </TableRow>
  );
};
