import { memo } from 'react';

import { DictionaryItem, MedicalCondition, PatientEnum } from 'apiServices';

export const getElementById = <T extends { id: string } | PatientEnum | DictionaryItem | MedicalCondition>(
  cururentElementId: string,
  options: T[]
): T | null => {
  return cururentElementId
    ? options.find(option => {
        if ('icd10Code' in option) {
          return cururentElementId === option.icd10Code;
        }
        if ('id' in option) {
          return cururentElementId === option.id;
        }
        if ('isoCode' in option) {
          return cururentElementId.toLowerCase() === option.isoCode?.toLowerCase();
        }

        return cururentElementId === option.value;
      }) ?? null
    : null;
};

export const getFilterApplyButtonStatus = <TFilters>({
  filterEntries,
  appliedFilters,
}: {
  filterEntries: [string, TFilters[keyof TFilters]][];
  appliedFilters: TFilters;
}): boolean => {
  return filterEntries.every(([key, value]) => {
    const appliedFilterValue = appliedFilters[key as keyof TFilters];

    if (typeof appliedFilterValue === 'object') {
      const currentFilterItemValues = Object.values(value || {});

      const appliedFilterItemValues = Object.values(appliedFilterValue || {});

      return appliedFilterItemValues?.[0] === currentFilterItemValues?.[0];
    }
    return !!appliedFilterValue === !!value;
  });
};

export const getFilterResetButtonStatus = <TFilters extends Record<string, any>>({
  filterEntries,
}: {
  filterEntries: [string, TFilters[keyof TFilters]][];
}): boolean => {
  return filterEntries.every(([_, value]) => {
    if (typeof value === 'object') {
      const currentFilterItemValues = Object.values(value || {});
      return !Boolean(currentFilterItemValues?.[0]);
    }
    return !Boolean(value);
  });
};

export function randomIntFromInterval(min: number = 0, max: number = 4) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export const genericMemo: <T>(component: T) => T = memo;

export function nameAvatar(firstName: string, lastName?: string) {
  const firstLetter = firstName.split(' ')?.[0]?.[0];

  if (lastName) {
    const lastLetter = lastName.split(' ')?.[0]?.[0];
    return `${firstLetter} ${lastLetter}`;
  }

  return firstLetter;
}

export const formatCountdownTime = (totalSeconds: number): string => {
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;
  const paddedMinutes = String(minutes).padStart(2, '0');
  const paddedSeconds = String(seconds).padStart(2, '0');
  return `${paddedMinutes}:${paddedSeconds}`;
};

export function hideEmail(email: string): string {
  const [localPart, domain] = email.split('@');

  if (localPart.length <= 1) return email;

  const hiddenLocalPart = localPart.substring(0, 2) + '*'.repeat(6);

  return `${hiddenLocalPart}@${domain}`;
}

export const checkIsFilterApplied = <TInitial extends { [key: string]: any }, TApplied extends { [key: string]: any }>(
  initialFilter: TInitial,
  appliedFilter: TApplied
): boolean => {
  const filterEntries = Object.entries(initialFilter);
  for (let index = 0; index < filterEntries.length; index++) {
    const key = filterEntries[index]?.[0];
    if (Boolean(initialFilter[key]) !== Boolean(appliedFilter[key])) return true;
  }

  return false;
};
