import { useEffect, useState } from 'react';

import { getAllAIModels } from 'apiServices/ml/ai-model.api';
import { AIModelItemResponse } from 'apiServices/ml/types';

export const useAIModels = () => {
  const [aiModels, setAIModels] = useState<AIModelItemResponse[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchAIModels = async () => {
      setIsLoading(true);
      try {
        const response = await getAllAIModels();
        setAIModels(response.data);
      } catch (err) {
        setError('Error fetching AI models');
      } finally {
        setIsLoading(false);
      }
    };

    fetchAIModels();
  }, []);

  return { aiModels, isLoading, error };
};
