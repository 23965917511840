import { FC } from 'react';

import { Dialog, DialogContent, Stack } from '@mui/material';

import { DialogActions, DialogHeader } from './components';
import { DiscardChangesDialogWindowProps } from './types';

export const DiscardChangesDialogWindow: FC<DiscardChangesDialogWindowProps> = ({
  onClickApproveButtonHandler,
  onClickCancelButtonHandler,
  title = 'Unsaved changes',
  ...other
}) => {
  return (
    <Dialog open={open} onClose={onClickApproveButtonHandler} {...other}>
      <Stack px={3}>
        <DialogHeader sx={{ px: 0 }} onClickCancelButtonHandler={onClickApproveButtonHandler} title={title} />
        <DialogContent sx={{ px: 0 }}>
          You have unsaved changes. Would you like to discard the changes or continue editing?
        </DialogContent>
        <DialogActions
          sx={{ px: 0 }}
          approveButtonProps={{ children: 'Continue editing', onClick: onClickApproveButtonHandler }}
          cancelButtonProps={{ children: 'Discard', color: 'error', onClick: onClickCancelButtonHandler }}
        />
      </Stack>
    </Dialog>
  );
};
