import { FC } from 'react';

import { Box, Grid } from '@mui/material';

import { MDTabs } from 'components/MDTabs';
import { useTabs } from 'hooks/useTabs';

import { TABS } from './config';
import { GlobalLibraryTagList, PracticeLibraryTagList } from './pages';

function renderTabValueContent(tabValue: number) {
  switch (tabValue) {
    case 1:
      return <PracticeLibraryTagList />;

    default:
      return <GlobalLibraryTagList />;
  }
}

export const TagManagment: FC = () => {
  const { handleSetTabValue, tabValue } = useTabs();

  return (
    <Box component="section">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box sx={{ maxWidth: 400, width: 1 }}>
            <MDTabs styleVariant="folder" value={tabValue} tabsData={TABS} handleSetTabValue={handleSetTabValue} />
          </Box>
          {renderTabValueContent(tabValue)}
        </Grid>
      </Grid>
    </Box>
  );
};
