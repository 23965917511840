import { styled } from '@mui/material';
import { ToastContainer } from 'react-toastify';

export const StyledToastContainer = styled(ToastContainer)(({ theme }) => ({
  '.Toastify__progress-bar': { opacity: 1 },

  '.Toastify__progress-bar--success': {
    background: theme.palette.primary.main,
  },

  '.Toastify__progress-bar--info': {
    background: theme.palette.info.main,
  },

  '.Toastify__toast-icon': {
    width: 16,
    height: 16,
    '& svg': {
      width: 16,
      height: 16,
    },
  },

  '.Toastify__close-button': {
    marginTop: 6,
  },
}));
