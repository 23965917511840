import { activateExpert, deactivateExpert } from 'apiServices';
import { StateCreator } from 'zustand';

import { ToastType, notice } from 'components/ToastNotification';
import { backendErrorHandler } from 'utils/errorHanders';

import { sliceResetFns } from '../../Store';
import { ExpertSliceType, ExpertsInitialState } from './types';

const initialState: ExpertsInitialState = {
  experts: {
    page: null,
    items: [],
    total: null,
    size: null,
    pages: null,
    links: null,
  },
  expert: null,
};

export const ExpertsSlice: StateCreator<
  ExpertSliceType,
  [['zustand/devtools', never], ['zustand/immer', never]],
  [],
  ExpertSliceType
> = set => {
  sliceResetFns.add(() => set(initialState));
  return {
    ...initialState,
    setExperts: experts => {
      set(state => {
        state.experts = experts;
      });
    },
    setExpert: expert => {
      set(state => {
        state.expert = expert;
      });
    },
    activateExpert: async id => {
      try {
        await activateExpert(id);

        notice(ToastType.SUCCESS, 'User has been successfully activated!');
        return true;
      } catch (error) {
        backendErrorHandler({
          error,
          config: {
            customErrorMessage: 'Failed to activate user, please try again!',
          },
        });
        return false;
      }
    },
    deactivateExpert: async id => {
      try {
        await deactivateExpert(id);
        notice(ToastType.SUCCESS, 'User has been successfully inactivated!');

        return true;
      } catch (error) {
        backendErrorHandler({
          error,
          config: {
            customErrorMessage: 'Failed to inactivate user, please try again!',
          },
        });
        return false;
      }
    },
  };
};
