import { FC, memo, useCallback, useState } from 'react';

import { AddOutlined } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { deletePatientAllergy, deletePatientMedicalCondition } from 'apiServices';
import { useFormContext } from 'react-hook-form';

import { BaseDialogWindow } from 'components/BaseDialogWindow';
import { FormHeader, FormItem } from 'components/Form';
import { RHFAutocompleteField, RHFTextField } from 'components/HookForm';
import MDButton from 'components/MDButton';
import { Scrollbar } from 'components/ScrollBar';
import { SideMenu } from 'components/SideMenu';
import { ToastType, notice } from 'components/ToastNotification';
import { useBoolean } from 'hooks/useBoolean';
import { backendErrorHandler } from 'utils/errorHanders';

import { PATIENT_CARD_ID } from '../../constants';
import { CreateAllergyMenuContent } from '../CreateAllergyMenuContent';
import { CreateMedicalConditionMenuContent } from '../CreateMedicalConditionMenuContent';
import { AllergyItem } from './AllergyItem';
import { MedicalConditionsItem } from './MedicalConditionsItem';
import { EditPatientFormSchema } from './form.config';
import { PatientCardProps } from './types';

const CustomScrollStyles = { width: '100%', maxHeight: 330, padding: '8px 0' };

export const PatientCard: FC<PatientCardProps> = memo(
  ({
    bioGenders,
    cardRef,
    memoizedGetOptionLabel,
    memoizedIsOptionEqualToValue,
    isAllPatientEnumsLoading,
    painTolerances,
    bloodTypes,
    patientMedicalConditions,
    patientAllergies,
    patientId,
    fetchPatient,
  }) => {
    const {
      control,
      register,
      formState: { errors },
    } = useFormContext<EditPatientFormSchema>();

    const [isDeleteMedicalConditionDialogOpen, openDeleteMedicalConditionDialog, closeDeleteMedicalConditionDialog] =
      useBoolean();

    const [selectedItemId, setSelectedItemId] = useState('');

    const [isDeletingMedicalConditions, setIsDeletingMedicalConditions] = useState(false);

    const onDeleteMedicalConditionHandler = async () => {
      try {
        setIsDeletingMedicalConditions(true);

        await deletePatientMedicalCondition(patientId, selectedItemId);
        await fetchPatient(patientId);

        closeDeleteMedicalConditionDialog();
        setIsDeletingMedicalConditions(false);
        setSelectedItemId('');

        notice(ToastType.SUCCESS, 'Medical conditions have been successfully removed!');
      } catch (error) {
        setIsDeletingAllergy(false);
        console.error(error);
        backendErrorHandler({
          error,
          config: { customErrorMessage: 'Failed to remove medical conditions, please try again!' },
        });
      }
    };

    const [isDeletingAllergy, setIsDeletingAllergy] = useState(false);

    const onDeleteAllergyHandler = async () => {
      try {
        setIsDeletingAllergy(true);

        await deletePatientAllergy(patientId, selectedItemId);
        await fetchPatient(patientId);

        closeDeleteAllergyDialog();
        setIsDeletingAllergy(false);
        setSelectedItemId('');

        notice(ToastType.SUCCESS, 'Allergy has been successfully removed!');
      } catch (error) {
        setIsDeletingAllergy(false);

        console.error(error);
        backendErrorHandler({
          error,
          config: { customErrorMessage: 'Failed to remove allergy, please try again!' },
        });
      }
    };

    const [isCreateMedicalConditionMenuOpen, openCreateMedicalConditionMenu, closeCreateMedicalConditionMenu] =
      useBoolean();

    const onClickRemoveButtonHandler = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
      openDeleteMedicalConditionDialog();
      setSelectedItemId(event.currentTarget.id);
    }, []);

    const [isDeleteAllergyDialogOpen, openDeleteAllergyDialog, closeDeleteAllergyDialog] = useBoolean();

    const onClickRemoveAllergyButtonHandler = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
      openDeleteAllergyDialog();
      setSelectedItemId(event.currentTarget.id);
    }, []);

    const [isCreateAllergyMenuOpen, openCreateAllergyMenu, closeCreateAllergyMenu] = useBoolean();

    return (
      <>
        <Stack ref={cardRef} id={PATIENT_CARD_ID} sx={{ p: { xs: 2, sm: 2.5, md: 3, lg: 3.5 } }}>
          <FormHeader title="Patient card" subtitle="Fill out the details for the pattient’s card." />

          <Stack>
            <FormItem title="Patient RAG">
              <RHFTextField
                register={register}
                registerName="patientCard.rag"
                registerErrors={errors.patientCard?.rag?.message}
                fullWidth
                multiline
                maxRows={5}
                placeholder="Enter patient RAG"
              />
            </FormItem>

            <FormItem title="Bio gender">
              <RHFAutocompleteField
                control={control}
                name="patientCard.bioGender"
                placeholder="Select bio gender"
                getOptionLabel={memoizedGetOptionLabel}
                isOptionEqualToValue={memoizedIsOptionEqualToValue}
                options={bioGenders}
                loading={isAllPatientEnumsLoading}
              />
            </FormItem>

            <FormItem title="Blood type">
              <RHFAutocompleteField
                control={control}
                name="patientCard.bloodType"
                placeholder="Select blood type"
                getOptionLabel={memoizedGetOptionLabel}
                isOptionEqualToValue={memoizedIsOptionEqualToValue}
                options={bloodTypes}
                loading={isAllPatientEnumsLoading}
              />
            </FormItem>

            <FormItem title="Pain tolerance">
              <RHFAutocompleteField
                control={control}
                name="patientCard.painTolerance"
                placeholder="Select pain tolerance"
                getOptionLabel={memoizedGetOptionLabel}
                isOptionEqualToValue={memoizedIsOptionEqualToValue}
                options={painTolerances}
                loading={isAllPatientEnumsLoading}
              />
            </FormItem>

            <FormItem
              title="Allergies"
              actionButton={
                <MDButton size="small" onClick={openCreateAllergyMenu} startIcon={<AddOutlined fontSize="medium" />}>
                  Add allergy
                </MDButton>
              }
            >
              {patientAllergies?.length ? (
                <Scrollbar style={CustomScrollStyles}>
                  <Stack spacing={2} width={1}>
                    {patientAllergies?.map(allergy => {
                      return (
                        <AllergyItem
                          key={allergy.id}
                          allergyName={allergy?.allergy?.name}
                          icd10Code={allergy?.allergyIcd10Code}
                          allergyId={allergy?.id}
                          onClickRemoveButtonHandler={onClickRemoveAllergyButtonHandler}
                        />
                      );
                    })}
                  </Stack>
                </Scrollbar>
              ) : null}
            </FormItem>

            <FormItem
              title="Medical conditions"
              isDivider={false}
              actionButton={
                <MDButton
                  size="small"
                  onClick={openCreateMedicalConditionMenu}
                  startIcon={<AddOutlined fontSize="medium" />}
                >
                  Add medical condition
                </MDButton>
              }
            >
              {patientMedicalConditions?.length ? (
                <Scrollbar style={CustomScrollStyles}>
                  <Stack spacing={2} width={1}>
                    {patientMedicalConditions?.map(medCondition => {
                      return (
                        <MedicalConditionsItem
                          key={medCondition.id}
                          medicalConditionIcd10Code={medCondition?.medicalConditionIcd10Code}
                          symptoms={medCondition?.symptoms}
                          medConditionId={medCondition?.id}
                          onClickRemoveButtonHandler={onClickRemoveButtonHandler}
                        />
                      );
                    })}
                  </Stack>
                </Scrollbar>
              ) : null}
            </FormItem>
          </Stack>
        </Stack>

        <BaseDialogWindow
          onClickCancelButtonHandler={closeDeleteMedicalConditionDialog}
          open={isDeleteMedicalConditionDialogOpen}
          description="Are you sure you want to delete medical condition?"
          title="Deleting medical condition"
          approveButtonTitle="Delete"
          onClickApproveButtonHandler={onDeleteMedicalConditionHandler}
          isApproveButtonDisabled={isDeletingMedicalConditions}
          isApproveButtonLoading={isDeletingMedicalConditions}
        />

        <BaseDialogWindow
          onClickCancelButtonHandler={closeDeleteAllergyDialog}
          open={isDeleteAllergyDialogOpen}
          description="Are you sure you want to delete allergy?"
          title="Deleting allergy"
          approveButtonTitle="Delete"
          onClickApproveButtonHandler={onDeleteAllergyHandler}
          isApproveButtonDisabled={isDeletingAllergy}
          isApproveButtonLoading={isDeletingAllergy}
        />

        <SideMenu
          isOpen={isCreateMedicalConditionMenuOpen}
          title="Add medical condition"
          onClose={closeCreateMedicalConditionMenu}
        >
          <CreateMedicalConditionMenuContent
            patientId={patientId}
            fetchPatient={fetchPatient}
            closeMenuHandler={closeCreateMedicalConditionMenu}
          />
        </SideMenu>

        <SideMenu isOpen={isCreateAllergyMenuOpen} title="Add allergy" onClose={closeCreateAllergyMenu}>
          <CreateAllergyMenuContent
            patientId={patientId}
            fetchPatient={fetchPatient}
            closeMenuHandler={closeCreateAllergyMenu}
          />
        </SideMenu>
      </>
    );
  }
);
