import * as yup from 'yup';

export type TOT_MFA_verification_code_schema = {
  TOTPverificationCode: string;
};

export const TOTP_MFA_verification_code_validation_schema: yup.ObjectSchema<TOT_MFA_verification_code_schema> = yup
  .object({
    TOTPverificationCode: yup.string().required().label('TOTP verification code').min(6).max(6),
  })
  .required();
