import { PRACTICE_CONTACT_PERSON_ID, PRACTICE_PROFILE_ID, PRACTICE_SERVICE_ID } from './constants';
import { PRACTICES_FORM_CARDS } from './practices.enum';

export const getFormSideNavItems = (activeCard: PRACTICES_FORM_CARDS) => [
  {
    label: PRACTICES_FORM_CARDS.PRACTICE_PROFILE,
    isActive: activeCard === PRACTICES_FORM_CARDS.PRACTICE_PROFILE,
    href: `#${PRACTICE_PROFILE_ID}`,
  },

  {
    label: PRACTICES_FORM_CARDS.PRACTICE_CONTACT_PERSON,
    isActive: activeCard === PRACTICES_FORM_CARDS.PRACTICE_CONTACT_PERSON,
    href: `#${PRACTICE_CONTACT_PERSON_ID}`,
  },

  {
    label: PRACTICES_FORM_CARDS.PRACTICE_SERVICE,
    isActive: activeCard === PRACTICES_FORM_CARDS.PRACTICE_SERVICE,
    href: `#${PRACTICE_SERVICE_ID}`,
  },
];
