import { FC } from 'react';

import { Card, CardProps } from '@mui/material';

type TableCardProps = CardProps & {
  children: React.ReactNode;
};

export const TableCard: FC<TableCardProps> = ({ children, sx, ...other }) => {
  return (
    <Card
      {...other}
      sx={{
        overflow: 'hidden',
        borderTopLeftRadius: 0,
        ...sx,
      }}
    >
      {children}
    </Card>
  );
};
