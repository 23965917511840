import { FC } from 'react';

import { Grid } from '@mui/material';

import { SideNavFormMenu } from 'components/BaseSideNavMenu';
import { FormActions } from 'components/Form';
import { FormProvider } from 'components/HookForm';
import { ROUTES } from 'constants/routes';
import { PATIENT_CARD_ID, PATIENT_CONTACT_ID, PATIENT_PROFILE_ID } from 'pages/Patients';

import { EditPatientForm } from './components';
import { usePatientProfileState } from './usePatientProfile.state';

export const PatientProfile: FC = () => {
  const {
    formMethods,
    activeSectionId,
    executeScroll,
    patientCardEntry,
    patientContactEntry,
    patientProfileEntry,
    refs,
    isSubmitButtonDisabled,
    isSubmitting,
    onFormSubmitHandler,
    patientId,
    fetchPatient,
    patientCard,
    isDirty,
  } = usePatientProfileState();

  return (
    <>
      <Grid
        item
        xs={12}
        sm={2}
        sx={({ borders, breakpoints }) => ({
          borderRight: `${borders.borderWidth[1]} solid ${borders.borderColor}`,
          borderBottom: 0,
          [breakpoints.down('sm')]: {
            borderRight: 0,
            borderBottom: `${borders.borderWidth[1]} solid ${borders.borderColor} `,
          },
        })}
      >
        <SideNavFormMenu
          activeSectionId={activeSectionId}
          onClick={executeScroll}
          sidenavItems={[
            {
              label: 'Patient profile',
              id: PATIENT_PROFILE_ID,
              entry: patientProfileEntry,
            },

            {
              label: 'Patient contact',
              id: PATIENT_CONTACT_ID,
              entry: patientContactEntry,
            },
            {
              label: 'Patient card',
              id: PATIENT_CARD_ID,
              entry: patientCardEntry,
            },
          ]}
        />
      </Grid>
      <Grid item xs={12} sm={10}>
        <FormProvider methods={formMethods} onSubmit={onFormSubmitHandler}>
          <EditPatientForm
            medicalConditions={patientCard?.medicalConditions}
            allergies={patientCard?.allergies}
            refs={refs}
            patientId={patientId}
            fetchPatient={fetchPatient}
          />
        </FormProvider>

        <FormActions
          isDisabled={isSubmitButtonDisabled}
          isLoading={isSubmitting}
          onFormSubmitHandler={onFormSubmitHandler}
          isDirtyForm={isDirty}
          backRoute={ROUTES.patients}
        />
      </Grid>
    </>
  );
};
