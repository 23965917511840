import { useMemo } from 'react';

import { Skeleton } from '@mui/material';
import { GLService, PLService } from 'apiServices';
import { AppStore, useAppStore } from 'store';
import { BreadcrumbComponentProps } from 'use-react-router-breadcrumbs';

import { RouterState } from 'hooks/useRouter';

type BreadcrumbData = { data?: string | null };

const connect = (mapStateToPropsFC: typeof mapPatientStateToProps) => (Component: React.FC<BreadcrumbData>) => {
  return (props: BreadcrumbComponentProps): JSX.Element => {
    const store = useAppStore();

    const stateProps = useMemo(() => mapStateToPropsFC(store, props), [store, props]);
    return <Component {...props} {...stateProps} />;
  };
};

export const getBreadCrumbName = ({ location: { state } }: { location: { state: RouterState } }) => {
  return <span>{state?.breadCrumbName}</span>;
};

const mapEntityStateToProps = (
  state: AppStore,
  props: BreadcrumbComponentProps,
  entityKey: keyof AppStore,
  idKey: string,
  nameCallback: (entity: any) => string | null
): BreadcrumbData => {
  const entity = state?.[entityKey];
  const isIdMatch = entity?.[idKey as keyof typeof entity] === props?.match?.params?.id;

  return {
    data: isIdMatch && entity ? nameCallback(entity) : null,
  };
};

const mapPatientStateToProps = (state: AppStore, props: BreadcrumbComponentProps) =>
  mapEntityStateToProps(
    state,
    props,
    'patient',
    'id',
    patient => `${patient?.person?.firstName} ${patient?.person?.middleName || ''} ${patient?.person?.lastName}`
  );

const mapPracticeStateToProps = (state: AppStore, props: BreadcrumbComponentProps) =>
  mapEntityStateToProps(state, props, 'practice', 'id', practice => practice?.name);

const mapTagCategoryStateToProps = (state: AppStore, props: BreadcrumbComponentProps) =>
  mapEntityStateToProps(state, props, 'breadcrumbData', 'categoryId', breadcrumbData => breadcrumbData?.name);

const mapUserStateToProps = (state: AppStore, props: BreadcrumbComponentProps) =>
  mapEntityStateToProps(
    state,
    props,
    'expert',
    'id',
    expert => `${expert?.person?.firstName} ${expert?.person?.middleName || ''} ${expert?.person?.lastName}`
  );

const mapGLServiceStateToProps = (state: AppStore, props: BreadcrumbComponentProps) =>
  mapEntityStateToProps(state, props, 'GLService', 'id', (glService: GLService) => glService?.title);

const mapPLServiceStateToProps = (state: AppStore, props: BreadcrumbComponentProps) =>
  mapEntityStateToProps(state, props, 'PLService', 'id', (glService: PLService) => glService?.title);

const mapGLProductStateToProps = (state: AppStore, props: BreadcrumbComponentProps) =>
  mapEntityStateToProps(state, props, 'GLProduct', 'id', glService => glService?.title);

const mapPLProductStateToProps = (state: AppStore, props: BreadcrumbComponentProps) =>
  mapEntityStateToProps(state, props, 'PLProduct', 'id', glService => glService?.title);

const BreadcrumbItem = ({ data }: BreadcrumbData) => {
  return data ? <span>{data}</span> : <Skeleton component="span" variant="text" width={100} height={20} />;
};

export const DYNAMIC_BREADCRUMBS = {
  patient: connect(mapPatientStateToProps)(BreadcrumbItem),
  practice: connect(mapPracticeStateToProps)(BreadcrumbItem),
  editPracticeTag: connect(mapTagCategoryStateToProps)(BreadcrumbItem),
  userProfile: connect(mapUserStateToProps)(BreadcrumbItem),
  glServiceProfile: connect(mapGLServiceStateToProps)(BreadcrumbItem),
  plServiceProfile: connect(mapPLServiceStateToProps)(BreadcrumbItem),
  glProductProfile: connect(mapGLProductStateToProps)(BreadcrumbItem),
  plProductProfile: connect(mapPLProductStateToProps)(BreadcrumbItem),
};
