import { useEffect, useState } from 'react';

import { getPatients } from 'apiServices/patient/patient.api';
import { Patient } from 'apiServices/patient/types';

export const usePatients = (practiceId?: string | number) => {
  const [patients, setPatients] = useState<Patient[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchPatients = async () => {
      setIsLoading(true);
      setPatients([]);
      try {
        const response = await getPatients(practiceId ? { practice_id: practiceId } : {});
        setPatients(response.data.items || []);
      } catch (err) {
        setError('Error fetching patients');
      } finally {
        setIsLoading(false);
      }
    };

    if (practiceId) {
      fetchPatients();
    } else {
      setPatients([]);
    }
  }, [practiceId]);

  return { patients, isLoading, error };
};
