import { FC } from 'react';

import { Typography } from '@mui/material';

type StepHeaderProps = {
  subtitle?: string;
  title?: string;
};

export const StepHeader: FC<StepHeaderProps> = ({ title = 'Welcome!', subtitle }) => {
  return (
    <>
      <Typography variant="h2" fontWeight={600} width={{ lg: 350, md: 'auto' }}>
        {title}
      </Typography>
      {subtitle && (
        <Typography variant="button" fontWeight={400} color="text.main">
          {subtitle}
        </Typography>
      )}
    </>
  );
};
