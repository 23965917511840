import { FC } from 'react';

import { useLocation } from 'react-router-dom';

import { CardWrapper } from './CardWrapper';
import { SideNavMenuItem } from './SideNavMenuItem';
import { BaseSideNaveMenuProps } from './types';

export const BaseSideNavMenu: FC<BaseSideNaveMenuProps> = ({ sidenavItems, sx }) => {
  const location = useLocation();

  const renderSidenavItems = sidenavItems.map(({ icon, label, href, isDisabled }, key) => {
    const itemKey = `item-${key}`;

    const isActive = location.pathname === href;

    return (
      <SideNavMenuItem
        key={itemKey}
        icon={icon}
        index={key}
        href={href}
        label={label}
        isActive={isActive}
        isDisabled={isDisabled}
      />
    );
  });

  return <CardWrapper sx={sx}>{renderSidenavItems}</CardWrapper>;
};
