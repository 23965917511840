/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// Material Dashboard 2 PRO React TS Base Styles
import borders from 'assets/theme/base/borders';

const tableContainer = {
  styleOverrides: {
    root: {
      'tr:last-child': {
        borderBottom: 0,
      },
      position: 'relative' as const,
      'th:first-of-type': {
        borderRadius: `${borders.borderRadius.sm} 0 0 0`,
      },
      'th:last-of-type': {
        borderRadius: `0  ${borders.borderRadius.sm} 0 0`,
      },
    },
  },
};

export default tableContainer;
