import { FC } from 'react';

import { Box, Card, Dialog, Divider, Grid } from '@mui/material';

import { TableFiltersPanel } from 'components/Table';
import { useBoolean } from 'hooks/useBoolean';
import { CardItem } from 'pages/BeforeAfter/components';

import { AddAlbumDialogWindowForm } from './components';

export const PracticeLibraryBeforeAfterList: FC = () => {
  const [isOpenAddAllbumDialogWindow, openAddAlbumDialogWindow, closeAddAlbumDialogWindow] = useBoolean();

  return (
    <Card>
      <TableFiltersPanel
        searchInputProps={{
          value: '',
          placeholder: 'Type to search',
          onChange: () => {},
        }}
        onOpenFilterMenuHandler={() => {}}
        actionButtonProps={{
          children: 'Add album',
          // onClick: openAddTagCategoryMenu,
        }}
        isFiltersApplied={false}
      />
      <Divider />

      <Box p={2}>
        <Grid container spacing={2} position="relative" height={1}>
          {[1].map((_, index) => (
            <Grid item key={index} xs={12} sm={6} md={6} lg={3}>
              <CardItem type="pl" />
            </Grid>
          ))}
        </Grid>
      </Box>

      <Dialog
        open={isOpenAddAllbumDialogWindow}
        onClose={closeAddAlbumDialogWindow}
        PaperProps={{
          sx: { width: 1, maxWidth: 750 },
        }}
      >
        <AddAlbumDialogWindowForm onCloseDialogHandler={closeAddAlbumDialogWindow} />
      </Dialog>
    </Card>
  );
};
