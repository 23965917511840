import colors from 'assets/theme/base/colors';
import typography from 'assets/theme/base/typography';
import pxToRem from 'assets/theme/functions/pxToRem';

const { primary, secondary } = colors;
const { size } = typography;

const contained = {
  base: {
    minHeight: pxToRem(40),
    padding: `${pxToRem(10)} ${pxToRem(24)}`,

    '&:active, &:active:focus, &:active:hover': {
      opacity: 0.85,
    },
  },

  small: {
    minHeight: pxToRem(32),
    padding: `${pxToRem(6)} ${pxToRem(16)}`,
    fontSize: size.xs,
  },

  large: {
    minHeight: pxToRem(47),
    padding: `${pxToRem(12)} ${pxToRem(28)}`,
    fontSize: size.sm,
  },

  primary: {
    backgroundColor: primary.main,

    '&:hover': {
      backgroundColor: primary.main,
    },
  },

  secondary: {
    backgroundColor: secondary.main,

    '&:hover': {
      backgroundColor: secondary.main,
    },
  },
};

export default contained;
