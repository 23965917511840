import { FC, useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Card, CardContent, CardHeader, Divider, Grid, Stack } from '@mui/material';
import { addAIModelVersion, updateAIModelVersion } from 'apiServices/ml/ai-model-version.api';
import { AIModelVersionPostRequest } from 'apiServices/ml/types';
import { Resolver, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import MDButton from 'components/MDButton';
import { MDInput } from 'components/MDInput';
import { ROUTES } from 'constants/routes';
import { useRouter } from 'hooks/useRouter';

import { AIModelVersionValidationSchema } from './form.config';

type ModelVersionFormProps = {
  mode?: 'create' | 'update';
  initialData?: AIModelVersionPostRequest;
};

export const AddUpdateModelVersion: FC<ModelVersionFormProps> = ({ mode = 'create' }) => {
  const { navigate, state, params } = useRouter();
  const initialData = state.version;
  const [jsonError, setJsonError] = useState<string | null>(null);
  const [jsonValue, setJsonValue] = useState<string>(
    initialData?.configuration ? JSON.stringify(initialData.configuration, null, 2) : ''
  );

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
    setValue,
  } = useForm<AIModelVersionPostRequest>({
    mode: 'onTouched',
    resolver: yupResolver(AIModelVersionValidationSchema) as unknown as Resolver<AIModelVersionPostRequest>,
    defaultValues: {
      ai_model_id: uuidv4(),
      version: '',
      full_name: '',
      api_endpoint: '',
      release_date: '',
      configuration: null,
      notes: '',
    },
  });

  useEffect(() => {
    if (mode === 'update' && initialData) {
      setValue('version', initialData.version);
      setValue('full_name', initialData.full_name);
      setValue('api_endpoint', initialData.api_endpoint);
      setValue('release_date', initialData.release_date);
      setValue('notes', initialData.notes);
      setValue('configuration', initialData.configuration || null);

      if (initialData.configuration) {
        setJsonValue(JSON.stringify(initialData.configuration, null, 2));
      }
    }
  }, [mode, initialData, setValue]);

  const onSubmit = handleSubmit(async formData => {
    try {
      formData.configuration = jsonValue.trim() === '' || jsonError ? null : JSON.parse(jsonValue);
      if (mode === 'create') {
        formData.ai_model_id = state.model.id;
        await addAIModelVersion(formData);
      } else {
        await updateAIModelVersion(params?.version_id || '', formData);
      }
      navigate(`/ai-models/${state.model.name}/${state.model.id}/versions`, { state: state });
    } catch (error) {
      console.error(`Failed to ${mode === 'create' ? 'create' : 'update'} model version:`, error);
    }
  });

  const handleConfigurationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setJsonValue(value);
    try {
      if (value.trim() === '') {
        setJsonError(null);
      } else {
        JSON.parse(value);
        setJsonError(null);
      }
    } catch (error) {
      setJsonError('Invalid JSON format');
    }
  };

  return (
    <Box component="section" height={1}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <CardHeader title={mode === 'create' ? 'Add AI Model Version' : 'Update AI Model Version'} />
            <CardContent>
              <Stack component="form" onSubmit={onSubmit} spacing={3} divider={<Divider sx={{ mt: '0 !important' }} />}>
                <Stack spacing={3} width={1}>
                  <MDInput
                    {...register('version')}
                    fullWidth
                    label="Version Number"
                    error={!!errors.version}
                    helperText={errors.version?.message}
                    sx={{ marginBottom: '20px' }}
                  />
                  <MDInput
                    {...register('full_name')}
                    fullWidth
                    label="Full Name"
                    error={!!errors.full_name}
                    helperText={errors.full_name?.message}
                    sx={{ marginBottom: '20px' }}
                  />
                  <MDInput
                    {...register('api_endpoint')}
                    fullWidth
                    label="API Endpoint"
                    error={!!errors.api_endpoint}
                    helperText={errors.api_endpoint?.message}
                    sx={{ marginBottom: '20px' }}
                  />
                  <MDInput
                    {...register('release_date')}
                    fullWidth
                    type="date"
                    label="Release Date"
                    error={!!errors.release_date}
                    helperText={errors.release_date?.message}
                    InputLabelProps={{ shrink: true }}
                    sx={{ marginBottom: '20px' }}
                  />
                  <MDInput
                    fullWidth
                    label="Configuration (JSON)"
                    value={jsonValue}
                    onChange={handleConfigurationChange}
                    multiline
                    error={!!jsonError}
                    helperText={jsonError || 'Enter valid JSON or leave empty'}
                    sx={{ marginBottom: '20px' }}
                  />
                  <MDInput
                    {...register('notes')}
                    fullWidth
                    label="Notes"
                    multiline
                    error={!!errors.notes}
                    helperText={errors.notes?.message}
                    sx={{ marginBottom: '20px' }}
                  />
                </Stack>
              </Stack>
            </CardContent>
          </Card>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            position: 'sticky',
            bottom: 10,
            zIndex: theme => theme.zIndex.fab,
          }}
        >
          <Card sx={{ border: theme => `1px solid ${theme.palette.grey[200]}` }}>
            <Box p={2} sx={{ background: 'none' }}>
              <Stack direction={{ xs: 'column', md: 'row' }} justifyContent={'flex-end'} spacing={2}>
                <MDButton variant="outlined" onClick={() => window.history.back()}>
                  Cancel
                </MDButton>
                <MDButton type="submit" onClick={onSubmit} disabled={!isValid || isSubmitting || !!jsonError}>
                  {mode === 'create' ? 'Add Version' : 'Update Version'}
                </MDButton>
              </Stack>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};
