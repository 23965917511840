import { FC, useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Stack } from '@mui/material';
import { createPracticeContactPerson } from 'apiServices';
import { Resolver, useForm } from 'react-hook-form';

import { RHFAutocompleteField, RHFPhoneTextField } from 'components/HookForm';
import { MDInput } from 'components/MDInput';
import { FormMenuPopoverButton } from 'components/MenuPopover';
import { Scrollbar } from 'components/ScrollBar';
import { ToastType, notice } from 'components/ToastNotification';
import { usePracticeEnums } from 'hooks/usePracticeEnums';
import { formErrorHandler } from 'utils/errorHanders';

import {
  CONTACT_PERSON_DEFAULT_VALUES,
  CreateContactPersonFormSchema,
  createContactPersonValidationSchema,
} from './form.config';

type CreateContactPersonMenuContentProps = {
  closeMenuHandler: VoidFunction;
  practiceId: string;
  fetchPractice: (patientId: string) => Promise<void>;
};

export const CreateContactPersonMenuContent: FC<CreateContactPersonMenuContentProps> = ({
  closeMenuHandler,
  practiceId,
  fetchPractice,
}) => {
  const { practicePersonTitlesEnum, fetchPracticePersonTitlesEnum, isPracticePersonTitlesEnumLoading } =
    usePracticeEnums();

  useEffect(() => {
    fetchPracticePersonTitlesEnum();
  }, []);

  const {
    register,
    handleSubmit,
    setError,
    control,
    formState: { errors, isValid, isSubmitting },
  } = useForm<CreateContactPersonFormSchema>({
    resolver: yupResolver(createContactPersonValidationSchema) as Resolver<CreateContactPersonFormSchema>,
    mode: 'onTouched',
    defaultValues: CONTACT_PERSON_DEFAULT_VALUES,
  });

  const onFormSubmitHandler = handleSubmit(async formData => {
    try {
      await createPracticeContactPerson(practiceId, {
        ...formData,
        position: formData.position || null,
        middleName: formData.middleName || null,
        contact: {
          phone: formData.contact.phone || null,
          email: formData.contact.email || null,
          website: formData.contact.website || null,
        },
      });

      await fetchPractice(practiceId);

      notice(ToastType.SUCCESS, 'Contact person has been successfully created!');
      closeMenuHandler();
    } catch (error) {
      console.error(error);
      formErrorHandler({
        error,
        customErrorMessage: 'Failed to create contact person, please try again!',
        config: { formError: { setError } },
      });
    }
  });

  return (
    <Box
      component="form"
      onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
        e.stopPropagation();
        onFormSubmitHandler(e);
      }}
      sx={{
        height: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Scrollbar style={{ maxHeight: 380 }}>
        <Stack flex={1} spacing={2}>
          <MDInput
            fullWidth
            label="First name"
            placeholder="Enter first name"
            {...register('firstName')}
            error={!!errors?.firstName?.message}
            helperText={errors?.firstName?.message}
            required
          />

          <MDInput
            fullWidth
            label="Middle name"
            placeholder="Enter middle name"
            {...register('middleName')}
            error={!!errors?.middleName?.message}
            helperText={errors?.middleName?.message}
          />

          <MDInput
            fullWidth
            label="Last name"
            placeholder="Enter last name"
            {...register('lastName')}
            error={!!errors?.lastName?.message}
            helperText={errors?.lastName?.message}
            required
          />

          <MDInput
            {...register('position')}
            placeholder="Enter position"
            fullWidth
            error={!!errors?.position?.message}
            helperText={errors?.position?.message}
            label="Position"
          />

          <RHFAutocompleteField
            control={control}
            name="title"
            label="Title"
            placeholder="Choose title"
            options={practicePersonTitlesEnum}
            getOptionLabel={option => {
              if (typeof option === 'string') return option;
              return option.value;
            }}
            isOptionEqualToValue={(option, value) => option?.value === value?.value}
            loading={isPracticePersonTitlesEnumLoading}
            isRequired
          />

          <Stack spacing={2} width={1}>
            <MDInput
              {...register('contact.email')}
              fullWidth
              error={!!errors?.contact?.email?.message}
              helperText={errors?.contact?.email?.message}
              label="Email"
              placeholder="Enter email"
            />

            <RHFPhoneTextField
              control={control}
              registerErrors={errors}
              registerName={`contact.phone`}
              country="us"
              label="Phone number"
              MUITextFieldProps={{ fullWidth: true, sx: { width: 1 } }}
            />

            <MDInput
              {...register(`contact.website`)}
              fullWidth
              error={!!errors?.contact?.website?.message}
              helperText={errors?.contact?.website?.message}
              label="Website"
              placeholder="Enter website"
            />
          </Stack>
        </Stack>
      </Scrollbar>
      <FormMenuPopoverButton disabled={isSubmitting || !isValid} isLoading={isSubmitting} type="submit" />
      {/* </Box> */}
      {/* <Stack direction="row" width={1} justifyContent="space-between" mt={2} spacing={1.5}>
        <MDButton variant="outlined" size="small" onClick={closeMenuHandler} fullWidth>
          Cancel
        </MDButton>
        <MDButton disabled={isSubmitting || !isValid} size="small" isLoading={isSubmitting} fullWidth type="submit">
          Save
        </MDButton>
      </Stack> */}
    </Box>
  );
};
