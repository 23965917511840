import { FC, memo } from 'react';

import { ArrowForwardIosRounded, MenuOpenRounded, MenuRounded } from '@mui/icons-material';
import { Box, IconButton, Link, Breadcrumbs as MuiBreadcrumbs, Stack, Typography } from '@mui/material';
import { Link as ReactRouterLink } from 'react-router-dom';
import useBreadcrumbs, { Options } from 'use-react-router-breadcrumbs';

import { Icon } from 'components/Icon';
import { BREADCRUMBS_ROUTES, ROUTES } from 'constants/routes';

import { BreadcrumbsProps } from './types';

const breadcrumbsConfig: Options = { excludePaths: [ROUTES.practiceManagement, '/catalog/:id'] };

export const Breadcrumbs: FC<BreadcrumbsProps> = memo(({ isTabletScreen, isOpenMobileNavBar, onOpenSideNav }) => {
  const breadcrumbs = useBreadcrumbs(BREADCRUMBS_ROUTES, breadcrumbsConfig);

  return (
    <Stack
      width={1}
      direction={{ xs: 'row', lg: 'row' }}
      spacing={0.5}
      alignItems="center"
      justifyContent="space-between"
      bgcolor={theme => theme.palette.common.white}
    >
      <Stack direction="row" spacing={1} alignItems="center">
        <Icon sx={{ color: theme => theme.palette.action.disabled }} type="page" />

        <MuiBreadcrumbs
          sx={{
            '& .MuiBreadcrumbs-ol': {
              flexWrap: 'nowrap',
            },
            '& .MuiBreadcrumbs-li': {
              display: 'flex',
              wordSpacing: 'initial',
            },
            '& .MuiBreadcrumbs-separator': { mx: '4px' },
          }}
          separator={<ArrowForwardIosRounded />}
        >
          {breadcrumbs.map(({ match, breadcrumb }, index) =>
            index !== breadcrumbs.length - 1 ? (
              <Link
                key={match.pathname}
                color={theme => theme.palette.action.disabled}
                to={match.pathname === '/' ? ROUTES.practiceManagement : match.pathname}
                component={ReactRouterLink}
                sx={{
                  fontSize: 14,
                  fontWeight: 500,
                  whiteSpace: 'nowrap',
                  width: 1,
                }}
              >
                {breadcrumb}
              </Link>
            ) : (
              <Typography
                key={match.pathname}
                color={theme => theme.palette.dark.main}
                variant="caption"
                sx={{
                  fontSize: 14,
                  fontWeight: 500,
                  width: 1,
                }}
              >
                {breadcrumb}
              </Typography>
            )
          )}
        </MuiBreadcrumbs>
      </Stack>

      <Box display={isTabletScreen ? 'flex' : 'none'}>
        <IconButton size="medium" onClick={onOpenSideNav}>
          {isOpenMobileNavBar ? <MenuOpenRounded /> : <MenuRounded />}
        </IconButton>
      </Box>
    </Stack>
  );
});
