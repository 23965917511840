import { matchPath, useLocation } from 'react-router-dom';

type ReturnType = {
  active: boolean;
  isExternalLink: boolean;
};

export const useActiveLink = (path: string | string[], deep = true): ReturnType => {
  const { pathname } = useLocation();

  const pathArray = Array.isArray(path) ? path : [path];

  const checkMatch = (path: string) => {
    const normalActive = !!matchPath({ path, end: true }, pathname);
    const deepActive = !!matchPath({ path, end: false }, pathname);
    return deep ? deepActive : normalActive;
  };

  const active = pathArray.some(checkMatch);

  const isExternalLink = pathArray.some(path => path.includes('http'));

  return {
    active,
    isExternalLink,
  };
};
