import { apiClient } from 'lib/api';

import { PatientEnum, PatientEnums } from './types';

const BASE_PATIENT_ENUM_API_URL = '/patient/enums';

export const getPatientEnums = async () => await apiClient.get<PatientEnums>(BASE_PATIENT_ENUM_API_URL);

export const getPatientGenderEnums = async () =>
  await apiClient.get<PatientEnum[]>(`${BASE_PATIENT_ENUM_API_URL}/genders`);

export const getPatientBioGenderEnums = async () =>
  await apiClient.get<PatientEnum[]>(`${BASE_PATIENT_ENUM_API_URL}/bio_genders`);

export const getPatientEthnicGroups = async () =>
  await apiClient.get<PatientEnum[]>(`${BASE_PATIENT_ENUM_API_URL}/ethnic_groups`);

export const getPatientPainTolerances = async () =>
  await apiClient.get<PatientEnum[]>(`${BASE_PATIENT_ENUM_API_URL}/pain_tolerances`);

export const getPatientPersonTitles = async () =>
  await apiClient.get<PatientEnum[]>(`${BASE_PATIENT_ENUM_API_URL}/person_titles`);

export const getPatientBloodTypes = async () =>
  await apiClient.get<PatientEnum[]>(`${BASE_PATIENT_ENUM_API_URL}/blood_types`);
