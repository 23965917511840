const stepLabel = {
  styleOverrides: {
    iconContainer: {
      paddingRight: 0,
    },
    label: {
      width: 26,
      height: 26,
    },
  },
};

export default stepLabel;
