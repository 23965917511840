import { getPractice } from 'apiServices';
import { StateCreator } from 'zustand';

import { backendErrorHandler } from 'utils/errorHanders';

import { sliceResetFns } from '../../Store';
import { PracticesInitialState, PracticesSliceType } from './types';

const initialState: PracticesInitialState = {
  practices: {
    page: null,
    practices: [],
    total: null,
    size: null,
    pages: null,
  },
  practice: null,
};

export const PracticesSlice: StateCreator<
  PracticesSliceType,
  [['zustand/devtools', never], ['zustand/immer', never]],
  [],
  PracticesSliceType
> = set => {
  sliceResetFns.add(() => set(initialState));
  return {
    ...initialState,
    setPractices: practices => {
      set(state => {
        state.practices = practices;
      });
    },
    setPractice: practice => {
      set(state => {
        state.practice = practice;
      });
    },
    fetchPractice: async id => {
      try {
        const { data } = await getPractice(id);
        set(state => {
          state.practice = data;
        });
      } catch (error) {
        console.error(error);
        backendErrorHandler({ error, config: { customErrorMessage: 'Failed to get practice, please try again!' } });
      }
    },
  };
};
