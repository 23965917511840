import * as yup from 'yup';

import { Shape } from 'types/RHF-types';

export type CreateAlbumFormSchema = { name: string; description?: string };

export const createAlbumValidationSchema = yup
  .object()
  .shape<Shape<CreateAlbumFormSchema>>({
    name: yup.string().required().label('Name'),
    description: yup.string().optional().label('Description'),
  })
  .required();

export const defaultValues: CreateAlbumFormSchema = {
  name: '',
  description: '',
};
