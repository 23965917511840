import { FC } from 'react';

import { Divider, Stack, Typography } from '@mui/material';

import { Icon } from 'components/Icon';
import MDButton from 'components/MDButton';

import { MenuPopover } from './MenuPopover';
import { MenuPopoverProps } from './types';

type PopoverWrapperProps = Omit<MenuPopoverProps, 'onClose'> & {
  handleClosePopover: () => void;
  title: string;
  titleDescription?: string;
  variant: 'filter' | 'form';
  children: React.ReactNode;
};

export const PopoverWrapper: FC<PopoverWrapperProps> = ({
  title,
  titleDescription,
  variant = 'form',
  sx,
  handleClosePopover,
  children,
  ...other
}) => {
  return (
    <MenuPopover
      sx={{
        width: 1,
        maxWidth: variant === 'filter' ? 290 : 'auto',
        mt: 1,
        px: 2,
        py: variant === 'filter' ? 1 : 0,
        ...sx,
      }}
      {...other}
      onClose={handleClosePopover}
    >
      <Stack py={variant === 'form' ? 1.5 : 0}>
        <Stack direction="row" gap={1} alignItems="center" justifyContent="space-between">
          <Typography variant="h6" sx={{ whiteSpace: 'initial' }}>
            {title}
          </Typography>
          <MDButton color="dark" onClick={handleClosePopover} variant="text" size="small" iconOnly>
            <Icon type="closeIcon" />
          </MDButton>
        </Stack>

        {titleDescription && (
          <Typography
            variant="body2"
            color="text"
            sx={{
              wordBreak: 'break-all',
              whiteSpace: 'initial',
            }}
          >
            {titleDescription}
          </Typography>
        )}
      </Stack>
      <Divider
        sx={{
          mb: 1,
          ...(variant === 'filter' && {
            my: 0.5,
          }),
        }}
      />
      {children}
    </MenuPopover>
  );
};
