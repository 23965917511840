import { FC } from 'react';

import { Chip, ChipProps, alpha } from '@mui/material';

export const TAG_COLORS: Extract<TypeColor, 'primary' | 'info' | 'success' | 'warning'>[] = [
  'primary',
  'info',
  'success',
  'warning',
];

type TagProps = ChipProps & {
  itemIndex?: number;
};

export const Tag: FC<TagProps> = ({ itemIndex, sx, ...rest }) => {
  const color = TAG_COLORS[itemIndex % TAG_COLORS.length];

  return (
    <Chip
      {...rest}
      sx={{
        ...(color && {
          bgcolor: theme => alpha(theme.palette[color].main, 0.1),
          color: theme => theme.palette[color].main,
          fontWeight: 500,
        }),
        ...sx,
      }}
    />
  );
};
