import { useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Skeleton, Stack } from '@mui/material';
import { AxiosResponse } from 'axios';
import { Resolver, useForm } from 'react-hook-form';

import { FormDialogWindowContent } from 'components/BaseDialogWindow';
import { RHFTextField } from 'components/HookForm';
import { ToastType, notice } from 'components/ToastNotification';
import { useSelectedItem } from 'hooks/useSelectedItem';
import { formErrorHandler } from 'utils/errorHanders';

import { CreateTagFormSchema, createTagValidationSchema } from './form.config';

type EditTagMenuContentProps<TResponse extends { name: string; isActive: boolean }> = {
  closeMenuHandler: VoidFunction;
  tagId: string;
  editTagHandler: (name: string, isActive: boolean) => Promise<void>;
  getItemFc: (tagId: string) => Promise<AxiosResponse<TResponse, any>>;
};

export const EditTagMenuContent = <TResponse extends { name: string; isActive: boolean }>({
  closeMenuHandler,
  editTagHandler,
  getItemFc,
  tagId,
}: EditTagMenuContentProps<TResponse>) => {
  const { isSelectedItemLoading, onGetItemHandler, selectedItem } = useSelectedItem({
    initialLoadingState: true,
    getItemById: getItemFc,
  });

  useEffect(() => {
    onGetItemHandler(tagId);
  }, []);

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isValid, isSubmitting, isDirty },
  } = useForm<CreateTagFormSchema>({
    resolver: yupResolver(createTagValidationSchema) as Resolver<CreateTagFormSchema>,
    mode: 'onTouched',
    values: { name: selectedItem?.name || '' },
  });

  const onSubmitFormHandler = handleSubmit(async ({ name }) => {
    try {
      await editTagHandler(name, selectedItem?.isActive);
      notice(ToastType.SUCCESS, 'Tag has been successfully edited!');
    } catch (error) {
      console.error(error);
      formErrorHandler({
        error,
        customErrorMessage: 'Failed to edit tag, please try again!',
        config: { formError: { setError } },
      });
    }
  });

  const isButtonDisabled = !isDirty || isSubmitting || !isValid;

  return (
    <FormDialogWindowContent
      onFormSubmitHandler={onSubmitFormHandler}
      headerProps={{ title: 'Add tag', onClickCancelButtonHandler: closeMenuHandler }}
      actionProps={{
        approveButtonProps: { disabled: isButtonDisabled, isLoading: isSubmitting },
      }}
    >
      {isSelectedItemLoading ? (
        <Stack spacing={0.5}>
          <Skeleton variant="rounded" height={15} width={70} />
          <Skeleton variant="rounded" height={35} />
        </Stack>
      ) : (
        <RHFTextField
          fullWidth
          register={register}
          label="Tag name"
          registerErrors={errors?.name?.message}
          registerName="name"
          required
          placeholder="Enter name"
        />
      )}
    </FormDialogWindowContent>
  );
};
