import { FC } from 'react';

import { Box, BoxProps } from '@mui/material';

type MDInputPhoneRootProps = {
  isDisabled?: boolean;
  isLabel?: boolean;
  children: React.ReactNode;
};

export const MDInputPhoneRoot: FC<MDInputPhoneRootProps> = ({ isDisabled, isLabel, children }) => {
  return (
    <Box
      position="relative"
      sx={{
        width: 1,
        lineHeight: 0.7,
        '.form-control ': {
          width: 1,
          py: '12px',
        },
        '.country-list': {
          marginTop: '0',
          top: 0,
          left: '55px',
        },

        '.country': {
          lineHeight: 1,
        },

        '.country-name': {
          whiteSpace: 'initial',
        },

        '.form-control:focus': {
          boxShadow: 'none',
          borderColor: theme => `${theme.palette.info.main} !important`,
          outline: theme => `1px solid ${theme.palette.info.main}`,
        },

        '.react-tel-input': {
          '& .flag-dropdown': {
            top: isLabel ? 19 : 0,
          },
        },
        '.react-tel-input :disabled': {
          cursor: 'default',
        },
        '.no-entries-message': {
          textAlign: 'center',
        },
        '.search-emoji': {
          display: 'inline !important',
        },
        '.search-box': {
          borderRadius: '8px',
        },
        '.MuiFormControl-root': {
          padding: 0,
          mx: 0,
          mb: 0,
          border: 0,
          position: 'relative',
        },
        '.MuiInputBase-input': {
          pl: 8,
          borderColor: 'none',
        },

        ...(isDisabled && {
          '.flag-dropdown': {
            pointerEvents: 'none',
          },
        }),
        '.Mui-disabled .MuiOutlinedInput-notchedOutline': {
          borderColor: theme => `${theme.palette.grey[400]}`,
        },
        '.selected-flag': {
          borderColor: 'inherit',
          border: 'none',
          outline: 'none',
        },
        '.selected-flag:focus:before': {
          boxShadow: 0,
          borderColor: 'inherit',
          border: 'none',
          outline: 'none',
        },
        '.selected-flag.open:before': {
          boxShadow: theme => theme.shadows[0],
          borderColor: 'inherit',
          border: 'none',
          outline: 'none',
        },
      }}
    >
      {children}
    </Box>
  );
};
