import React, { useCallback, useState } from 'react';

export const useTabs = (defaultTabValue = 0) => {
  const [tabValue, setTabValue] = useState(defaultTabValue);

  const handleSetTabValue = useCallback((_: React.SyntheticEvent<Element, Event>, newValue: number) => {
    setTabValue(newValue);
  }, []);

  return { tabValue, handleSetTabValue, setTabValue };
};
