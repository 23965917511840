import { useState } from 'react';

import {
  Allergy,
  DictionaryCityItem,
  DictionaryItem,
  GetAllergiesItemsParams,
  GetDictionaryItemsParams,
  MedicalCondition,
  getAllergies,
  getAllergyById,
  getCityById,
  getCountries,
  getCountryByISOcode,
  getCountryStatesByISOcode,
  getMedicalConditionById,
  getMedicalConditions,
  getStateByISOcode,
  getStateCities,
  getStates,
} from 'apiServices';

import { ToastType, notice } from 'components/ToastNotification';

export const useDictionary = () => {
  const [states, setStates] = useState<DictionaryItem[]>([]);
  const [isStatesLoading, setIsStatesLoading] = useState(true);

  const fetchStates = async (params: GetAllergiesItemsParams) => {
    try {
      const { data } = await getStates(params);
      setStates(data.items);
      setIsStatesLoading(false);
    } catch (err) {
      console.error(err);
      notice(ToastType.ERROR, 'Failed to get states, please try again!');
    } finally {
      setIsStatesLoading(false);
    }
  };

  const [state, setState] = useState<DictionaryItem | null>(null);
  const [isStateLoading, setIsStateLoading] = useState(true);

  const fetchState = async (ISOcode: string) => {
    try {
      const { data } = await getStateByISOcode(ISOcode);
      setState(data);
      setIsStateLoading(false);
    } catch (err) {
      console.error(err);
      notice(ToastType.ERROR, 'Failed to get state, please try again!');
    } finally {
      setIsStateLoading(false);
    }
  };

  const [medicalConditions, setMedicalConditions] = useState<MedicalCondition[]>([]);
  const [isMedicalConditionsLoading, setIsMedicalConditionsLoading] = useState(true);

  const fetchMedicalConditions = async (params: GetAllergiesItemsParams) => {
    try {
      const { data } = await getMedicalConditions(params);
      setMedicalConditions(data.items);
      setIsMedicalConditionsLoading(false);
    } catch (err) {
      console.error(err);
      notice(ToastType.ERROR, 'Failed to get medical conditions, please try again!');
    } finally {
      setIsMedicalConditionsLoading(false);
    }
  };

  const [medicalCondition, setMedicalCondition] = useState<MedicalCondition | null>(null);
  const [isMedicalConditionLoading, setIsMedicalConditionLoading] = useState(true);

  const fetchMedicalCondition = async (id: string) => {
    try {
      const { data } = await getMedicalConditionById(id);
      setMedicalCondition(data);
      setIsMedicalConditionLoading(false);
    } catch (err) {
      console.error(err);
      notice(ToastType.ERROR, 'Failed to get medical condition, please try again!');
    } finally {
      setIsMedicalConditionLoading(false);
    }
  };

  const [allergies, setAllergies] = useState<Allergy[]>([]);
  const [isAllergiesLoading, setIsAllergiesLoading] = useState(true);

  const fetchAllergies = async (params: GetDictionaryItemsParams) => {
    try {
      const { data } = await getAllergies(params);
      setAllergies(data.items);
      setIsAllergiesLoading(false);
    } catch (err) {
      console.error(err);
      notice(ToastType.ERROR, 'Failed to get allergies, please try again!');
    } finally {
      setIsAllergiesLoading(false);
    }
  };

  const [allergy, setAllergy] = useState<Allergy | null>(null);
  const [isAllergyLoading, setIsAllergyLoading] = useState(true);

  const fetchAllergy = async (id: string) => {
    try {
      const { data } = await getAllergyById(id);
      setAllergy(data);
      setIsAllergyLoading(false);
    } catch (err) {
      console.error(err);
      notice(ToastType.ERROR, 'Failed to get allergy, please try again!');
    } finally {
      setIsAllergyLoading(false);
    }
  };

  const [stateCity, setStateCity] = useState<DictionaryCityItem | null>(null);
  const [isStateCityLoading, setIsStateCityLoading] = useState(true);

  const fetchStateCity = async (cityId: string) => {
    try {
      const { data } = await getCityById(cityId);
      setStateCity(data);
      setIsStateCityLoading(false);
    } catch (err) {
      console.error(err);
      notice(ToastType.ERROR, 'Failed to get city, please try again!');
    } finally {
      setIsStateCityLoading(false);
    }
  };

  const [stateCities, setStateCities] = useState<DictionaryCityItem[]>([]);
  const [isStateCitiesLoading, setIsStateCitiesLoading] = useState(false);

  const fetchStateCities = async (ISOcode: string, params: GetDictionaryItemsParams) => {
    try {
      setIsStateCitiesLoading(true);

      const { data } = await getStateCities(ISOcode, params);

      setStateCities(data.items);
      setIsStateCitiesLoading(false);
    } catch (err) {
      console.error(err);
      notice(ToastType.ERROR, 'Failed to get cities, please try again!');
    } finally {
      setIsStateCitiesLoading(false);
    }
  };

  const [countries, setCountries] = useState<ListOfItems<DictionaryItem> | null>(null);
  const [isCountriesLoading, setIsCountriesLoading] = useState(false);

  const fetchCountries = async (params: GetDictionaryItemsParams) => {
    try {
      setIsCountriesLoading(true);

      const { data } = await getCountries(params);

      setCountries(data);
      setIsCountriesLoading(false);
    } catch (err) {
      console.error(err);
      notice(ToastType.ERROR, 'Failed to get countries, please try again!');
    } finally {
      setIsCountriesLoading(false);
    }
  };

  const [country, setCountry] = useState<DictionaryItem | null>(null);
  const [isCountryLoading, setIsCountryLoading] = useState(false);

  const fetchCountry = async (ISOcode: string) => {
    try {
      setIsCountryLoading(true);
      const { data } = await getCountryByISOcode(ISOcode);
      setCountry(data);
      setIsCountryLoading(false);
    } catch (err) {
      console.error(err);
      notice(ToastType.ERROR, 'Failed to get country please try again!');
    } finally {
      setIsCountryLoading(false);
    }
  };

  const [countryStates, setCountryStates] = useState<DictionaryItem[]>([]);
  const [isCountryStatesLoading, setIsCountryStatesLoading] = useState(false);

  const fetchCountryStates = async (ISOcode: string, params?: GetDictionaryItemsParams) => {
    try {
      setIsCountryStatesLoading(true);
      const { data } = await getCountryStatesByISOcode(ISOcode, params);
      setCountryStates(data.items);

      setIsCountryStatesLoading(false);
    } catch (err) {
      console.error(err);
      notice(ToastType.ERROR, 'Failed to get states please try again!');
    } finally {
      setIsCountryStatesLoading(false);
    }
  };

  return {
    state,
    fetchState,
    isStateLoading,
    states,
    fetchStates,
    isStatesLoading,
    medicalCondition,
    medicalConditions,
    fetchMedicalCondition,
    fetchMedicalConditions,
    isMedicalConditionLoading,
    isMedicalConditionsLoading,
    fetchCountryStates,
    countryStates,
    isCountryStatesLoading,
    fetchCountry,
    country,
    isCountryLoading,
    fetchCountries,
    countries,
    isCountriesLoading,
    fetchAllergies,
    isAllergiesLoading,
    allergies,
    fetchAllergy,
    isAllergyLoading,
    allergy,
    fetchStateCities,
    isStateCitiesLoading,
    stateCities,
    fetchStateCity,
    stateCity,
    isStateCityLoading,
    setCountries,
    setCountryStates,
    setStateCities,
    setCountry,
  };
};
