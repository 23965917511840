import { FC, useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Stack } from '@mui/material';
import { ContactPerson, createPracticeContactPerson, getPracticeContactPerson } from 'apiServices';
import { Resolver, useForm } from 'react-hook-form';

import { RHFAutocompleteField, RHFPhoneTextField } from 'components/HookForm';
import { Loader } from 'components/Loader';
import MDButton from 'components/MDButton';
import { MDInput } from 'components/MDInput';
import { ToastType, notice } from 'components/ToastNotification';
import { usePracticeEnums } from 'hooks/usePracticeEnums';
import { backendErrorHandler, formErrorHandler } from 'utils/errorHanders';

import {
  CreateContactPersonFormSchema,
  createContactPersonValidationSchema,
} from '../CreateContactPersonMenuContent/form.config';

type EditContactPersonMenuContentProps = {
  closeMenuHandler: VoidFunction;
  practiceId: string;
  fetchPractice: (patientId: string) => Promise<void>;
  contactPersonId: string;
};

// TODO use request to edit contact person
export const EditContactPersonMenuContent: FC<EditContactPersonMenuContentProps> = ({
  closeMenuHandler,
  practiceId,
  fetchPractice,
  contactPersonId,
}) => {
  const { practicePersonTitlesEnum, fetchPracticePersonTitlesEnum, isPracticePersonTitlesEnumLoading } =
    usePracticeEnums();

  const [contactPerson, setContactPerson] = useState<ContactPerson | null>(null);

  const [isLoading, setIsLoading] = useState(true);

  const onGetContactPersonHandler = async () => {
    try {
      const { data } = await getPracticeContactPerson(practiceId, contactPersonId);

      setContactPerson(data);
    } catch (error) {
      console.error(error);

      backendErrorHandler({ error, config: { customErrorMessage: 'Failed to get contact person, please try again!' } });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    onGetContactPersonHandler();
    fetchPracticePersonTitlesEnum();
  }, []);

  const {
    register,
    handleSubmit,
    setError,
    control,
    formState: { errors, isValid, isSubmitting, isDirty },
  } = useForm<CreateContactPersonFormSchema>({
    resolver: yupResolver(createContactPersonValidationSchema) as Resolver<CreateContactPersonFormSchema>,
    mode: 'onTouched',
    values: contactPerson,
  });

  const onFormSubmitHandler = handleSubmit(async formData => {
    try {
      await createPracticeContactPerson(practiceId, {
        ...formData,
        position: formData.position || null,
        middleName: formData.middleName || null,
        contact: {
          phone: formData.contact.phone || null,
          email: formData.contact.email || null,
          website: formData.contact.website || null,
        },
      });

      await fetchPractice(practiceId);

      notice(ToastType.SUCCESS, 'Contact person has been successfully edited!');
      closeMenuHandler();
    } catch (error) {
      console.error(error);
      formErrorHandler({
        error,
        customErrorMessage: 'Failed to edit contact person, please try again!',
        config: { formError: { setError } },
      });
    }
  });

  return (
    <Box
      component="form"
      onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
        e.stopPropagation();
        onFormSubmitHandler(e);
      }}
      sx={{
        pb: 1.5,
        height: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Stack flex={1} spacing={2}>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <MDInput
              fullWidth
              label="First name"
              placeholder="Enter first name"
              {...register('firstName')}
              error={!!errors?.firstName?.message}
              helperText={errors?.firstName?.message}
            />

            <MDInput
              fullWidth
              label="Middle name"
              placeholder="Enter middle name"
              {...register('middleName')}
              error={!!errors?.middleName?.message}
              helperText={errors?.middleName?.message}
            />

            <MDInput
              fullWidth
              label="Last name"
              placeholder="Enter last name"
              {...register('lastName')}
              error={!!errors?.lastName?.message}
              helperText={errors?.lastName?.message}
            />

            <MDInput
              {...register('position')}
              placeholder="Enter position"
              fullWidth
              error={!!errors?.position?.message}
              helperText={errors?.position?.message}
              label="Position"
            />

            <RHFAutocompleteField
              control={control}
              name="title"
              label="Title"
              placeholder="Choose title"
              options={practicePersonTitlesEnum}
              getOptionLabel={option => {
                if (typeof option === 'string') return option;
                return option.value;
              }}
              isOptionEqualToValue={(option, value) => option?.value === value?.value}
              loading={isPracticePersonTitlesEnumLoading}
            />

            <Stack spacing={2} width={1}>
              <MDInput
                {...register('contact.email')}
                fullWidth
                error={!!errors?.contact?.email?.message}
                helperText={errors?.contact?.email?.message}
                label="Email"
                placeholder="Enter email"
              />

              <RHFPhoneTextField
                control={control}
                registerErrors={errors}
                registerName={`contact.phone`}
                country="us"
                label="Phone number"
                MUITextFieldProps={{ fullWidth: true, sx: { width: 1 } }}
              />

              <MDInput
                {...register(`contact.website`)}
                fullWidth
                error={!!errors?.contact?.website?.message}
                helperText={errors?.contact?.website?.message}
                label="Website"
                placeholder="Enter website"
              />
            </Stack>
          </>
        )}
      </Stack>

      <Stack direction="row" width={1} justifyContent="space-between" mt={2} spacing={1.5}>
        <MDButton variant="outlined" size="small" onClick={closeMenuHandler} fullWidth>
          Cancel
        </MDButton>
        <MDButton
          disabled={isSubmitting || !isValid || !isDirty}
          size="small"
          isLoading={isSubmitting}
          fullWidth
          type="submit"
        >
          Save
        </MDButton>
      </Stack>
    </Box>
  );
};
