import { useCallback, useEffect, useState } from 'react';

import { FileWithPath } from 'react-dropzone';

export const useFilePicker = () => {
  const [file, setFile] = useState<FileWithPath | null>(null);

  const [previewURL, setPreviewURL] = useState('');

  const onUploadDropzoneFileHandler = useCallback((files: readonly FileWithPath[]) => {
    const file = files?.[0];
    setFile(file);
    setPreviewURL(URL.createObjectURL(file));
  }, []);

  const onClickRemoveFileHandler = useCallback(() => {
    setFile(null);
    setPreviewURL('');
  }, []);

  const onUploadFileHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    setPreviewURL(URL.createObjectURL(file));
    setFile(file);

    event.target.value = '';
  }, []);

  void useEffect(() => {
    return () => {
      if (previewURL) URL.revokeObjectURL(previewURL);
    };
  }, [previewURL]);

  return {
    file,
    previewURL,
    onUploadFileHandler,
    onUploadDropzoneFileHandler,
    onClickRemoveFileHandler,
  };
};
