import { FC, PropsWithChildren } from 'react';

import { Navigate } from 'react-router-dom';

import { ROUTES } from 'constants/routes';
import { useUserProfile } from 'hooks/useUserProfile';

export const PrivateRouter: FC<PropsWithChildren> = ({ children }) => {
  const { cognitoUser } = useUserProfile();

  return cognitoUser ? <>{children}</> : <Navigate to={ROUTES.signIn} replace />;
};
