import { StateCreator } from 'zustand';

import { sliceResetFns } from '../../Store';
import { CatalogServiceProfileInitialState, CatalogServiceProfileSliceType } from './types';

const initialState: CatalogServiceProfileInitialState = {
  GLService: null,
  PLService: null,
};

export const CatalogServiceProfile: StateCreator<
  CatalogServiceProfileSliceType,
  [['zustand/devtools', never], ['zustand/immer', never]],
  [],
  CatalogServiceProfileSliceType
> = set => {
  sliceResetFns.add(() => set(initialState));
  return {
    ...initialState,
    setGLService: data => {
      set(state => {
        state.GLService = data;
      });
    },
    setPLService: data => {
      set(state => {
        state.PLService = data;
      });
    },
  };
};
