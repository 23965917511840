import { LIBRARY_TABS } from 'types/enums';

export const TABS: LIBRARY_TABS[] = [LIBRARY_TABS.GLOBAL_LIBRARY, LIBRARY_TABS.PRACTICE_LIBRARY];

export const PRODUCTS_SERVICES_TABLE_HEADERS: TableHeaderConfig<any>[] = [
  { id: 'itemName', label: 'Item name' },
  { id: 'description', label: 'Description' },
  { id: 'tags', label: 'Tags' },
  { id: 'linkedContents', label: 'Linked contents' },
  { id: 'actions' },
];
