import { PracticeListItem } from 'apiServices';

export const PRACTICES_SERVICES_TABLE_HEAD: TableHeaderConfig<PracticeListItem>[] = [
  { id: 'practiceName', label: 'Practice name' },
  { id: 'primaryShortName', label: 'Practice short name' },
  { id: 'contactPersonsCount', label: 'Contact persons count' },
  { id: 'servicesCount', label: 'Services count' },
  { id: 'officesCount', label: 'Offices count' },
  { id: 'createdAt', label: 'Creation date' },
  { id: 'updatedAt', label: 'Last update' },
  { id: 'actions' },
];
