import { differenceInDays, differenceInMonths, differenceInYears, format } from 'date-fns';

import { YYYY_MM_DD_DATE_FORMAT } from 'constants/dateFormats';

type InputValue = Date | string | number | null;

export function dateToCustomFormat(date: InputValue, newFormat?: string) {
  const fm = newFormat || YYYY_MM_DD_DATE_FORMAT;

  return date ? format(new Date(date), fm) : '';
}

export function calculateAge(dateOfBirth: InputValue): number | string {
  const currentDate = new Date();

  const age = differenceInYears(currentDate, dateOfBirth);

  if (age) return `${age} y.o.`;

  const months = differenceInMonths(currentDate, dateOfBirth);

  if (months) return months === 1 ? '1 mo.' : `${months} mos.`;

  const days = differenceInDays(currentDate, dateOfBirth);

  return `${days} d`;
}
