import { FC } from 'react';

import { CloseOutlined } from '@mui/icons-material';
import { DialogTitle, Divider, IconButton, Stack, Typography } from '@mui/material';

import { DialogHeaderProps } from '../types';

export const DialogHeader: FC<DialogHeaderProps> = ({ isHideDivider, sx, onClickCancelButtonHandler, title, icon }) => {
  return (
    <Stack sx={{ px: { xs: 2, sm: 2.5 }, ...sx }}>
      <DialogTitle sx={{ py: 1, px: 0 }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Stack direction="row" spacing={0.5} alignItems="center">
            {icon && icon}
            <Typography fontWeight={500} fontSize={{ xs: 16, sm: 20 }}>
              {title}
            </Typography>
          </Stack>

          {onClickCancelButtonHandler && (
            <IconButton onClick={onClickCancelButtonHandler}>
              <CloseOutlined fontSize="medium" />
            </IconButton>
          )}
        </Stack>
      </DialogTitle>
      {!isHideDivider && <Divider />}
    </Stack>
  );
};
