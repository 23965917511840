import { FC, useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Card, CardContent, CardHeader, Divider, FormControlLabel, Grid, Stack, Switch } from '@mui/material';
import { getPLService } from 'apiServices';
import { updatePLService } from 'apiServices';
import { Controller, Resolver, useForm } from 'react-hook-form';
import { useAppStore } from 'store';
import { shallow } from 'zustand/shallow';

import { FormActionsCard } from 'components/Form';
import { RHFTextField } from 'components/HookForm';
import { ToastType, notice } from 'components/ToastNotification';
import { ROUTES } from 'constants/routes';
import { useRouter } from 'hooks';
import { EditCatalogPLItemFormSchema, ProfileSkeleton, editCatalogPLItemValidationSchema } from 'pages/Catalog';
import { backendErrorHandler, formErrorHandler } from 'utils/errorHanders';

export const PracticeLibraryServiceProfile: FC = () => {
  const { params } = useRouter();

  const serviceId = params?.id;

  const { PLService, setPLService } = useAppStore(
    store => ({
      PLService: store.PLService,
      setPLService: store.setPLService,
    }),
    shallow
  );

  const {
    control,
    register,
    handleSubmit,
    setError,
    formState: { isDirty, isValid, isSubmitting, errors },
  } = useForm<EditCatalogPLItemFormSchema>({
    resolver: yupResolver(editCatalogPLItemValidationSchema) as Resolver<EditCatalogPLItemFormSchema>,
    mode: 'onTouched',
    values: { title: PLService?.title, description: PLService?.description || '', isPreferred: PLService?.isPreferred },
  });

  const onFormSubmitHandler = handleSubmit(async ({ title, description, isPreferred }) => {
    try {
      const { data } = await updatePLService(serviceId, { title, isPreferred, ...(description && { description }) });

      notice(ToastType.SUCCESS, 'Service has been successfully edited!');

      setPLService(data);
    } catch (error) {
      formErrorHandler({ error, config: { formError: { setError } } });
    }
  });

  const [isLoading, setIsLoading] = useState(true);

  const onGetPLServiceHandler = async () => {
    !isLoading && setIsLoading(true);

    try {
      const { data } = await getPLService(serviceId);

      setPLService(data);
    } catch (error) {
      backendErrorHandler({ error, config: { customErrorMessage: 'Failed to get service, please try again!' } });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    onGetPLServiceHandler();

    return () => {
      setPLService(null);
    };
  }, []);

  const isSubmitButtonDisabled = !isValid || isSubmitting || !isDirty;

  return (
    <Box component="section">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <CardHeader title="Edit service" sx={{ pb: 0 }} />
            <Divider sx={{ my: 2 }} />

            <CardContent sx={{ pt: 0 }}>
              {isLoading ? (
                <ProfileSkeleton isSwitcher />
              ) : (
                <Stack component="form" onSubmit={onFormSubmitHandler} spacing={2}>
                  <Box>
                    <Controller
                      name="isPreferred"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <FormControlLabel
                          control={<Switch checked={value} onChange={onChange} />}
                          label="Is preffered"
                          labelPlacement="start"
                        />
                      )}
                    />
                  </Box>

                  <RHFTextField
                    register={register}
                    registerErrors={errors?.description?.message}
                    registerName="title"
                    label="Name"
                    required
                    placeholder="Enter name"
                  />

                  <RHFTextField
                    register={register}
                    registerErrors={errors?.description?.message}
                    registerName="description"
                    label="Description"
                    placeholder="Enter description"
                    multiline
                    minRows={5}
                    maxRows={8}
                  />
                </Stack>
              )}
            </CardContent>
          </Card>
        </Grid>

        {!isLoading && (
          <Grid item xs={12}>
            <FormActionsCard
              isDisabled={isSubmitButtonDisabled}
              isLoading={isSubmitting}
              isFormDirty={isDirty}
              backRoute={ROUTES.catalog}
              onFormSubmitHandler={onFormSubmitHandler}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
