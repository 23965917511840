import { StateCreator } from 'zustand';

import { sliceResetFns } from '../../Store';
import { AppSettingsInitialState, AppSettingsSliceType } from './types';

const initialState: AppSettingsInitialState = {
  themeLayout: 'default',
};

export const AppSettingsSlice: StateCreator<
  AppSettingsSliceType,
  [['zustand/devtools', never], ['zustand/immer', never]],
  [],
  AppSettingsSliceType
> = set => {
  sliceResetFns.add(() => set(initialState));
  return {
    ...initialState,
    onChangeAppSettingsValue: (key, value) => {
      set(state => {
        state[key] = value;
      });
    },
  };
};
