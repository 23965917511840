import { FC } from 'react';

import { ArrowBackIosNewRounded } from '@mui/icons-material';
import { Box, Button, CardContent, Divider, Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

import fallback from 'assets/icons/before_after_avatar_fallback_icon.svg';
import { ActiveStatusChip } from 'components/ActiveStatusChip';
import { MDAvatar } from 'components/MDAvatar';
import { ROUTES } from 'constants/routes';

type ProfileHeaderProps = {
  openActivateDialog: () => void;
  openDeactivateDialog: () => void;
  isStatusLoading: boolean;
  isActive: boolean;
  title: string;
  backRoute: ROUTES;
  subtitle?: string;
};

export const ProfileHeader: FC<ProfileHeaderProps> = ({
  title,
  subtitle,
  isActive,
  openActivateDialog,
  openDeactivateDialog,
  isStatusLoading,
  backRoute,
}) => {
  return (
    <Box>
      <CardContent sx={{ p: 2 }}>
        <Stack
          mb={1}
          direction={{ xs: 'column', sm: 'row' }}
          justifyContent="space-between"
          gap={1}
          alignItems="center"
        >
          <Button
            variant="text"
            component={Link}
            to={backRoute}
            sx={{ fontWeight: 500, color: theme => theme.palette.common.black, fontSize: 14, px: 0.5 }}
            startIcon={<ArrowBackIosNewRounded />}
          >
            Back
          </Button>
          <ActiveStatusChip
            isLoading={isStatusLoading}
            isActive={isActive}
            onDeactivateHandler={openDeactivateDialog}
            onActivateHandler={openActivateDialog}
            sx={{ alignSelf: { xs: 'center', sm: 'auto' } }}
          />
        </Stack>
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          justifyContent="space-between"
          alignItems="flex-start"
          spacing={1}
        >
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            width={1}
            justifyContent={{ xs: 'center', sm: 'flex-start' }}
            alignItems="center"
            spacing={2}
          >
            <MDAvatar avatarSize="3xl" src={fallback} />
            <Stack
              justifyContent={{ xs: 'center', sm: 'flex-start' }}
              textAlign={{ xs: 'center', sm: 'left' }}
              spacing={{ xs: 1, sm: 0 }}
            >
              <Typography fontWeight={500} fontSize={22}>
                {title}
              </Typography>

              <Typography variant="body2" fontSize={14}>
                {subtitle}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </CardContent>
      <Divider />
    </Box>
  );
};
