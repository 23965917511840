import { FC } from 'react';

import { Box, Card, Divider, Grid, Stack, Table, TableBody, TableContainer, Typography } from '@mui/material';
import { useInView } from 'react-intersection-observer';

import { SideNavFormMenu } from 'components/BaseSideNavMenu';
import { FormActions } from 'components/Form';
import { Icon } from 'components/Icon';
import { MDAutocomplete } from 'components/MDAutocomplete';
import MDButton from 'components/MDButton';
import { MDInput } from 'components/MDInput';
import { Scrollbar } from 'components/ScrollBar';
import { ROUTES } from 'constants/routes';
import { useSideNavScrollTracker } from 'hooks';
import { useFilePicker } from 'hooks/useFilePicker';
import useResponsive from 'hooks/useResponsive';
import { ProfileHeader } from 'pages/BeforeAfter';

import { UploadImageCard } from './components';
import { TagsTableRow } from './components/TagsTableRow';

// TODO create separete file and ENUMS for IDs
export const AddBeforeAfter: FC = () => {
  const {
    onClickRemoveFileHandler: onClickRemoveBeforeFileHandler,
    onUploadDropzoneFileHandler: onUploadBeforeFileHandler,
    onUploadFileHandler: onUploadAnotherBeforeFileHandler,
    previewURL: beforePreviewURL,
  } = useFilePicker();

  const { onClickRemoveFileHandler, onUploadDropzoneFileHandler, onUploadFileHandler, previewURL } = useFilePicker();

  const isSmallSreen = useResponsive('down', 'sm');

  const { isScrolling, activeSectionId, executeScroll, onChangeInViewHandler } = useSideNavScrollTracker({
    defaultActiveSectionId: 'photo',
  });

  const [photoRef, isPhotoInView, photoEntry] = useInView({
    threshold: 0.9,
    initialInView: true,
    onChange: onChangeInViewHandler,
  });

  const [infoRef, isInfoInView, infoEntry] = useInView({
    threshold: 0.9,
    skip: isScrolling,
    onChange: onChangeInViewHandler,
  });

  const [tagsRef, isTagsInView, tagsEntry] = useInView({
    threshold: 0.9,
    skip: isScrolling,
    onChange: onChangeInViewHandler,
  });

  return (
    <Box component="section">
      <Card>
        <Grid container>
          <Grid item xs={12}>
            <ProfileHeader
              openActivateDialog={() => {}}
              openDeactivateDialog={() => {}}
              isStatusLoading={false}
              isActive={false}
              title="Facial Rejuvenation"
              subtitle="Dermal filler for fuller lips"
              backRoute={ROUTES.beforeAfter}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <SideNavFormMenu
              onClick={executeScroll}
              activeSectionId={activeSectionId}
              sidenavItems={[
                {
                  label: 'Photo',
                  entry: photoEntry,
                  id: 'photo',
                },

                {
                  label: 'Info',
                  entry: infoEntry,
                  id: 'info',
                },
                {
                  label: 'Tags',
                  entry: tagsEntry,
                  id: 'tags',
                },
              ]}
            />
            {isSmallSreen && <Divider orientation="horizontal" flexItem />}
          </Grid>

          <Grid item xs={12} sm={10}>
            <Stack direction="row" width={1}>
              {!isSmallSreen && <Divider orientation="vertical" flexItem />}
              <Stack width={1}>
                <Stack width={1} ref={photoRef} id="photo">
                  <Stack
                    p={{ xs: 2, sm: 2.5, md: 3, lg: 3.5 }}
                    width={1}
                    direction={{ xs: 'column', sm: 'row' }}
                    gap={1.5}
                  >
                    <UploadImageCard
                      previewURL={beforePreviewURL}
                      onClickRemoveImageHandler={onClickRemoveBeforeFileHandler}
                      onUploadAnotherFileHandler={onUploadAnotherBeforeFileHandler}
                      onUploadFileHandler={onUploadBeforeFileHandler}
                      type="before"
                    />

                    <UploadImageCard
                      previewURL={previewURL}
                      onClickRemoveImageHandler={onClickRemoveFileHandler}
                      onUploadAnotherFileHandler={onUploadFileHandler}
                      onUploadFileHandler={onUploadDropzoneFileHandler}
                      type="after"
                      isDisabled={!previewURL && !beforePreviewURL}
                    />
                  </Stack>
                  <Divider flexItem />
                </Stack>

                <Stack ref={infoRef} id="info">
                  <Stack p={{ xs: 2, sm: 2.5, md: 3, lg: 3.5 }} spacing={2.5} maxWidth={700}>
                    <MDInput label="Name" placeholder="Enter name" />

                    <MDInput multiline minRows={5} maxRows={8} label="Description" placeholder="Enter description" />

                    <MDAutocomplete
                      multiple
                      options={[{ id: '1', name: 'Product 1' }]}
                      inputProps={{ label: 'Products' }}
                      getOptionLabel={option => {
                        if (typeof option !== 'string') return option.name;
                      }}
                    />

                    <MDAutocomplete
                      multiple
                      options={[{ id: '1', name: 'Service 1' }]}
                      inputProps={{ label: 'Services' }}
                      getOptionLabel={option => {
                        if (typeof option !== 'string') return option.name;
                      }}
                    />
                  </Stack>

                  <Divider />
                </Stack>

                <Stack ref={tagsRef} id="tags" p={{ xs: 2, sm: 2.5, md: 3, lg: 3.5 }} spacing={2}>
                  <Stack direction={{ xs: 'column', sm: 'row' }} alignItems="center" justifyContent="space-between">
                    <Typography>Tags (5/44)</Typography>

                    <Stack direction={{ xs: 'column', sm: 'row' }} gap={1}>
                      <MDButton
                        variant="outlined"
                        color="primary"
                        size="small"
                        sx={{
                          minWidth: 'unset',
                          width: 40,
                        }}
                      >
                        <Icon
                          type="filter"
                          sx={{
                            width: 20,
                            height: 20,
                            alignItems: 'center',
                          }}
                        />
                      </MDButton>
                      <MDButton startIcon={<Icon type="starsIcon" />}>AI assistant</MDButton>
                    </Stack>
                  </Stack>

                  <Card sx={{ overflow: 'hidden' }}>
                    <TableContainer>
                      <Scrollbar>
                        <Table size="medium" sx={{ minWidth: 600, th: { width: 200 } }}>
                          <TableBody>
                            <TagsTableRow />
                          </TableBody>
                        </Table>
                      </Scrollbar>
                    </TableContainer>
                  </Card>

                  <Card sx={{ overflow: 'hidden' }}>
                    <TableContainer>
                      <Scrollbar>
                        <Table size="medium" sx={{ minWidth: 600, th: { width: 200 } }}>
                          <TableBody>
                            <TagsTableRow />
                          </TableBody>
                        </Table>
                      </Scrollbar>
                    </TableContainer>
                  </Card>
                </Stack>

                <FormActions
                  isDisabled={false}
                  isLoading={false}
                  onFormSubmitHandler={async () => {}}
                  isDirtyForm
                  backRoute=""
                />
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
};
