import { FC } from 'react';

import { CheckRounded, ReplayRounded } from '@mui/icons-material';
import { Stack } from '@mui/material';

import MDButton from 'components/MDButton';

type FilterMenuPopoverButtonsProps = {
  isApplyButtonDisabled: boolean;
  isClearButtonDisabled: boolean;
  onClickClearButtonHandler: () => void;
  onClickApplyButtonHandler: () => void;
  isLoading?: boolean;
};

export const FilterMenuPopoverButtons: FC<FilterMenuPopoverButtonsProps> = ({
  isApplyButtonDisabled,
  isClearButtonDisabled,
  isLoading,
  onClickApplyButtonHandler,
  onClickClearButtonHandler,
}) => {
  return (
    <Stack
      spacing={1.5}
      py={1}
      direction="row"
      position="absolute"
      sx={{ bottom: 0, width: 1, zIndex: 10, bgcolor: theme => theme.palette.common.white }}
    >
      <MDButton
        onClick={onClickClearButtonHandler}
        disabled={isClearButtonDisabled}
        startIcon={<ReplayRounded fontSize="medium" />}
        variant="outlined"
        size="small"
        fullWidth
      >
        Clear
      </MDButton>
      <MDButton
        onClick={onClickApplyButtonHandler}
        disabled={isApplyButtonDisabled}
        startIcon={<CheckRounded fontSize="medium" />}
        isLoading={isLoading}
        size="small"
        fullWidth
      >
        Apply
      </MDButton>
    </Stack>
  );
};
