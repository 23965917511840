import { BASE_DICTIONARY_API_URL } from 'apiServices/api.routes';
import { apiClient } from 'lib/api';

import { Allergy, GetDictionaryItemsParams } from './types';

export const getAllergies = async (params: GetDictionaryItemsParams) =>
  await apiClient.get<ListOfItems<Allergy>>(`${BASE_DICTIONARY_API_URL}/allergies`, { params });

export const getAllergyById = async (id: string) =>
  await apiClient.get<Allergy>(`${BASE_DICTIONARY_API_URL}/allergies/${id}`);
