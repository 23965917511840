import { UserRoles } from 'types/enums';

export const UserRoleLabels: Record<UserRoles, string> = {
  [UserRoles.ROLE_ADMIN]: 'A360 Admin',
  [UserRoles.ROLE_PRACTICE]: 'Practice User',
  [UserRoles.ROLE_AI_TESTER]: 'AI Tester',
  [UserRoles.ROLE_SERVICE]: 'Service User',
  [UserRoles.ROLE_PRACTICE_ADMIN]: 'Practice Admin',
  [UserRoles.ROLE_PRACTICE_OFFICE_ADMIN]: 'Practice Office Admin',
  [UserRoles.ROLE_PRACTICE_DOCTOR]: 'Practice Doctor',
  [UserRoles.ROLE_PRACTICE_SECRETARY]: 'Practice Secretary',
};
