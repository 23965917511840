import React, { FC, useState } from 'react';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import CloseIcon from '@mui/icons-material/Close';
import CopyIcon from '@mui/icons-material/FileCopyOutlined';
import { Box, Divider, IconButton } from '@mui/material';

import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import { MDInput } from 'components/MDInput';
import MDTypography from 'components/MDTypography';

type FieldModel = {
  [key: string]: {
    model: string;
    fields: string[];
  };
};

type TextFieldWithDropdownProps = {
  text: string;
  setText: (value: string) => void;
  allFields: FieldModel;
  name: string;
};

const TextFieldWithDropdown: FC<TextFieldWithDropdownProps> = ({ text, setText, allFields, name = 'Text' }) => {
  const [expanded, setExpanded] = useState<string[]>([]);
  const [showFieldsPanel, setShowFieldsPanel] = useState(false);
  const [isFieldsDropdownExpanded, setIsFieldsDropdownExpanded] = useState(true);

  const copyToClipboard = (model: string, field: string) => {
    const textToCopy = `{${model}:${field}}`;
    navigator.clipboard.writeText(textToCopy).catch(err => {
      console.error('Failed to copy text:', err);
    });
  };

  const toggleCategory = (category: string) => {
    setExpanded(prev => (prev.includes(category) ? prev.filter(cat => cat !== category) : [...prev, category]));
  };

  const toggleFieldsDropdown = () => {
    setIsFieldsDropdownExpanded(prev => !prev);
  };

  const collapseAll = () => {
    setShowFieldsPanel(false);
  };

  return (
    <MDBox component="section" sx={{ position: 'relative' }}>
      <MDTypography variant="h6" sx={{ marginBottom: 1 }}>
        {name}
      </MDTypography>
      <MDInput
        fullWidth
        multiline
        rows={10}
        placeholder="Enter your text here"
        value={text}
        onFocus={() => setShowFieldsPanel(true)}
        onChange={e => setText(e.target.value)}
      />

      {showFieldsPanel && (
        <MDBox
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            width: isFieldsDropdownExpanded ? '220px' : '100px',
            maxHeight: '600px',
            overflowY: 'auto',
            border: '1px solid #ccc',
            borderRadius: '11px',
            backgroundColor: '#fff',
            zIndex: 10,
          }}
        >
          <MDBox display="flex" justifyContent="space-between" alignItems="center" padding={1}>
            <MDTypography variant="h6">Fields</MDTypography>
            <MDBox display="flex" alignItems="center">
              <IconButton size="small" onClick={toggleFieldsDropdown}>
                {isFieldsDropdownExpanded ? (
                  <ArrowDropDownIcon fontSize="small" sx={{ color: 'gray' }} />
                ) : (
                  <ArrowRightIcon fontSize="small" sx={{ color: 'gray' }} />
                )}
              </IconButton>
              <IconButton size="small" onClick={collapseAll}>
                <CloseIcon fontSize="small" sx={{ color: 'gray' }} />
              </IconButton>
            </MDBox>
          </MDBox>
          <Divider />

          {isFieldsDropdownExpanded && (
            <MDBox>
              {Object.entries(allFields).map(([category, { model, fields }]) => (
                <MDBox key={category} padding={1}>
                  <MDTypography
                    variant="subtitle1"
                    onClick={() => toggleCategory(category)}
                    sx={{
                      cursor: 'pointer',
                      display: 'flex',
                      alignItems: 'center',
                      color: 'gray',
                      fontWeight: 400,
                    }}
                  >
                    {expanded.includes(category) ? (
                      <ArrowDropDownIcon fontSize="small" />
                    ) : (
                      <ArrowRightIcon fontSize="small" />
                    )}
                    {category}
                  </MDTypography>
                  {expanded.includes(category) &&
                    fields.map(field => (
                      <MDBox
                        key={field}
                        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                      >
                        <MDButton
                          variant="text"
                          onClick={() => copyToClipboard(model, field)}
                          sx={{
                            textTransform: 'none',
                            width: '100%',
                            justifyContent: 'flex-start',
                            color: 'gray',
                            fontWeight: 400,
                          }}
                        >
                          {field}
                        </MDButton>
                        <IconButton size="small" onClick={() => copyToClipboard(model, field)}>
                          <CopyIcon fontSize="small" sx={{ color: 'gray' }} />
                        </IconButton>
                      </MDBox>
                    ))}
                </MDBox>
              ))}
            </MDBox>
          )}
        </MDBox>
      )}
    </MDBox>
  );
};

export default TextFieldWithDropdown;
