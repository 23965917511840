/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// Material Dashboard 2 PRO React TS Base Styles
import { alpha } from '@mui/material';

import colors from 'assets/theme/base/colors';
import typography from 'assets/theme/base/typography';

const { grey } = colors;
const { size } = typography;

const breadcrumbs = {
  styleOverrides: {
    separator: {
      fontSize: size.sm,
      color: alpha(grey[600], 0.4),
    },
  },
};

export default breadcrumbs;
