import pxToRem from 'assets/theme/functions/pxToRem';

const cardContent = {
  styleOverrides: {
    root: {
      marginTop: 0,
      marginBottom: 0,
      padding: pxToRem(16),
      '&:last-child': {
        paddingBottom: pxToRem(16),
      },
    },
  },
};

export default cardContent;
