import { useCallback, useEffect, useState } from 'react';

export const useCountdown = (seconds: number) => {
  const [countdown, setCountdown] = useState(seconds);
  const [isFinished, setIsFinished] = useState(false);
  const [isRunning, setIsRunning] = useState(false);

  const startCountdown = useCallback(() => {
    setCountdown(seconds);
    setIsFinished(false);
    setIsRunning(true);
  }, [seconds]);

  const stopCountdown = useCallback(() => {
    setCountdown(seconds);
    setIsFinished(false);
    setIsRunning(false);
  }, [seconds]);

  const resetCountdown = useCallback(() => {
    setCountdown(seconds);
    setIsFinished(false);
    setIsRunning(true);
  }, []);

  useEffect(() => {
    let interval: number | NodeJS.Timer | null = null;

    if (isRunning) {
      interval = setInterval(() => {
        setCountdown(prevCountdown => {
          if (prevCountdown > 0) {
            return prevCountdown - 1;
          }
          return 0;
        });
      }, 1000);
    }

    if (countdown === 0 && isRunning) {
      setIsFinished(true);
      setIsRunning(false);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [countdown, isRunning]);

  return { resetCountdown, stopCountdown, countdown, isFinished, isRunning, startCountdown };
};
