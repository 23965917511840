import { FC } from 'react';

import { Box, Card, CardContent, Stack } from '@mui/material';
import { Practice } from 'apiServices';

import { MDChip } from 'components/MDChip';
import MDTypography from 'components/MDTypography';

type PracticeHeaderProps = Pick<Practice, 'name' | 'nameShort' | 'isActive'>;

export const PracticeHeader: FC<PracticeHeaderProps> = ({ name, nameShort, isActive }) => {
  return (
    <Card>
      <CardContent sx={{ p: 2 }}>
        <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="space-between" alignItems="center" spacing={1}>
          <Stack
            justifyContent={{ xs: 'center', sm: 'flex-start' }}
            textAlign={{ xs: 'center', sm: 'left' }}
            spacing={{ xs: 1, sm: 0 }}
          >
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1}>
              <Stack>
                <MDTypography sx={{ fontWeight: 700 }}>{name}</MDTypography>
                {nameShort && (
                  <MDTypography variant="body2" sx={{ fontWeight: 500 }}>
                    {nameShort}
                  </MDTypography>
                )}
              </Stack>

              <Box>
                <MDChip
                  size="small"
                  variant="soft"
                  color={isActive ? 'primary' : 'default'}
                  label={isActive ? 'Active' : 'Deactivated'}
                />
              </Box>
            </Stack>

            {/* TODO */}
            {/* <MDTypography
              variant="subtitle2"
              sx={{ color: theme => theme.palette.grey[600], fontWeight: 400, maxWidth: { xs: 400, md: 470 } }}
            >
              Wilton Manors, Florida • +1 (754) 223-4657 • info@profileaw.com
            </MDTypography> */}
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};
