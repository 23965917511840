import { FC } from 'react';

import { MoreHorizOutlined } from '@mui/icons-material';
import { Button, Stack } from '@mui/material';

import { ActiveStatusChip } from 'components/ActiveStatusChip';
import { Icon } from 'components/Icon';
import MDButton from 'components/MDButton';
import { useRouter } from 'hooks';

import { ActionsHeaderProps } from './types';

export const ActionsHeader: FC<ActionsHeaderProps> = ({ activeStatusProps, onClickThreeDotsButtonHandler }) => {
  const { back } = useRouter();

  return (
    <Stack mb={1.5} direction={{ xs: 'column', sm: 'row' }} justifyContent="space-between" gap={1} alignItems="center">
      <Button
        onClick={back}
        variant="text"
        sx={{ fontWeight: 500, color: theme => theme.palette.common.black, fontSize: 14, px: 0.5 }}
        startIcon={<Icon type="arrowLeftIcon" />}
      >
        Back
      </Button>

      <Stack gap={1} direction={{ xs: 'column', sm: 'row' }} alignItems="center">
        {activeStatusProps && (
          <ActiveStatusChip {...activeStatusProps} sx={{ alignSelf: { xs: 'center', sm: 'auto' } }} />
        )}

        {onClickThreeDotsButtonHandler && (
          <MDButton
            sx={{ minWidth: 'unset', maxWidth: 30, maxHeight: 32 }}
            size="small"
            variant="outlined"
            color="dark"
            onClick={onClickThreeDotsButtonHandler}
          >
            <MoreHorizOutlined fontSize="medium" />
          </MDButton>
        )}
      </Stack>
    </Stack>
  );
};
