import * as yup from 'yup';

import { Shape } from 'types/RHF-types';
import { LIBRARY_TABS } from 'types/enums';

export const TABS: LIBRARY_TABS[] = [LIBRARY_TABS.GLOBAL_LIBRARY, LIBRARY_TABS.PRACTICE_LIBRARY];

export const PRODUCTS_SERVICES_TABLE_HEADERS: TableHeaderConfig[] = [
  { id: 'itemName', label: 'Item name' },
  { id: 'description', label: 'Description' },
  // TODO
  // { id: 'tags', label: 'Tags' },
  // { id: 'linkedContents', label: 'Linked contents' },
  { id: 'actions' },
];

export type CreateCatalogGLItemFormSchema = {
  title: string;
  description?: string;
};

export type CreateCatalogPLItemFormSchema = CreateCatalogGLItemFormSchema & {
  practiceId: string;
};

export type EditCatalogPLItemFormSchema = CreateCatalogGLItemFormSchema & {
  isPreferred?: boolean;
};

export const createCatalogGLItemValidationSchema = yup
  .object()
  .shape<Shape<CreateCatalogGLItemFormSchema>>({
    title: yup.string().required().label('Name'),
    description: yup.string().optional().label('Description'),
  })
  .required();

export const createCatalogPLItemValidationSchema = createCatalogGLItemValidationSchema.concat(
  yup
    .object()
    .shape({
      practiceId: yup.string().required().label('Practice'),
    })
    .required()
);

export const editCatalogPLItemValidationSchema = createCatalogGLItemValidationSchema.concat(
  yup.object().shape({
    isPreferred: yup.boolean().optional().label('Preferred'),
  })
);

export const createCatalogGLItemDefaultValues: CreateCatalogGLItemFormSchema = {
  title: '',
  description: '',
};

export const createCatalogPLItemDefaultValues: CreateCatalogPLItemFormSchema = {
  ...createCatalogGLItemDefaultValues,
  practiceId: '',
};
