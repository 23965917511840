import { FC } from 'react';

import { Dialog, DialogContent, Stack } from '@mui/material';

import { DialogActions, DialogHeader } from './components';
import { DialogWindowProps } from './types';

export const BaseDialogWindow: FC<DialogWindowProps> = ({
  description,
  title,
  open,
  approveButtonTitle,
  cancelButtonTitle = 'Cancel',
  onClickApproveButtonHandler,
  onClickCancelButtonHandler,
  isDivider,
  isApproveButtonDisabled,
  isApproveButtonLoading,
  approveButtonProps,
  cancelButtonProps,
  ...other
}) => {
  return (
    <Dialog open={open} onClose={onClickCancelButtonHandler} {...other}>
      <Stack px={3}>
        <DialogHeader sx={{ px: 0 }} onClickCancelButtonHandler={onClickCancelButtonHandler} title={title} />

        <DialogContent sx={{ px: 0, textAlign: { xs: 'center', sm: 'left' } }}>{description}</DialogContent>

        <DialogActions
          isDivider={isDivider}
          approveButtonProps={{
            isLoading: isApproveButtonLoading,
            disabled: isApproveButtonDisabled,
            children: approveButtonTitle,
            onClick: onClickApproveButtonHandler,
            ...approveButtonProps,
          }}
          cancelButtonProps={{
            children: cancelButtonTitle,
            onClick: onClickCancelButtonHandler,
            ...cancelButtonProps,
          }}
          sx={{ px: 0 }}
        />
      </Stack>
    </Dialog>
  );
};
