import colors from 'assets/theme/base/colors';
import typography from 'assets/theme/base/typography';

const { text } = colors;
const { size } = typography;

const inputLabel = {
  styleOverrides: {
    root: {
      fontSize: size.sm,
      color: text.main,
      lineHeight: 1,
      top: -4,
      '&.MuiInputLabel-shrink': {
        lineHeight: 1.5,
        fontSize: size.md,
        top: 0,

        '~ .MuiInputBase-root .MuiOutlinedInput-notchedOutline legend': {
          fontSize: '0.85em',
        },
      },
    },

    sizeSmall: {
      fontSize: size.xs,
      lineHeight: 1.625,

      '&.MuiInputLabel-shrink': {
        lineHeight: 1.6,
        fontSize: size.sm,
        top: 0,

        '~ .MuiInputBase-root .MuiOutlinedInput-notchedOutline legend': {
          fontSize: '0.72em',
        },
      },

      top: 1.5,
    },
  },
};

export default inputLabel;
