import borders from 'assets/theme/base/borders';
import colors from 'assets/theme/base/colors';

const tableRow = {
  styleOverrides: {
    root: {
      borderBottom: `${borders.borderWidth[1]} solid ${colors.light.main}`,

      '&:hover': {
        cursor: 'pointer',
      },
      '&.Mui-selected': {
        backgroundColor: colors.grey[100],
        '&:hover': {
          backgroundColor: colors.grey[100],
        },
      },
    },
  },
};

export default tableRow;
