import { Box, Link, Stack } from '@mui/material';

import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

const LINKS = ['Terms of Service', 'Privacy Policy'];

type FooterProps = {
  light?: boolean;
};

function Footer({ light }: FooterProps): JSX.Element {
  const year = new Date().getFullYear();
  return (
    <MDBox
      width={1}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      alignItems="center"
      px={1.5}
      sx={{ textAlign: 'center' }}
    >
      <Stack direction="row">
        {LINKS.map((link, index) => (
          <Stack key={link} direction="row" alignItems="center">
            <Box
              color="grey.600"
              fontWeight={400}
              fontSize={14}
              component={Link}
              href="#"
              sx={{
                ':hover': {
                  opacity: 0.75,
                },
              }}
            >
              {link}
            </Box>
            {index !== LINKS.length - 1 && (
              <Box
                component="span"
                sx={{ mx: 1.5, width: 5, height: 5, bgcolor: theme => theme.palette.dark.main, borderRadius: '50%' }}
              />
            )}
          </Stack>
        ))}
      </Stack>

      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
        color={light ? 'white' : 'text'}
        width={1}
        sx={{ fontSize: 14 }}
      >
        <MDTypography variant="caption" fontWeight={400} sx={{ color: 'grey.500' }}>
          &copy; {year} Aesthetics360, LLC. All rights reserved.
        </MDTypography>
      </MDBox>
    </MDBox>
  );
}

export default Footer;
