import { FC } from 'react';

import { Divider, Stack } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import { RHFTextField } from 'components/HookForm';

import { CreateBAalbumFormSchema } from './form.config';

export const ProfileFormSection: FC = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext<CreateBAalbumFormSchema>();

  return (
    <>
      <Stack p={{ xs: 2, sm: 2.5, md: 3, lg: 3.5 }}>
        <Stack spacing={3} maxWidth={700}>
          <RHFTextField
            register={register}
            registerErrors={errors?.name?.message}
            registerName="name"
            label="Name"
            required
            placeholder="Enter name"
          />

          <RHFTextField
            register={register}
            registerErrors={errors?.description?.message}
            registerName="description"
            label="Description"
            placeholder="Enter description"
            multiline
            minRows={5}
            maxRows={8}
          />
        </Stack>
      </Stack>
      <Divider />
    </>
  );
};
