import { FC, useEffect, useState } from 'react';

import { CircularProgressWithLabel } from './CircularProgressWithLabel';

type CircularWithValueLabelProps = {
  timerValue: number;
  startTimerValue: number;
};
const normalise = (value: number, startTimerValue: number) => (value * 100) / startTimerValue;

export const CircularWithValueLabel: FC<CircularWithValueLabelProps> = ({ timerValue, startTimerValue }) => {
  const [progress, setProgress] = useState(100);

  useEffect(() => {
    const progress = normalise(timerValue, startTimerValue);

    setProgress(progress);
  }, [timerValue]);

  return <CircularProgressWithLabel value={progress} valueToDisplay={timerValue} />;
};
