import { FC, useState } from 'react';

import StarIcon from '@mui/icons-material/Star';
import { Box, Rating, Stack } from '@mui/material';

import MDTypography from 'components/MDTypography';

interface RatingItemProps {
  ratingValue: number;
  name: string;
  onChange: (newValue: number | null) => void;
}

const getRatingLabel = (value: number) => {
  if (value === 0) return '';
  if (value >= 4.5) return 'Perfect';
  if (value >= 4) return 'Excellent';
  if (value >= 3) return 'Good';
  if (value >= 2) return 'Fair';
  if (value >= 1.5) return 'Bad';
  return 'Very Bad';
};

export const RatingItem: FC<RatingItemProps> = ({ ratingValue, name, onChange }) => {
  const [hover, setHover] = useState<number>(-1);

  return (
    <Stack direction="row" alignItems="center" spacing={2} sx={{ width: '100%' }}>
      <MDTypography variant="body2" sx={{ minWidth: '120px' }}>
        {name}
      </MDTypography>
      <Rating
        name="hover-feedback"
        value={ratingValue}
        precision={0.5}
        onChange={(event, newValue) => {
          onChange(newValue);
        }}
        onChangeActive={(event, newHover) => {
          setHover(newHover);
        }}
        emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
        sx={{ width: 'auto' }}
      />
      {((hover !== -1 && hover > 0) || ratingValue > 0) && (
        <MDTypography variant="body2">{getRatingLabel(hover !== -1 ? hover : ratingValue)}</MDTypography>
      )}
    </Stack>
  );
};
