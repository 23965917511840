import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';

export type TextFieldOwnerState = {
  disabled?: boolean;
};

export default styled(TextField)<{ ownerState: TextFieldOwnerState }>(({ theme, ownerState }) => {
  const { borders } = theme;
  const { disabled } = ownerState;

  return {
    borderWidth: 2,
    pointerEvents: disabled ? 'none' : 'auto',
    borderRadius: borders.borderRadius.lg,
  };
});
