import { FC, useState } from 'react';

import { CheckRounded, CloseRounded } from '@mui/icons-material';
import { Box, Dialog, DialogActions, DialogContent, Divider, Slider, Stack, Typography } from '@mui/material';
import Cropper from 'react-easy-crop';
import { Point } from 'react-easy-crop/types';

import { DialogHeader, ZoomCropDialogWindowProps } from 'components/BaseDialogWindow';
import { Icon } from 'components/Icon';
import MDButton from 'components/MDButton';
import { UploadFileButton } from 'components/UploadFileButton';

export const ZoomCropDialogWindow: FC<ZoomCropDialogWindowProps> = ({
  cropShape = 'round',
  image,
  onClickCloseButtonHandler,
  onUploadNewFileHandler,
  isLoading,
  onSaveFileHandler,
  ...props
}) => {
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);

  const onChangeZoomHandler = (event: Event, value: number | number[], activeThumb: number) => setZoom(Number(value));

  const onClickUploadNewFileButton = (event: React.ChangeEvent<HTMLInputElement>) => {
    onUploadNewFileHandler(event);
    if (zoom > 1) setZoom(1);
  };

  return (
    <Dialog onClose={onClickCloseButtonHandler} PaperProps={{ sx: { maxWidth: 550, width: 1 } }} {...props}>
      <Stack px={3}>
        <DialogHeader
          isHideDivider
          sx={{ px: 0 }}
          onClickCancelButtonHandler={onClickCloseButtonHandler}
          title="Add Photo"
        />

        <DialogContent sx={{ px: 0, pt: 0, pb: 0.5, overflow: 'visible' }}>
          <Stack
            position="relative"
            height={370}
            width={1}
            sx={{
              '.reactEasyCrop_CropArea': {
                color: 'rgba(0, 0, 0, 0.3)',
              },
            }}
          >
            <Cropper
              image={image}
              crop={crop}
              zoom={zoom}
              aspect={1}
              onCropChange={setCrop}
              cropShape={cropShape}
              restrictPosition={true}
              showGrid={false}
              onZoomChange={setZoom}
            />
            <Box
              sx={{
                borderRadius: theme => theme.borders.borderRadius.lg,
                position: 'absolute',
                bottom: 10,
                left: '50%',
                transform: 'translateX(-50%)',
                p: 1,
                bgcolor: 'rgba(24, 34, 48, 0.3)',
              }}
            >
              <Stack direction="row" alignItems="center" spacing={1} color={theme => theme.palette.common.white}>
                <Icon
                  type="moveIcon"
                  sx={{
                    svg: { width: 20, height: 20 },
                  }}
                />
                <Typography variant="caption" fontSize={14} fontWeight={500}>
                  Move
                </Typography>
              </Stack>
            </Box>
          </Stack>
          <Stack mt={2} spacing={1} position="relative">
            <Typography variant="body2" fontWeight={400}>
              Zoom
            </Typography>
            <Box px={0.5}>
              <Slider
                value={zoom}
                min={1}
                max={3}
                step={0.1}
                aria-labelledby="Zoom"
                valueLabelDisplay="off"
                onChange={onChangeZoomHandler}
              />
            </Box>
          </Stack>
        </DialogContent>

        <Divider orientation="horizontal" />
        <DialogActions sx={{ px: 0 }}>
          <Stack
            width={1}
            direction={{ xs: 'column', sm: 'row' }}
            alignItems="center"
            justifyContent="space-between"
            gap={1}
          >
            <MDButton
              onClick={onClickCloseButtonHandler}
              variant="outlined"
              color="dark"
              startIcon={<CloseRounded sx={{ fontSize: '16px !important' }} />}
            >
              Cancel
            </MDButton>

            <Stack direction={{ xs: 'column', sm: 'row' }} alignItems="center" justifyContent="space-between" gap={1}>
              <UploadFileButton
                onChangeHandler={onClickUploadNewFileButton}
                startIcon={<Icon type="refreshIcon" />}
                color="dark"
                disabled={isLoading}
              >
                Change photo
              </UploadFileButton>
              <MDButton
                isLoading={isLoading}
                disabled={isLoading}
                startIcon={<CheckRounded sx={{ fontSize: '16px !important' }} />}
                onClick={onSaveFileHandler}
              >
                Save
              </MDButton>
            </Stack>
          </Stack>
        </DialogActions>
      </Stack>
    </Dialog>
  );
};
