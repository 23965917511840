import Avatar from '@mui/material/Avatar';
import { CSSObject, styled } from '@mui/material/styles';

import avatarFallback from 'assets/icons/components/avatar_fallback_icon.svg';

export const AVATAR_SIZES_MAP = {
  xs: { width: 24, fontSize: 12 },
  sm: { width: 32, fontSize: 14 },
  md: { width: 40, fontSize: 16 },
  lg: { width: 48, fontSize: 18 },
  xl: { width: 56, fontSize: 20 },
  '2xl': { width: 64, fontSize: 24 },
  '3xl': { width: 82, fontSize: 28 },
};

export type AvatarOwnerState = {
  avatarSize?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl';
};

export default styled(Avatar)<{ ownerState: AvatarOwnerState }>(({ theme: { palette }, ownerState }) => {
  const sizeValue = AVATAR_SIZES_MAP[ownerState.avatarSize || 'md'];

  const baseStyles: CSSObject = {
    width: sizeValue.width,
    height: sizeValue.width,
    backgroundColor: palette.grey[200],
    border: `1px solid ${palette.grey[300]}`,
    color: palette.grey[600],
    transition: 'all 200ms ease-in-out',
    fontWeight: 500,
    fontSize: sizeValue.fontSize,

    '& .MuiAvatar-fallback': {
      background: `url(${avatarFallback}) center no-repeat`,
      backgroundSize: '100% 100%',
      path: {
        display: 'none',
      },
    },
  };

  return baseStyles;
});
