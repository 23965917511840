import { FC, memo, useEffect } from 'react';

import { Stack } from '@mui/material';

import { useMemoizedAutocompleteFunctions } from 'hooks/useMemoizedAutocompleteFunctions';
import { usePatientEnums } from 'hooks/usePatientEnums';

import { PatientCard } from './PatientCard';
import { PatientContactCard } from './PatientContactCard';
import { PatientProfileCard } from './PatientProfileCard';
import { EditPatientFormProps } from './types';

export const EditPatientForm: FC<EditPatientFormProps> = memo(
  ({ allergies: patientAllergies, refs, fetchPatient, patientId, medicalConditions: patientMedicalConditions }) => {
    const { isAllPatientEnumsLoading, fetchAllPatientEnums, patientEnums } = usePatientEnums();

    useEffect(() => {
      fetchAllPatientEnums();
    }, []);

    const {
      memoizedAddressGetOptionLabel,
      memoizedAddressIsOptionEqualToValue,
      memoizedCityGetOptionLabel,
      memoizedCityIsOptionEqualToValue,
      memoizedGetOptionLabel,
      memoizedIsOptionEqualToValue,
    } = useMemoizedAutocompleteFunctions();

    return (
      <Stack width={1} spacing={2} mb={2}>
        <PatientProfileCard
          cardRef={refs?.[0]}
          ethnicGroups={patientEnums?.ethnicGroups}
          genders={patientEnums?.genders}
          personTitles={patientEnums?.personTitles}
          memoizedGetOptionLabel={memoizedGetOptionLabel}
          memoizedIsOptionEqualToValue={memoizedIsOptionEqualToValue}
          isAllPatientEnumsLoading={isAllPatientEnumsLoading}
        />

        <PatientContactCard
          cardRef={refs?.[1]}
          memoizedAddressGetOptionLabel={memoizedAddressGetOptionLabel}
          memoizedCityGetOptionLabel={memoizedCityGetOptionLabel}
          memoizedAddressIsOptionEqualToValue={memoizedAddressIsOptionEqualToValue}
          memoizedCityIsOptionEqualToValue={memoizedCityIsOptionEqualToValue}
        />

        <PatientCard
          fetchPatient={fetchPatient}
          patientAllergies={patientAllergies}
          patientId={patientId}
          bioGenders={patientEnums?.bioGenders}
          bloodTypes={patientEnums?.bloodTypes}
          painTolerances={patientEnums?.painTolerances}
          cardRef={refs?.[2]}
          isAllPatientEnumsLoading={isAllPatientEnumsLoading}
          patientMedicalConditions={patientMedicalConditions}
          memoizedGetOptionLabel={memoizedGetOptionLabel}
          memoizedIsOptionEqualToValue={memoizedIsOptionEqualToValue}
        />
      </Stack>
    );
  }
);
