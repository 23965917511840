import { FC } from 'react';

import { MenuItem, MenuItemProps } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';

import MDTypography from 'components/MDTypography';

export type PopoverMenuItemProps = MenuItemProps & {
  title: string;
  titleVariant?: Variant;
  icon?: JSX.Element;
  itemId?: string;
};

export const PopoverMenuItem: FC<PopoverMenuItemProps> = ({
  title,
  icon,
  titleVariant = 'subtitle2',
  itemId,
  sx,
  ...rest
}) => {
  return (
    <MenuItem sx={sx} id={itemId} {...rest}>
      {icon && icon}

      <MDTypography
        variant={titleVariant}
        sx={{ fontSize: 14, fontWeight: 400, ml: icon ? 1 : 0, mr: '2px', whiteSpace: 'initial' }}
      >
        {title}
      </MDTypography>
    </MenuItem>
  );
};
