import { FC } from 'react';

import { NavList } from './NavList';
import { INavSubListProps } from './navList.types';

export const NavSubList: FC<INavSubListProps> = props => {
  const { data, depth, isNavMini } = props;
  return (
    <>
      {data.map(list => (
        <NavList
          key={list.title + list.path}
          isNavMini={isNavMini}
          data={list}
          depth={depth + 1}
          hasChild={!!list.children}
        />
      ))}
    </>
  );
};
