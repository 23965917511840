import * as yup from 'yup';

import { emailRegExp } from 'constants/regex';

export type RecoveryPasswordFormSchema = {
  email: string;
};

export const recoveryPasswordValidationFormSchema: yup.ObjectSchema<RecoveryPasswordFormSchema> = yup
  .object({
    email: yup.string().required().matches(emailRegExp, 'Please enter a valid email').label('Email'),
  })
  .required();
