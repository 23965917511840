/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// Material Dashboard 2 PRO React TS Base Styles
import borders from 'assets/theme/base/borders';
import colors from 'assets/theme/base/colors';

const tableHead = {
  styleOverrides: {
    root: {
      borderBottom: `${borders.borderWidth[1]} solid ${colors.light.main}`,
      'tr:first-of-type': {
        cursor: 'inherit',
        '&:hover': {
          boxShadow: 'none',
        },
      },
    },
  },
};

export default tableHead;
