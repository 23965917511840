/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// Material Dashboard 2 PRO React TS Base Styles
import { AvatarClasses, AvatarProps, Theme } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';

const avatar: {
  defaultProps?: Partial<AvatarProps>;
  styleOverrides: Partial<OverridesStyleRules<keyof AvatarClasses, 'MuiAvatar', Omit<Theme, 'components'>>>;
} = {
  styleOverrides: {
    root: {
      transition: 'all 200ms ease-in-out',
    },

    rounded: {
      borderRadius: '50%',
    },

    img: {
      height: 'auto',
    },
  },
};

export default avatar;
