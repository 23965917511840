import { BASE_PATIENTS_API_URL } from 'apiServices/api.routes';
import { apiClient } from 'lib/api';

import { Patient, PutBillingAddress, PutHomeAddress, UpdatePatientContactPayload } from './types';

export const updatePatientContacts = async (patientId: string, payload: UpdatePatientContactPayload) =>
  await apiClient.patch<Patient>(`${BASE_PATIENTS_API_URL}/${patientId}/contacts`, payload);

export const putPatientHomeAddress = async (patientId: string, payload: PutHomeAddress) =>
  await apiClient.put<Patient>(`${BASE_PATIENTS_API_URL}/${patientId}/contacts/home_address`, payload);

export const deletePatientHomeAddress = async (patientId: string) =>
  await apiClient.delete(`${BASE_PATIENTS_API_URL}/${patientId}/contacts/home_address`);

export const putPatientBillingAddress = async (patientId: string, payload: PutBillingAddress) =>
  await apiClient.put<Patient>(`${BASE_PATIENTS_API_URL}/${patientId}/contacts/billing_address`, payload);

export const deletePatientBillingAddress = async (patientId: string) =>
  await apiClient.delete(`${BASE_PATIENTS_API_URL}/${patientId}/contacts/billing_address`);
