import { FC } from 'react';

import { Badge, Box, Icon, Stack, Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';

import { sideNavMenuItemStyles } from './BaseSideNavMenu.styles';

type SideNavMenuItemProps = {
  index?: number;
  href: string;
  badgeContent?: React.ReactNode;
  label: string;
  isDisabled?: boolean;
  isActive: boolean;
  icon?: string | JSX.Element;
};

export const SideNavMenuItem: FC<SideNavMenuItemProps> = ({
  index,
  href,
  badgeContent,
  label,
  isActive,
  isDisabled,
  icon,
}) => {
  return (
    <Box component="li" pt={index === 0 ? 0 : 1}>
      <Stack
        component={NavLink}
        to={href}
        direction="row"
        spacing={{ xs: 1.5, sm: 1 }}
        sx={theme => sideNavMenuItemStyles({ theme, isDisabled, isActive })}
      >
        {icon && (
          <Badge badgeContent={badgeContent} color="error">
            {typeof icon === 'string' ? <Icon fontSize="small">{icon}</Icon> : icon}
          </Badge>
        )}
        <Typography variant="button" fontWeight="regular" textTransform="capitalize" noWrap>
          {label}
        </Typography>
      </Stack>
    </Box>
  );
};
