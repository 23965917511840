import { FC } from 'react';

import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';

import { LegalDocumentsDialogWindow } from 'components/BaseDialogWindow';
import { Breadcrumbs, MobileBreadCrumbs } from 'components/Breadcrumbs';
import { NavVertical } from 'components/NavVertical';
import { SIDE_NAVIGATION } from 'constants/layout-config';

import { ContentWrapper } from './ContentWrapper';
import { MainLayoutStyles as Styled } from './MainLayout.styles';
import { useMainLayoutState } from './useMainLayout.state';

export const MainLayout: FC = () => {
  const { handleClose, handleOpen, isMobile, isNavMini, isOpenMobileNavBar, isTablet } = useMainLayoutState();

  return (
    <Box sx={Styled.BoxSX}>
      <NavVertical isTabletScreen={isTablet} onCloseSideNav={handleClose} isOpenMobileNavBar={isOpenMobileNavBar} />

      <Box
        display="flex"
        flexDirection="column"
        flexGrow={1}
        width={1}
        overflow="clip"
        sx={{
          width: `calc(100% - ${SIDE_NAVIGATION.W_DASHBOARD}px)`,
          ...(isNavMini && {
            width: `calc(100% - ${SIDE_NAVIGATION.W_DASHBOARD_MINI}px)`,
          }),
          ...(isTablet && {
            width: 1,
          }),
        }}
      >
        <Styled.Header component="header">
          {isMobile ? (
            <MobileBreadCrumbs
              isMobileScreen={isMobile}
              isOpenMobileNavBar={isOpenMobileNavBar}
              onOpenSideNav={handleOpen}
            />
          ) : (
            <Breadcrumbs isTabletScreen={isTablet} isOpenMobileNavBar={isOpenMobileNavBar} onOpenSideNav={handleOpen} />
          )}
        </Styled.Header>
        <ContentWrapper>
          <Outlet />
        </ContentWrapper>
      </Box>

      <LegalDocumentsDialogWindow />
    </Box>
  );
};
