/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// @mui material components
import Fade from '@mui/material/Fade';

import borders from 'assets/theme/base/borders';
import colors from 'assets/theme/base/colors';
import typography from 'assets/theme/base/typography';
import pxToRem from 'assets/theme/functions/pxToRem';

const { grey, white } = colors;
const { size, fontWeightMedium } = typography;
const { borderRadius } = borders;

// types
type Types = any;

const tooltip: Types = {
  defaultProps: {
    arrow: true,
    TransitionComponent: Fade,
  },

  styleOverrides: {
    tooltip: {
      maxWidth: pxToRem(200),
      backgroundColor: grey[600],
      color: white.main,
      fontSize: size.xs,
      fontWeight: fontWeightMedium,
      textAlign: 'center',
      borderRadius: borderRadius.lg,
      marginBottom: '8px !important',
      padding: `${pxToRem(5)} ${pxToRem(8)} ${pxToRem(5)}`,
    },

    arrow: {
      color: grey[600],
      '&::before': {
        borderRadius: 1,
      },
    },
  },
};

export default tooltip;
