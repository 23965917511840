import React, { FC, memo, useRef, useState } from 'react';

import MoreHorizOutlined from '@mui/icons-material/MoreHorizOutlined';
import {
  Box,
  ButtonGroup,
  Checkbox,
  ClickAwayListener,
  MenuList,
  Paper,
  Popper,
  Stack,
  Typography,
  alpha,
} from '@mui/material';

import previewImage from 'assets/illustrations/before_after_card_image.png';
import { Icon, TIconType } from 'components/Icon';
import MDButton from 'components/MDButton';
import { PopoverMenuItem } from 'components/MenuPopover';
import { ToastType, notice } from 'components/ToastNotification';
import { usePopover } from 'hooks/usePopover';
import { useRouter } from 'hooks/useRouter';
import { TOTP_MFA_verification_code_validation_schema } from 'utils/formValidation';

type MenuItem = {
  icon: JSX.Element;
  title: string;
  onClick?: () => void; // optional click handler for the item
};

type CardItemProps = {
  isActive?: boolean;
  type: LibraryType;
  //TODO make required
  id?: string;
  onChangeCheckboxHandler?: (id: string, checked: boolean) => void;
  onClickHandler?: () => void;
  menuItems?: MenuItem[];
  checkedIds?: Set<string>;
};

export const CardItem: FC<CardItemProps> = memo(
  ({ checkedIds, onChangeCheckboxHandler, onClickHandler, isActive, type, id, menuItems }) => {
    const { handleClosePopover, handleOpenPopover, openPopover } = usePopover();

    const [isHover, setIsHover] = useState(false);

    const imageRef = useRef<HTMLDivElement | null>(null);

    const handleMouseEnter = () => setIsHover(true);
    const handleMouseLeave = () => setIsHover(false);

    const handleClickAway = (event: MouseEvent | TouchEvent) => {
      if (openPopover) handleClosePopover();
      if (imageRef.current && !imageRef.current.contains(event.target as Node)) {
        setIsHover(false);
      }
    };

    const handleDotsMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      openPopover ? handleClosePopover() : handleOpenPopover(event);
    };

    const renderButtonGroup = () => (
      <ClickAwayListener onClickAway={handleClickAway}>
        <ButtonGroup
          size="small"
          sx={theme => ({
            transition: theme.transitions.create('all', {
              duration: theme.transitions.duration.short,
              easing: theme.transitions.easing.easeOut,
            }),
            '& button': {
              maxWidth: 45,
              bgcolor: theme.palette.common.white,
              ':hover': {
                opacity: 1,
                bgcolor: alpha(theme.palette.common.white, 0.9),
              },
            },
            opacity: isHover || openPopover ? 1 : 0,
            position: 'absolute',
            top: 10,
            right: 10,
          })}
        >
          <MDButton size="small" variant="outlined" color="dark">
            <Icon type="editPen" />
          </MDButton>
          <MDButton size="small" variant="outlined" color="dark" onClick={handleDotsMenuClick}>
            <MoreHorizOutlined fontSize="medium" />
          </MDButton>
        </ButtonGroup>
      </ClickAwayListener>
    );

    const renderPopoverMenu = () => (
      <Popper
        open={!!openPopover}
        anchorEl={openPopover}
        disablePortal
        placement="bottom-end"
        sx={{ zIndex: theme => theme.zIndex.tooltip }}
      >
        <Paper sx={{ p: 1 }}>
          <MenuList>
            <PopoverMenuItem icon={<Icon type="add" />} title="Add before & after photos" />
            <PopoverMenuItem icon={<Icon type="copy" />} title="Clone" />
            <PopoverMenuItem
              icon={<Icon type={isActive ? 'eyeClosed' : 'eyeOpened'} />}
              title={isActive ? 'Deactivate' : 'Activate'}
            />
            <PopoverMenuItem icon={<Icon type="trashBin" fontSize="medium" />} title="Delete" />
          </MenuList>
        </Paper>
      </Popper>
    );

    const { navigate } = useRouter();

    const onClickCardHandler = (e: any) => {
      if (openPopover) return;
      if (onClickHandler) return onClickHandler();

      navigate(`/before-after/${type}/Facial-Rejuvenation`);
    };

    const onChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      event.stopPropagation();

      onChangeCheckboxHandler(event.currentTarget.id, checked);
    };

    return (
      <Stack spacing={1} onClick={onClickCardHandler}>
        <Box
          ref={imageRef}
          height={210}
          sx={{
            position: 'relative',
            ':hover': {
              cursor: 'pointer',
              ...(isHover && {
                '& .MuiButtonGroup-root, .MuiCheckbox-root': {
                  opacity: 1,
                },
              }),
            },
            ...(!isActive && {
              filter: 'grayscale(1)',
            }),
          }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <Box
            height={1}
            width={1}
            component="img"
            loading="lazy"
            alt="before & after image"
            borderRadius={theme => theme.borders.borderRadius.lg}
            sx={{ objectFit: 'cover' }}
            src={previewImage}
          />
          {onChangeCheckboxHandler && (
            <Checkbox
              id={id}
              checked={checkedIds.has(id)}
              onChange={onChangeCheckbox}
              onClick={event => event.stopPropagation()}
              // onMouseDown={(event) => event.stopPropagation()}
              sx={{
                opacity: 0,
                bgcolor: theme => theme.palette.common.white,
                position: 'absolute',
                zIndex: 100,
                top: 8,
                left: 8,
              }}
            />
          )}
          {renderButtonGroup()}
          {renderLinkedItems(isActive)}
        </Box>
        <Typography variant="body2" fontWeight={500}>
          Facial Rejuvenation
        </Typography>
        <Typography variant="body2" color="text.main" fontWeight={400} fontSize={12}>
          Facial Rejuvenation
        </Typography>
        {renderPopoverMenu()}
      </Stack>
    );
  }
);

const renderLinkedItems = (isActive: boolean) => (
  <Stack
    sx={{ position: 'absolute', bottom: 8, right: 8 }}
    direction="row"
    justifyContent="space-between"
    alignItems="center"
    spacing={1}
  >
    {renderLinkedItem(isActive, 'linkedBuilding', '2')}
    {renderLinkedItem(isActive, 'linkedMedical', '3')}
  </Stack>
);

const renderLinkedItem = (isActive: boolean, iconType: TIconType, text: string) => (
  <Box
    sx={{
      backdropFilter: 'blur(24px)',
      borderRadius: theme => theme.borders.borderRadius.lg,
      p: 1,
      color: theme => (isActive ? theme.palette.common.white : theme.palette.grey[400]),
    }}
  >
    <Stack direction="row" alignItems="center" spacing={1}>
      <Icon
        sx={{ color: theme => (isActive ? theme.palette.common.white : theme.palette.grey[400]) }}
        type={iconType}
      />
      <Typography variant="button" fontWeight={500}>
        {text}
      </Typography>
    </Stack>
  </Box>
);
