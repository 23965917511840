import { FC } from 'react';

import { KeyboardArrowDownRounded, KeyboardArrowUpRounded } from '@mui/icons-material';
import { Box, Link, ListItemText, Tooltip } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import { createTransition } from 'components/NavVertical/helpers';

import { StyledDotIcon, StyledIcon, StyledItem } from './NavItem.styles';
import { INavItemProps } from './navItem.types';

export const NavItem: FC<INavItemProps> = props => {
  const { item, depth, open, active, isExternalLink, isNavMini, ...other } = props;

  const { title, path, icon, info, children, disabled, caption } = item;

  const subItem = depth !== 1;

  const renderContent = (
    <Tooltip title={isNavMini ? title : ''} placement="right">
      <StyledItem
        isNavMini={isNavMini}
        depth={depth}
        active={active}
        disabled={disabled}
        caption={!!caption}
        {...other}
      >
        {icon && (
          <StyledIcon isNavMini={isNavMini} active={active && !!icon}>
            {icon}
          </StyledIcon>
        )}

        {subItem && (
          <StyledIcon>
            <StyledDotIcon active={active && subItem} />
          </StyledIcon>
        )}

        <ListItemText
          sx={{
            my: 0,
            lineHeight: 1,
            transition: theme => createTransition(theme, 'all'),
            ...(isNavMini && { flex: 0, overflow: 'hidden' }),
          }}
          primary={title}
          secondary={
            caption && (
              <Tooltip title={caption} placement="top-start">
                <span>{caption}</span>
              </Tooltip>
            )
          }
          primaryTypographyProps={{
            noWrap: true,
            component: 'span',
            variant: active ? 'subtitle2' : 'body2',
            fontWeight: 400,
            fontSize: 14,
          }}
          secondaryTypographyProps={{
            noWrap: true,
            variant: 'caption',
          }}
        />

        {info && !isNavMini && (
          <Box component="span" sx={{ lineHeight: 0 }}>
            {info}
          </Box>
        )}

        {!!children && (
          <Box width={16} ml={1} flexShrink={0}>
            {open ? <KeyboardArrowDownRounded /> : <KeyboardArrowUpRounded />}
          </Box>
        )}
      </StyledItem>
    </Tooltip>
  );

  const renderItem = () => {
    if (children) {
      return renderContent;
    }

    return (
      <Link component={RouterLink} to={path.path} underline="none">
        {renderContent}
      </Link>
    );
  };

  return renderItem();
};
