import { apiClient } from 'lib/api';

import { BASE_PROMPTS_API_URL } from '../api.routes';
import { PromptVersionItemResponse, PromptVersionPatchRequest, PromptVersionPostRequest } from './types';

export const getPromptVersions = (promptId: string) => {
  return apiClient.get<PromptVersionItemResponse[]>(`${BASE_PROMPTS_API_URL}/${promptId}/versions`);
};

export const addPromptVersion = (promptId: string, data: PromptVersionPostRequest) => {
  return apiClient.post<PromptVersionItemResponse>(`${BASE_PROMPTS_API_URL}/${promptId}/versions`, data);
};

export const updatePromptVersion = (promptId: string, version: number, data: PromptVersionPatchRequest) => {
  return apiClient.patch<PromptVersionItemResponse>(`${BASE_PROMPTS_API_URL}/${promptId}/versions/${version}`, data);
};

export const getPromptVersion = (promptId: string, version: number) => {
  return apiClient.get<PromptVersionItemResponse>(`${BASE_PROMPTS_API_URL}/${promptId}/versions/${version}`);
};

export const deletePromptVersion = (versionId: string) => {
  return apiClient.delete(`${BASE_PROMPTS_API_URL}/delete-version/${versionId}`);
};
