import { FC } from 'react';

import { Grid } from '@mui/material';

import { SideNavFormMenu } from 'components/BaseSideNavMenu';
import { FormActions } from 'components/Form';
import { FormProvider } from 'components/HookForm';
import { ROUTES } from 'constants/routes';

import { EditPatientForm } from './components';
import { CONTACT_ID, DEMOGRAPHICS_ID } from './constants';
import { useProfileState } from './useProfile.state';

export const Profile: FC = () => {
  const {
    formMethods,
    refs,
    activeSectionId,
    contactEntry,
    demographicsEntry,
    executeScroll,
    isSubmitButtonDisabled,
    isSubmitting,
    onFormSubmitHandler,
    patientId,
    fetchPatient,
    isDirty,
  } = useProfileState();

  return (
    <>
      <Grid
        item
        xs={12}
        sm={2}
        sx={({ borders, breakpoints }) => ({
          borderRight: `${borders.borderWidth[1]} solid ${borders.borderColor}`,
          borderBottom: 0,
          [breakpoints.down('sm')]: {
            borderRight: 0,
            borderBottom: `${borders.borderWidth[1]} solid ${borders.borderColor} `,
          },
        })}
      >
        <SideNavFormMenu
          activeSectionId={activeSectionId}
          onClick={executeScroll}
          sidenavItems={[
            {
              label: 'Demographics',
              id: DEMOGRAPHICS_ID,
              entry: demographicsEntry,
            },

            {
              label: 'Contact',
              id: CONTACT_ID,
              entry: contactEntry,
            },
            // TODO: Add note card when BE is ready
            /*   {
    label: "Note",
    isActive: activeCard === PROFILE_FORM_CARDS.NOTE,
    href: `#${NOTE_ID}`,
  }, */
          ]}
        />
      </Grid>
      <Grid item xs={12} sm={10}>
        <FormProvider methods={formMethods} onSubmit={onFormSubmitHandler}>
          <EditPatientForm refs={refs} patientId={patientId} fetchPatient={fetchPatient} />
        </FormProvider>

        <FormActions
          isDisabled={isSubmitButtonDisabled}
          isLoading={isSubmitting}
          onFormSubmitHandler={onFormSubmitHandler}
          isDirtyForm={isDirty}
          backRoute={ROUTES.patients}
        />
      </Grid>
    </>
  );
};
