import * as yup from 'yup';

export const CreatePromptValidationSchema = yup.object().shape({
  name: yup.string().required('Name is required').max(100, 'Name must be at most 100 characters'),
  text: yup.string().required('Text is required'),
  comment: yup.string().optional(),
});

export const defaultPromptValues = {
  name: 'Prompt Name',
  text: 'Prompt Text',
  comment: '',
};
