import { FC, memo } from 'react';

import { TableCell, TableRow, Typography } from '@mui/material';
import { PracticeListItem } from 'apiServices';

import { ThreeDotsTableCell } from 'components/Table';
import { MM_DD_YYYY_DATE_FORMAT } from 'constants/dateFormats';
import { usePopover } from 'hooks/usePopover';
import { useRouter } from 'hooks/useRouter';
import { dateToCustomFormat } from 'utils/helpers';

import { ActivationDataInitialState } from '../types';

type PracticesTableRowProps = {
  practice: PracticeListItem;
  onClickActivateDeactivateOptionHandler: (activationStateData: ActivationDataInitialState) => void;
};

export const PracticesTableRow: FC<PracticesTableRowProps> = memo(
  ({ practice, onClickActivateDeactivateOptionHandler }) => {
    const { contactPersonsCount, createdAt, isActive, id, name, nameShort, officesCount, servicesCount, updatedAt } =
      practice;

    const { navigate } = useRouter();

    const onClickRowHandler = () => navigate(`/practice-management/${id}`);

    const formattedCreatedAtDate = dateToCustomFormat(createdAt, MM_DD_YYYY_DATE_FORMAT);

    const formattedUpdatedAtDate = dateToCustomFormat(updatedAt, MM_DD_YYYY_DATE_FORMAT);

    const { handleClosePopover, handleOpenPopover, openPopover } = usePopover();

    const onCloseThreeDotsMenuHandler = (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      handleClosePopover();
    };

    const onClickThreeDotsMenuButtonHandler = (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();

      handleOpenPopover(event);
    };

    const onClickActivityButtonHandler = (event: React.MouseEvent<HTMLLIElement>) => {
      event.stopPropagation();

      handleClosePopover();

      onClickActivateDeactivateOptionHandler({
        practiceId: id,
        practiceName: name,
        activationStatus: isActive ? 'deactivate' : 'activate',
      });
    };

    const onClickPaperHandler = (event: React.MouseEvent<HTMLDivElement>) => event?.stopPropagation();

    return (
      <TableRow
        onClick={onClickRowHandler}
        sx={{
          ...(!isActive && {
            bgcolor: theme => theme.palette.grey[100],
          }),
        }}
      >
        <TableCell>
          <Typography variant="body2">{name}</Typography>
        </TableCell>

        <TableCell>
          <Typography variant="body2">{nameShort || '-'}</Typography>
        </TableCell>

        <TableCell>
          <Typography variant="body2">{contactPersonsCount}</Typography>
        </TableCell>

        <TableCell>
          <Typography variant="body2">{servicesCount}</Typography>
        </TableCell>

        <TableCell>
          <Typography variant="body2">{officesCount}</Typography>
        </TableCell>

        <TableCell>
          <Typography variant="body2">{formattedCreatedAtDate}</Typography>
        </TableCell>

        <TableCell>
          <Typography variant="body2">{formattedUpdatedAtDate || '-'}</Typography>
        </TableCell>

        <ThreeDotsTableCell
          open={openPopover}
          onClickButtonHandler={onClickThreeDotsMenuButtonHandler}
          slotProps={{
            paper: { onClick: onClickPaperHandler },
          }}
          menuItems={[
            {
              title: isActive ? 'Deactivate' : 'Activate',
              onClick: onClickActivityButtonHandler,
            },
          ]}
          onClose={onCloseThreeDotsMenuHandler}
        />
      </TableRow>
    );
  }
);
